import React, {} from 'react';
import MetaTags from 'react-meta-tags';
import ConfirmRegisteredSignature from './resource/confirm/confirmRegisteredSignature';


const DocumentSignatureViewDocPlace = (props) => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DocPlace eSignature</title>
                </MetaTags>
            </div>
          <ConfirmRegisteredSignature
            show={props.show}
            close={props.close}
          />
        </React.Fragment>
    );
}

export default DocumentSignatureViewDocPlace
