import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import React from "react";

import { RequestSignatures, SpanSignature, TitleSignature } from "./styles";
import SignatureCard from "./resource/signatureCard";
import SpinnerSkeleton from "../../../../components/spenner";

export default function SignatureDrawerListView(props) {
  const onPlaceSignatures = () => {
    props.onPlaceSignature();
  };
  
  const resendRequest = (email) => {
    props.resendRequest(email);
  };

  return (
    <Box
      sx={{
        width: "450px",
        padding: "15px",
        position: "relative",
      }}
    >
      <TitleSignature>Signatures</TitleSignature>
      {props.loading && <SpinnerSkeleton />}

      {props.hasSignatures?.data?.length === 0 ||
      props.signatures?.signers?.length === 0 ? (
        <SpanSignature>
          It seems that you haven't requested any signature for this document.
          Please click the 'Request Signature' button to request signatures.
        </SpanSignature>
      ) : (
        ""
      )}

      <RequestSignatures onClick={onPlaceSignatures}>
        Request signatures
      </RequestSignatures>
      <Divider textAlign="center" />

      {props.hasSignatures?.data?.length !== 0 && (
        <div>
          {props.signatures?.signers?.map((item, i) => (
            <SignatureCard
              key={i}
              signature={item}
              resendRequest={resendRequest}
              onPlaceSignature={props.onPlaceSignature}
            />
          ))}
        </div>
      )}
    </Box>
  );
}
