import React, { Component } from 'react';
import { PropTypes } from 'prop-types'; 
import objectAssign from 'object-assign';
import style from './styles';

class Region extends Component {
	constructor (props) {
		super(props);
	}
	renderHandles () {
		return (
			<div>
				<div data-dir='se' style={style.RegionHandleSE} />
				<div data-dir='sw' style={style.RegionHandleSW} />
				<div data-dir='nw' style={style.RegionHandleNW} />
				<div data-dir='ne' style={style.RegionHandleNE} />
			</div>
		);
	}
	render () {
		const localStyle = {
			width: this.props.width + '%',
			height: this.props.height + '%',
			left: `${this.props.x}%`,
			top: `${this.props.y}%`
		};
		const dataRenderArgs = {
			data: this.props.data,
			isChanging: this.props.changing,
			index: this.props.index
		};

		return (
			<>			
			
			{this.props.type == 1 &&
			<div
				style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
				onClick={this.props.onClick}
				className="selectED"
				onMouseDown={this.props.onCropStart}
				onTouchStart={this.props.onCropStart}
				data-wrapper="wrapper"
				>
				{ this.renderHandles() }
				{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
			</div>
			}
			{this.props.type == 0 &&
			<div
				style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
				onClick={this.props.onClick}
				title={`Field: ${this.props.data.label}`}

				data-wrapper="wrapper"
				>
				
				{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
			</div>
			}
			</>

		);
	}
}
Region.propTypes = {
	x: PropTypes.number.isRequired,
	y: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	onCropStart: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
	handles: PropTypes.bool,
	changing: PropTypes.bool,
	dataRenderer: PropTypes.func,
	data: PropTypes.object,
	customStyle: PropTypes.object
};


export default Region;