import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../components/theme";
import styled from "styled-components";

export const SuperUser = styled.div`
  position: absolute;
  bottom: 0px;
  left: 51px;
  font-size: 12px;
  color: #016ac6;
  font-weight: 100;
`;

export const ContainerUser = styled.div`
  max-height: 35px;
  min-height: 10px;
  position: relative;
  margin-bottom: 10px;
  color: ${colors.blue};
  font-weight: 900;
  font-size: 12px;
  width: 186px;
  display: flex;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1;
`;

export const Version = styled.label`
  font-size: 0.5em;
  color: #607d8b;
  text-align: center;
  font-weight: 900;
  position: fixed; 
  bottom: 10px;
  margin: 10px 0px 1px 10px;
  display: inline-block;
`;
export const DivLogoEsignature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const DivTextEsignature = styled.div`
  color: ${colors.white};
  font-size: 12px;
  font-weight: bold;
  margin: -17px 0 0 19px;
`;

export const useStyles = makeStyles((theme) => ({
  logoDocPlace: {
    objectFit: "cover",
    width: "100%",
  },
  Bg: {
    backgroundColor: colors.blue,
  },
  color: {
    color: colors.white,
  },
  colorBlue: {
    span: {
      color: colors.blue,
      fontWeight: 900,
      cursor: "pointer",
    },
    color: colors.blue,
  },
  activeColor: {
    backgroundColor: colors.activeColor + "!important",
  },
  profile: {
    position: "absolute",
    right: "25px",
  },
  mouseHover: {
    cursor: "pointer",
  },
  MuiListItem: {
    paddingLeft: "15px",
  },
}));
