import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  AttributeWrapper,
  AttributeWrapperEDLI,
  AttributeWrapperLI,
  AttributeWrapperUL,
  CommentContent,
  CommentDate,
  CommentsWrapper,
  CommentUser,
  MenuOptionFile2,
  MenuOptionFileUl,
  MenuOptionFloder,
  MenuOptionFloderLi,
  MenuOptionFloderMenuLi,
  MenuOptionFloderMenuNew,
  MenuOptionFloderMenuTitle,
  MenuOptionFloderMenuUl,
  MenuOptionFloderTitle,
  MenuOptionFloderUl,
  MessageWrapperLI,
  MessageWrapperUL,
  useStyles,
  WrapperMenuOption,
} from "./style";
import iconFolder from "../../../../assets/196-folder-8.png";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import BorderColorIcon from "@material-ui/icons/BorderColor";

import AutoRenew from "@material-ui/icons/Autorenew";

import GetAppIcon from "@material-ui/icons/GetApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ShareIcon from "@material-ui/icons/Share";
import moment from "moment-timezone";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { toast, ToastContainer } from "react-toastify";
import Zoom from "react-img-zoom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

import {
  postDocComment,
  putDocAttribute,
} from "../../../../store/explorer/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../../components/loading";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export function OptionMenuDocPlace(props, prev) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = (msj, event) => toast[event](msj);
  const [value, setValue] = React.useState(0);
  const [attributes, setAttributes] = React.useState([]);
  const [showEditAttbutes, setShowEditAttbutes] = React.useState(false);
  const [newMessage, setNewMessage] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [savedMessage, setSavedMessage] = React.useState(false);

  const { responseDocAttribute, errorDocAttribute, loading } = useSelector(
    (state) => ({
      responseDocAttribute: state.Explorer.responseDocAttribute,
      errorDocAttribute: state.Explorer.errorDocAttribute,
      loading: state.Explorer.loadingDocAttribute,
    })
  );

  const [commentPrev, setCommentPrev] = React.useState({});
  const responseDocComment = useSelector(
    (state) => state.Explorer.responseDocComment
  );
  const loading2 = useSelector((state) => state.Explorer.loadingDocComment);

  useEffect(() => {
    if (errorDocAttribute) {
      if (errorDocAttribute.response.data) {
        notify(
          errorDocAttribute.response.data.error.messages[0].message,
          "error"
        );
      }
    }
  }, [errorDocAttribute]);

  useEffect(() => {
    if (responseDocComment?.data.length > 0 && savedMessage) {
      props.addComment(true);
      return () => clearInterval();
    }
  }, [responseDocComment]);

  const click = (action) => {
    props.click(action);
  };
  useEffect(() => {
    console.log("Trash comp", props.isTrash);
  }, [props.isTrash]);

  const setFolder = (item) => {
    return (
      <MenuOptionFloder>
        <img src={iconFolder} className={classes.imgFolder} alt="" />
        <MenuOptionFloderTitle>
          {item.folder ? item.name : item.fkArchive.name}
        </MenuOptionFloderTitle>
      </MenuOptionFloder>
    );
  };

  const setFile = (item) => {
    return (
      <MenuOptionFile2>
        <div style={{ margin: "0 auto", width: "250px" }}>
          <Zoom
            img={
              item.fileReferences?.pages?.length > 0
                ? item.fileReferences?.pages[0]
                : item.fileReferences?.thumbs
                ? item.fileReferences.thumbs[0]
                : "https://app.docplace.io/static/media/logo2.c7c1d5fc.svg"
            }
            className={classes.imgFile}
            zoomScale={4}
            margin={"0 auto"}
            width={250}
            height={270}
          />
        </div>
        <MenuOptionFloderTitle>
          {item.folder
            ? item.name
            : item.fileReferences?.originalFileName
            ? item.fileReferences?.originalFileName
            : item.fkArchive?.name}
        </MenuOptionFloderTitle>
      </MenuOptionFile2>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setFolderInfo = (item) => {
    return (
      <MenuOptionFloderUl>
        <MenuOptionFloderLi>
          DOCUMENT:{" "}
          <span className={classes.spanText}>
            {" "}
            {!item.folder ? item.fkArchive.name : item.name}{" "}
          </span>
        </MenuOptionFloderLi>
        <MenuOptionFloderLi>
          LAST MODIFY:{" "}
          <span className={classes.spanText}> {item.updatedAt} </span>
        </MenuOptionFloderLi>
        {/*
          <MenuOptionFloderLi>
            CANONICAL PATH:{" "}
            <span className={classes.spanText}> {!item.folder ? "" : "/"} </span>
          </MenuOptionFloderLi>
          */}
        {item?.fkArchive?.type && (
          <MenuOptionFloderLi>
            TYPE:{" "}
            <span className={classes.spanText}>
              {" "}
              {!item.folder ? item.fkArchive.type : item.type}{" "}
            </span>
          </MenuOptionFloderLi>
        )}

        {/* <MenuOptionFloderLi>
            VERSION: <span className={classes.spanText}> {item.version} </span>
           </MenuOptionFloderLi>*/}
      </MenuOptionFloderUl>
    );
  };

  const getFilesStructure = (item) => {
    let cnp = item.fkArchive.canonicalPath.replaceAll("\\", "/");
    return (
      <MenuOptionFileUl>
        <MenuOptionFloderLi>
          DOCUMENT:{" "}
          <span className={classes.spanText}>
            {item.folder ? item.name : item.fkArchive.name}
          </span>
        </MenuOptionFloderLi>
        <MenuOptionFloderLi>
          CREATED DATE:{" "}
          <span className={classes.spanText}>
            {" "}
            {!item.folder
              ? moment
                  .tz(item.createdAt, "UTC")
                  .tz("America/New_York")
                  .format("MM/DD/yyyy hh:mm:ss")
              : ""}{" "}
          </span>
        </MenuOptionFloderLi>
        <MenuOptionFloderLi>
          LAST MODIFIED DATE:{" "}
          <span className={classes.spanText}>
            {" "}
            {!item.folder
              ? moment
                  .tz(item.updateAt, "UTC")
                  .tz("America/New_York")
                  .format("MM/DD/yyyy hh:mm:ss")
              : ""}{" "}
          </span>
        </MenuOptionFloderLi>
        {
          <MenuOptionFloderLi>
            LOCATION:
            <span className={classes.spanText}> {cnp} </span>
          </MenuOptionFloderLi>
        }

        <MenuOptionFloderLi>
          DOCTYPE:{" "}
          <span className={classes.spanText}>
            {" "}
            {!item.folder ? item.fkDoctype.name : ""}{" "}
          </span>
        </MenuOptionFloderLi>
        {item?.originId && (
          <MenuOptionFloderLi>
            ORIGIN ID:{" "}
            <span className={classes.spanText}>
              {" "}
              {!item.folder ? item.originId : ""}{" "}
            </span>
          </MenuOptionFloderLi>
        )}

        {item?.originType && (
          <MenuOptionFloderLi>
            ORIGIN TYPE:{" "}
            <span className={classes.spanText}>
              {" "}
              {!item.folder ? item.originType : ""}{" "}
            </span>
          </MenuOptionFloderLi>
        )}
        {/*DOC-1682 adding the new attribute number of pages, not displaying it if the attribute doesn't exists, reads first the dedicated value for total pages
        but that is only available in document viewer, therefore, in search or explorer it shows the lenght of pages array*/}
        {item?.fileReferences?.pages && item?.fileReferences?.pages.length > 0 && (
          <MenuOptionFloderLi>
            NUMBER OF PAGES:{" "}
            <span className={classes.spanText}>
              {" "}
              {!item.folder
                ? item.fileReferences.totalPages > 0
                  ? item.fileReferences.totalPages
                  : item.fileReferences.pages.length
                : ""}{" "}
            </span>
          </MenuOptionFloderLi>
        )}

        {/*
            <MenuOptionFloderLi>
             VERSION: <span className={classes.spanText}> {item.version} </span>
              </MenuOptionFloderLi>
          */}
      </MenuOptionFileUl>
    );
  };

  const getAttributesStructure = (item) => {
    let clActive = classes.attributeRowColor;
    let test = {
      attribute_1: "62-New",
      attribute_2: "York",
      attribute_3: "or",
      attribute_4: "Manhattan",
      attribute_5: "RENEWAL",
      attribute_6: "LEASE",
      attribute_7: "FORM",
      attribute_8: "Owners",
      attribute_9: "and",
      attribute_10: "Tenants",
      attribute_11: "should",
      attribute_12: "read",
      attribute_13: "INSTRUCTION",
    };
    return (
      <>
        {!showEditAttbutes ? (
          <AttributeWrapper>
            <AttributeWrapperUL>
              {item.fkDoctype.fields && item.fkDoctype.fields.length ? (
                <>
                  {/*DOC-1718 adding conditional to not show the edit button if there are not attributes */}
                  {item.fkDoctype.fields.length > 0 && (
                    <Button
                      onClick={(e) => setShowEditAttbutes(true)}
                      className={classes.attributeEdit}
                      variant="outlined"
                      color="primary"
                    >
                      Edit
                    </Button>
                  )}
                  {item.fkDoctype.fields.map((atr, idx) => {
                    return (
                      <AttributeWrapperLI
                        className={idx % 2 == 0 ? "" : clActive}
                        key={idx}
                      >
                        <Grid
                          container
                          className={classes.clearRow}
                          spacing={2}
                        >
                          <Grid
                            item
                            className={classes.attributeTitle}
                            xs={6}
                            md={6}
                          >
                            {atr.label}
                          </Grid>
                          <Grid
                            item
                            className={classes.attributeContent}
                            xs={6}
                            md={6}
                          >
                            {item.content[atr.alias]}
                          </Grid>
                        </Grid>
                      </AttributeWrapperLI>
                    );
                  })}
                </>
              ) : (
                Object.entries(item.content).map(([key, value]) => (
                  <AttributeWrapperLI
                    className={key % 2 == 0 ? "" : clActive}
                    key={key}
                  >
                    <Grid container className={classes.clearRow} spacing={2}>
                      <Grid
                        item
                        className={classes.attributeTitle}
                        xs={6}
                        md={6}
                      >
                        {key}
                      </Grid>
                      <Grid
                        item
                        className={classes.attributeContent}
                        xs={6}
                        md={6}
                      >
                        {value}
                      </Grid>
                    </Grid>
                  </AttributeWrapperLI>
                ))
              )}
            </AttributeWrapperUL>
          </AttributeWrapper>
        ) : (
          editAttributesStructure(item)
        )}
      </>
    );
  };

  const saveComment = () => {
    if (newMessage) {
      let obj = {
        content: newMessage,
      };
      dispatch(postDocComment({ data: obj, id: props.item.id }, navigate));
      setShowEditAttbutes(false);
      setNewMessage("");
      setSavedMessage(true);
    } else {
      notify("Enter the message", "error");
    }
  };

  const getCommentStructure = (item) => {
    let clActive = classes.attributeRowColor;
    let messagesList = item.comments ? item.comments : [];

    return (
      <CommentsWrapper>
        <TextField
          id="filled-multiline-static"
          label="Write a message"
          multiline
          style={{
            width: "100%",
            backgroundColor: "#fff",
            marginBottom: "20px",
          }}
          rows={2}
          defaultValue={newMessage}
          value={newMessage}
          variant="filled"
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <Button
          onClick={(e) => saveComment()}
          className={classes.attributeEdit}
          variant="outlined"
          color="primary"
        >
          Send
        </Button>
        <MessageWrapperUL>
          {messagesList.map((comment, idx) => {
            return (
              <MessageWrapperLI
                className={idx % 2 == 0 ? "" : clActive}
                key={idx}
              >
                <CommentContent>{comment.content}</CommentContent>
                <CommentDate>
                  {moment
                    .tz(comment.createdAt, "UTC")
                    .tz("America/New_York")
                    .format("MM/DD/yyyy hh:mm:ss")}
                </CommentDate>
                <CommentUser>
                  <AccountBoxIcon />
                  {comment.createdByUserName}
                </CommentUser>
              </MessageWrapperLI>
            );
          })}
        </MessageWrapperUL>
      </CommentsWrapper>
    );
  };

  const saveDataAttribute = (value) => {
    let data = value.item;
    data[value.field] = value.value;

    try {
      delete data.file_name;
    } catch (error) {
      console.log("No file name property");
    }
    setAttributes(data);
  };

  const saveAttribute = () => {
    let obj = {
      content: attributes,
    };
    dispatch(putDocAttribute({ data: obj, id: props.item.id }, navigate));
    setShowEditAttbutes(false);
  };

  const editAttributesStructure = (item) => {
    let clActive = classes.attributeRowColor;
    return (
      <AttributeWrapper>
        <AttributeWrapperUL
          style={{ backgroundColor: "#fff", borderRadius: "8px" }}
        >
          {item.fkDoctype.fields.map((atr, idx) => {
            return (
              <AttributeWrapperEDLI
                style={{ marginTop: "15px", marginBottom: "50px" }}
                className={idx % 2 == 0 ? "" : clActive}
                key={idx}
              >
                <Grid container className={classes.clearRow} spacing={2}>
                  <TextField
                    style={{
                      width: "100%",
                      marginLeft: "20px",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                    id={atr.alias}
                    variant="outlined"
                    defaultValue={item.content[atr.alias]}
                    onChange={(e) =>
                      saveDataAttribute({
                        fields: item.fkDoctype.fields,
                        item: item.content,
                        value: e.target.value,
                        field: atr.alias,
                      })
                    }
                    label={atr.label}
                  />
                </Grid>
              </AttributeWrapperEDLI>
            );
          })}
          <Button
            onClick={(e) => setShowEditAttbutes(false)}
            className={classes.attributeCancel}
            variant="outlined"
            color="secundary"
          >
            Cancel
          </Button>
          <Button
            onClick={saveAttribute.bind()}
            className={classes.attributeSave}
            variant="outlined"
            color="primary"
          >
            Save
          </Button>
        </AttributeWrapperUL>
      </AttributeWrapper>
    );
  };

  const setFileInfo = (item) => {
    return (
      <div>
        <AppBar style={{ marginTop: "25px" }} position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Properties" {...a11yProps(0)} />
            <Tab label="Attributes" {...a11yProps(1)} />
            <Tab label="Comments" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel style={{ marginTop: "-45px" }} value={value} index={0}>
          {getFilesStructure(item)}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {getAttributesStructure(item)}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {getCommentStructure(item)}
        </TabPanel>
      </div>
    );
  };

  const canShare = (permissions) => {
    return permissions.Read || permissions.Share;
  };

  const setOptionMenuFolder = (item) => {
    let iconOpen = classes.iconMenuOPT + " " + classes.iconGreen;
    let iconRename = classes.iconMenuOPT + " " + classes.iconBlue;
    let iconDelete = classes.iconMenuOPT + " " + classes.iconRed;
    let iconRecover = classes.iconMenuOPT + " " + classes.iconBlue;
    return (
      <MenuOptionFloderMenuUl>
        <MenuOptionFloderMenuLi>
          <MenuOptionFloderMenuTitle> OPTIONS </MenuOptionFloderMenuTitle>
        </MenuOptionFloderMenuLi>
        {!props.notShowOpen &&
          props.permissions.Read == 1 &&
          props.isTrash === false && (
            <MenuOptionFloderMenuLi onClick={click.bind(this, "open")}>
              <OpenInBrowserIcon className={iconOpen}></OpenInBrowserIcon>
              <MenuOptionFloderMenuNew> OPEN </MenuOptionFloderMenuNew>
            </MenuOptionFloderMenuLi>
          )}
        {!props.notShowOpen &&
          props.permissions.Read == 1 &&
          props.isTrash === false && (
            <MenuOptionFloderMenuLi onClick={click.bind(this, "downloadZip")}>
              <SystemUpdateAltIcon className={iconOpen}></SystemUpdateAltIcon>
              <MenuOptionFloderMenuNew>
                {" "}
                DOWNLOAD (.ZIP){" "}
              </MenuOptionFloderMenuNew>
            </MenuOptionFloderMenuLi>
          )}
        {props.permissions.Write == 1 && props.isTrash === false && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "move")}>
            <FileCopyIcon className={iconRename}></FileCopyIcon>
            <MenuOptionFloderMenuNew> MOVE </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Write == 1 && props.isTrash === false && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "rename")}>
            <BorderColorIcon className={iconRename}></BorderColorIcon>
            <MenuOptionFloderMenuNew> RENAME </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}

        {canShare(props.permissions) && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "share")}>
            <ShareIcon className={iconRename}></ShareIcon>
            <MenuOptionFloderMenuNew> SHARE </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Delete == 1 && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "delete")}>
            <DeleteForeverIcon className={iconDelete}></DeleteForeverIcon>
            <MenuOptionFloderMenuNew> DELETE </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Write == 1 && props.isTrash === true && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "recover")}>
            <AutoRenew className={iconRecover}></AutoRenew>
            <MenuOptionFloderMenuNew> RECOVER </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
      </MenuOptionFloderMenuUl>
    );
  };

  const setOptionMenuFile = (item) => {
    let iconOpen = classes.iconMenuOPT + " " + classes.iconGreen;
    let iconRename = classes.iconMenuOPT + " " + classes.iconBlue;
    let iconReprocess = classes.iconMenuOPT + " " + classes.iconReprocess;
    let iconRecover = classes.iconMenuOPT + " " + classes.iconRecover;

    let iconYellow = classes.iconMenuOPT + " " + classes.iconYellow;
    let iconDelete = classes.iconMenuOPT + " " + classes.iconRed;
    return (
      <MenuOptionFloderMenuUl>
        <MenuOptionFloderMenuLi>
          <MenuOptionFloderMenuTitle> OPTIONS </MenuOptionFloderMenuTitle>
        </MenuOptionFloderMenuLi>
        {!props.notShowOpen &&
          props.permissions.Read == 1 &&
          props.isTrash === false && (
            <MenuOptionFloderMenuLi onClick={click.bind(this, "open")}>
              <OpenInBrowserIcon className={iconOpen}></OpenInBrowserIcon>
              <MenuOptionFloderMenuNew> OPEN</MenuOptionFloderMenuNew>
            </MenuOptionFloderMenuLi>
          )}
        {props.permissions.Read == 1 && props.isTrash === false && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "download")}>
            <GetAppIcon className={iconRename}></GetAppIcon>
            <MenuOptionFloderMenuNew> DOWNLOAD </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Write == 1 && props.isTrash === false && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "move")}>
            <FileCopyIcon className={iconRename}></FileCopyIcon>
            <MenuOptionFloderMenuNew> MOVE </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Read == 1 && props.isTrash === false && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "share")}>
            <ShareIcon className={iconYellow}></ShareIcon>
            <MenuOptionFloderMenuNew> SHARE </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Write == 1 && props.isTrash === false && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "rename")}>
            <BorderColorIcon className={iconRename}></BorderColorIcon>
            <MenuOptionFloderMenuNew> RENAME </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Write == 1 && props.isTrash === false && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "reprocess")}>
            <AutoRenew className={iconRename}></AutoRenew>
            <MenuOptionFloderMenuNew> REPROCESS </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Write == 1 && props.isTrash === true && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "recover")}>
            <AutoRenew className={iconRecover}></AutoRenew>
            <MenuOptionFloderMenuNew> RECOVER </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Delete == 1 && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "delete")}>
            <DeleteForeverIcon className={iconDelete}></DeleteForeverIcon>
            <MenuOptionFloderMenuNew> DELETE </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
        {props.permissions.Read === 1 && !props.hideFileLocationOption && (
          <MenuOptionFloderMenuLi onClick={click.bind(this, "fileLocation")}>
            <DriveFolderUploadIcon
              className={iconRename}
            ></DriveFolderUploadIcon>
            <MenuOptionFloderMenuNew>
              {" "}
              OPEN FILE LOCATION{" "}
            </MenuOptionFloderMenuNew>
          </MenuOptionFloderMenuLi>
        )}
      </MenuOptionFloderMenuUl>
    );
  };

  return (
    <WrapperMenuOption>
      <ToastContainer autoClose={3000} />
      <LoadingComponent
        show={loading || loading2}
        text={"Updating Attributes..."}
      />
      {props.item ? (
        <Grid container className={classes.clearRow} spacing={2}>
          <Grid item xs={4} md={4}>
            {props.item.folder
              ? setFolder(props.item)
              : props.item.fkArchive.type === "Folder"
              ? setFolder(props.item)
              : setFile(props.item)}
          </Grid>
          <Grid item xs={4} md={4}>
            {props.item.folder
              ? setFolderInfo(props.item)
              : props.item.fkArchive.type === "Folder"
              ? setFolderInfo(props.item)
              : setFileInfo(props.item)}
          </Grid>
          <Grid item xs={4} md={4}>
            {props.item.folder
              ? setOptionMenuFolder(props.item)
              : props.item.fkArchive.type === "Folder"
              ? setOptionMenuFolder(props.item)
              : setOptionMenuFile(props.item)}
          </Grid>
        </Grid>
      ) : null}
    </WrapperMenuOption>
  );
}

export default OptionMenuDocPlace;
