import React from "react";
import Dropzone from "react-dropzone";
import filePdf from "../../../../../assets/file-pdf.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";

import {
  DragAndDropText,
  FileNewUpload,
  TitleDocumentUpload,
  useStyles,
} from "./style";
import UploadIcon from "@mui/icons-material/Upload";

const UploadAttachFileView = (props) => {
  const classes = useStyles();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Dropzone>
        {() => (
          <section
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              {...props.getRootProps({
                className:
                  props.selectedFiles.length > 0
                    ? classes.dropZoneComplete
                    : classes.dropzone,
              })}
            >
              <input {...props.getInputProps()} />
              {props.selectedFiles && props.selectedFiles.length ? (
                <div>
                  <FileNewUpload>
                    <UploadIcon className={classes.iconUploadFile} />
                    <TitleDocumentUpload>UPLOAD</TitleDocumentUpload>
                    <DragAndDropText>Drag and Drop your Files</DragAndDropText>
                  </FileNewUpload>

                  {props.selectedFiles.length > 50
                    ? `${props.selectedFiles.length} files`
                    : props.selectedFiles.map((file, index) => (
                        <div className={classes.dropBox} key={index}>
                          <img
                            alt={"DocPlace"}
                            className={classes.imgDoc}
                            src={filePdf}
                          />
                          <div className={classes.imgDescription}>
                            {file.name}
                          </div>
                          <div className={classes.imgDescription}>
                            {(file.size / 1000000).toFixed(2)} MB
                          </div>
                        </div>
                      ))}
                </div>
              ) : (
                <FileNewUpload>
                  <UploadIcon className={classes.iconUploadFile} />
                  <TitleDocumentUpload>UPLOAD</TitleDocumentUpload>
                  <DragAndDropText> Drag and Drop your Files</DragAndDropText>
                </FileNewUpload>
              )}
            </div>
          </section>
        )}
      </Dropzone>
      {props.showUpload && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <Button
            onClick={props.saveUpload}
            startIcon={<CloudUploadIcon />}
            variant="outlined"
            sx={{ textTransform: "none" }}
          >
            Upload Files
          </Button>
          <Button
            onClick={props.closeUpload}
            color="error"
            startIcon={<ClearIcon />}
            variant="outlined"
            sx={{ textTransform: "none" }}
          >
            Close Upload
          </Button>
        </div>
      )}
    </div>
  );
};

export default UploadAttachFileView;
