import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { colors } from "../../../theme";

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    display: "flex",
    paddingRight: "15px",
    gap: "20px",
    width: "100%",
    fontSize: "12px",
    fontWeight: "100",
    marginLeft: "12px",
    marginTop: "3px",
  },
}));

export const WrapperTextShort = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: white;
`;

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.white};
  position: relative;
  height: 100%;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainerTools = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerTextField = styled.div`
  width: 100%;
`;

export const DivHelperTextTitle = styled.div`
  margin-bottom: 10px;
  text-align: left;
  font-weight: 100 !important;
  font-size: 12px;
`;
