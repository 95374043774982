
import { useStyles } from "./style";


import React, { useEffect, useState, useRef } from "react";
import iconFolder from "../../../../assets/yellow.svg";
import Modal from "@mui/material/Modal";

import ShipmentDocPlace from "../Shipment";
import {
  SquareBox,
  BoxTitle,
  BoxDate,
  BurgerIcon,
  PopupMenu,
  BoxThumbnail,
  BoxShipments,
  BoxCompleted
} from "./style";

const style = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "auto",
  width: "40%",
  height: "fit-content",
  bgcolor: "background.paper",
  p: 4,
};



export function Template(props, prev) {

  const title = () => {
    let max = 30;
    if (props.title.length > max) {
      return props.title.substring(0, max) + '...';
    } else {
      return props.title;
    }
  }
  const [showShippings, setShowShippings] = React.useState(false);
  const showShipping = () => {
    setShowShippings(true)
  };
  return (
    <>
     
      <Modal
        open={showShippings}
        onClose={() => setShowShippings(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ShipmentDocPlace id={props.id} />
      </Modal>

      <SquareBox key={props.idx} onClick={showShipping} >
        <BoxThumbnail >
          <img
            src={iconFolder}
            alt="Templates folder"
          />

        </BoxThumbnail>
        <BoxTitle>{title()}</BoxTitle>
        <BoxShipments>Shipments: <span>{props.shared ? props.shared : 0}</span></BoxShipments>
        <BoxCompleted>Completed: <span>{props.published ? props.published : 0}</span></BoxCompleted>
      </SquareBox>
    </>

  );
}
export default Template;
