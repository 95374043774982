import {
  API_ERROR_DOC_ATTRIBUTE,
  API_ERROR_DOC_COMMENT,
  API_ERROR_DOC_DELETE,
  API_ERROR_DOC_DOWNLOAD,
  API_ERROR_DOC_MOVE,
  API_ERROR_DOC_RECOVER,
  API_ERROR_DOC_RENAME,
  API_ERROR_DOC_REPROCESS,
  API_ERROR_DOC_SHARE,
  API_ERROR_EMPTY_TRASH_BIN,
  API_ERROR_NEW_FOLDER,
  DOC_ATTRIBUTE_PUT,
  DOC_ATTRIBUTE_PUT_SUCCESS,
  DOC_COMMENT_POST,
  DOC_COMMENT_POST_SUCCESS,
  DOC_DELETE_POST,
  DOC_DELETE_POST_SUCCESS,
  DOC_DOWNLOAD_POST,
  DOC_DOWNLOAD_POST_SUCCESS,
  DOC_MOVE_POST,
  DOC_MOVE_POST_SUCCESS,
  DOC_RECOVER_POST,
  DOC_RECOVER_POST_SUCCESS,
  DOC_RENAME_POST,
  DOC_RENAME_POST_SUCCESS,
  DOC_REPROCESS_POST,
  DOC_REPROCESS_POST_SUCCESS,
  DOC_SHARE_POST,
  DOC_SHARE_POST_SUCCESS,
  EMPTY_TRASH_BIN,
  EMPTY_TRASH_BIN_SUCCESS,
  FOLDER_DOWNLOAD_ZIP_POST,
  FOLDER_DOWNLOAD_ZIP_POST_CLEAR_DATA,
  FOLDER_DOWNLOAD_ZIP_POST_ERROR,
  FOLDER_DOWNLOAD_ZIP_POST_SUCCESS,
  FOLDER_NEW_POST,
  FOLDER_NEW_POST_SUCCESS
} from "./actionTypes";

const initialState = {
  errorNewFolder: null,
  responseNewFolder: null,
  loadingNewFolder: false,

  errorMoveDoc: null,
  responseMoveDoc: null,
  loadingMoveDoc: false,

  errorShareDoc: null,
  responseShareDoc: null,
  loadingShareDoc: false,

  errorRenameDoc: null,
  responseRenameDoc: null,
  loadingRenameDoc: false,

  errorReprocessDoc: null,
  responseReprocessDoc: null,
  loadingReprocessDoc: false,

  errorRecoverDoc: null,
  responseRecoverDoc: null,
  loadingRecoverDoc: false,

  errorDeleteDoc: null,
  responseDeleteDoc: null,
  loadingDeleteDoc: false,

  errorDocAttribute: null,
  responseDocAttribute: null,
  loadingDocAttribute: false,

  errorDocComment: null,
  responseDocComment: null,
  loadingDocComment: false,

  errorDocDownload: null,
  responseDocDownload: null,
  loadingDocDownload: false,

  errorEmptyTrash: null,
  responseEmptyTrash: null,
  loadingEmptyTrash: null,

  errorFolderDownload: null,
  responseFolderDownload: null,
  loadingFolderDownload: false,
};

const explorer = (state = initialState, action) => {
  switch (action.type) {
    case DOC_COMMENT_POST:
      state = {
        ...state,
        errorDocComment: null,
        loadingDocComment: true,
      };
      break;
    case DOC_DOWNLOAD_POST:
      state = {
        ...state,
        errorDocDownload: null,
        loadingDocDownload: true,
      };
      break;
    case DOC_ATTRIBUTE_PUT:
      state = {
        ...state,
        errorDocAttribute: null,
        loadingDocAttribute: true,
      };
      break;
    case FOLDER_NEW_POST:
      state = {
        ...state,
        errorNewFolder: null,
        loadingNewFolder: true,
      };
      break;
    case DOC_MOVE_POST:
      state = {
        ...state,
        errorMoveDoc: null,
        loadingMoveDoc: true,
      };
      break;
    case DOC_SHARE_POST:
      state = {
        ...state,
        errorShareDoc: null,
        loadingShareDoc: true,
      };
      break;
    case DOC_REPROCESS_POST:
      state = {
        ...state,
        errorReprocessDoc: null,
        loadingReprocessDoc: true,
      };
      break;
    case DOC_RENAME_POST:
      state = {
        ...state,
        errorRenameDoc: null,
        loadingRenameDoc: true,
      };
      break;
    case DOC_REPROCESS_POST:
      state = {
        ...state,
        errorReprocessDoc: null,
        loadingReprocessDoc: true,
      };
      break;
      case DOC_RECOVER_POST:
        state = {
          ...state,
          errorRecoverDoc: null,
          loadingRecoverDoc: true,
        };
        break;
    case EMPTY_TRASH_BIN:
      state = {
        ...state,
        errorEmptyTrash: null,
        loadingEmptyTrash: true,
      };
      break;
    case DOC_DELETE_POST:
      state = {
        ...state,
        errorDeleteDoc: null,
        loadingDeleteDoc: true,
      };
      break;
    case DOC_COMMENT_POST_SUCCESS:
      state = {
        ...state,
        errorDocComment: null,
        responseDocComment: action.payload,
        loadingDocComment: false,
      };
      break;
    case DOC_DOWNLOAD_POST_SUCCESS:
      state = {
        ...state,
        errorDocDownload: null,
        responseDocDownload: action.payload,
        loadingDocDownload: false,
      };
      break;
    case DOC_ATTRIBUTE_PUT_SUCCESS:
      state = {
        ...state,
        errorDocAttribute: null,
        responseDocAttribute: action.payload,
        loadingDocAttribute: false,
      };
      break;
    case FOLDER_NEW_POST_SUCCESS:
      state = {
        ...state,
        errorNewFolder: null,
        responseNewFolder: action.payload,
        loadingNewFolder: false,
      };
      break;
    case DOC_MOVE_POST_SUCCESS:
      state = {
        ...state,
        errorMoveDoc: null,
        responseMoveDoc: action.payload,
        loadingMoveDoc: false,
      };
      break;
    case DOC_SHARE_POST_SUCCESS:
      state = {
        ...state,
        errorShareDoc: null,
        responseShareDoc: action.payload,
        loadingShareDoc: false,
      };
      break;
    case DOC_RENAME_POST_SUCCESS:
      state = {
        ...state,
        errorRenameDoc: null,
        responseRenameDoc: action.payload,
        loadingRenameDoc: false,
      };
      break;
    case DOC_REPROCESS_POST_SUCCESS:
      state = {
        ...state,
        errorReprocessDoc: null,
        responseReprocessDoc: action.payload,
        loadingReprocessDoc: false,
      };
      break;
      case DOC_RECOVER_POST_SUCCESS:
        state = {
          ...state,
          errorRecoverDoc: null,
          responseRecoverDoc: action.payload,
          loadingRecoverDoc: false,
        };
        break;
    case EMPTY_TRASH_BIN_SUCCESS:
      state = {
        ...state,
        errorEmptyTrash: null,
        responseEmptyTrash: action.payload,
        loadingEmptyTrash: false,
      };
      break;
    case DOC_DELETE_POST_SUCCESS:
      state = {
        ...state,
        errorDeleteDoc: null,
        responseDeleteDoc: action.payload,
        loadingDeleteDoc: false,
      };
      break;
    case API_ERROR_DOC_COMMENT:
      state = {
        ...state,
        errorDocComment: action.payload,
        loadingDocComment: false,
        responseDocComment: null,
      };
      break;
    case API_ERROR_DOC_DOWNLOAD:
      state = {
        ...state,
        errorDocDownload: action.payload,
        loadingDocDownload: false,
        responseDocDownload: null,
      };
      break;
    case API_ERROR_DOC_ATTRIBUTE:
      state = {
        ...state,
        errorDocAttribute: action.payload,
        loadingDocAttribute: false,
        responseDocAttribute: null,
      };
      break;
    case API_ERROR_NEW_FOLDER:
      state = {
        ...state,
        errorNewFolder: action.payload,
        loadingNewFolder: false,
        responseNewFolder: null,
      };
      break;
    case API_ERROR_DOC_MOVE:
      state = {
        ...state,
        errorMoveDoc: action.payload,
        loadingMoveDoc: false,
        responseMoveDoc: null,
      };
      break;
    case API_ERROR_DOC_SHARE:
      state = {
        ...state,
        errorShareDoc: action.payload,
        loadingShareDoc: false,
        responseShareDoc: null,
      };
      break;
    case API_ERROR_DOC_RENAME:
      state = {
        ...state,
        errorRenameDoc: action.payload,
        loadingRenameDoc: false,
        responseRenameDoc: null,
      };
      break;
    case API_ERROR_DOC_REPROCESS:
      state = {
        ...state,
        errorReprocessDoc: action.payload,
        loadingReprocessDoc: false,
        responseReprocessDoc: null,
      };
      break;
      case API_ERROR_DOC_RECOVER:
          state = {
            ...state,
            errorRecoverDoc: action.payload,
            loadingRecoverDoc: false,
            responseRecoverDoc: null,
          };
          break;
    case API_ERROR_EMPTY_TRASH_BIN:
      state = {
        ...state,
        errorEmptyTrash: action.payload,
        loadingEmptyTrash: false,
        responseEmptyTrash: null,
      };
      break;

    case API_ERROR_DOC_DELETE:
      state = {
        ...state,
        errorDeleteDoc: action.payload,
        loadingDeleteDoc: false,
        responseDeleteDoc: null,
      };
      break;
    case FOLDER_DOWNLOAD_ZIP_POST:
      state = {
        ...state,
        errorFolderDownload: null,
        loadingFolderDownload: true,
      };
      break;
    case FOLDER_DOWNLOAD_ZIP_POST_SUCCESS:
      state = {
        ...state,
        errorFolderDownload: null,
        responseFolderDownload: action.payload,
        loadingFolderDownload: false,
      };
      break;
    case FOLDER_DOWNLOAD_ZIP_POST_ERROR:
      state = {
        ...state,
        errorFolderDownload: action.payload,
        loadingFolderDownload: false,
        responseFolderDownload: null,
      };
      break;
    case FOLDER_DOWNLOAD_ZIP_POST_CLEAR_DATA:
      state = {
        ...state,
        errorFolderDownload: null,
        loadingFolderDownload: false,
        responseFolderDownload: null,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default explorer;
