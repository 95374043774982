import React from "react";
import {WrapperTree, TreeUl, TreeLi, TreeLiText, WrapperBackTree} from './style';
import '../../explorer-style.scss';
import folderClose from '../../../../assets/folder@2x.png';
import arrowClose from '../../../../assets/chevron-right-02@2x.png';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const mapTree = [];

class TreeItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listItems: this.props.tree,
            item: '',
            click: 0,
            mapExplorer: [],
            mapExplorerBack: []

        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    setChild = (children) => {
        return (
            children.map((item, idx) => {
                if(item.children.length > 0){
                    this.setChild(item.children);
                }

                return (
                    <TreeUl key={idx}>
                        <TreeLi
                            className={item.open === true ? 'openFolder' : 'closeFolder'}
                            key={idx}>

                            <span
                                className={item.id === this.state.item.id ? 'activeTree' : ''}
                                onClick={this.openChild.bind(this,item.id,item.name)}>
                                <img src={arrowClose} className='imgArrowTree' alt=""/>
                                <img src={folderClose} className='imgFolderTree' alt=""/>
                                <TreeLiText>{item.name}</TreeLiText>
                            </span>

                            {
                                item.children ? this.setChild(item.children) : null
                            }
                        </TreeLi>
                    </TreeUl>
                )
            })
        )}

    openChild = (id,name) => {
        if(id.folder){
            this.props.click({label:id.name,value:id.id,parent: id.parent});
        }else{
            this.props.click({label:name,value:id });
        }

        this.setMap(this.props.tree.data,id);
        this.setState({
            listItems: this.props.tree.data,
            item: {id:id,name:name},
            mapExplorerBack: this.state.mapExplorer
        })
    }


    backTree = (value) => {
        if(value.length > 0){
            this.props.backTree({label:value[0].name,value:value[0].parent ? value[0].parent.id : ''});
        }else{
            this.props.backTree({
                label:this.props.treePrevius[this.props.treePrevius.length -1].name,
                value:this.props.treePrevius[this.props.treePrevius.length -1].id });
        }

    }


    recursiveTree = (tree,id) => {
        tree.map((item, idx) => {
            if(item.children.length > 0){
                this.recursiveTree(item.children,id)
            }
            if(item.id === id){
                item.open = !item.open;
            }
        })
    }

    setMap = (tree,id) => {
        tree.map((item, idx) => {
            if(item.id === id){
                mapTree.push({id:item.id,name:item.name});
            }
        })
    }

    render() {
        return (
            <WrapperTree>
                { this.props.treePrevius.length > 0 && this.props.treePrevius[0].name &&
                        <WrapperBackTree onClick={this.backTree.bind(this,
                            this.props.tree.data)}>
                            <ArrowUpwardIcon style={{float:'left', margin:'-2px 6px 0px 8px'}} />
                            Back   _<span style={{fontWeight:'900'}}> { this.props.treePrevius[this.props.treePrevius.length -1].name } </span>
                        </WrapperBackTree>
                }

                { this.props.tree.data && this.props.tree.data.length > 0 &&
                    <TreeUl>
                        {
                            this.props.tree.data.map((item, idx) => {
                                return (
                                    <>
                                    { this.props.hideTrash == true && item.nameLower == "trash" ? null :
                                    <TreeLi
                                        onClick={this.openChild.bind(this,item)}
                                        key={idx}>
                                        <span>
                                            <ArrowForwardIosIcon className='imgArrowTree'  />
                                            <img src={folderClose} className='imgFolderTree' alt=""/>
                                            <TreeLiText>
                                                {item.name}
                                            </TreeLiText>
                                        </span>
                                    </TreeLi>
                            }</>
                                )
                            })
                        }
                    </TreeUl>
                }

            </WrapperTree>
        )
    }
}


export default TreeItems
