import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../../style";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DeleteIcon from "@mui/icons-material/Delete";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import CloseIcon from "@mui/icons-material/Close";
const initialState = {
  mouseX: null,
  mouseY: null,
};
/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PageDocumentSlider = (props) => {
  /* const [content, setContent] = useState(<></>); */
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentId, setCurrentId] = useState(0);

  const [state, setState] = useState(initialState);

  const data = props.data

  const getPageRequest = (pageNumber, id) => {
    setCurrentIndex(pageNumber)
    setCurrentId(id)
    props.retrivePageHandle(pageNumber, id)
  }

  const getImageOverlayClass = (index) => {
    if (index === currentIndex) {
      return 'doc-img-overlay'
    } else {
      return 'none'
    }
  }

  const getImageContainerClass = (index) => {
    if (index === currentIndex) {
      return 'doc-img-thumbnail doc-img-background'
    } else {
      return 'doc-img-thumbnail'
    }
  }

  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
  };

  const handleClose = () => {
    setState(initialState);
  }

  const deletePage = (id) => {
    setCurrentIndex(0)
    props.deletePage(id);
    handleClose();
  }
  const MoveUp = (id) => {
    props.moveUp(id,currentIndex-1);
    setCurrentIndex(currentIndex => currentIndex - 1)
    handleClose();
  }
  const MoveDown = (id) => {
    props.moveDown(id,currentIndex+1);
    setCurrentIndex(currentIndex => currentIndex + 1)
    handleClose();
  }
  const RotateRight = (id) => {
    props.rotateRight(id);
    handleClose();
  }
  const RotateLeft = (id) => {
    props.rotateLeft(id);
    handleClose();
  }
  return (
    <>
      <div id='card-thumbnail' className='container' onContextMenu={handleClick} style={{ cursor: 'context-menu' }}>
        <div className='row row-cols-1'>
          {data != null
            ? (data.map((item, index) => (
              <div className='col' key={index}>
                <div style={{ height: '100%' }}>
                  <a
                    href='#'
                    style={{ zIndex: '100' }}
                    onClick={() => {
                      getPageRequest(index, item.id)
                    }}
                    className='thumbnail_1ehclew'
                  >
                    <div className={getImageContainerClass(index)}>
                      <img
                        src={item.thumb}
                        className={classes.thumbnail}
                        alt=''
                        loading="eager"
                      />
                      <div className={getImageOverlayClass(index)} />
                    </div>
                  </a>
                  <div className='doc-center'><span className='doc-page-label'>Page  {index + 1}</span>
                  </div>
                </div>
              
                  {index == currentIndex &&  
                    <Menu
                    keepMounted
                    open={state.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                      state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                    }
                  >
                  <MenuItem > <b>Page  {index + 1} </b></MenuItem>
                
                  <MenuItem disabled={index == 0}   onClick={()=>{MoveUp(item.id)}}><MoveUpIcon/> Move Up</MenuItem> 
                  <MenuItem disabled={index == data.length-1}
                  onClick={()=>{MoveDown(item.id)}}><MoveDownIcon/> Move Down</MenuItem>
                  <MenuItem onClick={()=>{RotateRight(item.id)}}><RotateRightIcon/> Rotate Right</MenuItem>
                  <MenuItem onClick={()=>{RotateLeft(item.id)}}><RotateLeftIcon/> Rotate Left</MenuItem>
                  <MenuItem  onClick={()=>{deletePage(item.id)}}><DeleteIcon/> Delete</MenuItem>
                  <MenuItem onClick={handleClose}><CloseIcon/> Close</MenuItem>
                  </Menu>
                  
                  }
                
              </div>

            )))

            : <></>}
        </div>

      </div>
    </>
  )
}

export default PageDocumentSlider
