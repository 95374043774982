import React, { useEffect, useState } from "react";
import ShortTextViewDocPlace from "./view";

const SignatureDocPlace = ({
  callbackIsChecked,
  isChecked,
  title,
  id,
  getDescription,
  content,
  getTitle,
  type,
  viewPdf,
  handleDelete,
  onMouseEnter,
  onMouseLeave,
  _title,
  _description,
  highlightItem,
  pdf,
}) => {
  const [show, setShow] = useState(pdf === true);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    setHighlight(highlightItem);
  }, [highlightItem]);

  const changeChecked = (event) => {
    callbackIsChecked(event.target.checked);
  };

  const onChangeTitle = (e) => {
    getTitle(e.target.value, id);
  };

  const onChangeDescription = (e) => {
    getDescription(e.target.value, id);
  };

  const onChangeViewPdf = () => {
    setShow(!show);
    viewPdf(show, id);
  };

  const onChangeDelete = () => {
    handleDelete(id);
  };

  const onMouseEnterEvent = () => {
    onMouseEnter(id, true);
    setHighlight(true);
  };

  const onMouseLeaveEvent = () => {
    onMouseLeave(id, false);
    setHighlight(false);
  };

  return (
    <div
      className={highlight === true && "highlighted"}
      onMouseEnter={onMouseEnterEvent}
      onMouseLeave={onMouseLeaveEvent}
    >
      <ShortTextViewDocPlace
        changeChecked={changeChecked}
        isChecked={isChecked}
        title={title}
        type={type}
        onChangeTitle={onChangeTitle}
        onChangeDescription={onChangeDescription}
        onChangeDelete={onChangeDelete}
        onChangeViewPdf={onChangeViewPdf}
        viewPdf={show}
        disabled={true}
        _title={_title}
        _description={_description}
      />
    </div>
  );
};

export default SignatureDocPlace;
