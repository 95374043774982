import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../../components/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 35px;
  border-radius: 4px;
  margin-top: 20px;
  display: inline-block;
`;

export const WrapperIcon = styled.div`
  width: 38px;
  height: 36px;
  border-radius: 4px;
  margin-right: 12px;
  float: left;
  cursor: pointer;
`;
export const WrapperPathMapUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
`;

export const WrapperPathMapLi = styled.li`
  float: left;
  padding: 0;
  margin: 9px 0 0 10px;
  color: #006ac6;
  cursor: pointer;
`;

export const WrapperPathMapSpan = styled.span`
  margin-right: 9px;
`;

export const useStyles = makeStyles((theme) => ({
  inActiveTree: {
    backgroundColor: "#fff",
  },
  activeTree: {
    backgroundColor: "#006ac6",
  },
  refresh: {
    backgroundColor: "rgb(251, 167, 48)",
    padding: "9px",
  },
  downloadZip: {
    backgroundColor: colors.white,
    padding: "9px",
  },
  optionMenu: {
    border: "1px #006ac63 solid",
    padding: "9px",
    marginLeft: "13px",
    backgroundColor: "#fff",
  },
  rootFolder: {
    padding: "9px",
    backgroundColor: "rgb(255 255 255)",
    width: "38px",
    height: "36px",
    borderRadius: "4px",
    float: "left",
  },
  iconRefresh: {
    fontSize: "18px",
    color: "#fff",
  },
  up: {
    backgroundColor: "#fff",
    padding: "9px",
  },
  iconUp: {
    fontSize: "18px",
    paddingLeft: "1px",
    color: "#006ac6",
  },
  btnUpload: {
    textAlign: "right",
    marginTop: "5px",
  },
  PathMapIconFolder: {
    width: "23px",
    height: "28px",
    padding: "8px 0px 0px 0px",
    float: "left",
    cursor: "pointer",
    margin: "-8px 0px 0px -1px",
  },
  PathActive: {
    color: "#8b8b8b !important",
  },
}));
