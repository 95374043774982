import React, { useEffect, useState } from "react";
import {
  Wrapper,
  Title,
  useStyles,
  ListAttributesLI,
  ListAttributes,
  TitleField,
} from "./style";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputDocPlace from "../../../../components/form/input";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fieldSave, getFields } from "../../../../services/ocr";
import SaveIcon from "@mui/icons-material/Save";

let listFieldsTemp = [];

export function ManualFieldsDocPlace(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const notify = (msj, event) => toast[event](msj);
  const [fieldNew, setFieldNew] = useState({ value: "", error: false });
  const [listFields, setlistFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (listFieldsTemp.length > 0) {
      setlistFields(listFieldsTemp);
    }
  }, [listFieldsTemp]);

  useEffect(() => {
    setLoading(true);
    getFields(props.documentTypeID, (event) => {
      //
    })
      .then((response) => {
        let fields = listFields;


        if (response.data.data.length > 0) {
          let obj = response.data.data[0];
          Object.keys(obj).forEach(key => {

            fields.push({
              fieldKey: obj[key].alias,
              fieldLabel: obj[key].label,
              fieldType: "string",
              fieldFormat: "not-specified",
              origin: "custom",
            });
          })





          setlistFields(fields);
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [])

  const getSnakeCase = (value) => {
    let v = value
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("_");

    return v;
  };

  const addField = () => {
    let fields = listFields;
    if (fieldNew.value) {
      let newAlias = fieldNew.value;
      fields.push({
        fieldKey: getSnakeCase(newAlias),
        fieldLabel: newAlias,
        fieldType: "string",
        fieldFormat: "not-specified",
        origin: "custom",
      });
      setFieldNew({ value: "", error: false });
      setlistFields(fields);
    } else {
      notify("Enter Attribute value", "error");
    }
  };

  const removeField = (id) => {
    let ary = [];
    listFields.map(function (list, i) {
      if (id != i) {
        ary.push(list);
      }
    });
    setlistFields(ary);
    setFieldNew({ value: "", error: false });
  };

  const saveAttributes = () => {
    if (listFields.length > 0) {



      fieldSave(props.documentTypeID, listFields, (event) => {
        setLoading(true);
      })
        .then((response) => {
          toast.success("Attibutes Saved");
          navigate("/documentType");
          setFieldNew({ value: "", error: false });
          setlistFields([]);
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Error saved the fields: " + err);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Add at least one attribute!");
    }
  };

  return (
    <Wrapper>
      <Title>
        You can manually add attributes to your documents to index them. Add the
        attributes that will be used in the documents linked to this DocType.
      </Title>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <InputDocPlace
            id={"fieldNew"}
            field={fieldNew}
            setField={setFieldNew}
            required={true}
            type="text"
            label="Attribute"
            helperText="Enter your Attribute"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            style={{
              height: "58px",
              backgroundColor: "#006ac6",
              color: "#fff",
            }}
            variant="outlined"
            onClick={addField.bind(this)}
            startIcon={<AddIcon />}
          >
            Add Attribute
          </Button>
        </Grid>
      </Grid>
      <TitleField>Attributes list</TitleField>



      <ListAttributes>
        {listFields.map((f, idx) => (
          <ListAttributesLI key={idx}>
            {f.fieldLabel}
            <RemoveCircleOutlineIcon
              onClick={removeField.bind(this, idx)}
              className={classes.removeField}
            />
          </ListAttributesLI>
        ))}
      </ListAttributes>
      <Button
        style={{
          height: "35px",
          float: "right",
          marginTop: "100px",
          backgroundColor: "#119949",
          color: "#fff",
        }}
        variant="outlined"
        onClick={saveAttributes.bind(this)}
        startIcon={<SaveIcon />}
      >
        SAVE
      </Button>
    </Wrapper>
  );
}

export default ManualFieldsDocPlace;
