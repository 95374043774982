import React from "react";
import Button from "@mui/material/Button";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { colors } from "../../../../components/theme";

import {
  ButtonContainerInferior,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  DateWrap,
  Title,
  useStyles,
} from "./style";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const DatePickerFormView = (props) => {
  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <Title>{props.title}</Title>
        <ButtonContainerInferior>
          {props.viewPdf ? (
            <Button
              variant="text"
              onClick={props.onChangeViewPdf}
              style={{
                width: "175px",
                textTransform: "none",
                backgroundColor: props.notSeeIntoPdfDp && colors.gray3,
              }}
              startIcon={<VisibilityOffIcon />}
            >
              Hide in PDF
            </Button>
          ) : (
            <Button
              variant="text"
              onClick={props.onChangeViewPdf}
              style={{
                width: "175px",
                textTransform: "none",
                backgroundColor: props.notSeeIntoPdfDp && colors.gray3,
              }}
              startIcon={<VisibilityIcon />}
            >
              Show in PDF
            </Button>
          )}
        </ButtonContainerInferior>
        <ContainerTextField>
          <TextField
            placeholder="Click to Enter Title"
            value={props._title}
            fullWidth={true}
            variant="standard"
            size="small"
            onChange={props.onChangeTitle}
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: "12px",
                fontWeight: "bold",
              },
            }}
          />
        </ContainerTextField>

        <DateWrap>
          <MobileDatePicker
            id={props.id}
            disabled={true}
            value={new Date()}
            renderInput={(params) => <TextField {...params} />}
            onChange={props.setDate}
          />
        </DateWrap>

        <TextField
          id="helpTestDescription"
          placeholder="Click to Enter Description"
          fullWidth={true}
          value={props._description}
          variant="standard"
          onChange={props.onChangeDescription}
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: "12px",
              fontWeight: "lighter",
            },
          }}
        />

        <ButtonContainerTools>
          <FormControlLabel
            required
            control={
              <Checkbox
                checked={props.isChecked}
                onChange={props.changeChecked}
              />
            }
            label="Mandatory."
          />
          <Button
            onClick={props.onChangeDelete}
            variant={"text"}
            color="error"
            style={{
              textTransform: "none",
            }}
            startIcon={<DeleteForeverIcon />}
          >
            Delete
          </Button>
        </ButtonContainerTools>
      </Container>
    </LocalizationProvider>
  );
};

export default DatePickerFormView;
