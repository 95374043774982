import MetaTags from "react-meta-tags"
import React, { useRef, useEffect, useState } from "react"
import TextField from "@material-ui/core/TextField";
import Button from "@mui/material/Button";
import {
    useStyles,
    Wrapper,
    WrapperSignature

} from './style';
import { Container } from "reactstrap"




const Signature = (props) => {
    const classes = useStyles();

    const canvas = useRef();
    let ctx = null;

    const [signature, setSignature] = useState('Docplace');


    // initialize the canvas context
    useEffect(() => {
        // dynamically assign the width and height to canvas
        const canvasEle = canvas.current;
        canvasEle.width = canvasEle.clientWidth;
        canvasEle.height = canvasEle.clientHeight;

        // get context of the canvas
        ctx = canvasEle.getContext("2d");
    }, []);


    // write a text
    const writeText = (info, style = {}) => {
        const { text, x, y } = info;
        const { fontSize = 30, fontFamily = 'Mulish', color = 'black', textAlign = 'left', textBaseline = 'top' } = style;

        ctx.beginPath();
        ctx.font = fontSize + 'px ' + fontFamily;
        ctx.font = "normal 40px   'Mr Dafoe', cursive";
        ctx.textAlign = textAlign;
        ctx.textBaseline = textBaseline;
        ctx.fillStyle = color;
        ctx.fillText(text, x, y);
        ctx.stroke();
    }
    const handleInputChange = (text) => {
        writeText({ text: text, x: 10, y: 100 });
    }

    const onClear = () => {
        const canvasEles = canvas.current;
        canvasEles.width = canvasEles.clientWidth;
        canvasEles.height = canvasEles.clientHeight;

        // get context of the canvas
        ctx = canvasEles.getContext("2d");

        ctx.beginPath();
        ctx.clearRect(10, 100, canvasEles.width, canvasEles.height)
     
    }
    return (
        <React.Fragment>
            <MetaTags>
                <title>Signature</title>
            </MetaTags>
            <div className="auth-page">
                <Container fluid className="p-0">

                    <Wrapper>
                        <WrapperSignature>
                            <h1>Signature</h1>

                            <h3>Write text on Canvas</h3>
                            <TextField
                                style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                    marginBottom: "10px",
                                }}
                                id="delete"
                                variant="outlined"
                                onChange={(e) => handleInputChange(e.target.value)}
                                label=""
                            />
                            <button onClick={()=> onClear()}>Clear</button>

                    

                            <canvas ref={canvas}></canvas>
                        </WrapperSignature>
                    </Wrapper>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Signature
