import { call, put, takeEvery } from "redux-saga/effects"
import {TREE_LIST} from "./actionTypes"
import { apiErrorTree, treeSuccess } from "./actions"

import {
  getTree,
} from "../../helpers/call_api"


function* getTreeList({ payload: { tree, navigate } }) {
  try {
    const response = yield call(getTree, tree.parentArchiveId, tree.direction, tree.hidenReservedFolder)
    yield put(treeSuccess(response))
  } catch (error) {
    if(error.response?.status){
      if(error.response.status===401){
        navigate('/login');
      }
    }else{
      console.log('ERROR: ',error);
    }
     yield put(apiErrorTree(error))
  }
}


function* authSaga() {
  yield takeEvery(TREE_LIST, getTreeList)
}

export default authSaga
