import React from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

export const MetadataForm = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const dynamicContent = [
      {
        content: {
          [data.key]: data.value,
        },
        comments: "", // Asignar un valor vacío al campo "comments"
      },
    ];

    props.onSendMetadata(dynamicContent);
    reset();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          width: "75%",
          marginTop: "7px",
        }}
      >
        <div>
          <TextField
            id="content"
            label="Add label"
            variant="outlined"
            {...register("key", { required: "Label is required" })}
            error={!!errors.key}
            helperText={errors.key ? errors.key.message : ""}
            size={"small"}
            fullWidth={true}
          />
        </div>

        <div>
          <TextField
            id="comments"
            label="Add value"
            variant="outlined"
            {...register("value", { required: "Value is required" })}
            error={!!errors.value}
            helperText={errors.value ? errors.value.message : ""}
            size={"small"}
            fullWidth={true}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button type="submit" variant="contained" endIcon={<SaveIcon />}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
