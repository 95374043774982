import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Message, useStyles, WrapperForm } from "./style";
import LoadingComponent from "../../../../components/loading";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import InputFormDocPlace from "../../../../components/form/input";
import SelectDropdownDocPlace from "../../../../components/form/selectDropdown";
import {
  postInputMethod,
  putInputMethod,
} from "../../../../store/inputMethod/actions";
import { getDocTypeList1 } from "../../../../store/docType/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const OriginTypeList = [
  { value: "SCANNER", label: "SCANNER" },
  { value: "WEB_UI", label: "WEB_UI" },
];

export function NewItemDocPlace(props) {
  const classes = useStyles();
  const [showNew, setShowNew] = useState(false);
  const [name, setName] = useState({ value: "", error: false });
  const [description, setDescription] = useState({ value: "", error: false });
  const [physicalName, setPhysicalName] = useState({ value: "", error: false });
  const [documentType, setDocumentType] = useState({ value: "", error: false });
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [originID, setOriginID] = useState({ value: "", error: false });
  const [originType, setOriginType] = useState({ value: "", error: false });
  const [defaultData, setDefaultData] = React.useState("");

  const notify = (msj, event) => toast[event](msj);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { responseInputMethodPost, errorInputMethodPost, loading } =
    useSelector((state) => ({
      responseInputMethodPost: state.InputMethod.responseInputMethodPost,
      errorInputMethodPost: state.InputMethod.errorInputMethodPost,
      loading: state.InputMethod.loadingInputMethodPost,
    }));
  const { responseInputMethodPut, errorInputMethodPut, loading2 } = useSelector(
    (state) => ({
      responseInputMethodPut: state.InputMethod.responseInputMethodPut,
      errorInputMethodPut: state.InputMethod.errorInputMethodPut,
      loading2: state.InputMethod.loadingInputMethodPut,
    })
  );

  const { responseDocType1, errorDocType1, loading3 } = useSelector(
    (state) => ({
      responseDocType1: state.DocType.responseDocType1,
      errorDocType1: state.DocType.errorDocType1,
      loading3: state.DocType.loadingDocType1,
    })
  );

  useEffect(() => {
    if (responseDocType1) {
      let ob = [];
      {
        responseDocType1.data.map((doctype) =>
          ob.push({ value: doctype.id, label: doctype.name })
        );
      }
      setDocumentTypeList(ob);
    }
  }, [responseDocType1]);
  useEffect(() => {
    setDefaultData(props.default);
    if (props.default) {
      setName({ value: props.default.name, error: false });
      setDescription({ value: props.default.description, error: false });
      setPhysicalName({ value: props.default.physicalName, error: false });
      setOriginID({ value: props.default.originId, error: false });
      setOriginType({ value: props.default.originType, error: false });
      setDocumentType({ value: props.default.docType, error: false });
    } else {
      clearForm();
    }
    setShowNew(props.show);
  }, [props.default]);
  useEffect(() => {
    setShowNew(props.show);
    dispatch(
      getDocTypeList1(
        {
          page: 1,
          pageSize: 1000,
          sortBy: "name",
          sortOrder: 1,
          filtered: true,
        },
        navigate
      )
    );
  }, [props.show]);

  useEffect(() => {
    if (responseInputMethodPost !== null) {
      clearForm();
      close();
      props.done(true);
    }
  }, [responseInputMethodPost]);
  useEffect(() => {
    if (responseInputMethodPut) {
      clearForm();
      close();
      props.done(true);
    }
  }, [responseInputMethodPut]);

  const close = () => {
    props.close();
  };

  const clearForm = () => {
    setName({ value: "", error: false });
    setDescription({ value: "", error: false });
    setPhysicalName({ value: "", error: false });
    setDocumentType({ value: "", error: false });
    setOriginID({ value: "", error: false });
    setOriginType({ value: "", error: false });
  };

  const newAcction = () => {
    if (
      !name.error &&
      name.value &&
      !description.error &&
      description.value &&
      !physicalName.error &&
      physicalName.value &&
      documentType.value.value &&
      !documentType.error &&
      originType.value.value &&
      !originType.error &&
      originID.value
    ) {
      if (defaultData) {
        let obj = {
          doctypeId: documentType.value.value,
          inputMethodType: 1,
          methodName: description.value,
          description: description.value,
          originId: originID.value,
          originType: originType.value.value,
          values: {
            name: name.value,
            physicalName: physicalName.value,
          },
        };
        dispatch(putInputMethod({ id: defaultData.id, data: obj }, navigate));
      } else {
        let obj = {
          doctypeId: documentType.value.value,
          inputMethodType: 1,
          methodName: description.value,
          originId: originID.value,
          originType: originType.value.value,
          values: {
            name: name.value,
            physicalName: physicalName.value,
          },
        };
        dispatch(postInputMethod(obj, navigate));
      }
    } else {
      notify("Please enter all the required information", "error");
    }
  };

  return (
    <Dialog
      fullScreen
      open={showNew}
      onClose={close}
      TransitionComponent={Transition}
    >
      <LoadingComponent
        show={loading || loading2 || loading3}
        text={
          defaultData
            ? "Updating..."
            : loading3
            ? "Getting Document Types"
            : "Creating..."
        }
      />
      <AppBar sx={{ position: "relative" }}>
        <Toolbar style={{ backgroundColor: "#006ac6" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {defaultData ? "Update Input Method" : "New Input Method"}
          </Typography>
          <Button autoFocus color="inherit" onClick={newAcction}>
            {defaultData ? "Update" : "Create"}
          </Button>
        </Toolbar>
      </AppBar>
      <article style={{ margin: "30px" }}>
        <Message>
          To add a new input method, all files marked as required must be
          completed.
        </Message>
        <WrapperForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFormDocPlace
                id={"name"}
                field={name}
                setField={setName}
                required={true}
                type="text"
                label="Name"
                helperText="The name can include only letters and numbers."
                expresionRegular={/^[a-zA-Z0-9 ]*$/}
              />
            </Grid>
            <Grid item xs={6} className={classes.row}>
              <InputFormDocPlace
                id={"physicalName"}
                field={physicalName}
                setField={setPhysicalName}
                required={true}
                type="text"
                label="Physical Name"
                helperText="Enter Physical Name"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectDropdownDocPlace
                id={"documentType"}
                field={documentType}
                setField={setDocumentType}
                defaultList={props.default?.docType}
                required={true}
                label="Document Type"
                helperText="Select Document Type!"
                arrayList={documentTypeList}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFormDocPlace
                id={"origin8Id"}
                field={originID}
                setField={setOriginID}
                required={true}
                type="text"
                label="Origin Id"
                helperText="Enter Origin Id!"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectDropdownDocPlace
                id={"originType"}
                field={originType}
                setField={setOriginType}
                defaultList={props.default?.originType}
                required={true}
                label="Origin Type"
                helperText="Select Origin Type!"
                arrayList={OriginTypeList}
              />
            </Grid>
            <Grid item xs={12}>
              <InputFormDocPlace
                id={"description"}
                field={description}
                setField={setDescription}
                required={true}
                type="text"
                label="Method Description"
                helperText="Enter Method Description!"
              />
            </Grid>
          </Grid>
        </WrapperForm>
      </article>
    </Dialog>
  );
}

export default NewItemDocPlace;
