import React, { useState } from "react";
import HeaderDocPlaceForms from "../../components/forms_module/tools/header";
import Paragraph from "../../components/forms_module/tools/paragraph";
import DatePickerForms from "../../components/forms_module/tools/datePickerForm";
import ShortTextDocPlace from "../../components/forms_module/tools/shortText";
import SelectListForms from "../../components/forms_module/tools/selectList";
import CheckBox from "../../components/forms_module/tools/checkBox";
import RadioButtonForm from "../../components/forms_module/tools/radioButton";

const ComponentForms = () => {
  // Header Component
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleHeader, setTitleHeader] = useState("");
  const [subTitleHeader, setSubTitleHeader] = useState("");
  const [notSeeIntoPdfHeader, setNotSeeIntoPdfHeader] = useState(false);
  const [deleteHeaderRes, setDeleteHeaderRes] = useState(false);
  const [settingHeaderRes, setSettingHeaderRes] = useState(false);
  // Paragraph Component
  const [content, setContent] = useState("");
  const [notSeeIntoPdfParagraph, setNotSeeIntoPdfParagraph] = useState(false);
  const [deleteParagraphRes, setDeleteParagraphRes] = useState(false);
  const [settingParagraphRes, setSettingParagraphRes] = useState(false);

  // DatePicker Component
  const [dateForm, setDateForm] = useState({ value: "" });
  const [helperText, setHelperText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [notSeeIntoPdfDp, setNotSeeIntoPdfDp] = useState(false);
  const [deleteDatePicker, setDeleteDateP] = useState(false);
  const [settingDatePicker, setSettingDateP] = useState(false);

  // Short Text
  const [shortText, setShortText] = useState("");
  const [helperShortText, setHelperShortText] = useState("");
  const [descriptionShortText, setDescriptionShortText] = useState("");
  const [notSeeIntoPdfResponse, setNotSeeIntoPdfResponse] = useState(false);
  const [deleteShortTextResponse, setDeleteShortTextResponse] = useState(false);
  const [settingShortTextResponse, setSettingShortTextResponse] =
    useState(false);
  const [isChecked, setIsChecked] = useState(true);
  // SELECT COMPONENT
  const [selectOption, setSelectOption] = React.useState([]);
  const [titleSelect, setTitleSelect] = useState("");
  const [subTitleSelect, setSubTitleSelect] = useState("");
  const [notSeeIntoPdfSelectRes, setNotSeeIntoPdfSelectRes] = useState(false);
  const [deleteSelectRes, setDeleteSelectRes] = useState(false);
  const [settingSelectRes, setSettingSelectRes] = useState(false);
  const [listData] = useState([
    {
      order: "1",
      label: "Campo 1",
    },
    {
      order: "2",
      label: "Campo 2",
    },
    {
      order: "3",
      label: "Campo 3",
    },
    {
      order: "4",
      label: "Campo 4",
    },
  ]);
  // SELECT COMPONENT END

  // RADIO BUTTON COMPONENT
  const [radioOption, setRadioOption] = React.useState({ label: "" });
  const [titleRadioB, setTitleRadioB] = useState("");
  const [subTitleRadioB, setSubTitleRadioB] = useState("");
  const [notSeeIntoPdfRadioB, setNotSeeIntoPdfRadioB] = useState(false);
  const [deleteRadioB, setDeleteRadioB] = useState(false);
  const [settingRadioB, setSettingRadioB] = useState(false);
  const [item] = useState([
    {
      order: 1,
      value: "female",
      label: "Female",
      disabled: false,
    },
    {
      order: 2,
      value: "male",
      label: "Male",
      disabled: false,
    },
    {
      order: 3,
      value: "other",
      label: "Other",
      disabled: true,
    },
  ]);
  // RADIO BUTTON COMPONENT END

  // CHECK BOX COMPONENT
  const [checkBoxSelect, setCheckBoxSelect] = useState("");
  const [checkBoxLabel] = useState("asdfasdfasdf");
  const [titleCheckBox, setTitleCheckBox] = useState("");
  const [subTitleCheckBox, setSubTitleCheckBox] = useState("");
  const [notSeeIntoPdfCheckB, setNotSeeIntoPdfCheckB] = useState(false);
  const [deleteCheckB, setDeleteCheckB] = useState(false);
  const [settingCheckB, setSettingCheckB] = useState(false);
  const [checkItem] = useState([
    {
      order: 1,
      value: "female",
      label: "Female",
    },
    {
      order: 2,
      value: "male",
      label: "Male",
    },
    {
      order: 3,
      value: "other",
      label: "Other",
    },
  ]);

  // HEADER COMPONENT
  const getTitle = (value) => {
    setTitle(value);
  };

  const getTitleHeader = (value) => {
    setTitleHeader(value);
  };

  const getDescription = (value) => {
    setDescription(value);
  };

  const getSubTitleHeader = (value) => {
    setSubTitleHeader(value);
  };

  const notIntoPdfHeader = (v) => {
    setNotSeeIntoPdfHeader(v);
  };

  const deleteHeader = (v) => {
    setDeleteHeaderRes(v);
  };

  const settingHeader = (v) => {
    setSettingHeaderRes(v);
  };
  // HEADER COMPONENT END

  // Paragraph Component
  const getContent = (value) => {
    setContent(value);
  };

  const notIntoPdfParagraph = (v) => {
    setNotSeeIntoPdfParagraph(v);
  };

  const deleteParagraph = (v) => {
    setDeleteParagraphRes(v);
  };

  const settingParagraph = (v) => {
    setSettingParagraphRes(v);
  };
  // Paragraph Component END

  //   DatePicker Component
  const getHelperText = (value) => {
    setHelperText(value);
  };

  const getDescriptionText = (value) => {
    setDescriptionText(value);
  };

  const notIntoPdfDp = (v) => {
    setNotSeeIntoPdfDp(v);
  };

  const deleteDateP = (v) => {
    setDeleteDateP(v);
  };

  const settingDateP = (v) => {
    setSettingDateP(v);
  };

  // DatePicker Component END

  // short text

  const callbackIsChecked = (value) => {
    setIsChecked(value);
  };

  const getShortText = (value) => {
    setShortText(value);
  };

  const getHelpText = (value) => {
    setHelperShortText(value);
  };

  const getDescriptionHelpText = (value) => {
    setDescriptionShortText(value);
  };

  const responseNotIntoPdf = (v) => {
    setNotSeeIntoPdfResponse(v);
  };

  const responseDeleteShortText = (v) => {
    setDeleteShortTextResponse(v);
  };

  const responseSettingShortText = (v) => {
    setSettingShortTextResponse(v);
  };
  //short text END

  // SELECT COMPONENT
  const getSelect = (value) => {
    setSelectOption(value);
  };

  const getTitleSelect = (value) => {
    setTitleSelect(value);
  };

  const getSubTitleSelect = (value) => {
    setSubTitleSelect(value);
  };

  const notIntoPdfSelect = (v) => {
    setNotSeeIntoPdfSelectRes(v);
  };

  const deleteSelect = (v) => {
    setDeleteSelectRes(v);
  };

  const settingSelect = (v) => {
    setSettingSelectRes(v);
  };
  // END SELECT COMPONENT

  // CHECK BOX COMPONENT
  const getCheckBoxSelect = (value) => {
    setCheckBoxSelect(value);
  };

  const getTitleCheckBox = (value) => {
    setTitleCheckBox(value);
  };

  const getSubTitleCheckBox = (value) => {
    setSubTitleCheckBox(value);
  };

  const notIntoPdfCheckB = (v) => {
    setNotSeeIntoPdfCheckB(v);
  };

  const deleteCheckBox = (v) => {
    setDeleteCheckB(v);
  };

  const settingCheckBox = (v) => {
    setSettingCheckB(v);
  };
  // END CHECKBOX COMPONENT

  // RADIO BUTTON COMPONENT
  const getRadioB = (value) => {
    setRadioOption(value);
  };

  const getTitleRadioButton = (value) => {
    setTitleRadioB(value);
  };

  const getSubTitleRadioButton = (value) => {
    setSubTitleRadioB(value);
  };

  const notIntoPdfRadioB = (v) => {
    setNotSeeIntoPdfRadioB(v);
  };

  const deleteRadioButton = (v) => {
    setDeleteRadioB(v);
  };

  const settingRadioButton = (v) => {
    setSettingRadioB(v);
  };

  // RADIO BUTTON COMPONENT END

  return (
    <div style={{ width: "1000px", margin: "0 auto" }}>
      <section>
        <h1>Header Component</h1>
        <HeaderDocPlaceForms
          getTitle={getTitle}
          getDescription={getDescription}
          getTitleHeader={getTitleHeader}
          getSubTitle={getSubTitleHeader}
          notSeeIntoPDF={notIntoPdfHeader}
          notIntoPdfHeader={notSeeIntoPdfHeader}
          deleteHeader={deleteHeader}
          setting={settingHeader}
        />
        <HeaderDocPlaceForms
          type={"view"}
          title={title}
          description={description}
          getTitle={getTitle}
          getDescription={getDescription}
          titleHeader={titleHeader}
          subTitleHeader={subTitleHeader}
        />
      </section>
      <section>
        <h1>Paragraph</h1>
        <Paragraph
          getContent={getContent}
          notSeeIntoPDF={notIntoPdfParagraph}
          deleteParagraph={deleteParagraph}
          settingParagraph={settingParagraph}
          notSeeIntoPdfParagraph={notSeeIntoPdfParagraph}
        />
        <Paragraph type={"view"} content={content} />
      </section>
      <section>
        <h1>Datepicker</h1>
        <DatePickerForms
          id={"demo"}
          label={"Select date"}
          getHelperText={getHelperText}
          getDescriptionText={getDescriptionText}
          notSeeIntoPDFDp={notIntoPdfDp}
          deleteDateP={deleteDateP}
          settingDateP={settingDateP}
          notSeeIntoPdfDp={notSeeIntoPdfDp}
          field={dateForm}
          setField={setDateForm}
          disabled={true}
          disableFuture={false}
          disablePast={false}
          inputFormat={"MM/dd/yyyy"}
          // getContentDataPicker={getContentDataPicker}
        />
        <DatePickerForms
          id={"demo"}
          label={"Select date"}
          type={"view"}
          getHelperText={getHelperText}
          getDescriptionText={getDescriptionText}
          helperText={helperText}
          descriptionText={descriptionText}
          field={dateForm}
          setField={setDateForm}
          disabled={false}
          disableFuture={false}
          disablePast={false}
          inputFormat={"MM/dd/yyyy"}
          // getContentDataPicker={getContentDataPicker}
        />
      </section>
      <section>
        <h1>Short Text and Number</h1>
        <ShortTextDocPlace
          callbackIsChecked={callbackIsChecked}
          isChecked={isChecked}
          getShortText={getShortText}
          getHelpText={getHelpText}
          label={"Short Text"}
          getDescriptionHelpText={getDescriptionHelpText}
          notSeeIntoPDF={responseNotIntoPdf}
          deleteShortText={responseDeleteShortText}
          settingShortText={responseSettingShortText}
          notSeeIntoPdfResponse={notSeeIntoPdfResponse}
          disabled={true}
        />
        <ShortTextDocPlace
          callbackIsChecked={callbackIsChecked}
          isChecked={isChecked}
          mode={"view"}
          type={"text"} //put "number" if you want it to accept only numbers
          getShortText={getShortText}
          label={"Short Text"}
          placeholder={"Enter Short Text"}
          disabled={false}
          shortText={shortText}
          helperShortText={helperShortText}
          descriptionShortText={descriptionShortText}
        />
      </section>
      <section>
        <h1>Select Component</h1>
        <SelectListForms
          id={"Demo"}
          options={listData}
          defaultValue="Select Option"
          variant="filled"
          text="Select Option"
          disable={true}
          getSelect={getSelect}
          getTitleSelect={getTitleSelect}
          getSubTitleSelect={getSubTitleSelect}
          notSeeIntoPDF={notIntoPdfSelect}
          notSeeIntoPdfSelectRes={notSeeIntoPdfSelectRes}
          deleteSelect={deleteSelect}
          settingSelect={settingSelect}
          selectOption={selectOption}
        />
        <SelectListForms
          id={"Demo"}
          type="view"
          options={listData}
          getSelect={getSelect}
          defaultValue="Select Option"
          selectOption={selectOption}
          variant="filled"
          text="Select Option"
          titleSelect={titleSelect}
          subTitleSelect={subTitleSelect}
          isMultiple={false}
          required={true}
          error={false}
        />
      </section>
      <section>
        <h1>CheckBox Component</h1>
        <CheckBox
          defaultChecked={false}
          checkBoxLabel={checkBoxLabel}
          getCheckBoxSelect={getCheckBoxSelect}
          checkBoxSelect={checkBoxSelect}
          getTitleCheckBox={getTitleCheckBox}
          getSubTitleCheckBox={getSubTitleCheckBox}
          notIntoPdfCheckB={notSeeIntoPdfCheckB}
          notSeeIntoPDF={notIntoPdfCheckB}
          deleteCheckBox={deleteCheckBox}
          settingCheckBox={settingCheckBox}
        />
        <CheckBox
          type={"view"}
          required={true}
          checkItem={checkItem}
          defaultChecked={false}
          getCheckBoxSelect={getCheckBoxSelect}
          checkBoxSelect={checkBoxSelect}
          titleCheckBox={titleCheckBox}
          subTitleCheckBox={subTitleCheckBox}
        />
      </section>
      <section>
        <h1>Radio Button</h1>
        <RadioButtonForm
          size={"small"} // small-medium
          name={"demo1"}
          disabled={true}
          item={item}
          row={true} // row or colum
          notSeeIntoPDF={notIntoPdfRadioB}
          notSeeIntoPdfRadioB={notSeeIntoPdfRadioB}
          deleteRadioButton={deleteRadioButton}
          settingRadioButton={settingRadioButton}
          getTitleRadioButton={getTitleRadioButton}
          getSubTitleRadioButton={getSubTitleRadioButton}
          getRadioB={getRadioB}
          radioOption={radioOption}
        />
        <RadioButtonForm
          getRadioB={getRadioB}
          name={"demoView"}
          radioOption={radioOption}
          type={"view"}
          size={"small"} // small-medium
          disabled={true}
          item={item}
          row={true} // row or colum
          titleRadioB={titleRadioB}
          subTitleRadioB={subTitleRadioB}
        />
      </section>
    </div>
  );
};

export default ComponentForms;
