import { makeStyles, useTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    buttons: {
        width: '100%',
        display: 'inline-block',
        marginTop: '20px',
        height: '40px',
        color: '#560e1a'
    },  
    btnSave: {
        backgroundColor: "#1876d2 !important",
        margin: "16px 0px 18px 0px !important",
        color: "#fff !important",
        borderBottom: "2px solid #1876d2 !important",
        boxShadow: "0 0 0 !important",
      },
}));


  



