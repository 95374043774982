import React, { useEffect, useState } from "react";

import { WrapperDatePicker } from "./style";
import DatePickerFormView from "./view";

const DatePickerForms = ({
  callbackIsChecked,
  isChecked,
  title,
  id,
  type,
  inputFormat,
  disableFuture,
  disablePast,
  getDescription,
  getTitle,
  viewPdf,
  handleDelete,
  onMouseEnter,
  onMouseLeave,
  _title,
  _description,
  pdf,
  highlightItem,
}) => {
  const [show, setShow] = useState(pdf === true);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    setHighlight(highlightItem);
  }, [highlightItem]);

  const changeChecked = (event) => {
    callbackIsChecked(event.target.checked);
  };

  const onChangeTitle = (e) => {
    getTitle(e.target.value, id);
  };

  const onChangeDescription = (e) => {
    getDescription(e.target.value, id);
  };

  const onChangeViewPdf = () => {
    setShow(!show);
    viewPdf(show, id);
  };

  const onChangeDelete = () => {
    handleDelete(id);
  };

  const onMouseEnterEvent = () => {
    onMouseEnter(id, true);
    setHighlight(true);
  };

  const onMouseLeaveEvent = () => {
    onMouseLeave(id, false);
    setHighlight(false);
  };

  return (
    <div
      className={highlight === true && "highlighted"}
      onMouseEnter={onMouseEnterEvent}
      onMouseLeave={onMouseLeaveEvent}
    >
      <WrapperDatePicker>
        <DatePickerFormView
          changeChecked={changeChecked}
          isChecked={isChecked}
          title={title}
          type={type}
          onChangeTitle={onChangeTitle}
          onChangeDescription={onChangeDescription}
          onChangeDelete={onChangeDelete}
          onChangeViewPdf={onChangeViewPdf}
          viewPdf={show}
          _title={_title}
          _description={_description}
          disabled={true}
          inputFormat={inputFormat}
          disableFuture={disableFuture}
          disablePast={disablePast}
        />
      </WrapperDatePicker>
    </div>
  );
};

export default DatePickerForms;
