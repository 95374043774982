import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import {colors} from "../../../../components/theme";

export const Message = styled.section`
      width: 100%;
      min-height: 50px;
      background-color: ${colors.help};
      margin-bottom: 35px;
      margin-top: 0px;
      border-radius: 5px;
      padding: 12px;
`;

export const WrapperTree = styled.div`
    width: 100%;
    height: calc(69vh);
    border-radius: 6px;
    background-color: #fff;
    display: inline-block;
    padding: 10px;
    overflow:auto;
`;

export const WrapperBackTree = styled.article`
      width: auto;
      min-height: 30px;
      background-color: ${colors.ground};
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      font-weight: 100;
      text-align: left;
      padding-top: 5px;
      padding-left: 0px;
      margin-left: 15px;
      color: ${colors.Title2};
      padding-bottom: 7px;
`;

export const TreeUl = styled.ul`
    list-style: none;
    margin: 0px 0px 0px 0px;
    padding:0;
`;

export const TreeLi = styled.li`
    margin: 20px 0 10px 20px;
    min-width: 150px;
    cursor:pointer;
    border-radius: 5px;
    &:hover {
      border-bottom:2px solid #1976d22e;
    }
`;

export const TreeLiText = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#006ac6;
    display: inline-block;
    margin-top: 9px;
    margin-left: -4px;
    max-width: 75%;
`;

export const ChooseFolder = styled.span`
      width: 100px;
      height: 25px;
      color: ${colors.blue};
      font-size: 12px;
      display: inline-block;
      padding-top: 3px;
      text-align: left;
      border-radius: 7px;
      margin-left: 25px;
`;

export const WrapperForm = styled.article`
      width: 75%;
      margin: 0 auto;
      height: calc(100vh - 400px);
      border-radius: 5px;
      padding: 20px;
`;

export const useStyles = makeStyles((theme) => ({
    buttons: {
        width: '100%',
        display: 'inline-block',
        marginTop: '20px',
        height: '40px',
        color: '#560e1a'
    },
    row: {
        boxShadow: '0 0 0 !important'
    }
}));


  



