import React, { useEffect, useState } from "react";
import TitleDocPlace from "../../../components/title/title";
import LoadingComponent from "../../../components/loading";
import SearchDocPlace from "../../../components/search/search";
import { useDispatch, useSelector } from "react-redux";
import DataGridDemo from "./resource/grid/grid";
import { Wrapper } from "./style";
import { getRequiredProcess } from "../../../store/workFlow/actions";

export const RequestedWorkflow = () => {
  const dispatch = useDispatch();
  const [documentName, setDocumentName] = useState([]);
  const [userRequiredSignature, setUserRequiredSignature] = useState(true);
  const [rows, setRows] = useState([]);

  const {
    responseRequiredSignatureDocument,
    loadingRequiredSignatureDocument,
  } = useSelector((state) => ({
    responseRequiredSignatureDocument:
      state.UserValidateSignature.responseRequiredSignatureDocument,
    loadingRequiredSignatureDocument:
      state.UserValidateSignature.loadingRequiredSignatureDocument,
  }));

  const {
    responseGetRequiredProcess,
    loadingGetRequiredProcess,
    errorGetAttachment,
  } = useSelector((state) => ({
    responseGetRequiredProcess:
      state.WorkFlowProcess.responseGetRequiredProcess?.data,
    loadingGetRequiredProcess: state.WorkFlowProcess.loadingGetRequiredProcess,
    errorGetAttachment: state.WorkFlowProcess.errorGetAttachment,
  }));

  useEffect(() => {
    dispatch(getRequiredProcess());
  }, []);

  useEffect(() => {
    dispatch(getRequiredProcess());
  }, []);

  useEffect(() => {
    let newObject = [];
    if (responseGetRequiredProcess?.length > 0) {
      responseGetRequiredProcess?.map((rows, i) => {
        newObject.push({ ...rows, id: i });
      });
      setRows(newObject);
    }
  }, [responseGetRequiredProcess]);

  const findDocumentTypes = (term) => {
    setUserRequiredSignature(
      term
        ? documentName.filter((doc) =>
            doc.document_name.toLowerCase().includes(term)
          )
        : documentName
    );
  };

  const searchFile = (filterSearch) => {
    findDocumentTypes(filterSearch);
  };

  return (
    <React.Fragment>
      <TitleDocPlace
        title={"Documents for approval process"}
        viewActive={false}
      />
      <LoadingComponent
        show={loadingGetRequiredProcess}
        text={"Obtaining documents for approval process..."}
      />
      <SearchDocPlace callSearch={searchFile.bind(this)} />
      <Wrapper>
        <DataGridDemo rows={rows} />
      </Wrapper>
    </React.Fragment>
  );
};
