import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import {
  useStyles,
  Wrapper,
  WrapperLogin,
  LoginFormDocPlace,
  WrapperFormDocPlace,
} from './style';
import { Container, Label } from "reactstrap"
import Button from '@mui/material/Button';
import logoFD from '../../assets/logoColorsDocPlace.png';

import { useSelector, useDispatch } from "react-redux"
import { resetPassword, resetApp } from "../../store/actions"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputDocPlace from "../../components/form/input";
import LoadingComponent from "../../components/loading";
import { expressions } from "../../components/theme";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha"

const ResetPassword = (props) => {
  const classes = useStyles();
  const notify = (msj, event) => toast[event](msj);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [password, setPassword] = useState({ value: '', error: false });
  const [passwordRepeat, setPasswordRepeat] = useState({ value: '', error: false });
  const [passwordErr, setPasswordErr] = useState('');
  const [passwordRepeatErr, setPasswordRepeatErr] = useState('');
  const [hasError, setError] = useState(false);
  const [rMessage, setMessage] = useState("");

  //get url parameters
  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get("hash");
  //behavior page
  const [ready, setReady] = useState(true);
  const { responseReset, errorReset, loading } = useSelector(state => ({  //Hook to have the responses from redux
    responseReset: state.Login.responseResetPassword,
    errorReset: state.Login.errorResetPassword,
    loading: state.Login.loadingResetPassword,
  }));
  const [isRobot, setRobot] = useState(true);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {   //execute a single time
      if (!token) {
        setReady(false);
        setError(true);
        notify("Incorrect request, we can't process your password change in this moment", "error");
      }
    }
    return () => { ignore = true; }
  }, []);

  /**Hook for actions after the success response */
  useEffect(() => {
    if (responseReset) {
      if (responseReset.errors.length > 0) {
        notify("We can't change your password at this moment due expired or incorrect request.", "error");
        setMessage("We can't change your password at this moment due expired or incorrect request.");
        console.error(responseReset.errors[0]);
        setError(true);
      } else {
        notify("Your password was successfully reset.", "success");
        setMessage("Your password was successfully reset, you can now log in with your new password.");
      }
    }
    if (errorReset || responseReset) setReady(false);

  }, [responseReset, errorReset]);


  const handleValidSubmit = (e) => {
    e.preventDefault();
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const passwordLength = password.value.length;
    const uppercasePassword = uppercaseRegExp.test(password.value);
    const lowercasePassword = lowercaseRegExp.test(password.value);
    const digitsPassword = digitsRegExp.test(password.value);
    const minLengthPassword = minLengthRegExp.test(password.value);
    let errMsg = "";
    setPasswordRepeatErr("")
    setPasswordRepeat({ ...password, error: false })


    if (passwordLength === 0) {
      errMsg = "Your new password must be at least 8 characters length and contain uppercase and lowercase characters, and at least one number";
      setPasswordRepeat({ ...password, error: true })
    } else if (!uppercasePassword) {
      errMsg = "Your new password must be at least 8 characters length and contain uppercase and lowercase characters, and at least one number";
      setPasswordRepeat({ ...password, error: true })
    } else if (!lowercasePassword) {
      errMsg = "Your new password must be at least 8 characters length and contain uppercase and lowercase characters, and at least one number";
      setPasswordRepeat({ ...password, error: true })
    } else if (!digitsPassword) {
      errMsg = "Your new password must be at least 8 characters length and contain uppercase and lowercase characters, and at least one number";
      setPasswordRepeat({ ...password, error: true })
    } else if (!minLengthPassword) {
      errMsg = "Your new password must be at least 8 characters length and contain uppercase and lowercase characters, and at least one number";
      setPasswordRepeat({ ...password, error: true })
    } else if (password.value !== passwordRepeat.value) {
      setPasswordRepeatErr("The password does not match");
      setPasswordRepeat({ ...passwordRepeat, error: true });
    }
    else {  //password correct
      errMsg = "";
      let obj = {
        "appId": "DocPlace",
        "data": {
          "confirmPassword": passwordRepeat.value,
          "hashCode": token,
          "password": password.value
        }
      }
      dispatch(resetPassword(obj, navigate));
    }
    setPasswordErr(errMsg);

  }

  /**after a successfully email link generation, the user can navigate back to login */
  const navigateLogin = (e) => {
    setReady(false);
    setError(false);
    dispatch(resetApp());  //reset redux auth to default values, clearing up for next time. 
    navigate("/login");
    setRobot(true);
  }

  const recaptchaOnChange = (value) => {
    setRobot(false);
  }

  const recaptchaExpired = (value) => {
    setRobot(true);
  }


  return (
    <React.Fragment>
      <MetaTags>
        <title>Password Reset</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <ToastContainer autoClose={3000} />
          <LoadingComponent show={loading} text={"Changing Password..."} />
          <Wrapper>
            <WrapperLogin>
              <img alt="docplace" className={classes.imgLogo} src={logoFD} />

              <LoginFormDocPlace onSubmit={handleValidSubmit}>
                <WrapperFormDocPlace>

                  {ready &&
                    <InputDocPlace
                      id={'password'}
                      field={password}
                      setField={setPassword}
                      required={true}
                      type='password'
                      label="Password"
                      expresionRegular={expressions.password}
                      helperText={passwordErr}
                    />}
                  {ready &&
                    <InputDocPlace
                      id={'passwordRepeat'}
                      field={passwordRepeat}
                      setField={setPasswordRepeat}
                      required={true}
                      type='password'

                      label="Repeat"
                      helperText={passwordRepeatErr}
                    />}
                  {(ready && !isRobot) && <Button type="submit" className={classes.buttons} disabled={isRobot} variant="contained" color="success">
                    Set a new password
                  </Button>}
                  {ready &&
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      size="compact"
                      onChange={recaptchaOnChange}
                      onExpired={recaptchaExpired}
                    />}

                  {!ready &&
                    <Label style={{ color: hasError ? 'red' : '' }}>
                      {rMessage}
                    </Label>
                  }
                  <br />
                  <br />
                  {!ready && <Button type="button" onClick={navigateLogin} className={classes.buttons} variant="contained" color="success">
                    Go to login
                  </Button>}

                </WrapperFormDocPlace>
              </LoginFormDocPlace>
            </WrapperLogin>
          </Wrapper>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
