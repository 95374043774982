import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TitleDocPlace from "../../components/title/title";
import SearchDocPlace from "../../components/search/search";
import { IconButtonContainer, WrapperSignature } from "./style";
import DataGridDemo from "./resource/grid/grid";
import {
  getESignatureRequests,
  getESignatureRequestsClearData,
} from "../../store/eSignature/actions";
import LoadingComponent from "../../components/loading";
import RefreshIcon from "@mui/icons-material/Refresh";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

export const EsignatureRequests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eSignatureRequests, setESignatureRequests] = useState(true);
  const [documentName, setDocumentName] = useState([]);

  const { responseESignatureRequests, loadingESignatureRequests } = useSelector(
    (state) => ({
      responseESignatureRequests:
        state.UserValidateSignature.responseESignatureRequests,
      loadingESignatureRequests:
        state.UserValidateSignature.loadingESignatureRequests,
    })
  );

  useEffect(() => {
    let newObject = [];
    if (responseESignatureRequests) {
      responseESignatureRequests?.data?.map((signatureRequests, i) => {
        newObject.push({ ...signatureRequests, id: i });
      });
      setESignatureRequests(newObject);
      setDocumentName(newObject);
    }
    dispatch(getESignatureRequestsClearData());
  }, [responseESignatureRequests]);

  console.log("responseESignatureRequests", responseESignatureRequests);

  useEffect(() => {
    if (!responseESignatureRequests) {
      dispatch(getESignatureRequests());
    }
  }, []);

  const findDocumentTypes = (term) => {
    setESignatureRequests(
      term
        ? documentName.filter((doc) =>
            doc.document_name.toLowerCase().includes(term)
          )
        : documentName
    );
  };

  const searchFile = (filterSearch) => {
    findDocumentTypes(filterSearch);
  };

  const pageRefresh = () => {
    dispatch(getESignatureRequests());
  };

  return (
    <React.Fragment>
      <Container fluid>
        <TitleDocPlace title={"E-signature requests"} viewActive={false} />
        <LoadingComponent
          show={loadingESignatureRequests}
          text={"Obtaining documents.."}
        />
        <SearchDocPlace callSearch={searchFile.bind(this)} />
        <IconButtonContainer>
          <Tooltip title={"Refresh"} arrow={true}>
            <Avatar
              style={{
                backgroundColor: "rgb(251, 167, 48)",
                cursor: "pointer",
              }}
              variant={"rounded"}
              onClick={pageRefresh}
            >
              <RefreshIcon />
            </Avatar>
          </Tooltip>
        </IconButtonContainer>
        <WrapperSignature>
          <DataGridDemo rows={eSignatureRequests} />
        </WrapperSignature>
      </Container>
    </React.Fragment>
  );
};
