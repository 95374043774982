import React, { useEffect, useState } from "react";

export function DocumentListDocPlace(props) {
  const [documents, setDocuments] = useState([]);
  const [selected, setSelected] = useState();
  const inputRef = React.useRef(null);
  const [ocr, setOcr] = useState([]);

  var ocrDocs = [];

  useEffect(() => {
    setDocuments(props.documents);
  }, [props.documents]);

  useEffect(() => {
    if (documents && documents.length > 0) {
      setTimeout(() => {
        inputRef.current.click();
      }, 500);
    }
  }, [documents]);

  const ocrDoc = (doc, id) => {
    if (!ocr.includes(id)) {
      setOcr((regions) => [...regions, id]);
    }

    setSelected(doc.thumbnail);

    props.getDocument(
      doc.thumbnail,
      id,
      doc.fileName,
      doc.path,
      doc.ocr,
      doc,
      doc.fileOriginalName
    );
  };

  return (
    <>
      <div className="documentList">
        {documents.map((doc, i) => (
          <div
            key={i}
            className={selected == doc.thumbnail ? "documentSelected" : ""}
            onClick={() => ocrDoc(doc, i)}
            ref={i == 0 ? inputRef : null}
          >
            <img
              className="docImage"
              title={`Click to select`}
              src={doc.thumbnail}
              alt={doc.fileName}
            />
            <div className="documentTitle">
              <span className="number">Page {i + 1}</span>{" "}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default DocumentListDocPlace;
