import {
  USER_LIST,
  USER_LIST_SUCCESS,
  API_ERROR_USER,
  USER_POST,
  USER_POST_SUCCESS,
  API_ERROR_USER_POST,
  USER_PUT,
  USER_PUT_SUCCESS,
  API_ERROR_USER_PUT,
  USER_DELETE,
  USER_DELETE_SUCCESS,
  API_ERROR_USER_DELETE,
  ROLE_LIST,
  ROLE_LIST_SUCCESS,
  API_ERROR_ROLE,
  USER_PASSWORD,
  USER_PASSWORD_SUCCESS,
  API_ERROR_USER_PASSWORD,
  USER_ASG_ROL_POST,
  USER_ASG_ROL_POST_SUCCESS,
  API_ERROR_USER_ASG_ROL_POST,
  USER_ASG_ROL_PUT,
  USER_ASG_ROL_PUT_SUCCESS,
  API_ERROR_USER_ASG_ROL_PUT,
  USER_ARCHIVE_ROLES,
  USER_ARCHIVE_ROLES_SUCCESS,
  API_ERROR_USER_ARCHIVE
} from "./actionTypes";

export const getUserList = (user, navigate) => {
  return {
    type: USER_LIST,
    payload: { user, navigate },
  };
};

export const userSuccess = (user) => {
  return {
    type: USER_LIST_SUCCESS,
    payload: user,
  };
};

export const apiErrorUser = (error) => {
  return {
    type: API_ERROR_USER,
    payload: error,
  };
};

export const changePassword = (user) => {
  return {
    type: USER_PASSWORD,
    payload: { user },
  };
};
export const userPasswordSuccess = (user) => {
  return {
    type: USER_PASSWORD_SUCCESS,
    payload: user,
  };
};

export const apiErrorUserPassword = (error) => {
  return {
    type: API_ERROR_USER_PASSWORD,
    payload: error,
  };
};

export const postUserList = (user, navigate) => {
  return {
    type: USER_POST,
    payload: { user, navigate },
  };
};

export const userPostSuccess = (user) => {
  return {
    type: USER_POST_SUCCESS,
    payload: user,
  };
};

export const apiErrorUserPost = (error) => {
  return {
    type: API_ERROR_USER_POST,
    payload: error,
  };
};

export const putUserList = (user, navigate) => {
  return {
    type: USER_PUT,
    payload: { user, navigate },
  };
};

export const userPutSuccess = (user) => {
  return {
    type: USER_PUT_SUCCESS,
    payload: user,
  };
};

export const apiErrorUserPut = (error) => {
  return {
    type: API_ERROR_USER_PUT,
    payload: error,
  };
};

export const deleteUserList = (user, navigate) => {
  return {
    type: USER_DELETE,
    payload: { user, navigate },
  };
};

export const userDeleteSuccess = (user) => {
  return {
    type: USER_DELETE_SUCCESS,
    payload: user,
  };
};

export const apiErrorUserDelete = (error) => {
  return {
    type: API_ERROR_USER_DELETE,
    payload: error,
  };
};

export const getRoleList = (role, navigate) => {
  return {
    type: ROLE_LIST,
    payload: { role, navigate },
  };
};

export const roleSuccess = (role) => {
  return {
    type: ROLE_LIST_SUCCESS,
    payload: role,
  };
};

export const apiErrorRole = (error) => {
  return {
    type: API_ERROR_ROLE,
    payload: error,
  };
};

export const postUserAsgRolList = (userAsgRol, navigate) => {
  return {
    type: USER_ASG_ROL_POST,
    payload: { userAsgRol, navigate },
  };
};

export const userAsgRolPostSuccess = (userAsgRol) => {
  return {
    type: USER_ASG_ROL_POST_SUCCESS,
    payload: userAsgRol,
  };
};

export const apiErrorUserAsgRolPost = (error) => {
  return {
    type: API_ERROR_USER_ASG_ROL_POST,
    payload: error,
  };
};

export const putUserAsgRolList = (userAsgRol, navigate) => {
  return {
    type: USER_ASG_ROL_PUT,
    payload: { userAsgRol, navigate },
  };
};

export const userAsgRolPutSuccess = (userAsgRol) => {
  return {
    type: USER_ASG_ROL_PUT_SUCCESS,
    payload: userAsgRol,
  };
};

export const apiErrorUserAsgRolPut = (error) => {
  return {
    type: API_ERROR_USER_ASG_ROL_PUT,
    payload: error,
  };
};

/**DOC-1778 get archive role list by user */
export const getUserArchiveRoles = (userID, navigate) => {
  return {
    type: USER_ARCHIVE_ROLES,
    payload: { userID, navigate},
  };
};

export const userArchiveRolesSuccess = archiveRoleList => {
  return {
    type: USER_ARCHIVE_ROLES_SUCCESS,
    payload: archiveRoleList,
  };
};

export const apiErrorUserArchiveRoles = (error) => {
  return {
    type: API_ERROR_USER_ARCHIVE,
    payload: error,
  };
};