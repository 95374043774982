import { call, put, takeEvery } from "redux-saga/effects"
import {REVIEWAL_LIST,DOC_PERCENTAGE_LIST,DOC_SHARED_LIST,DOC_PD_LIST} from "./actionTypes"
import {
    apiErrorReviewal,
    apiErrorDocPercentage,
    apiErrorDocShared ,
    apiErrorDocPD,
    reviewalSuccess,
    docPercentageSuccess,
    docSharedSuccess,
    docPDSuccess } from "./actions"

import {
  getReviewal,
  getDocPercentage,
  getDocShared,
  getDocPD,
} from "../../helpers/call_api"


function* getReviewalList({ payload: { reviewal, navigate } }) {
  try {
    const response = yield call(getReviewal, reviewal.from, reviewal.to, reviewal.period)
    yield put(reviewalSuccess(response))
  } catch (error) {
      if(error.response.status){
          if(error.response.status===401){
              navigate('/login');
          }
      }
     yield put(apiErrorReviewal(error))
  }
}

function* getDocPercentageList({ payload: { docPercentage, navigate } }) {
  try {
    const response = yield call(getDocPercentage, docPercentage.from, docPercentage.to, docPercentage.period)
    yield put(docPercentageSuccess(response))
  } catch (error) {
      if(error.response.status){
          if(error.response.status===401){
              navigate('/login');
          }
      }
    yield put(apiErrorDocPercentage(error))
  }
}

function* getDocSharedList({ payload: { docShared, navigate } }) {
  try {
    const response = yield call(getDocShared, docShared.from, docShared.to, docShared.period)
    yield put(docSharedSuccess(response))
  } catch (error) {
      if(error.response.status){
          if(error.response.status===401){
              navigate('/login');
          }
      }
    yield put(apiErrorDocShared(error))
  }
}

function* getDocPDList({ payload: { docPD, navigate } }) {
  try {
    const response = yield call(getDocPD, docPD.from, docPD.to, docPD.period)
    yield put(docPDSuccess(response))
  } catch (error) {
      if(error.response.status){
          if(error.response.status===401){
              navigate('/login');
          }
      }
    yield put(apiErrorDocPD(error))
  }
}

function* authSaga() {
  yield takeEvery(REVIEWAL_LIST, getReviewalList)
  yield takeEvery(DOC_PERCENTAGE_LIST, getDocPercentageList)
  yield takeEvery(DOC_SHARED_LIST, getDocSharedList)
  yield takeEvery(DOC_PD_LIST, getDocPDList)

}

export default authSaga
