import React from "react";
import { Container } from "reactstrap";

const DashboardSignatures = () => {
  return (
    <React.Fragment>
      <Container fluid>
        <p>hi this is a dashboard</p>
      </Container>
    </React.Fragment>
  );
};

export default DashboardSignatures