import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import metaDataColor from "../../../../assets/metaDataColor.svg";
import DocPlaceColor from "../../../../assets/DocPlaceColor.svg";
import {
  ContentUl,
  DivContainerG1,
  DivContainerG2,
  DivLogoDocPlace,
  DivTextG1,
  NameAttachment,
  StyledLi,
  StyledUl,
  TextSend,
  Title,
  useStyles,
} from "./style";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";

export const DrawerComplete = (props) => {
  const [openComplete, setOpenComplete] = React.useState(
    props.openDrawerComplete
  );
  const classes = useStyles();

  useEffect(() => {
    setOpenComplete(props.openDrawerComplete);
  }, [props.openDrawerComplete]);

  const handleDrawerClose = () => {
    setOpenComplete(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    backgroundColor: "rgba(33, 150, 243, 0.52)",
  }));

  return (
    <React.Fragment>
      <Drawer
        anchor={"bottom"}
        slots={{ backdrop: CustomBackdrop }}
        open={openComplete}
        onClose={handleDrawerClose}
      >
        <Grid container>
          <Grid item xs={2.5} style={{ paddingTop: "15px" }}>
            <DivLogoDocPlace>
              <img
                alt="Logo"
                className={classes.logoDocPlace}
                src={DocPlaceColor}
              />
            </DivLogoDocPlace>
          </Grid>
          <Grid xs={9.5} style={{ paddingTop: "15px" }}>
            <DivContainerG2>
              <img
                alt="Logo"
                className={classes.logoAttach}
                src={metaDataColor}
              />
              <Title>Complete</Title>
            </DivContainerG2>
          </Grid>
          <Grid
            item
            xs={2.5}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "15px 15px 15px 15px",
            }}
          >
            <DivContainerG1>
              <DivTextG1>
                aca abra texto para colocar lo que desee para describir lo que
                desee
              </DivTextG1>
            </DivContainerG1>
          </Grid>
          <Grid
            item
            xs={9.5}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 25px 15px 15px",
            }}
          >
            <ContentUl>
              <StyledUl>
                {props.departmentList?.map((item, index) => (
                  <StyledLi
                    key={item.id}
                    className={index === item.length - 1 ? "last" : ""}
                  >
                    <div className={classes.logoMetaData}>
                      <img
                        alt="Logo"
                        className={classes.logoAttach}
                        src={metaDataColor}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <NameAttachment>{item.name}</NameAttachment>
                    </div>
                    <IconButton size="standard">
                      <TextSend> send</TextSend>
                      <SendIcon style={{ color: "#006ac6" }} fontSize="small" />
                    </IconButton>
                  </StyledLi>
                ))}
              </StyledUl>
            </ContentUl>
          </Grid>
        </Grid>
      </Drawer>
    </React.Fragment>
  );
};
