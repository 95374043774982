import React from "react";
import Button from '@mui/material/Button';
import {
	useStyles
} from '../../style';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));
const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;
	
	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

const ConfirmRegisteredSignatureView = (props) => {
	const classes = useStyles();
	return (
		<BootstrapDialog
			onClose={() => {
				props.close()
			}}
			style={{ overflow: 'hidden' }}
			aria-labelledby="customized-dialog-title"
			open={props.open}
		>
			<BootstrapDialogTitle id="customized-dialog-title" onClose={() => {
				props.close()
			}}>
				Confirm Registered Signature!
			</BootstrapDialogTitle>
			<DialogContent style={{ width: '500px', overflow: 'hidden' }} dividers>
				Your user does not have the module active to be able to make signatures.
				<br/>
				You want to activate the signature module?
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={props.close} className={classes.buttonModelCancel}>NO</Button>
				<Button variant="outlined" onClick={props.ok} className={classes.buttonModelOk}>YES</Button>
			</DialogActions>
		</BootstrapDialog>
	)
}

export default ConfirmRegisteredSignatureView
