import {
  TREE_LIST,
  TREE_LIST_SUCCESS,
  API_ERROR_TREE,
} from "./actionTypes"

export const getTreeList = (tree, navigate) => {
  return {
    type: TREE_LIST,
    payload: {tree, navigate},
  }
}

export const treeSuccess = tree => {
  return {
    type: TREE_LIST_SUCCESS,
    payload: tree,
  }
}

export const apiErrorTree = error => {
  return {
    type: API_ERROR_TREE,
    payload: error,
  }
}
