import React from "react";

import SelectListFormsView from "./view";

const SelectListForms = ({
  callbackIsChecked,
  isChecked,
  id,
  options,
  defaultValue,
  variant,
  text,
  type,
  disable,
  getSelect,
  notSeeIntoPDF,
  deleteSelect,
  settingSelect,
  getTitleSelect,
  titleSelect,
  getSubTitleSelect,
  subTitleSelect,
  notSeeIntoPdfSelectRes,
  isMultiple,
  required,
  error,
}) => {
  const [selectOption, setSelectOption] = React.useState(null);

  const changeChecked = (event) => {
    callbackIsChecked(event.target.checked);
  };

  const handleChange = (event) => {
    const selectedOption = event.target.value;
    setSelectOption(selectedOption);
    getSelect(selectedOption.value);
  };

  const onChangeNotSeeIntoPDF = () => {
    notSeeIntoPDF((prevNotSeeIntoPDF) => !prevNotSeeIntoPDF);
  };

  const onChangeDeleteSelect = () => {
    deleteSelect(true);
  };

  const onChangeSettingSelect = () => {
    settingSelect(true);
  };

  const onChangeTitleSelect = (value) => {
    getTitleSelect(value.target.value);
  };

  const onChangeSubTitleSelect = (value) => {
    getSubTitleSelect(value.target.value);
  };

  return (
    <SelectListFormsView
      changeChecked={changeChecked}
      isChecked={isChecked}
      id={id}
      onChangeNotSeeIntoPDF={onChangeNotSeeIntoPDF}
      onChangeDeleteSelect={onChangeDeleteSelect}
      onChangeSettingSelect={onChangeSettingSelect}
      onChangeTitleSelect={onChangeTitleSelect}
      onChangeSubTitleSelect={onChangeSubTitleSelect}
      titleSelect={titleSelect}
      subTitleSelect={subTitleSelect}
      notSeeIntoPdfSelectRes={notSeeIntoPdfSelectRes}
      defaultValue={defaultValue}
      selectOption={selectOption}
      onChange={handleChange}
      options={options}
      variant={variant}
      text={text}
      type={type}
      disable={disable}
      isMultiple={isMultiple}
      required={required}
      error={error}
    />
  );
};

export default SelectListForms;
