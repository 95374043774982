import React, { useEffect, useState } from 'react';

import {
    useStyles,
} from './style';

import { Container } from "reactstrap";
import SearchDocShipPlace, { SearchShipDocPlace } from "../search/search";
import GridDocPlace from "./components/grid/grid";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LoadingComponent from "./components/loading";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';




import {
    getShippedForms2
  } from "../../../../services/form";
  


/**DOC-1768 general values to access through the different calls to the API */


const ShipmentDocPlace = (props) => {
    const notify = (msj, event) => toast[event](msj);
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [startPage] = useState(true);
    const [dataRols, setDataRols] = useState([]);

    const [loading, setLoading] = useState(false);



    const style = {
        position: "absolute",
        top: 20,
        left: 20,
        right: 20,
        bottom: 20,
        margin: "auto",
        width: "80%",
        height: "fit-content",
        bgcolor: "background.paper",
        p: 4,
      };



    useEffect(() => {
        let body = {
            "isCompleted": true,
            "FormTemplateId": props.id
          };
        setLoading(true);
          getShippedForms2(body, (event) => { })
        .then((response) => {
            setDataRols(response.data.data);
        })
        .catch((error) => {
        })
        .finally(() => {
            setLoading(false);
        });
         
    }, [startPage])



    /**END DOC-1768 */





    const optionGrid = (value) => {
        if (value.action === 'edit') {
            //setDefaultData(value.row.row);
        } else {
        }
    }


   
    return (
        <Container >
             <div className="page-content">
        <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Completed forms
        </Typography>
        <Typography
          component={"div"}
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
        </Typography>
               
                <Container fluid>
                    <ToastContainer autoClose={3000} />

                    <LoadingComponent show={loading } text={loading ? "Getting Forms..." : ''} />
         
                  
                    <GridDocPlace data={dataRols} option={optionGrid.bind(this)} />

                </Container>
            
        </Box>
        </div>
        </Container>
    );
}

export default ShipmentDocPlace;
