import React, { useEffect, useState } from "react";
import Bookmark from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/material/IconButton';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import "./styles.css";
import DocumentSinglePageDocPlace from "./singlePage";
export function DocumentPageDocPlace(props) {

    const [currentPage, setCurrentPage] = useState(0);
    const [pages, setPages] = useState([]);

    useEffect(() => {

        if (props.document.pages) {

            setPages(props.document.pages);

        } else {
            setPages([props.document]);
        }

    }, [props.document.pages]);

    useEffect(() => {
           change(props.move)
    }, [props.move]);

   

    useEffect(() => {
     
        props.move(currentPage)
 }, [currentPage]);

    const ocrDoc = (doc) => {
        props.ocrDoc(doc, doc.absoluteIndex, currentPage,props.originalName)
        
    };

    const change = (direction) => {
        if ((currentPage + direction < pages.length) && (currentPage + direction >= 0)) {
            setCurrentPage(currentPage + direction);

            if (pages[currentPage + direction].ocr == true) {
                ocrDoc(pages[currentPage + direction])

            } else {
                ocrDoc(pages[currentPage])
            }

        }
    }
    return (
        <div key={props.index + 100}  >

            {(pages && pages.length > 1) && (
                <>

                    <div className={'pager'} sx={{ position: 'absolute', zIndex: '99999', fontSize: '10px', marginTop: '0px', marginLeft: '-80px' }} >
                        {currentPage + 1 > 1 ?
                            <IconButton onClick={() => change(- 1)} color="success" sx={{ zIndex: '99999' }} aria-label="Move left" title="Left">
                                <ArrowLeft />
                            </IconButton>
                            : <div className="false"></div>
                        }

                        {currentPage + 1} of {pages.length}

                        {currentPage + 1 < pages.length ?
                            <IconButton onClick={() => change(1)} color="success" sx={{ zIndex: '99999' }} aria-label="Move right" title="Right">
                                <ArrowRight />
                            </IconButton>
                            : <div className="false"></div>
                        }
                    </div>
                </>)}


            {pages.map((page, index) => (
                <DocumentSinglePageDocPlace
                    key={index}
                    page={page}
                    show={currentPage}
                    index={index}
                    document={ocrDoc}
                    ocrDone={page.ocr}

                />
            ))
            }


        </div>
    )
}

export default DocumentPageDocPlace

