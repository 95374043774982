import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export const Wrapper = styled.div`
    width: 100%;
    height: 35px;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 20px;
`;

export const WrapperUpload = styled.section`
    width: 100%;
`;

export const WrapperDropZone = styled.article`
    width: 50%;
    min-height: calc(100vh - 215px);
    background-color: #fff;
    border-radius: 4px;
    margin-top: 20px;
    float: left;
`;

export const DescriptionDetail = styled.section`
      width: 100%;
      font-size: 14px;
      color: #646464;
      background-color: #e1e5e8;
      min-height: 40px;
      padding-top: 9px;
      padding-left: 12px;
      margin-top: 11px;
      padding-bottom: 10px;
`;

export const WrapperDocumentAdded = styled.section`
      width: 40%;
      height: calc(100% - 130px);
      background-color: #fff;
      border-radius: 4px;
      margin-top: 20px;
      float: right;
`;

export const WrapperOutOCR = styled.article`
      width: 50%;
      height: 40px;
      border-radius: 4px;
      margin-top: 20px;
      float: right;
`;

export const WrapperFormOutOCR = styled.article`
      width: 50%;
      border-radius: 4px;
      float: left;
`;

export const OutOcrText = styled.article`
      margin: 2px 0px 0px 12px;
      min-height: 40px;
      padding: 7px 17px 0px 4px;
      border-radius: 5px;
      background-color: #006ac6;
      color: #fff;
      font-weight: 900;
      text-align: right;
      font-size: 16px;
      cursor:pointer;
      padding-bottom:5px;
      :hover{
        border: 2px solid #0a88f6;
        padding-top: 5px;
      }
`;

export const OutOCRDescription = styled.div`
      font-size: 11px;
      width: 100%;
      display: inline-block;
`;

export const WpMessage = styled.article`
      width: 50%;
      height: 40px;
      border-radius: 4px;
      margin-top: 20px;
      float: right;
`;

export const WpMssText = styled.div`
      margin: 2px 0px 0px 12px;
      min-height: 40px;
      padding: 7px 17px 0 0px;
      border-radius: 5px;
      background-color: ;
      color: #fff;
      font-weight: 900;
      text-align: right;
      font-size: 16px;
      cursor:pointer;
      padding-bottom:5px;
      :hover{
        border: 2px solid #0a88f6;
        padding-top: 5px;
      }
`;

export const WpForm = styled.article`
      background-color: #fff;
      margin-top: 18px;
      margin-left: 12px;
      min-height: calc(100vh - 276px);
      border-radius: 4px;
      display: inline-block;
      padding-bottom: 20px;
      padding-left: 22px;
      padding-right: 22px;
      width: 98%;
      overflow-y: auto;
`;

export const WpFileWatcher = styled.div`
      width: 100%;
      margin-top: 10px;
      text-align: right;
      margin-bottom: 15px;
`;

export const WrapperPath = styled.section`
      width: 100%;
      height: 50px;
      background-color: #006ac6;
      border-radius: 5px;
      color: #fff;
      margin-bottom: 25px;
`;

export const PathTitle = styled.section`
      font-size: 15px;
      font-weight: bold;
      width: 75%;
      float: left;
      padding-top: 17px;
      padding-left: 10px;
      word-break: break-word;
      line-height: 1;
      overflow-y: auto;
      height: 50px;
`;

export const PathOpen = styled.section`
      font-size: 15px;
      font-weight: bold;
      width: 25%;
      float: right;
      height: 50px;
      background-color: white;
      text-align: center;
      padding-top: 13px;
      color: #000;
      border: 1px solid #006ac6;
      border-radius: 0px 5px 5px 0px;
      cursor: pointer;
      :hover{
        background-color: #e1e5e8;
      }
`;

export const NotePath = styled.span`
      margin-top: 0px;
      font-size: 12px;
      padding-left: 25px;
`;


export const ErrorWrap = styled.div
    `
      border: 2px solid red;
      border-radius: 7px;
      height: 54px;
      margin-bottom: 30px;
    `;





export const useStyles = makeStyles((theme) => ({
    errorPath: {
        border: '2px solid red',
        borderRadius: '7px',
        height: '54px',
        marginBottom: '30px'
    },
    iconSearch: {
        width: '18px',
        height: '18px',
        margin: '19px 16px 0',
        position: 'absolute'
    },
    dropBox: {
        float: 'left',
        width: '100px',
        height: '150px',
        fontSize: '12px',
        wordBreak: 'break-word',
        display: 'inline-block',
        padding: '5px',
        color: '#000',
        margin: '1px 12px 18px 12px',
        position:'relative',
        overflowY: 'hidden'
    },
    buttonSearch: {
        float: 'right',
        marginTop: '-45px !important',
        marginRight: '16px !important',
        backgroundColor: '#006ac6 !important'
    },
    input: {
        height: '40px',
        paddingTop: '18px'
    },
    baseSearch:{
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '4px',
        position: 'relative',
        height:'58px'
    },
    uploadW: {
        width: '100%',
        display: 'inline-block',
        height: '200px',
        textAlign: 'center',
        paddingTop: '85px',
        border: '2px dashed #2196f3',
    },
    nextButton: {
        float: 'right',
        marginTop: '25px !important',
        backgroundColor: '#1f6ac6 !important'
    },
    inputForm: {
        margin: '0px 0px 22px 0px !important',
        width: '100%',
    },
    textRequired: {
        margin: '20px 0px 20px 0px',
        backgroundColor: '#e1e5e8',
        padding:'8px',
        fontWeight: '900'
    },
    activeOCR: {
        backgroundColor: '#fff',
        margin: '11px 0px 11px 16px'
    },
    buttonModelOk: {
        float: 'right',
        marginTop: '3px !important',
        marginRight: '4px !important',
        backgroundColor: '#006ac6 !important',
        height: '38px',
        paddingTop: '7px',
        color: '#fff'
    },
    buttonModelCancel: {
        float: 'left',
        marginTop: '3px !important',
        marginRight: '15px !important',
        backgroundColor: 'red !important',
        height: '38px',
        paddingTop: '7px',
        color: '#fff'
    },
    modalS: {
        top: '50%',
        left: '50%',
        width: '400px',
        border: '2px dashed #006ac6',
        backgroundColor: '#fff',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0 5px #006ac6',
        minHeight: '400px',
        overflowY: 'auto'
    },
    headerModal:{
        height: '50px',
        backgroundColor: '#607d8b',
        color: '#fff',
        paddingTop: '9px',
        paddingLeft: '10px'
    }
    
  }));


  



