import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./store";
import './Fonts/AlexBrush/AlexBrush-Regular.ttf';

const app = (
  <Provider store={store}>
       <App />
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))