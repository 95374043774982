import React, {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import {
    useStyles
} from './style';
import TextField from '@material-ui/core/TextField';
import Creatable from 'react-select/creatable';
import LoadingComponent from "../../../../components/loading";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getUserList} from "../../../../store/user/actions";
import {postShareDoc} from "../../../../store/explorer/actions";
import {toast} from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export function ShareDocPlace(props,prev) {
    const classes = useStyles();
    const [emailText, setEmailText] = React.useState('');
    const [shareEmails, setShareEmails] = React.useState('');
    const [listUser, setListUser] = React.useState([]);
    const [showNew, setShowNew] = useState(false);
    const [item, setItem] = useState(false);
    const notify = (msj,event) => toast[event](msj);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { responseUser, errorUser, loading } = useSelector(state => ({
        responseUser: state.User.responseUser,
        errorUser: state.User.errorUser,
        loading: state.User.loadingUser,
    }))
    const { responseShareDoc, errorShareDoc, loading2 } = useSelector(state => ({
        responseShareDoc: state.Explorer.responseShareDoc,
        errorShareDoc: state.Explorer.errorShareDoc,
        loading2: state.Explorer.loadingShareDoc,
    }))


    useEffect(() => {
        if(responseShareDoc !== null){
            close();
        }
    }, [responseShareDoc]);
    useEffect(() => {
        console.log('ITEM> ', props.itemShare);
        setItem(props.itemShare);
        setShowNew(props.show)
        dispatch(getUserList({}, navigate));
    }, [props.show]);
    useEffect(() => {
        let l = [];
        if(responseUser && responseUser.data.length > 0 ){
            responseUser.data.map((v) => {
                l.push({
                    label:v.fullName,
                    value: v.email})
            });
            setListUser(l);
        }
    }, [responseUser]);


const close = () =>{
        props.close();
    }

    const shareFile = () => {
        let obj = {
            description: emailText,
            emails: shareEmails.map(e => {
                return e.value
            })
        };
        dispatch(postShareDoc({data:obj,id:item.id}, navigate));
    }

    const handleCreatableOnChange = (newValue, actionMeta) => {
        setShareEmails(newValue);
    };

    return (
        <BootstrapDialog
            onClose={() =>{
                close()
            }}
            style={{overflow:'hidden'}}
            aria-labelledby="customized-dialog-title"
            open={showNew}
        >
            <LoadingComponent show={loading || loading2} text={"Sharing Document..."} />
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() =>{
                close()
            }}>
                Share Document
            </BootstrapDialogTitle>
            <DialogContent style={{width:'500px'}} dividers>
            <Creatable
                    id="shareEmails"
                    name="shareEmails"
                    isMulti
                    style={{zIndex:'999 !important',marginBottom:'30px'}}
                    options={listUser}
                    onChange={handleCreatableOnChange.bind(this)}
                    pageSize={3}
                />
                <TextField
                    id="filled-multiline-static"
                    label="Message"
                    multiline
                    style={{width:'100%',backgroundColor:'#fff',marginTop:'20px',zIndex: '0'}}
                    rows={4}
                    defaultValue={emailText}
                    variant="filled"
                    onChange={e => setEmailText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={close.bind(this)} className={classes.buttonModelCancel}>CANCEL</Button>
                <Button variant="outlined" onClick={shareFile.bind(this)} className={classes.buttonModelOk}>SEND</Button>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default ShareDocPlace
