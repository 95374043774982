import {
  USER_LIST,
  USER_LIST_SUCCESS,
  API_ERROR_USER,
  USER_POST,
  USER_POST_SUCCESS,
  API_ERROR_USER_POST,
  USER_PUT,
  USER_PUT_SUCCESS,
  API_ERROR_USER_PUT,
  USER_DELETE,
  USER_DELETE_SUCCESS,
  API_ERROR_USER_DELETE,
  ROLE_LIST,
  ROLE_LIST_SUCCESS,
  API_ERROR_ROLE,
  USER_PASSWORD,
  USER_PASSWORD_SUCCESS,
  API_ERROR_USER_PASSWORD,
  USER_ASG_ROL_POST,
  USER_ASG_ROL_POST_SUCCESS,
  API_ERROR_USER_ASG_ROL_POST,
  USER_ASG_ROL_PUT,
  USER_ASG_ROL_PUT_SUCCESS,
  API_ERROR_USER_ASG_ROL_PUT,
  USER_ARCHIVE_ROLES,
  USER_ARCHIVE_ROLES_SUCCESS,
  API_ERROR_USER_ARCHIVE
} from "./actionTypes";

const initialState = {
  errorUser: null,
  responseUser: null,
  loadingUser: false,

  errorUserPost: null,
  responseUserPost: null,
  loadingUserPost: false,

  errorUserPassword: null,
  responseUserPassword: null,
  loadingUserPassword: false,

  errorUserPut: null,
  responseUserPut: null,
  loadingUserPut: false,

  errorUserDelete: null,
  responseUserDelete: null,
  loadingUserDelete: false,

  errorRole: null,
  responseRole: null,
  loadingRole: false,

  errorUserAsgRolPost: null,
  responseUserAsgRolPost: null,
  loadingUserAsgRolPost: false,

  errorUserAsgRolPut: null,
  responseUserAsgRolPut: null,
  loadingUserAsgRolPut: false,

  responseUserArchiveRoles: null,
  errorUserArchiveRoles: null,
  loadingUserArchiveRoles: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_LIST:
      state = {
        ...state,
        errorUser: null,
        loadingUser: true,
      };
      break;
    case USER_LIST_SUCCESS:
      state = {
        ...state,
        errorUser: null,
        responseUser: action.payload,
        loadingUser: false,
      };
      break;
    case API_ERROR_USER:
      state = {
        ...state,
        errorUser: action.payload,
        loadingUser: false,
        responseUser: null,
      };
      break;

    case USER_PASSWORD:
      state = {
        ...state,
        errorUserPassword: null,
        loadingUserPassword: true,
      };
      break;
    case USER_PASSWORD_SUCCESS:
      state = {
        ...state,
        errorUserPassword: null,
        responseUserPassword: action.payload,
        loadingUserPassword: false,
      };
      break;
    case API_ERROR_USER_PASSWORD:
      state = {
        ...state,
        errorUserPassword: action.payload,
        loadingUserPassword: false,
        responseUserPassword: null,
      };
      break;
    case USER_POST:
      state = {
        ...state,
        errorUserPost: null,
        loadingUserPost: true,
      };
      break;

    case USER_POST_SUCCESS:
      state = {
        ...state,
        errorUserPost: null,
        responseUserPost: action.payload,
        loadingUserPost: false,
      };
      break;
    case API_ERROR_USER_POST:
      state = {
        ...state,
        errorUserPost: action.payload,
        loadingUserPost: false,
        responseUserPost: null,
      };
      break;

    case USER_PUT:
      state = {
        ...state,
        errorUserPut: null,
        loadingUserPut: true,
      };
      break;
    case USER_PUT_SUCCESS:
      state = {
        ...state,
        errorUserPut: null,
        responseUserPut: action.payload,
        loadingUserPut: false,
      };
      break;
    case API_ERROR_USER_PUT:
      state = {
        ...state,
        errorUserPut: action.payload,
        loadingUserPut: false,
        responseUserPut: null,
      };
      break;

    case USER_DELETE:
      state = {
        ...state,
        errorUserDelete: null,
        loadingUserDelete: true,
      };
      break;
    case USER_DELETE_SUCCESS:
      state = {
        ...state,
        errorUserDelete: null,
        responseUserDelete: action.payload,
        loadingUserDelete: false,
      };
      break;
    case API_ERROR_USER_DELETE:
      state = {
        ...state,
        errorUserDelete: action.payload,
        loadingUserDelete: false,
        responseUserDelete: null,
      };
      break;

    case ROLE_LIST:
      state = {
        ...state,
        errorRole: null,
        loadingRole: true,
      };
      break;
    case ROLE_LIST_SUCCESS:
      state = {
        ...state,
        errorRole: null,
        responseRole: action.payload,
        loadingRole: false,
      };
      break;
    case API_ERROR_ROLE:
      state = {
        ...state,
        errorRole: action.payload,
        loadingRole: false,
        responseRole: null,
      };
      break;    //DOC-1778 add archive roles
    case USER_ARCHIVE_ROLES:
      state = {
        ...state,
        errorUserArchiveRoles: null,
        loadingUserArchiveRoles: true,
      }
      break;
    case USER_ARCHIVE_ROLES_SUCCESS:
      state = {
        ...state,
        errorUserArchiveRoles: null,
        responseUserArchiveRoles: action.payload,
        loadingUserArchiveRoles: false,
      }
      break;
    case API_ERROR_USER_ARCHIVE:
      state = {
        ...state,
        errorUserArchiveRoles: action.payload,
        loadingUserArchiveRoles: false,
        responseUserArchiveRoles: null
      }
      break;  //end add archive roles
    case USER_ASG_ROL_POST:
      state = {
        ...state,
        errorUserAsgRolPost: null,
        loadingUserAsgRolPost: true,
      };
      break;

    case USER_ASG_ROL_POST_SUCCESS:
      state = {
        ...state,
        errorUserAsgRolPost: null,
        responseUserAsgRolPost: action.payload,
        loadingUserAsgRolPost: false,
      };
      break;
    case API_ERROR_USER_ASG_ROL_POST:
      state = {
        ...state,
        errorUserAsgRolPost: action.payload,
        loadingUserAsgRolPost: false,
        responseUserAsgRolPost: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default user;
