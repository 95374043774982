import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { colors } from "../../../../components/theme";

export const Message = styled.section`
  width: 100%;
  min-height: 50px;
  background-color: ${colors.help};
  margin-bottom: 35px;
  margin-top: 0px;
  border-radius: 5px;
  padding: 12px;
`;

export const WrapperForm = styled.article`
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 400px);
  border-radius: 5px;
  padding: 20px;
`;

export const useStyles = makeStyles((theme) => ({
  buttons: {
    width: "100%",
    display: "inline-block",
    marginTop: "20px",
    height: "40px",
    color: "#560e1a",
  },
  row: {
    boxShadow: "0 0 0 !important",
  },
}));
