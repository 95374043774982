import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import http from "../../services/http-common";
import {
  CountDown,
  CountDownNumber,
  CountDownText,
  ForgetPassword,
  LoginFormDocPlace,
  ResendCodeContent,
  ResendCodeContentOpt,
  ResendCodeTitle,
  ResendCodeWrapper,
  ResendEmail,
  useStyles,
  Wrapper,
  WrapperFormDocPlace,
  WrapperLogin,
} from "./style";
import { Container } from "reactstrap";
import Button from "@mui/material/Button";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import logoFD from "../../assets/logoColorsDocPlace.png";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { useDispatch, useSelector } from "react-redux";
import { apiError, customerUser, loginUser } from "../../store/actions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputDocPlace from "../../components/form/input";
import LoadingComponent from "../../components/loading";
import { expressions } from "../../components/theme";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectDropdown from "../../components/form/selectDropdown";

const Login = (props) => {
  const classes = useStyles();
  const notify = (msj, event) => toast[event](msj);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState({ value: "", error: false });
  const [code, setCode] = useState({ value: "", error: false });

  const [password, setPassword] = useState({ value: "", error: false });
  const [mfaId, setMfaId] = useState("");
  const [mfaIsRequired, setMfaIsRequired] = useState(false);
  const [customer, setCustomer] = useState({
    value: "MONTH",
    label: "Monthly",
  });
  const [customers, setCustomers] = useState([]);
  const [activationMsg, setActivationMsg] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showHashInvalid, setShowHashInvalid] = useState(false);
  const [resend, setResend] = useState(false);
  const [sending, setSending] = useState(false);
  const [tiempoRestante, setTiempoRestante] = useState(120);

  const [showLogin, setShowLogin] = useState(false);

  const { responseLogin, errorLogin, loading } = useSelector((state) => ({
    responseLogin: state.Login.responseLogin,
    errorLogin: state.Login.errorLogin,
    loading: state.Login.loadingLogin,
  }));

  const planIds = ["1", "2", "4", "6", "", null];

  const { responseCustomerUser, errorCustomerUser, loadingCustomerUser } =
    useSelector((state) => ({
      responseCustomerUser: state.Login.responseCustomerUser,
      errorCustomerUser: state.Login.errorCustomerUser,
      loadingCustomerUser: state.Login.loadingCustomerUser,
    }));
  const { responseHashValidate, errorHashValidate, loadingHashValidate } =
    useSelector((state) => ({
      //Hook to have the responses from redux
      responseHashValidate: state.Login.responseHashValidate,
      errorHashValidate: state.Login.errorHashValidate,
      loadingHashValidate: state.Login.loadingHashValidate,
    }));
  const handleValidSubmit = (e) => {
    e.preventDefault();
    if (!userName.error && !password.error) {
      let obj = {
        email: userName.value,
        password: password.value,
      };
      dispatch(loginUser(obj, props.history));
    } else {
      notify("Please enter all the required information", "error");
    }
  };
  /**navigates to the forgot password page and sen the current email on username */
  const navigateForgot = (e) => {
    e.preventDefault();
    navigate("/forgot-password", { state: { emailParam: userName.value } });
  };

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  useEffect(() => {
    if (tiempoRestante > 0) {
      const intervalId = setInterval(() => {
        setTiempoRestante(tiempoRestante - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [tiempoRestante]);

  useEffect(() => {
    //Todo: replace with redux saga logout method
    if (responseLogin && localStorage.getItem("docplace-logout") === null) {
      //setResend(true)

      if (responseLogin.mfaRequired) {
        setMfaId(responseLogin?.mfaId);
        setMfaIsRequired(responseLogin?.mfaRequired);
      } else {
        if (
          responseLogin.superUser === 1 &&
          responseLogin.customerId === null
        ) {
          localStorage.setItem("docplace", JSON.stringify(responseLogin));
          localStorage.removeItem("docplace-logout");
          dispatch(customerUser("", props.history));
        } else {
          notify("Welcome " + userName.value, "success");
          localStorage.setItem("docplace", JSON.stringify(responseLogin));
          localStorage.removeItem("docplace-logout");
          window.location.href = planIds.includes(responseLogin?.plan?.id)
            ? "/explorer"
            : "/eSignatureInbox";
        }
      }
    } else {
      localStorage.removeItem("docplace-logout");
    }
  }, [responseLogin, userName.value]);

  useEffect(() => {
    //Todo: replace with redux saga logout method
    if (JSON.parse(localStorage.getItem("docplace"))) {
      if (responseCustomerUser && responseCustomerUser.data.length > 0) {
        let customerL = [];
        responseCustomerUser.data.map((cp) => {
          customerL.push({ value: cp, label: cp });
        });

        setCustomers(customerL);
      } else {
        localStorage.removeItem("docplace-logout");
      }
    }
  }, [responseCustomerUser]);

  useEffect(() => {
    if (errorLogin) {
      if (
        errorLogin.response.data.error.messages[0].message ===
        "User is not Active!"
      ) {
        setResend(true);
      }
      notify(errorLogin.response.data.error.messages[0].message, "error");
    }
  }, [errorLogin]);

  useEffect(() => {
    if (responseHashValidate?.success == false) {
      setShowHashInvalid(true);
    }
  }, [responseHashValidate]);

  useEffect(() => {
    let hash = searchParams.get("hash");
    setResend(false);

    if (hash && hash.length > 5) {
      let obj = {
        appId: "Docplace",
        data: {
          hashCode: hash,
        },
      };

      //dispatch(hashValidate(obj, props.history));

      let url = `${process.env.REACT_APP_HOST_API}/billing/Api/V1/Public/login?hash=${hash}`;

      http
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response) {
            notify(
              "You account was successfully activated, you are ready to login and enjoy the benefits of DocPlace",
              "success"
            );
          } else {
            notify("The activation link you used has already expired", "error");
          }
        })
        .catch((err) => {
          notify("The activation link you used has already expired", "error");
        });
    }
  }, []);

  const enterCustomer = () => {
    let ls = JSON.parse(localStorage.getItem("docplace"));
    ls["customerId"] = customer.value.value;
    localStorage.setItem("docplace", JSON.stringify(ls));
    setCustomers([]);
    window.location.href = "/explorer";
    //navigate("/explorer");
  };
  const resendEmail = () => {
    let url =
      process.env.REACT_APP_HOST_API +
      "/billing/Api/V1/Public/SendResetPasswordLinkDocPlace";
    let body = {
      appId: "DocPlace",
      data: {
        email: userName.value,
      },
    };
    setShowLogin(true);
    http
      .post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((respData) => {
        if (respData.data.success == true) {
          notify("The email has been sent", "success");
        } else {
          notify(respData.data.errors[0].description, "error");
        }
        setShowLogin(false);
      })
      .catch((response) => {
        console.log("Error: " + response);
        setShowLogin(false);
        notify("Error sending the email", "error");
      })
      .finally(() => {
        setShowLogin(false);
      });
  };

  const sendCode = () => {
    let body = {
      mfaId: mfaId,
      code: code.value,
    };

    http
      .post(
        `${process.env.REACT_APP_HOST_API}/security/api/v1.0/Account/MfaVerfication`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          if (
            response.data.superUser === 1 &&
            response.data.customerId === null
          ) {
            localStorage.setItem("docplace", JSON.stringify(response.data));
            localStorage.removeItem("docplace-logout");
            dispatch(customerUser("", props.history));
          } else {
            notify("Welcome " + userName.value, "success");
            localStorage.setItem("docplace", JSON.stringify(response.data));
            localStorage.removeItem("docplace-logout");
            window.location.href = planIds.includes(response.data?.plan?.id)
              ? "/explorer"
              : "/eSignatureInbox";
          }
        } else {
          notify("The activation link you used has already expired", "error");
        }
      })
      .catch((err) => {
        notify(err.response?.data?.error?.messages[0].message, "error");
      });
  };

  const resendCodeEmail = (type) => {
    let body = {
      mfaId: mfaId,
      sendBy: type,
    };

    setTiempoRestante(120);
    setShowLogin(true);

    http
      .post(
        `${process.env.REACT_APP_HOST_API}/security/api/v1.0/Account/MfaResend`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          setMfaId(response.data.mfaId);
          notify("New Code sended", "success");
        }

        setShowLogin(false);
      })
      .catch((err) => {
        setShowLogin(false);
        notify(err.response?.data?.error?.messages[0].message, "error");
      });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <ToastContainer autoClose={3000} />
          <LoadingComponent
            show={loading || showLogin}
            text={"Login to DocPlace..."}
          />
          <Wrapper>
            <WrapperLogin>
              <img alt="docplace" className={classes.imgLogo} src={logoFD} />
              {showHashInvalid ? (
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    marginLeft: "20px",
                  }}
                >
                  The link you are using has already expired
                </div>
              ) : (
                <LoginFormDocPlace onSubmit={handleValidSubmit}>
                  <WrapperFormDocPlace>
                    {!mfaIsRequired && (
                      <>
                        {" "}
                        <InputDocPlace
                          id={"username"}
                          field={userName}
                          setField={setUserName}
                          required={true}
                          type="text"
                          label="Username"
                          shrink={true}
                          expresionRegular={expressions.email}
                        />
                        <InputDocPlace
                          id={"password"}
                          field={password}
                          setField={setPassword}
                          required={true}
                          type="password"
                          label="Password"
                          shrink={true}
                        />{" "}
                      </>
                    )}

                    {mfaIsRequired && customers.length <= 0 && (
                      <>
                        <InputDocPlace
                          id={"code"}
                          field={code}
                          setField={setCode}
                          required={true}
                          type="text"
                          label="Code"
                        />
                      </>
                    )}

                    {mfaIsRequired && customers.length <= 0 && (
                      <>
                        <Button
                          onClick={sendCode}
                          className={classes.buttons}
                          variant="contained"
                          style={{ marginBottom: "75px" }}
                          color="success"
                        >
                          Enter Code
                        </Button>
                        {tiempoRestante > 0 ? (
                          <CountDown>
                            <CountDownText>
                              you can request a new code in:
                              <CountDownNumber>
                                {tiempoRestante}
                              </CountDownNumber>
                              seconds
                            </CountDownText>
                          </CountDown>
                        ) : (
                          <ResendCodeWrapper>
                            <ResendCodeTitle>
                              Resend code verification
                            </ResendCodeTitle>
                            <ResendCodeContent>
                              <ResendCodeContentOpt
                                onClick={resendCodeEmail.bind(this, "EMAIL")}
                              >
                                <MarkEmailReadIcon
                                  style={{ color: "#006ac6" }}
                                />{" "}
                                <span
                                  style={{
                                    display: "inline-block",
                                    margin: "4px 0 0 12px",
                                    position: "absolute",
                                  }}
                                >
                                  Email
                                </span>
                              </ResendCodeContentOpt>
                              <ResendCodeContentOpt
                                onClick={resendCodeEmail.bind(this, "PHONE")}
                              >
                                <MarkUnreadChatAltIcon
                                  style={{ color: "#006ac6" }}
                                />{" "}
                                <span
                                  style={{
                                    display: "inline-block",
                                    margin: "0px 0 0 12px",
                                    position: "absolute",
                                  }}
                                >
                                  {" "}
                                  Send a text message{" "}
                                </span>
                              </ResendCodeContentOpt>
                            </ResendCodeContent>
                          </ResendCodeWrapper>
                        )}
                      </>
                    )}

                    {customers.length > 0 && (
                      <section>
                        <SelectDropdown
                          id={"customer"}
                          field={customer}
                          arrayList={customers}
                          defaultList={[]}
                          setField={setCustomer}
                          label="Customer"
                          helperText="Choose a Customer"
                        />
                        <Button
                          onClick={enterCustomer.bind(this)}
                          className={classes.buttons}
                          variant="contained"
                          color="success"
                        >
                          Enter
                        </Button>
                      </section>
                    )}

                    {customers.length <= 0 && !mfaIsRequired && (
                      <section>
                        <Button
                          type="submit"
                          className={classes.buttons}
                          variant="contained"
                          color="success"
                        >
                          Sign in
                        </Button>
                        <ForgetPassword onClick={navigateForgot}>
                          Forgot my password
                        </ForgetPassword>
                        <ResendEmail onClick={resendEmail}>
                          {resend == true && sending == false && (
                            <div> Resend activation email </div>
                          )}
                          {resend == true && sending == true && (
                            <div
                              style={{
                                color: "lightgray",
                                pointerEvents: "none",
                              }}
                            >
                              {" "}
                              Resend activation email{" "}
                            </div>
                          )}
                        </ResendEmail>
                      </section>
                    )}
                  </WrapperFormDocPlace>
                </LoginFormDocPlace>
              )}
            </WrapperLogin>
          </Wrapper>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
