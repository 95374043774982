import http from "./http-common";
import axios from "axios";

/* Process send request emails to sign*/

export const requiredESignature = (body) => {
  let url =
    process.env.REACT_APP_HOST_API + `/automation/api/v1.0/RequiredEsignature`;
  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

/* Process Sign*/

export const SignDocumentByRequest = (id) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/signature/user/SignDocumentByRequest/${id}`;
  return http.post(
    url,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
        customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      },
    }
  );
};

/* Save Labeled data */

export const saveLabeledData = (document, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/EsignatureProcess/CreateLabelData`;

  const tokenData = localStorage.getItem("docplace");
  const parsedTokenData = JSON.parse(tokenData);

  if (!parsedTokenData) {
    throw new Error("No se encontró información en localStorage");
  }

  const { token, customerId } = parsedTokenData;

  return axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      customerId: customerId,
      documentId: document,
    },
  });
};
/* Get signature process */

export const getIdRequestSignatureByDocumentId = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/RequiredEsignature/GetIdRequestSignatureByDocumentId`;
  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      documentId: documentId,
    },
  });
};

/* Get Labeled data */

export const getEsignatureData = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/EsignatureProcess/GetLabelData`;
  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      documentId: documentId,
    },
  });
};

export const getWorkFlowData = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/WorkflowProcessRequest/GetLabelData`;
  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      documentId: documentId,
    },
  });
};

/* Delete Labeled data */

export const deleteLabeledData = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/EsignatureProcess/DeleteLabelData`;
  return http.delete(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      documentId: documentId,
    },
  });
};

/* Update Labeled data */

export const updateLabeledData2 = (documentId, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/EsignatureProcess/UpdateLabelData`;

  const tokenData = localStorage.getItem("docplace");
  const parsedTokenData = JSON.parse(tokenData);

  if (!parsedTokenData) {
    throw new Error("No se encontró información en localStorage");
  }

  const { token, customerId } = parsedTokenData;

  return axios.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      customerId: customerId,
      templateId: documentId,
    },
  });
};

export const updateLabeledData = (documentId, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/EsignatureProcess/UpdateLabelData`;
  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      templateId: documentId,
    },
  });
};

/* Get Labeled data */

export const getEsignature = (id) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Signature/User/${id}/All?Page=1&PageSize=10`;
  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: id,
    },
  });
};
