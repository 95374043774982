import React, { useEffect } from "react";
import {
  DescriptionDetail,
  OutOCRDescription,
  OutOcrText,
  useStyles,
  WpMessage,
  WpMssText,
  WrapperDropZone,
  WrapperFormOutOCR,
  WrapperOutOCR,
  WrapperUpload,
} from "./style";
import FileUploadDocPlace from "../../../../components/fileUpload/fileUpload";
import Switch from "@mui/material/Switch";
import { FormWrapperDocPlace } from "./formWrapper";

export function UploadDocumentDocTypeDocPlace(props) {
  const classes = useStyles();
  const [totalFiles, setTotalFiles] = React.useState([]);
  const [updateAllFiles, setUpdateAllFiles] = React.useState(false);
  const [useOCR, setUseOCR] = React.useState(false);
  const [listTree, setListTree] = React.useState(false);

  const updateFile = (files) => {
    setTotalFiles(files);
  };

  const setFormData = (id) => {
    props.callBack({ id: id, useOCR: useOCR });
  };

  const setValueUseOCR = (e) => {
    setUseOCR(e.currentTarget.checked);
  };

  useEffect(() => {
    setListTree(props.tree);
  }, [props.tree]);

  return (
    <WrapperUpload>
      {!useOCR ? (
        <>
          <DescriptionDetail>
            To create a new Document Type, it is necessary to upload at least 5
            different files. Please select files that show the possible
            differences in the documents that you will process in the future.
            Good examples are movement of text due to scanning and documents
            with text maximum lengths.
          </DescriptionDetail>
          <WrapperDropZone>
            <FileUploadDocPlace
              uploadF={updateFile.bind(this)}
              updateAllFiles={updateAllFiles}
            />
          </WrapperDropZone>
        </>
      ) : (
        <></>
      )}

      {totalFiles.length <= 0 ? (
        <>
          <WrapperOutOCR>
            <OutOcrText>
              Create Document Type WITHOUT OCR
              <OutOCRDescription>
                If you select this option, none of the documents upload using
                this Document Type will be processed by the OCR engine for
                content scan and their attributes will have to be indexed
                manually.
              </OutOCRDescription>
              <div className={classes.activeOCR}>
                <Switch onChange={setValueUseOCR.bind(this)} />
              </div>
            </OutOcrText>
          </WrapperOutOCR>
          {useOCR ? (
            <WrapperFormOutOCR>
              <FormWrapperDocPlace
                tree={listTree}
                callBack={setFormData.bind(this)}
                outFileWatcher={useOCR}
                files={totalFiles ? totalFiles : []}
              />
            </WrapperFormOutOCR>
          ) : null}
        </>
      ) : (
        <WpMessage>
          <WpMssText
            style={{
              backgroundColor:
                5 - totalFiles.length <= 0 ? "#006ac6" : "#607d8b",
            }}
          >
            {5 - totalFiles.length <= 0
              ? "Enter the details of this new DocType"
              : "You need to add at least " +
                (totalFiles.length - 5) * -1 +
                " documents!"}
          </WpMssText>

          <FormWrapperDocPlace
            tree={listTree}
            callBack={setFormData.bind(this)}
            outFileWatcher={useOCR}
            files={totalFiles ? totalFiles : []}
          />
        </WpMessage>
      )}
    </WrapperUpload>
  );
}

export default UploadDocumentDocTypeDocPlace;
