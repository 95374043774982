import {
  SEARCH_DOCUMENT_LIST,
  SEARCH_DOCUMENT_LIST_SUCCESS,
  API_ERROR_SEARCH_DOCUMENT,
  SEARCH_FORM_LIST,
  SEARCH_FORM_LIST_SUCCESS,
  API_ERROR_SEARCH_FORM
} from "./actionTypes"

export const getSearchDocument = (search, navigate) => {
  return {
    type: SEARCH_DOCUMENT_LIST,
    payload: {search, navigate},
  }
}

export const searchDocumentSuccess = search => {
  return {
    type: SEARCH_DOCUMENT_LIST_SUCCESS,
    payload: search,
  }
}


export const apiErrorSearchDocument = error => {
  return {
    type: API_ERROR_SEARCH_DOCUMENT,
    payload: error,
  }
}

export const getSearchForm = (search, navigate) => {
  return {
    type: SEARCH_FORM_LIST,
    payload: {search, navigate},
  }
}

export const searchFormSuccess = search => {
  return {
    type: SEARCH_FORM_LIST_SUCCESS,
    payload: search,
  }
}

export const apiErrorSearchForm = error => {
  return {
    type: API_ERROR_SEARCH_FORM,
    payload: error,
  }
}


