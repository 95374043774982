import {
  GET_ALL_FORMS_REQUESTED,
  GET_ALL_FORMS_REQUESTED_CLEAR_DATA,
  GET_ALL_FORMS_REQUESTED_ERROR,
  GET_ALL_FORMS_REQUESTED_SUCCESS,
} from "./actionTypes";

// Get all forms requested
export const getFormsRequested = (data) => {
  return {
    type: GET_ALL_FORMS_REQUESTED,
    payload: { data },
  };
};

export const getFormsRequestedSuccess = (success) => {
  return {
    type: GET_ALL_FORMS_REQUESTED_SUCCESS,
    payload: success,
  };
};

export const getFormsRequestedError = (error) => {
  return {
    type: GET_ALL_FORMS_REQUESTED_ERROR,
    payload: error,
  };
};

export const getFormsRequestedClearData = () => {
  return {
    type: GET_ALL_FORMS_REQUESTED_CLEAR_DATA,
    payload: {},
  };
};
// ****---END---****
