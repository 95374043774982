import React, {useEffect} from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Menu from "@mui/material/Menu";

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Delete from '@mui/icons-material/DeleteTwoTone';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


export function SetRowGridDocPlace(props) {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [row, setRow] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [actions, setActions] = React.useState([]);

    useEffect(() => {
        if(props.row){
           setRow(props.row);
       }
    }, [props.row])

    useEffect(() => {
        if(props.columns){
            setColumns(props.columns);
        }
    }, [props.columns])

    useEffect(() => {
        if(props.actions){
            setActions(props.actions);
        }
    }, [props.actions])

    const openOption = Boolean(anchorEl);

    const clickMenuOption = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const closeMenuOption = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const selectOption = (op) => {
        props.option({action:op,id:props.row});
        setAnchorEl(null);
        setOpen(false);
    }

    return (
        <React.Fragment>
            <TableRow style={{backgroundColor:props.bg}} sx={{ '& > *': { borderBottom: 'unset' } }}>
                {columns.map((c,idxC) => (
                    <TableCell key={idxC}>
                        {
                           c.type === 'date' ?
                               row[c.label]
                               :
                           row[c.label]
                        }

                    </TableCell>
                ))}
                <TableCell style={{width:'160px'}} align="center">
                    <Button
                        id="demo-customized-button"
                        aria-haspopup="true"
                        aria-expanded={openOption ? 'true' : undefined}
                        variant="contained"
                        onClick={clickMenuOption}
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        Acciones
                    </Button>
                    <StyledMenu
                        id="demo-customized-menu"
                        anchorEl={anchorEl}
                        open={openOption}
                        onClose={closeMenuOption}
                    >
                        {actions.map((ac,idxC1) => (
                            <div key={idxC1}>
                                <MenuItem  onClick={selectOption.bind(this,ac.label)} disableRipple>
                                    {ac.name}
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                            </div>
                        ))}
                    </StyledMenu>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default SetRowGridDocPlace
