import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import { colors } from "../../../theme";

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    display: "flex",
    paddingRight: "15px",
    gap: "20px",
    width: "100%",
    fontSize: "12px",
    fontWeight: "100",
    marginLeft: "12px",
    marginTop: "3px",
  },
}));

export const WrapperRadioButton = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  display: inline-block;
  background-color: white;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.white};
  position: relative;
  height: auto;
`;

export const SelectTitle = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainerTools = styled.section`
  position: absolute;
  bottom: -30px;
  right: 0;
`;

export const ContainerTextField = styled.div`
  width: 100%;
`;
