import React from "react";
import {useStyles, Wrapper, WrapperIcon, WrapperPathMapLi, WrapperPathMapSpan, WrapperPathMapUl,} from "./style";
import iconTreeActive from "../../../../assets/folder-tree@2x.png";
import iconTreeInActive from "../../../../assets/folder-tree.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import iconFolder from "../../../../assets/folder@2x.png";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import {postFolderDownloadZip} from "../../../../store/explorer/actions";
import {useDispatch, useSelector} from "react-redux";

export function NavDocPlace(props) {
  const classes = useStyles();
  const [openUpload, setOpenUpload] = React.useState(false);
  const [showTree, setShowTree] = React.useState(true);
  const dispatch = useDispatch();

  const { responseFolderDownload, loadingFolderDownload } = useSelector((state) => ({
    responseFolderDownload: state.Explorer.responseFolderDownload,
    loadingFolderDownload: state.Explorer.loadingFolderDownload,
  }));

  const activeTree = (v) => {
    props.showTree(!showTree);
    setShowTree(!showTree);
  };

  const updateFile = () => {
    setOpenUpload(true);
  };

  const updateClose = () => {
    setOpenUpload(false);
  };

  const clickMap = (item) => {
    props.clickMap(item);
  };

  const homeMap = (item) => {
    props.clickHomeMap(item);
  };

  const upLevel = (item) => {
    props.upLevel(item);
  };

  const refresh = (item) => {
    props.refresh(item);
  };

  const openMenuItems = () => {
    props.callBackOptionMenuItemsOpen();
  };

  /**DOC-1441 clear trash bin */
  const clearTrashBin = () => {
    props.clearTrash();
  };

  let activeFolder = props.mapPath[props.mapPath.length - 1];
  const downloadFolder = () => {
    let folderName = activeFolder?.name
    let obj = [activeFolder?.id]
    dispatch(postFolderDownloadZip(folderName, obj));
  }

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={8} md={10}>
          <WrapperIcon
            onClick={activeTree}
            className={showTree ? classes.activeTree : classes.inActiveTree}
          >
            {showTree ? (
              <img
                src={iconTreeActive}
                style={{ objectFit: "contain", width: "25px", margin: "5px" }}
                alt="tree"
              />
            ) : (
              <img
                src={iconTreeInActive}
                style={{ objectFit: "contain", width: "25px", margin: "5px" }}
                alt="tree"
              />
            )}
          </WrapperIcon>
          <WrapperIcon onClick={refresh.bind(this)} className={classes.refresh}>
            <RefreshIcon
              style={{ color: "#fff", margin: "-3px 0px 0px -2px" }}
            />
          </WrapperIcon>
          {
            props?.mapPath?.length > 0 && (
                  <WrapperIcon onClick={downloadFolder} className={classes.downloadZip}>
                    <SystemUpdateAltIcon
                        style={{ color: "#4caf50", margin: "-3px 0px 0px -2px" }}
                    />
                  </WrapperIcon>
              )
          }
          <div style={{ float: "left" }}>
            <article className={classes.rootFolder}>
              <img
                onClick={() => homeMap()}
                src={iconFolder}
                className={classes.PathMapIconFolder}
                alt="Folder"
              />
            </article>
            {(props.mapPath.length > 0 && props.mapPath[props.mapPath.length - 1].name === "Trash") &&
              <div style={{ width: '80px', float: 'left' }}>
                <Button onClick={clearTrashBin} sx={{
                  backgroundColor: 'orange', color: 'white', float: 'right', '&:hover': {
                    background: "#ffaf7a",
                  },
                }}>
                  Empty
                </Button>
              </div>
            }
            <WrapperPathMapUl>
              {props.mapPath.map((map, idx) => {
                return (
                  <WrapperPathMapLi
                    key={idx}
                    onClick={clickMap.bind(this, map.id)}
                    className={
                      idx <= props.mapPath.length ? "IK" : classes.PathActive
                    }
                  >
                    {idx === 0 ? null : (
                      <WrapperPathMapSpan>/</WrapperPathMapSpan>
                    )}
                    {map.name}
                  </WrapperPathMapLi>
                );
              })}
            </WrapperPathMapUl>
          </div>
          {props.selectedFiles.length > 10000 ? (
            <WrapperIcon
              onClick={openMenuItems.bind(this)}
              className={classes.optionMenu}
            >
              <MoreHorizIcon
                style={{ color: "#006ac6", margin: "-3.5px 0px 0px -2px" }}
              />
            </WrapperIcon>
          ) : null}
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default NavDocPlace;
