import { call, put, takeEvery } from "redux-saga/effects";
import {
  USER_LIST,
  USER_POST,
  USER_PASSWORD,
  USER_PUT,
  USER_DELETE,
  ROLE_LIST,
  USER_ASG_ROL_POST,
  USER_ASG_ROL_PUT,
  USER_ARCHIVE_ROLES,
} from "./actionTypes";
import {
  apiErrorUser,
  userSuccess,
  apiErrorUserPost,
  userPostSuccess,
  apiErrorUserPassword,
  userPasswordSuccess,
  apiErrorUserPut,
  userPutSuccess,
  apiErrorUserDelete,
  userDeleteSuccess,
  apiErrorRole,
  roleSuccess,
  apiErrorUserAsgRolPost,
  userAsgRolPostSuccess,
  apiErrorUserAsgRolPut,
  userAsgRolPutSuccess,
  userArchiveRolesSuccess,
  apiErrorUserArchiveRoles,
} from "./actions";

import {
  getUser,
  postUser,
  changePassword,
  putUser,
  deleteUser,
  getRole,
  postUserAsgRolAPI,
  putUserAsgRolAPI,
  getUserArchiveRolesAPI,
} from "../../helpers/call_api";
import { toast } from "react-toastify";

function* getUserList({ payload: { user, navigate } }) {
  try {
    const response = yield call(getUser, user);
    yield put(userSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorUser(error));
  }
}

function* postUserList({ payload: { user, navigate } }) {
  try {
    const response = yield call(postUser, user);
    toast.success("New User was added");
    yield put(userPostSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }

    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorUserPost(error));
  }
}
function* postChangePassword({ payload: { user } }) {
  try {
    const response = yield call(changePassword, user);
    toast.success("The password has been updated");
    yield put(userPasswordSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        //navigate('/login');
      }
    }

    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorUserPassword(error));
  }
}
function* putUserList({ payload: { user, navigate } }) {
  try {
    const response = yield call(putUser, user.data, user.id);
    toast.success("User was updated");
    yield put(userPutSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorUserPut(error));
  }
}

function* deleteUserList({ payload: { user, navigate } }) {
  try {
    const response = yield call(deleteUser, user);
    toast.success("User was deleted");
    yield put(userDeleteSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorUserDelete(error));
  }
}

function* getRoleList({ payload: { role, navigate } }) {
  try {
    const response = yield call(getRole, role);
    yield put(roleSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorRole(error));
  }
}

function* postUserAsgRol({ payload: { userAsgRol, navigate } }) {
  try {
    const response = yield call(postUserAsgRolAPI, userAsgRol);
    yield put(userAsgRolPostSuccess(response));
  } catch (error) {
    if (error?.response?.status) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }

    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorUserAsgRolPost(error));
  }
}

function* putUserAsgRol({ payload: { userAsgRol, navigate } }) {
  try {
    const response = yield call(putUserAsgRolAPI, userAsgRol);
    yield put(userAsgRolPutSuccess(response));
  } catch (error) {
    if (error?.response?.status) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
    }

    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorUserAsgRolPut(error));
  }
}
/**DOC-1778 get the list of archive roles for one user */
function* getArchiveRoles({ payload: { userID, navigate } }) {
  try {
    const response = yield call(getUserArchiveRolesAPI, userID)
    yield put(userArchiveRolesSuccess(response))
  } catch (error) {
    if(error?.response?.status){
      if(error?.response?.status===401){
        navigate('/login');
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
     yield put(apiErrorUserArchiveRoles(error))
  }
}

function* authSaga() {
  yield takeEvery(USER_LIST, getUserList);
  yield takeEvery(USER_POST, postUserList);
  yield takeEvery(USER_PASSWORD, postChangePassword);
  yield takeEvery(USER_PUT, putUserList);
  yield takeEvery(USER_DELETE, deleteUserList);
  yield takeEvery(ROLE_LIST, getRoleList);
  yield takeEvery(USER_ASG_ROL_POST, postUserAsgRol);
  yield takeEvery(USER_ASG_ROL_PUT, putUserAsgRol);
  yield takeEvery(USER_ARCHIVE_ROLES,getArchiveRoles);
}

export default authSaga;
