import { call, put, takeEvery } from "redux-saga/effects"
import { SEARCH_DOCUMENT_LIST} from "./actionTypes"
import { SEARCH_FORM_LIST } from "./actionTypes";
import {
    apiErrorSearchDocument,
    searchDocumentSuccess,
    apiErrorSearchForm,
    searchFormSuccess
} from "./actions"
import {toast, ToastContainer} from "react-toastify";


import {
    getSearchDocumentAPI,
    getSearchFormAPI,
} from "../../helpers/call_api"


function* getSearchDocument({ payload: { search, navigate } }) {
  try {
   
    const response = yield call(getSearchDocumentAPI, search.page, search.pageSize, search.period , search.data)
    /*try {
      //toast('Result Search: ' + response.data.length + " documents",'success');
    } catch (error) {
      toast('Result Search Error ','success');
    }
      */
    yield put(searchDocumentSuccess(response))
  } catch (error) {
      if(error.response.status){
          if(error.response.status===401){
              navigate('/login');
          }
      }
      toast.error(error?.response?.data?.error?.messages[0].message);
     yield put(apiErrorSearchDocument(error))
  }
}

function* getSearchForm({ payload: { search, navigate } }) {
  try {
        const response = yield call(getSearchFormAPI, search.page, search.pageSize, search.data)
        /*try {
          //toast('Result Search: ' + response.data.length + " documents",'success');
        } catch (error) {
          toast('Result Search Error ','success');
        }
          */
        yield put(searchFormSuccess(response))
    } catch (error) {
        if(error.response.status){
            if(error.response.status===401){
                navigate('/login');
            }
        }
        toast.error(error?.response?.data?.error?.messages[0].message);
        yield put(apiErrorSearchForm(error))
    }
}



function* authSaga() {
  yield takeEvery(SEARCH_DOCUMENT_LIST, getSearchDocument)
  yield takeEvery(SEARCH_FORM_LIST, getSearchForm)
}

export default authSaga
