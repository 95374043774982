import styled from "styled-components";
import {colors} from '../../../../components/theme';



export const TitleSignature = styled.h1`
  margin: -7px 0 0 0;
  font-variant-caps: petite-caps;
  color: ${colors.blue};
`;

export const RequestSignatures = styled.span`
	font-size: 14px;
  color: ${colors.gray2};
  text-decoration: underline;
	font-weight: bold;
  cursor: pointer;
  width: 150px;
  display: block;
  height: 35px;
  padding: 8px;
  margin: 20px 0px 13px 0;
  border-radius: 5px;
	&:hover{
    background-color: #f2f2f2;
	}
`;

export const SpanSignature = styled.span`
  font-size: 18px;
  color: #5c5c5cc4;
`;

export const ContainerSignatures = styled.div`
  display: flex;
	border-radius: 5px;
  flex-direction: column;
  margin-top: 28px;
  background-color: #f1f1f1;
  padding: 20px;
  border-bottom: 3px solid #666666;
`;

export const Section1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const UserSignature = styled.div`
  font-size: 15px;
  line-height: 1;
  width: 250px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UserSignatureType = styled.div`
  font-size: 18px;
  line-height: 1;
  width: 250px;
  text-align: left;
  font-weight: 900;
  color: #016ac6;
`;



export const Section2 = styled.div`
  margin-top: 7px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  height: 45px;
	border-radius: 5px;
`;

export const ImgSignature = styled.img`
  margin: 0 auto;
  max-width: 100%;
	object-fit: contain;
`;