import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { colors } from "../../../../components/theme";

export const useStyles = makeStyles((theme) => ({
  headerStyle: {
    backgroundColor: "white",
    fontSize: "15px",
  },
  subtitle: {
    fontSize: "12px",
    fontWeight: "lighter",
  },
}));

export const WrapperHeader = styled.div`
  width: 100%;
  padding: 20px;
  overflow: hidden;
  border-radius: 4px;
  display: inline-block;
  background-color: white;
  height: auto;
`;
export const CTitle = styled.h4`
  color: #006ac6;
  margin: 0px auto;
  text-align: left;
  font-weight: 900;
  position: absolute;
`;
export const Container = styled.div`
  width: 100%;
  background-color: ${colors.white};
  position: relative;
  height: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainerTools = styled.section`
  position: absolute;
  bottom: -30px;
  right: 0;
`;

export const ContainerTextField = styled.div`
  width: 100%;
`;

export const DivHelperTextTitle = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
`;
