import React from "react";
import MetaTags from "react-meta-tags";

import ConfirmRegisteredSignature from "./resouerce/confirm/confirmRegisteredSignature";

const AssignedFormsViewDocPlace = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DocPlace forms</title>
        </MetaTags>
      </div>
      <ConfirmRegisteredSignature show={props.show} close={props.close} />
    </React.Fragment>
  );
};

export default AssignedFormsViewDocPlace;
