import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useStyles } from "./style";
import "./style.scss";

import { Container } from "reactstrap";
import TitleDocPlace from "../../components/title/title";
import SearchDocPlace from "../../components/search/search";
import GridDocPlace from "./components/grid/grid";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LoadingComponent from "../../components/loading";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  deleteDocType,
  getDocTypeList1,
  updateDocType,
} from "../../store/docType/actions";
import PathTree from "../DocumentType/components/detalle/pathTreeEdit";

import TextField from "@material-ui/core/TextField";

import FormControl from "@mui/material/FormControl";

const DocumentTypeDocPlace = (props) => {
  const notify = (msj, event) => toast[event](msj);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startPage] = useState(true);
  const [doctypes, setDoctypes] = useState([]);
  const [docTypesAPI, setDocTypesAPI] = useState([]);
  const [page] = useState(1);
  const [pageSize] = useState(1000);
  const [open, setOpen] = useState(false);
  const [emptyPath, setEmptyPath] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [doctypeId, setDoctypeId] = useState(null);

  const [folderId, setFolderId] = useState("");
  const [folderName, setFolderName] = useState("");

  const [fileWatcher, setFileWatcher] = useState(true);
  const [path, setPath] = useState({ value: "", error: false });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 4,
  };
  const { responseTree, loadingTree } = useSelector((state) => ({
    responseTree: state.Tree.responseTree,
    loadingTree: state.Tree.loadingTree,
  }));

  const { responseDocType1, errorDocType1, loading } = useSelector((state) => ({
    responseDocType1: state.DocType.responseDocType1,
    errorDocType1: state.DocType.errorDocType1,
    loading: state.DocType.loadingDocType1,
  }));
  const { responseDocTypeDelete, errorDocTypeDelete, loading2 } = useSelector(
    (state) => ({
      responseDocTypeDelete: state.DocType.responseDocTypeDelete,
      errorDocTypeDelete: state.DocType.errorDocTypeDelete,
      loading2: state.DocType.loadingDocTypeDelete,
    })
  );

  const { responseDocTypeUpdate, errorDocTypeUpdate, loading3 } = useSelector(
    (state) => ({
      responseDocTypeUpdate: state.DocType.responseDocTypeUpdate,
      errorDocTypeUpdate: state.DocType.errorDocTypeUpdate,
      loading3: state.DocType.loadingDocTypeUpdate,
    })
  );

  useEffect(() => {
    dispatch(
      getDocTypeList1(
        {
          page: page,
          pageSize: pageSize,
          sortBy: "created_at",
          sortOrder: 2,
          filtered: false,
        },
        navigate
      )
    );
  }, [startPage, page, pageSize]);

  useEffect(() => {
    if (responseDocType1 && responseDocType1.data.length > 0) {
      setDoctypes(responseDocType1.data);
      setDocTypesAPI(responseDocType1.data);
    }
  }, [responseDocType1]);
  useEffect(() => {
    if (responseDocTypeDelete !== null) {
      dispatch(
        getDocTypeList1(
          {
            page: page,
            pageSize: pageSize,
            sortBy: "created_at",
            sortOrder: 2,
            filtered: false,
          },
          navigate
        )
      );
    }
  }, [responseDocTypeDelete]);

  useEffect(() => {
    if (responseDocTypeUpdate !== null) {
      dispatch(
        getDocTypeList1(
          {
            page: page,
            pageSize: pageSize,
            sortBy: "created_at",
            sortOrder: 2,
            filtered: false,
          },
          navigate
        )
      );
    }
  }, [responseDocTypeUpdate]);

  const findDocumentTypes = (term) => {
    setDoctypes(
      term
        ? docTypesAPI.filter((doc) => doc.name.toLowerCase().includes(term))
        : docTypesAPI
    );
  };

  const optionGrid = (value) => {
    if (value.action === "attributes") {
      /* Decide if we need to use the new or the legacy version for the labeling tool, based on the hardcoded date.*/
      console.log(window.location.hostname);
      let legacyDate = "2023-03-06T00:00:00";
      let d = new Date(value.row.row.createdAt);
      //Is dev? Todo: get the value from function
      if (window.location.hostname != "app.docplace.io") {
        legacyDate = "2023-01-18T00:00:00";
      }

      let d2 = new Date(legacyDate);

      let newVersion = d.getTime() > d2.getTime() ? true : false;

      navigate(
        "/documentType/detail/" +
          value.id +
          "/" +
          value.row.row.ignoreOcr +
          "/" +
          newVersion
      );
    }
    if (value.action === "edit") {
      setName(value.row.row.name);
      setDescription(value.row.row.description);
      setFolderId(value.row.row.targetArchiveId);
      setFolderName(value.row.row.targetFolderName);
      setDoctypeId(value.row.row.id);
      setOpen(true);
    }

    if (value.action === "remove") {
      deleteItem(value.id);
    }
  };

  const handleOpen = () => {
    setPath({
      value: null,
      error: false,
    });
    setOpen(true);
  };
  const updateItem = () => {
    console.log(name, description, folderId, path.value.value);
    if (path.value.value == undefined) {
      let data = {
        name: name,
        description: description,
        targetArchiveId: folderId,
      };
      dispatch(updateDocType(doctypeId, { data: data }, null));
      setOpen(false);
      //toast.warning("Select a valid path.");
    } else {
      let data = {
        name: name,
        description: description,
        targetArchiveId: path.value.value,
      };
      dispatch(updateDocType(doctypeId, { data: data }, null));
      setOpen(false);
    }
  };
  const deleteItem = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Are you sure?",
      text: "",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        dispatch(deleteDocType({ id: id }, navigate));
      }
    });
  };

  const searchFile = (filterSearch) => {
    findDocumentTypes(filterSearch);
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleOpen()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Document Type
          </Typography>
          <Typography
            component={"div"}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          ></Typography>

          <FormControl fullWidth>
            <TextField
              style={{ width: "100%", marginBottom: "8px" }}
              id="Name"
              defaultValue={name}
              onChange={handleChangeName}
              label="Name"
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              style={{ width: "100%", marginBottom: "8px" }}
              id="Description"
              defaultValue={description}
              onChange={handleChangeDescription}
              label="Description"
            />
          </FormControl>
          <div className="col-sm-12">
            <PathTree
              emptyPath={emptyPath}
              folderId={folderId}
              folderName={folderName}
              style={{ marginTop: "70px" }}
              tree={responseTree ? responseTree : []}
              hidenReservedFolder={true}
              callBack={(v) => {
                setPath({
                  value: v,
                  error: false,
                });
              }}
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={() => updateItem()}
            sx={{ mt: 2, width: "100px", float: "right" }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
            sx={{ mt: 2, mr: 1, width: "100px", float: "right" }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>DocPlace Document Type</title>
          </MetaTags>
          <Container fluid>
            <TitleDocPlace title={"Document Type"} viewActive={false} />
            <ToastContainer autoClose={3000} />
            <LoadingComponent
              show={loading || loading2 || loading3}
              text={"Getting Documents Type..."}
            />
            <SearchDocPlace callSearch={searchFile.bind(this)} />
            <div className={classes.WrapperNew}>
              <Button
                onClick={() => {
                  navigate("/documentType/detail/null/null/null");
                }}
                size="small"
                color="primary"
                variant="contained"
              >
                <AddBoxIcon />
                <span>New</span>
              </Button>
            </div>
            <GridDocPlace
              data={doctypes ? doctypes : []}
              option={optionGrid.bind(this)}
            />
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default DocumentTypeDocPlace;
