import React from "react";
import { WrapperTree, TreeUl, TreeLi, TreeLiText, WrapperBackTree, ChooseFolder } from './style';
import '../../../Explorer/explorer-style.scss';
import folderClose from '../../../../assets/folder@2x.png';
import folderGray from '../../../../assets/folderGray.png';  //DOC-1621 add folder gray
import arrowClose from '../../../../assets/chevron-right-02@2x.png';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import imgRoot from '../../../../assets/folder-tree.png';


import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddTaskIcon from '@mui/icons-material/AddTask';

const mapTree = [];

class TreeItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listItems: this.props.tree,
            item: '',
            click: 0,
            mapExplorer: [],
            mapExplorerBack: []

        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    setChild = (children) => {
        return (
            children.map((item, idx) => {
                if (item.children.length > 0) {
                    this.setChild(item.children);
                }

                return (
                    <TreeUl key={idx}>
                        <TreeLi
                            className={item.open === true ? 'openFolder' : 'closeFolder'}
                            key={idx}>

                            <span
                                className={item.id === this.state.item.id ? 'activeTree' : ''}
                                onClick={this.openChild.bind(this, item.id, item.name)}>
                                <img src={arrowClose} className='imgArrowTree' alt="" />
                                <img src={folderClose} className='imgFolderTree' alt="" />
                                <TreeLiText>{item.name}</TreeLiText>
                            </span>

                            {
                                item.children ? this.setChild(item.children) : null
                            }
                        </TreeLi>
                    </TreeUl>
                )
            })
        )
    }

    openChild = (item, event) => {
        if (item.id === 'Root') return;  //if it is the root folder, don't navigate to the child.
        if (item.folder) {
            this.props.click({ label: item.name, value: item.id, parent: item.parent });
        } else {
            this.props.click({ label: item.name, value: item.id });
        }

        this.setMap(this.props.tree.data, item);
        this.setState({
            listItems: this.props.tree.data,
            item: { id: item, name: item.name },
            mapExplorerBack: this.state.mapExplorer
        })
    }

    addChild = (item) => {
        this.props.addFolder({ name: item.name, id: item.id });
    }




    backTree = (value) => {
        if (value.length > 0) {
            this.props.backTree({ label: value[0].name, value: value[0].parent ? value[0].parent.id : '' });
        } else {
            this.props.backTree({
                label: this.props.treePrevius[this.props.treePrevius.length - 1].name,
                value: this.props.treePrevius[this.props.treePrevius.length - 1].id
            });
        }

    }


    recursiveTree = (tree, id) => {
        tree.map((item, idx) => {
            if (item.children.length > 0) {
                this.recursiveTree(item.children, id)
            }
            if (item.id === id) {
                item.open = !item.open;
            }
        })
    }

    setMap = (tree, id) => {
        tree.map((item, idx) => {
            if (item.id === id) {
                mapTree.push({ id: item.id, name: item.name });
            }
        })
    }

    render() {
        return (
            <WrapperTree>
                {this.props.treePrevius.length > 0 && this.props.treePrevius[0].name &&
                    <WrapperBackTree onClick={this.backTree.bind(this,
                        this.props.tree.data)}>
                        <ArrowUpwardIcon style={{ float: 'left', margin: '-2px 6px 0px 8px' }} />
                        Back   _<span style={{ fontWeight: '900' }}> {this.props.treePrevius[this.props.treePrevius.length - 1].name} </span>
                    </WrapperBackTree>
                }

                {this.props.tree.data && this.props.tree.data.length > 0 &&
                    <TreeUl>
                        {
                            this.props.tree.data.map((item, idx) => {
                                return (
                                    <div style={{ position: 'relative' }}>
                                        <AddTaskIcon onClick={this.addChild.bind(this, item)} className='addFolderTree' />
                                        <TreeLi
                                            onClick={this.openChild.bind(this, item)}
                                            key={idx}>
                                            <span>
                                                {item.id !== 'Root' && <img src={folderClose} className='imgFolderTree' alt="" />}
                                                {item.id === 'Root' && <img src={folderGray} className='imgFolderTree' alt="" />}
                                                <TreeLiText style={{ color: (item.id === 'Root') ? '#383838' : '#006ac6' }}>
                                                    {item.name}
                                                </TreeLiText>
                                            </span>
                                        </TreeLi>
                                    </div>
                                )
                            })
                        }
                    </TreeUl>
                }

            </WrapperTree>
        )
    }
}


export default TreeItems
