import React from "react";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Editor } from "react-draft-wysiwyg";
import { colors } from "../../../theme";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  ShowContent,
  WrapperParagraph,
} from "./style";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ParagraphView = (props) => {
  return (
    <WrapperParagraph>
      <Container>
        <>
          {props.type === "view" ? (
            <ShowContent dangerouslySetInnerHTML={{ __html: props.content }} />
          ) : (
            <>
              <ButtonContainer>
                <Button
                  variant="text"
                  onClick={props.onChangeNotSeeIntoPDF}
                  style={{
                    textTransform: "none",
                    margin: "-12px 0 20px 0",
                    backgroundColor:
                      props.notSeeIntoPdfParagraph && colors.gray3,
                  }}
                  startIcon={<VisibilityOffIcon />}
                >
                  Not see into PDF
                </Button>
              </ButtonContainer>
              <Editor
                editorState={props.editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={props.onEditorStateChange}
              />
              <ButtonContainerTools>
                <Button
                  onClick={props.onChangeSetting}
                  style={{
                    textTransform: "none",
                    marginRight: "20px",
                  }}
                  variant={"text"}
                  startIcon={<SettingsSuggestIcon />}
                >
                  Settings
                </Button>
                <Button
                  onClick={props.onChangeDelete}
                  style={{
                    textTransform: "none",
                  }}
                  color="error"
                  variant={"text"}
                  startIcon={<DeleteForeverIcon />}
                >
                  Delete
                </Button>
              </ButtonContainerTools>
            </>
          )}
        </>
      </Container>
    </WrapperParagraph>
  );
};

export default ParagraphView;
