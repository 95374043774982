import React from "react";
import Dropzone from "react-dropzone";
import filePdf from "../../../../assets/file-pdf.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";

import {
  DragAndDropText,
  FileNewUpload,
  TitleDocumentUpload,
  useStyles,
  WrapperIcon,
} from "./style";
import UploadIcon from "@mui/icons-material/Upload";

const UploadFileRequestSignatureView = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Dropzone>
        {() => (
          <section>
            <div
              {...props.getRootProps({
                className:
                  props.selectedFiles.length > 0
                    ? classes.dropZoneComplete
                    : classes.dropzone,
              })}
            >
              <input {...props.getInputProps()} />
              {props.selectedFiles && props.selectedFiles.length ? (
                <div>
                  <FileNewUpload style={{ float: "left" }}>
                    <WrapperIcon>
                      <UploadIcon className={classes.iconUploadFile} />
                      <TitleDocumentUpload>UPLOAD</TitleDocumentUpload>
                      <DragAndDropText>
                        {" "}
                        Drag and Drop your Files
                      </DragAndDropText>
                    </WrapperIcon>
                  </FileNewUpload>

                  {props.selectedFiles.length > 50
                    ? `${props.selectedFiles.length} files`
                    : props.selectedFiles.map((file, index) => (
                        <div className={classes.dropBox} key={index}>
                          <img
                            alt={"DocPlace"}
                            className={classes.imgDoc}
                            src={filePdf}
                          />
                          <div className={classes.imgDescription}>
                            {file.name}
                          </div>
                          <div className={classes.imgDescription}>
                            {(file.size / 1000000).toFixed(2)} MB
                          </div>
                        </div>
                      ))}
                </div>
              ) : (
                <FileNewUpload>
                  <UploadIcon className={classes.iconUploadFile} />
                  <TitleDocumentUpload>UPLOAD</TitleDocumentUpload>
                  <DragAndDropText> Drag and Drop your Files</DragAndDropText>
                </FileNewUpload>
              )}
            </div>
          </section>
        )}
      </Dropzone>
      {props.showUpload && (
        <Button
          onClick={props.saveUpload}
          style={{ marginTop: "17px" }}
          startIcon={<CloudUploadIcon />}
          variant="outlined"
        >
          Upload Files
        </Button>
      )}
      {props.showUpload && (
        <Button
          onClick={props.closeUpload}
          style={{ marginTop: "17px", float: "right" }}
          color="error"
          startIcon={<ClearIcon />}
          variant="outlined"
        >
          Close Upload
        </Button>
      )}
    </div>
  );
};

export default UploadFileRequestSignatureView
