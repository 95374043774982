import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const DivLogoDocPlace = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  justify-content: initial;
`;

export const DivContainerG1 = styled.div`
  width: 100%;
  height: 206px;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 15px;
`;

export const DivTextG1 = styled.div`
  min-width: 236px;
  max-width: 300px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-align: justify;
  border-radius: 5px;
`;

export const DivContainerG2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  gap: 10px;
`;

export const Title = styled.h3`
  margin: 0 !important;
  color: #006ac6;
`;

export const ContentUl = styled.section`
  background-color: rgb(245, 247, 250);
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  height: 206px;
`;

export const StyledUl = styled.ul`
  width: 100%;
  margin: 0;
  list-style-type: none;
  background-color: transparent;
  border-radius: 5px;
  max-height: 190px;
  overflow-y: auto;
  padding: 0 8px 0 0;

  /* Estilo para la barra de desplazamiento */

  &::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Color de fondo de la pista */
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888; /* Color de la barra */
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color de la barra al pasar el mouse por encima */
  }
`;

export const StyledLi = styled.li`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 0 0 0 5px;
  margin-bottom: 8px;

  &.last {
    margin-bottom: 0;
  }
`;

export const NameAttachment = styled.p`
  padding: 0;
  width: 100%;
  margin: 0 0 0 10px;
  font-size: 15px;
  word-break: break-all;
  line-height: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #363636;
  font-weight: bold;
`;

export const MetaDataDescription = styled.p`
  padding: 0;
  width: 100%;
  margin: 0 0 0 10px;
  font-size: 13px;
  word-break: break-all;
  line-height: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #555;
`;

export const TextSend = styled.p`
  padding: 0;
  width: 100%;
  margin: 0 5px 0 10px;
  font-size: 15px;
  word-break: break-all;
  line-height: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #006ac6;
  font-weight: bold;
`;

export const useStyles = makeStyles((theme) => ({
  logoDocPlace: {
    objectFit: "cover",
    width: "70%",
  },
  logoMetaData: {
    objectFit: "cover",
    width: "auto",
  },
  logoAttach: {
    objectFit: "cover",
    width: "35px",
    height: "35px",
    margin: "0 !important",
  },
}));
