import { makeStyles, useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { colors } from "../../components/theme";

export const TitleDocType = styled.label`
  font-size: 1.2em;
  color: #607d8b;
  text-align: center;
  font-weight: 900;
  margin: 10px 0px 1px 10px;
  display: inline-block;
`;

export const File = styled.div`
  width: 120px;
  height: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 5px;
  padding-top: 15px;
  &:hover {
    background-color: #eef5fb;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23199CE3FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    cursor: pointer;
  }
`;

export const TitleDocument = styled.div`
  width: 100%;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  text-align: center;
  color: #006ac6;
  margin-top: 0px;
  word-break: break-word;
  line-height: 1;
  padding: 0px 5px 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 24px;
`;

export const WrapperFilter = styled.div`
  width: 100%;
  height: 40px;
  margin-top: 0px;
`;

export const FilterDoctype = styled.div`
  width: auto;
  height: 40px;
  font-size: 12px;
  background-color: ${colors.aqua};
  color: ${colors.white};
  margin-top: 0px;
  position: absolute;
`;

export const WPFilterContainer = styled.div`
  display: flex;
`;

export const WPFilter = styled.div`
  width: auto;
  float: left;
  margin-bottom: 20px;
  color: #fff;
  background-color: ${colors.blue};
  border-radius: 5px;
  padding: 5px;
  margin-right: 15px;
  position: relative;
  border: solid;
`;

export const WPFilterSpan = styled.span`
  font-size: 12px;
  display: block;
`;

export const WPFilterText = styled.div`
  font-weight: 900;
  margin: -4px 0px 0px 0px;
`;

export const useStyles = makeStyles((theme) => ({
  buttons: {
    width: "100%",
    display: "inline-block",
    marginTop: "20px",
    height: "40px",
    color: "#560e1a",
  },
  titleTooltip: {
    backgroundColor: "#1876d2",
    color: "#fff !important",
    width: "100%",
    height: "auto",
    padding: "10px",
    fontSize: "13px !important",
    wordBreak: "break-all !important",
    lineHeight: "1 !important",
  },
  float_center: {
    display: "grid",
    gridGap: "14px",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    gridAutoRows: "120px",
  },
  btnFilter: {
    float: "right",
    marginTop: "20px !important",
  },
  btnSave: {
    backgroundColor: "#1876d2 !important",
    margin: "16px 0px 18px 0px !important",
    color: "#fff !important",
    borderBottom: "2px solid #1876d2 !important",
    boxShadow: "0 0 0 !important",
  },
  btnReset: {
    backgroundColor: "red !important",
    margin: "16px 10px 18px 10px !important",
    color: "#fff !important",
    borderBottom: "2px solid red !important",
    boxShadow: "0 0 0 !important",
  },
  closeUpload: {
    backgroundColor: "red",
    color: "red !important",
    margin: "16px 0 0 0 !important",
    border: "1px solid red !important",
    float: "right",
  },
  helperText: {
    fontSize: "12px",
    backgroundColor: "#607d8b3d",
    padding: "5px",
    borderRadius: "3px",
  },
  closeOpt: {
    top: "11px",
    left: "11px",
    color: "white",
    width: "65px",
    cursor: "pointer",
    height: "31px",
    padding: "5px 0 0 8px",
    zIndex: "9999",
    position: "absolute",
    fontSize: "14px",
    backgroundColor: "#006ac6",
    borderRadius: "4px",
  },
  iconCloseFilter: {
    position: "absolute",
    cursor: "pointer",
    right: "0",
    top: "-22px",
    color: "#000",
    fontSize: "18px !important",
  },
}));
