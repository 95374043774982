import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { colors } from "../../components/theme";
export const LiRename = styled.li`
  margin: 6px 0 0 50px !important;
`;

export const LiMenu = styled.li`
  margin: 6px 0 0 10px !important;
`;
export const Message = styled.section`
  width: 100%;
  min-height: 50px;
  background-color: ${colors.help};
  margin-bottom: 35px;
  margin-top: 0px;
  border-radius: 5px;
  padding: 12px; 
  font-size: 14px;
`;
export const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: "100%",
  },
  closeOpt: {
    top: "11px",
    left: "11px",
    color: "white",
    width: "65px",
    cursor: "pointer",
    height: "31px",
    padding: "5px 0 0 8px",
    zIndex: "9999",
    position: "absolute",
    fontSize: "14px",
    backgroundColor: "#006ac6",
    borderRadius: "4px",
  },
  btnCancel: {
    backgroundColor: "#bb2929 !important",
    color: "#fff !important",
    float: "left",
  },
  btnDelete: {
    backgroundColor: "#006ac6 !important",
    color: "#fff !important",
    float: "right",
  },
})); 
