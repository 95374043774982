import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import attachFile from "../../../../assets/AttachfileB.svg";
import DocPlaceColor from "../../../../assets/DocPlaceColor.svg";
import {
  ContentUl,
  DivContainerG1,
  DivContainerG2,
  DivLogoDocPlace,
  DivTextG1,
  NameAttachment,
  StyledLi,
  StyledUl,
  Title,
  useStyles,
} from "./style";
import { UploadAttachFile } from "./uploadFile/uploadFile";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export const DrawerAttachment = (props) => {
  const [open, setOpen] = React.useState(props.openDrawerAttach);
  const classes = useStyles();

  useEffect(() => {
    setOpen(props.openDrawerAttach);
  }, [props.openDrawerAttach]);

  const handleDrawerClose = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    backgroundColor: "rgba(33, 150, 243, 0.52)",
  }));
  return (
    <React.Fragment>
      <Drawer
        anchor={"bottom"}
        slots={{ backdrop: CustomBackdrop }}
        open={open}
        onClose={handleDrawerClose}
      >
        <Grid container>
          <Grid item xs={2.5} style={{ paddingTop: "15px" }}>
            <DivLogoDocPlace>
              <img
                alt="Logo"
                className={classes.logoDocPlace}
                src={DocPlaceColor}
              />
            </DivLogoDocPlace>
          </Grid>
          <Grid xs={6} style={{ paddingTop: "15px" }}>
            <DivContainerG2>
              <img alt="Logo" className={classes.logoAttach} src={attachFile} />
              <Title>Attachment list</Title>
            </DivContainerG2>
          </Grid>
          <Grid xs={3.5}></Grid>
          <Grid
            item
            xs={2.5}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "15px 15px 15px 15px",
            }}
          >
            <DivContainerG1>
              <DivTextG1>aca abra texto para colocar lo que desee</DivTextG1>
            </DivContainerG1>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 15px 15px 0",
            }}
          >
            <ContentUl>
              <StyledUl>
                {props.attachmentFile?.map((item, index) => (
                  <StyledLi
                    key={item.id}
                    className={index === item.length - 1 ? "last" : ""}
                  >
                    <IconButton size="standard">
                      <CancelIcon
                        style={{ color: "#006ac6" }}
                        fontSize="inherit"
                        onClick={() => {
                          props.deleteAttachment(item);
                        }}
                      />
                    </IconButton>
                    <NameAttachment>{item.fileName}</NameAttachment>
                    <IconButton size="standard">
                      <FileDownloadIcon
                        style={{ color: "#006ac6" }}
                        fontSize="inherit"
                        onClick={() => {
                          props.downloadAttachment(item.url, item.fileName);
                        }}
                      />
                    </IconButton>
                  </StyledLi>
                ))}
              </StyledUl>
            </ContentUl>
          </Grid>
          <Grid
            item
            xs={3.5}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px 15px 15px 0",
            }}
          >
            <UploadAttachFile documentId={props.documentId} />
          </Grid>
        </Grid>
      </Drawer>
    </React.Fragment>
  );
};
