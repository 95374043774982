import {
  INPUT_METHOD_LIST,
  INPUT_METHOD_LIST_SUCCESS,
  API_ERROR_INPUT_METHOD,
  INPUT_METHOD_POST,
  INPUT_METHOD_POST_SUCCESS,
  API_ERROR_INPUT_METHOD_POST,
  INPUT_METHOD_PUT,
  INPUT_METHOD_PUT_SUCCESS,
  API_ERROR_INPUT_METHOD_PUT,
  INPUT_METHOD_DELETE,
  INPUT_METHOD_DELETE_SUCCESS,
  API_ERROR_INPUT_METHOD_DELETE,
} from "./actionTypes"

export const getInputMethodList = (inputMethod, navigate) => {
  return {
    type: INPUT_METHOD_LIST,
    payload: {inputMethod, navigate},
  }
}

export const inputMethodSuccess = inputMethod => {
  return {
    type: INPUT_METHOD_LIST_SUCCESS,
    payload: inputMethod,
  }
}

export const apiErrorInputMethod = error => {
  return {
    type: API_ERROR_INPUT_METHOD,
    payload: error,
  }
}

export const postInputMethod = (inputMethod, navigate) => {
  return {
    type: INPUT_METHOD_POST,
    payload: {inputMethod, navigate},
  }
}

export const inputMethodPostSuccess = inputMethod => {
  return {
    type: INPUT_METHOD_POST_SUCCESS,
    payload: inputMethod,
  }
}

export const apiErrorInputMethodPost = error => {
  return {
    type: API_ERROR_INPUT_METHOD_POST,
    payload: error,
  }
}

export const putInputMethod = (inputMethod, navigate) => {
  return {
    type: INPUT_METHOD_PUT,
    payload: {inputMethod, navigate},
  }
}

export const inputMethodPutSuccess = inputMethod => {
  return {
    type: INPUT_METHOD_PUT_SUCCESS,
    payload: inputMethod,
  }
}

export const apiErrorInputMethodPut = error => {
  return {
    type: API_ERROR_INPUT_METHOD_PUT,
    payload: error,
  }
}

export const deleteInputMethod = (inputMethod, navigate) => {
  return {
    type: INPUT_METHOD_DELETE,
    payload: {inputMethod, navigate},
  }
}

export const inputMethodDeleteSuccess = inputMethod => {
  return {
    type: INPUT_METHOD_DELETE_SUCCESS,
    payload: inputMethod,
  }
}

export const apiErrorInputMethodDelete = error => {
  return {
    type: API_ERROR_INPUT_METHOD_DELETE,
    payload: error,
  }
}

