import { makeStyles, useTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    buttons: {
        width: '100%',
        display: 'inline-block',
        marginTop: '20px',
        height: '40px',
        color: '#560e1a'
    }
}));


  



