import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  CUSTOMER_USER,
  CUSTOMER_SUCCESS,
  CUSTOMER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  FORGET_PASSWORD,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  HASH_VALIDATE,
  HASH_VALIDATE_SUCCESS,
  HASH_VALIDATE_ERROR,
  RESET_APP,
  /**Esignature register */
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  REGISTER,
  REGISTER_CLEAR_DATA
} from "./actionTypes"

const initialState = {
  errorLogin: null,
  responseLogin: null,
  loadingLogin: false,
  //forget my password
  errorForgetPassword: null,
  responseForgetPassword: null,
  loadingForgetPassword: false,
  //reset password
  errorResetPassword: null,
  responseResetPassword: null,
  loadingResetPassword: false,
  //Hash Validate
  errorHashValidate: null,
  responseHashValidate: null,
  loadingHashValidate: false,
  //customer user
  errorCustomerUser: null,
  responseCustomerUser: null,
  loadingCustomerUser: false,
  /**create esignature user */
  errorRegister: null,
  responseRegister: null,
  loadingRegister: false,
  /**END */
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      state = initialState;
      break
    case LOGIN_USER:
      state = {
        ...state,
        errorLogin: null,
        loadingLogin: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        errorLogin: null,
        responseLogin: action.payload,
        loadingLogin: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = {
        ...state,
        errorLogin: action.payload,
        loadingLogin: false,
        responseLogin: null
      }
      break
    case FORGET_PASSWORD:  //forget password
      state = {
        ...state,
        errorForgetPassword: null,
        loadingForgetPassword: true,
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        errorForgetPassword: null,
        responseForgetPassword: action.payload,
        loadingForgetPassword: false,
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = {
        ...state,
        errorForgetPassword: action.payload,
        loadingForgetPassword: false,
        responseForgetPassword: null
      }
      break
    case RESET_PASSWORD:  //RESET PASSWORD
      state = {
        ...state,
        errorResetPassword: null,
        loadingResetPassword: true,
      }
      break
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        errorResetPassword: null,
        responseResetPassword: action.payload,
        loadingResetPassword: false,
      }
      break
    case RESET_PASSWORD_ERROR:
      state = {
        ...state,
        errorResetPassword: action.payload,
        loadingResetPassword: false,
        responseResetPassword: null
      }
      break
    case HASH_VALIDATE:  //HASH_VALIDATE
      state = {
        ...state,
        errorHashValidate: null,
        loadingHashValidate: true,
      }
      break
    case HASH_VALIDATE_SUCCESS:
      state = {
        ...state,
        errorHashValidate: null,
        responseHashValidate: action.payload,
        loadingHashValidate: false,
      }
      break
    case HASH_VALIDATE_ERROR:
      state = {
        ...state,
        errorHashValidate: action.payload,
        loadingHashValidate: false,
        responseHashValidate: null
      }
      break
    case CUSTOMER_USER:  //RESET PASSWORD
      state = {
        ...state,
        errorCustomerUser: null,
        loadingCustomerUser: true,
      }
      break
    case CUSTOMER_SUCCESS:
      state = {
        ...state,
        errorCustomerUser: null,
        responseCustomerUser: action.payload,
        loadingCustomerUser: false,
      }
      break
    case CUSTOMER_ERROR:
      state = {
        ...state,
        errorCustomerUser: action.payload,
        loadingCustomerUser: false,
        responseCustomerUser: null
      }
      break
    /**Create esignature register */
    case REGISTER:
      state = {
        ...state,
        errorRegister: null,
        loadingRegister: true,
      }
      break;
    case REGISTER_SUCCESS:
      state = {
        ...state,
        errorRegister: null,
        responseRegister: action.payload,
        loadingRegister: false,
      }
      break;
    case REGISTER_ERROR:
      state = {
        ...state,
        errorRegister: action.payload,
        responseRegister: null,
        loadingRegister: false,
      }
      break;
    case REGISTER_CLEAR_DATA:
      state = {
        ...state,
        errorRegister: null,
        responseRegister: null,
        loadingRegister: false,
      }
      break;
    /**end */
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
