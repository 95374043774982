import {
  API_ERROR_DETAIL_FOLDER,
  API_ERROR_DOCUMENT_PAGES,
  API_ERROR_SEARCH_ARCHIVES,
  DETAIL_FOLDER_LIST,
  DETAIL_FOLDER_LIST_SUCCESS,
  DOCUMENT_PAGES_LIST,
  DOCUMENT_PAGES_LIST_SUCCESS,
  DOCUMENT_PERMISSIONS,
  DOCUMENT_PERMISSIONS_ERROR,
  DOCUMENT_PERMISSIONS_SUCCESS,
  LIST_REQUIRE_SIGNATURE_DOCUMENT,
  LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA,
  LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR,
  LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS,
  RESEND_SIGNATURE_REQUEST,
  RESEND_SIGNATURE_REQUEST_CLEAR_DATA,
  RESEND_SIGNATURE_REQUEST_ERROR,
  RESEND_SIGNATURE_REQUEST_SUCCESS,
  SEARCH_ARCHIVES_LIST,
  SEARCH_ARCHIVES_LIST_SUCCESS,
  DOCUMENT_VERSIONS,
  DOCUMENT_VERSIONS_SUCCESS,
  DOCUMENT_VERSIONS_ERROR,
  DOCUMENT_VERSIONS_CLEAR_DATA,
  RESTORE_DOCUMENT_VERSION,
  RESTORE_DOCUMENT_VERSION_SUCCESS,
  RESTORE_DOCUMENT_VERSION_ERROR,
  RESTORE_DOCUMENT_VERSION_CLEAR_DATA,
} from "./actionTypes";

const initialState = {
  errorDocumentPages: null,
  responseDocumentPages: null,
  loadingDocumentPages: false,
  errorDetailFolder: null,
  responseDetailFolder: null,
  loadingDetailFolder: false,
  errorSearchArchives: null,
  responseSearchArchives: null,
  loadingSearchArchives: false,
  responseDocumentPermissions: null,
  loadingDocumentPermissions: false,
  errorDocumentPermissions: null,
  responseListRequireSignatureDocument: null,
  loadingListRequireSignatureDocument: false,
  errorListRequireSignatureDocument: null,
  responseResendSignatureRequest: null,
  loadingResendSignatureRequest: false,
  errorResendSignatureRequest: null,
  responseDocumentVersions: null,
  loadingDocumentVersions: false,
  errorDocumentVersions: null,
  responseRestoreDocumentVersion: null,
  loadingRestoreDocumentVersion: false,
  errorRestoreDocumentVersion: null,
};

const detailFolder = (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT_PAGES_LIST:
      state = {
        ...state,
        errorDocumentPages: null,
        loadingDocumentPages: true,
      }
      break
    case DETAIL_FOLDER_LIST:
      state = {
        ...state,
        errorDetailFolder: null,
        loadingDetailFolder: true,
      }
      break
    case DOCUMENT_PAGES_LIST_SUCCESS:
      state = {
        ...state,
        errorDocumentPages: null,
        responseDocumentPages: action.payload,
        loadingDocumentPages: false,
      }
      break
    case DETAIL_FOLDER_LIST_SUCCESS:
      state = {
        ...state,
        errorDetailFolder: null,
        responseDetailFolder: action.payload,
        loadingDetailFolder: false,
      }
      break
    case API_ERROR_DOCUMENT_PAGES:
      state = {
        ...state,
        errorDocumentPages: action.payload,
        loadingDocumentPages: false,
        responseDocumentPages: null
      }
      break
    case API_ERROR_DETAIL_FOLDER:
      state = {
        ...state,
        errorDetailFolder: action.payload,
        loadingDetailFolder: false,
        responseDetailFolder: null
      }
      break
    case SEARCH_ARCHIVES_LIST:
      state = {
        ...state,
        errorSearchArchives: null,
        loadingSearchArchives: true,
      }
      break
    case SEARCH_ARCHIVES_LIST_SUCCESS:
      state = {
        ...state,
        errorSearchArchives: null,
        responseSearchArchives: action.payload,
        loadingSearchArchives: false,
      }
      break
    case API_ERROR_SEARCH_ARCHIVES:
      state = {
        ...state,
        errorSearchArchives: action.payload,
        loadingSearchArchives: false,
        responseSearchArchives: null
      }
      break
    case DOCUMENT_PERMISSIONS:
      state = {
        ...state,
        errorDocumentPermissions: null,
        loadingDocumentPermissions: true
      }
      break
    case DOCUMENT_PERMISSIONS_SUCCESS:
      state = {
        ...state,
        errorDocumentPermissions: null,
        responseDocumentPermissions: action.payload,
        loadingDocumentPermissions: false,
      }
      break
    case DOCUMENT_PERMISSIONS_ERROR:
      state = {
        ...state,
        errorDocumentPermissions: action.payload,
        loadingDocumentPermissions: false,
        responseDocumentPermissions: null
      }
      break
    case LIST_REQUIRE_SIGNATURE_DOCUMENT:
      state = {
        ...state,
        errorListRequireSignatureDocument: null,
        loadingListRequireSignatureDocument: true,
      }
      break
    case LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS:
      state = {
        ...state,
        errorListRequireSignatureDocument: null,
        loadingListRequireSignatureDocument: false,
        responseListRequireSignatureDocument: action.payload,
      }
      break
    case LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR:
      state = {
        ...state,
        errorListRequireSignatureDocument: action.payload,
        loadingListRequireSignatureDocument: false,
        responseListRequireSignatureDocument: null,
      }
      break
    case LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA:
      state = {
        ...state,
        errorListRequireSignatureDocument: null,
        responseListRequireSignatureDocument: null,
        loadingListRequireSignatureDocument: false,
      }
      break
    case RESEND_SIGNATURE_REQUEST:
      state = {
        ...state,
        errorResendSignatureRequest: null,
        loadingResendSignatureRequest: true,
      }
      break
    case RESEND_SIGNATURE_REQUEST_SUCCESS:
      state = {
        ...state,
        errorResendSignatureRequest: null,
        loadingResendSignatureRequest: false,
        responseResendSignatureRequest: action.payload,
      }
      break
    case RESEND_SIGNATURE_REQUEST_ERROR:
      state = {
        ...state,
        errorResendSignatureRequest: action.payload,
        loadingResendSignatureRequest: false,
        responseResendSignatureRequest: null,
      }
      break
    case RESEND_SIGNATURE_REQUEST_CLEAR_DATA:
      state = {
        ...state,
        errorResendSignatureRequest: null,
        loadingResendSignatureRequest: null,
        responseResendSignatureRequest: false,
      }
      break
    case DOCUMENT_VERSIONS:
      state = {
        ...state,
        errorDocumentVersions: null,
        loadingDocumentVersions: true,
      }
      break
    case DOCUMENT_VERSIONS_SUCCESS:
      state = {
        ...state,
        errorDocumentVersions: null,
        loadingDocumentVersions: false,
        responseDocumentVersions: action.payload,
      }
      break
    case DOCUMENT_VERSIONS_ERROR:
      state = {
        ...state,
        errorDocumentVersions: action.payload,
        loadingDocumentVersions: false,
        responseDocumentVersions: null,
      }
      break
    case DOCUMENT_VERSIONS_CLEAR_DATA:
      state = {
        ...state,
        errorDocumentVersions: null,
        loadingDocumentVersions: null,
        responseDocumentVersions: false,
      }
      break
    case RESTORE_DOCUMENT_VERSION:
      state = {
        ...state,
        errorRestoreDocumentVersion: null,
        loadingRestoreDocumentVersion: true,
      }
      break
    case RESTORE_DOCUMENT_VERSION_SUCCESS:
      state = {
        ...state,
        errorRestoreDocumentVersion: null,
        loadingRestoreDocumentVersion: false,
        responseRestoreDocumentVersion: action.payload,
      }
      break
    case RESTORE_DOCUMENT_VERSION_ERROR:
      state = {
        ...state,
        errorRestoreDocumentVersion: action.payload,
        loadingRestoreDocumentVersion: false,
        responseRestoreDocumentVersion: null,
      }
      break
    case RESTORE_DOCUMENT_VERSION_CLEAR_DATA:
      state = {
        ...state,
        errorRestoreDocumentVersion: null,
        loadingRestoreDocumentVersion: null,
        responseRestoreDocumentVersion: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default detailFolder
