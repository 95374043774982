import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import LoadingComponent from "../../../../../components/loading";
import UploadAttachFileView from "./view";
import { getAllAttachment } from "../../../../../store/workFlow/actions";
import { uploadAttachment } from "../../../../../services/FileUploadService";

export const UploadAttachFile = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const acceptedFileTypes = ""; // Aceptar todos los tipos de archivos
  const [uploaded, setUploaded] = React.useState(false);
  const [messageText, setMessageText] = React.useState("Getting Explorer Data");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = (msj, event) => toast[event](msj);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFiles(acceptedFiles);
      setShowUpload(true);
    } else {
      setShowUpload(false);
      notify("Failed to process the file. Please try again.", "error");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  });

  // const saveUpload = () => {
  //   selectedFiles.map((file) => {
  //     setUploaded(true);
  //     setMessageText("Uploading file");
  //     uploadAttachment(file, props.documentId)
  //       .then((response) => {
  //         setUploaded(false);
  //         notify(file.name + " uploaded", "success");
  //         setShowUpload(false);
  //         setSelectedFiles([]);
  //       })
  //
  //       .catch((err) => {
  //         setUploaded(false);
  //         toast.error(err?.response?.data?.error?.messages[0].message);
  //       })
  //       .finally(() => {
  //         setMessageText("Getting Explorer Data");
  //         dispatch(getAllAttachment({ id: props.documentId }));
  //       });
  //   });
  // };

  const saveUpload = () => {
    setUploaded(true);
    setMessageText("Uploading files");

    uploadAttachment(selectedFiles, props.documentId)
      .then((response) => {
        setUploaded(false);
        notify("Files uploaded successfully", "success");
        setShowUpload(false);
        setSelectedFiles([]);
      })
      .catch((err) => {
        setUploaded(false);
        toast.error(
          err?.response?.data?.error?.messages[0]?.message ||
            "Error uploading files"
        );
      })
      .finally(() => {
        setMessageText("Getting Explorer Data");
        dispatch(getAllAttachment({ id: props.documentId }));
      });
  };

  const closeUpload = useCallback(() => {
    setShowUpload(false);
    setSelectedFiles([]);
  }, []);

  useEffect(() => {
    if (props.closeUp) {
      setSelectedFiles([]);
    }
  }, [props.closeUp]);

  return (
    <>
      <UploadAttachFileView
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        selectedFiles={selectedFiles}
        closeUpload={closeUpload}
        showUpload={showUpload}
        saveUpload={saveUpload.bind(this)}
      />
      <LoadingComponent show={uploaded === true} text={messageText} />
      <ToastContainer autoClose={3000} />
    </>
  );
};
