import styled from 'styled-components';

export const WrapperDocPlace = styled.div`
  width: 95%;
  height: 100%;
  margin: 33px 0 0 0;
  padding: 0;
`;





