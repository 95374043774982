import React, { useEffect, useState } from "react";
import { useStyles, Wrapper, WrapperLabeling } from "./style";
import { makeStyles } from "@material-ui/core/styles";
import MetaTags from "react-meta-tags";
import Title from "../../components/title/title";
import { Container } from "reactstrap";
import LoadingComponent from "../../components/loading";
import { DocumentListDocPlace } from "./documentList";
import { SettingsDocPlace } from "./settings";
import { connect, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import ReactRegion from "./RegionSelectP";
import "./styles.css";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import initialDocument from "../../assets/initialDocument.png";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import TitleIcon from "@mui/icons-material/Title";
import ParagraphIcon from "@mui/icons-material/ViewHeadline";
import DateIcon from "@mui/icons-material/DateRange";
import TextIcon from "@mui/icons-material/TextFields";
import NumberIcon from "@mui/icons-material/FormatListNumbered";
import SelectIcon from "@mui/icons-material/Menu";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonIcon from "@mui/icons-material/RadioButtonChecked";
import SignatureIcon from "@mui/icons-material/Draw";
import HeaderDocPlaceForms from "./forms_module/header";
import Paragraph from "./forms_module/paragraph";
import DatePickerForms from "./forms_module/datePickerForm";
import ShortTextDocPlace from "./forms_module/shortText";
import SelectListForms from "./forms_module/selectList";
import CheckBox from "./forms_module/checkBox";
import RadioButtonForm from "./forms_module/radioButton";
import SignatureForm from "./forms_module/signature";
import {
  deleteFormData,
  getData,
  getFormTemplate,
  updateData,
} from "../../services/form";
import { toast, ToastContainer } from "react-toastify";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";

const useStylesx = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  p: 4,
};
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  bgcolor: "background.paper",
  p: 4,
};
var firstLoad = true;
var running = false;
var _regions = [];

export function FormTemplate(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [docTypeID, setDocTypeID] = React.useState("");
  const [regions, setRegions] = React.useState([]);
  const [document, setDocument] = useState("");
  const [startPage] = useState(true);
  const dispatch = useDispatch();
  const [labels, setLabels] = useState([]);
  const [fields, setFields] = useState([]);
  const [current, setCurrent] = useState("Not selected");
  const [currentEmail, setCurrentEmail] = useState("");
  const [documents, setDocuments] = useState([]);
  const [currentDocId, setCurrentDocId] = useState(0);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [deleteLabels, setDeleteLabels] = useState(false);
  const [deleteOCR, setDeleteOCR] = useState(false);
  const [counter, setCounter] = useState(0);
  const [currentLabelIndex, setCurrentLabelIndex] = useState(0);
  const [currentLabel, setCurrentLabel] = useState("");

  const [enableTrain, setEnableTrain] = useState(false);

  const [order, setOrder] = useState(false);

  const [theDoc, setTheDoc] = useState({});

  const [updating, setUpdating] = useState(false);

  const [ocrShow, setOcrShow] = useState(false);

  const [currentDocument, setCurrentDocument] = useState(initialDocument);

  const [saving, setSaving] = useState(true);

  const [showSettings, setShowSettings] = useState(false);

  const [currentOrder, setCurrentOrder] = useState(0);
  const [currentId, setCurrentId] = useState(0);
  const [showHeader, setShowHeader] = useState(false);
  const [allRegions, setAllRegions] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [componentsList, setComponentsList] = useState([]);

  //  const [responseDocumentPages, setResponseDocumentPages] = useState(null);
  var responseDocumentPages;

  const [colorArray, setColorArray] = useState([
    "255, 225, 25",
    "0, 130, 200",
    "230, 25, 75",
    "60, 180, 75",
    "245, 130, 48",
    "145, 30, 180",
    "70, 240, 240",
    "240, 50, 230",
    "210, 245, 60",
    "250, 190, 212",
    "0, 128, 128",
    "220, 190, 255",
    "170, 110, 40",
    "255, 250, 200",
    "128, 0, 0",
    "170, 255, 195",
    "128, 128, 0",
    "255, 215, 180",
    "0, 0, 128",
    "128, 128, 128",
    "255, 255, 255",
    "0, 0, 0",
  ]);

  let params = useParams();
  const handleClose = () => {
    setOpen(false);
    setOcrShow(false);
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if (params.id !== "null" && params.id) {
      getFormTemplate(params.id, (event) => {})
        .then((response) => {
          init(response);
        })
        .catch((error) => {
          toast.error(
            "There is an error loading the document info. Please contact your administrator." +
              error
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/explorer");
    }
  }, [startPage]);

  const init = (responseDocumentPages) => {
    if (params.id) {
      setDocTypeID(params.id);
    }
    if (startPage && responseDocumentPages) {
      //@params props.documentTypeID

      setLoading(true);
      try {
        getData(params.id, (event) => {})
          .then((response) => {
            if (Object.keys(response.data).length == 0) {
              console.log("No data, then get files.");
              let response = responseDocumentPages.data.pathUrl[0].pages;
              setUpdating(false);
              let sampleFiles = response;
              if (sampleFiles && sampleFiles.length > 0) {
                let newArray = [];

                sampleFiles.map((item) => {
                  newArray.push({
                    thumbnail: item.thumbnail,
                    fileName: item.fileName,
                  });
                });
                let docRegions = new Array(sampleFiles.length).fill([]);
                setAllRegions(docRegions);

                docArray = newArray;

                setDocuments(docArray);
                setDocument(docArray[0].thumbnail);
                setCurrentDocument(docArray[0].thumbnail);
                setRegions([]);
                _regions = [];
              }

              setUpdating(false);
            } else {
              console.log("Data for this document, has been found.");

              let data = response.data;

              //setLabels(response.data.labels)
              setRegions([]);
              _regions = [];
              setFields(data.labels);

              setComponentsList(data.components);

              setDocument(data.documents[0].thumbnail);
              setCurrentDocument(data.documents[0].thumbnail);

              setCurrent("Not selected");
              setDocuments(data.documents);
              setCurrentDocId(-1);
              setCounter(data.counter);
              setCurrentLabelIndex(data.currentLabelIndex);
              //setTheDoc(data.theDoc);
              setAllRegions(data.allRegions);

              for (var i = 0; i < data.allRegions.length; i++) {
                let obj = data.allRegions[i];

                let newArray = obj.filter(function (el) {
                  return el.type == 1 && el.data.label != "";
                });
              }

              setUpdating(true);
            }
          })
          .catch((error) => {
            toast.error(
              "There is an error loading the document info. Please contact your administrator." +
                error
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        //
        console.log("No data, then get files.");
        let response = responseDocumentPages.data.pathUrl[0].pages;
        setUpdating(false);
        let sampleFiles = response;
        if (sampleFiles && sampleFiles.length > 0) {
          let newArray = [];

          sampleFiles.map((item) => {
            newArray.push({
              thumbnail: item.thumbnail,
              fileName: item.fileName,
            });
          });
          let docRegions = new Array(sampleFiles.length).fill([]);
          setAllRegions(docRegions);

          docArray = newArray;

          setDocuments(docArray);
          setDocument(docArray[0].thumbnail);
          setCurrentDocument(docArray[0].thumbnail);
          setRegions([]);
          _regions = [];
        }

        setUpdating(false);
      }

      firstLoad = false;
    }
  };

  useEffect(() => {
    try {
      _regions = [...regions];

      if (currentDocId >= 0) {
        let allRegionsTemp = allRegions;
        allRegionsTemp[currentDocId] = regions;
        setAllRegions(allRegionsTemp);
      }
    } catch (error) {
      console.log("Error creating regions: " + error);
    }
  }, [allRegions, regions]);

  const deleteRegion = (label) => {
    setSaving(false);
    resetRegionByLabel(label);
    if (label == current) {
      setCurrent("Not selected");
    }
  };

  const resetRegionByLabel = (label) => {
    let data;
    allRegions.map((iRegions) => {
      for (let item in iRegions) {
        if (iRegions[item].data.label == label) {
          data = iRegions[item].data;
          data.label = "";
          data.regionStyle = regionStyleStart;
          iRegions[item].data = data;
        }
      }
    });
  };
  const onChange = (regions) => {
    _regions = [...regions];
    setRegions(regions);
  };

  const handleOrder = (user_order) => {
    setOrder(user_order);
  };

  const deleteText = (text, xlabel) => {
    setSaving(false);
    let nowRegions = [];
    let index = 0;
    allRegions.map((iRegions) => {
      if (index === currentDocId) {
        for (let item in iRegions) {
          //find Label
          let data = iRegions[item].data;
          if (typeof data.label !== "undefined") {
            if (data.label == xlabel && data.text === text) {
              data.label = "";
              data.regionStyle = regionStyleStart;
              iRegions[item].data = data;
            }
          }
        }
      }
      nowRegions.push(iRegions);
      index++;
    });

    setAllRegions(nowRegions);
  };

  const deleteRegionById = (id) => {
    let temp = [...allRegions];
    temp[currentDocId].splice(id, 1); // 2nd parameter means remove one item only
    setAllRegions(temp);
  };

  const selectRegion = (event, id, data, type) => {
    switch (event.detail) {
      case 1: {
        setSaving(false);
        //let regionP = regions[id];
        let regionP = [...regions];

        if (current !== "Not selected" && document !== "") {
          //asdf
          regionP[id].data = {
            label: current,
            email: currentEmail,
            freeText: data.freeText,

            text: regionP[id].data.text,
            boundingBoxes: regionP[id].data.boundingBoxes,
            width: regionP[id].data.width,
            height: regionP[id].data.height,
            regionStyle: regionStylePicker(currentOrder),
          };

          setRegions(regionP);
          _regions = [...regionP];
        } else {
          //toast.warning("To start, select a recipient and a document first.");
        }
        break;
      }
      case 2: {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: "Remove the element?",
          text: "",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
        }).then((willDelete) => {
          if (willDelete.value) {
            deleteRegionById(id);
          }
        });

        break;
      }

      default: {
        break;
      }
    }
  };

  const deleteComponentList = (id) => {
    let temp = [...componentsList];
    let now = temp.filter((item) => item.id !== id);
    if (now.type == 1) {
      setShowHeader(false);
    }
    setComponentsList(now);
  };

  const deleteRegionId = (id) => {
    let temp = [...regions];
    let now = temp.filter((item) => item.id !== id);
    setRegions(now);
  };

  const handleDeleteComponent = (id) => {
    deleteComponentList(id);
    deleteRegionId(id);
  };

  const callbackIsChecked = (value, id) => {
    const updatedComponents = componentsList.map((component) => {
      if (component.id === id) {
        return { ...component, required: value, isChecked: value };
      }
      return component;
    });
    setComponentsList(updatedComponents);
  };

  const handleSettingsComponent = (id, data) => {
    setCurrentId(id);
    setShowSettings(true);
    setFields(data);
  };

  const createRegion = (label, valueText = null) => {
    let text = "";
    if (showHeader === true && label === 1) {
      toast.warning("You can only add one header.");
      return false;
    }

    if (label === 1 && showHeader === false) {
      setShowHeader(true);
      text = "header #";
      setComponentsList([
        ...componentsList,
        {
          id: counter,
          name: "Header",
          type: label,
          title: "",
          description: "",
          value: "",
          viewPdf: false,
          data: [],
        },
      ]);
    }
    if (label === 2) {
      text = "paragraph #";
      setComponentsList([
        ...componentsList,
        {
          id: counter,
          name: "Paragraph",
          type: label,
          title: "",
          description: "",
          value: "",
          viewPdf: false,
          data: [],
        },
      ]);
    }
    if (label === 3) {
      text = "date #";
      setComponentsList([
        ...componentsList,
        {
          id: counter,
          name: "Date",
          type: label,
          title: "",
          value: "",
          viewPdf: true,
          description: "",
          data: [],
          required: true,
          isChecked: true,
        },
      ]);
    }
    if (label === 4) {
      text = "text #";
      setComponentsList([
        ...componentsList,
        {
          id: counter,
          name: "Text",
          type: label,
          title: "",
          viewPdf: true,
          value: "",
          description: "",
          data: [],
          required: true,
          isChecked: true,
        },
      ]);
    }
    if (label === 5) {
      text = "number #";
      setComponentsList([
        ...componentsList,
        {
          id: counter,
          name: "Number",
          type: label,
          title: "",
          viewPdf: true,
          value: "",
          description: "",
          data: [],
          required: true,
          isChecked: true,
        },
      ]);
    }
    if (label === 6) {
      text = "select #";
      setComponentsList([
        ...componentsList,
        {
          id: counter,
          name: "Select",
          type: label,
          value: "",
          viewPdf: true,
          title: "",
          description: "",
          data: [],
          required: true,
          isChecked: true,
        },
      ]);
    }
    if (label === 7) {
      text = "checkbox #";
      setComponentsList([
        ...componentsList,
        {
          id: counter,
          name: "Checkbox",
          type: label,
          viewPdf: true,
          value: "",
          title: "",
          description: "",
          data: [],
          required: true,
          isChecked: true,
        },
      ]);
    }
    if (label === 8) {
      text = "radiobutton #";
      setComponentsList([
        ...componentsList,
        {
          id: counter,
          name: "Radiobutton",
          type: label,
          title: "",
          description: "",
          viewPdf: true,
          value: "",
          data: [],
          required: true,
          isChecked: true,
        },
      ]);
    }
    if (label === 9) {
      text = "Signature #";
      setComponentsList([
        ...componentsList,
        {
          id: counter,
          name: "Signature",
          type: label,
          title: "",
          viewPdf: true,
          value: "",
          description: "",
          data: [],
          required: true,
          isChecked: true,
        },
      ]);
    }
    if (label !== 1) {
      setRegions((regions) => [
        ...regions,
        {
          x: 10,
          y: 10,
          type: label,
          width: 20,
          id: counter,
          height: 4,
          highlight: false,
          data: {
            text: text + (counter + 1),
            polygon: [],
            boundingBoxes: [],
            width: 10,
            height: 10,
            freeText: valueText,
            label: label,
            regionStyle: {
              background: "rgb(0, 106, 198, 20%)",
            },
          },
        },
      ]);

      let temp = allRegions;
      temp[currentDocId] = regions;
      setAllRegions(temp);
      _regions = [...regions];
    }
    setCounter(counter + 1);
  };

  const getInitials = (string) => {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      return <div></div>;
    }
  };

  const regionStyleStart = {
    background: "rgba(255,255,255, 100%)",
    border: "2px solid rgb(0, 106, 198)",
    borderRadius: "1px",
  };
  const regionStyle = {
    background: "rgba(255,255,0, 100%)",

    border: "2px solid rgb(0, 106, 198)",
    borderRadius: "1px",
  };

  const regionStylePicker = (i) => {
    return {
      background: `rgba(${colorArray[i]}, 20%)`,

      border: "2px solid rgb(0, 106, 198)",
      borderRadius: "1px",
    };
  };

  const getDocument = (
    document,
    id,
    fileName,
    filePath,
    ocr,
    doc,
    fileOriginalName
  ) => {
    setTheDoc(doc);

    setRegions([]);
    _regions = [];
    setRegions(allRegions[id]);
    _regions = [allRegions[id]];
    setCurrentDocId(id);
    setDocument(document);
    setCurrentDocument(document);
  };

  const selectLabel = (label, i, text, val) => {
    setSaving(false);
    setCurrentLabelIndex(i);
    setCurrent(label);
    setCurrentEmail(text.email);

    setCurrentOrder(val.order);
  };

  const getLabels = (labels, added) => {
    setLabels(labels);
  };

  const deleteData = (opt) => {
    setSaving(false);
    setLoadingText("Deleting data.");
    setLoading(true);

    deleteFormData(params.id, (event) => {})
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        window.location.reload(false);
        toast.error("Error deleting the data: " + error);
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  const saveAndClose = () => {
    saveLabels(null, null, true);
  };

  const getCoords = (id) => {
    let coords = {};
    let json = [...allRegions];
    json.map((page, idx) => {
      page.map((item) => {
        if (item.id == id) {
          coords = {
            x: item.x,
            y: item.y,
            w: item.width,
            h: item.height,
            page: idx,
          };
        }
      });
    });
    return coords;
  };

  /* Persist data */
  const saveLabels = (docdata, docArray, close, reload) => {
    setLoadingText("Saving data");
    setLoading(true);
    let _documents, _allRegions;
    if (docdata) {
      _documents = docdata;
      _allRegions = docArray;
    } else {
      _documents = [...documents];
      _allRegions = [...allRegions];
    }

    let _document = document;

    if (document == "") {
      setDocument(_documents[0].thumbnail);
      _document = _documents[0].thumbnail;
    }

    let _fields = [];
    componentsList.map((item, idx) => {
      let coords = {};
      //Header
      if (item.type == 1) {
        coords = { x: 0, y: 0, w: 0, h: 0, page: 0 };
      } else {
        coords = getCoords(item.id);
      }
      _fields.push({
        fieldId: idx,
        fieldTitle: item.title,
        fieldType: item.type,
        fieldDescription: item.description,
        fieldRequired: item.required,
        fieldVisible: item.viewPdf,
        fieldValue: item.value,

        fieldOptions: item.data,

        fieldPosition: coords,
      });
    });

    let form = [
      {
        formId: params.id,
        formTitle: "",
        formDescription: "",
        fields: _fields,
      },
    ];

    let data = {
      region: regions,
      allRegions: _allRegions,
      labels: labels,
      document: _document,
      current: current,
      documents: _documents,
      currentDocId: currentDocId,
      counter: counter,
      currentLabelIndex: currentLabelIndex,
      doc: theDoc,

      components: componentsList,
      form: form,
    };

    if (updating == true) {
      updateData(params.id, data, (event) => {})
        .then((response) => {
          toast.success("Document has been updated");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error updating the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          if (reload) {
            window.location.reload(false);
          }
        });
    } else {
      updateData(params.id, data, (event) => {})
        .then((response) => {
          setUpdating(true);
          toast.success("Document changes has been saved");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error saving the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);

          if (reload) {
            window.location.reload(false);
          }
        });
    }
  };

  var docArray = [];

  const saveOptions = () => {
    setShowSettings(false);

    let json = [...componentsList];
    json.map((item) => {
      if (item.id == currentId) {
        item.data = labels;
      }
    });
    setComponentsList(json);
    setLabels([]);
    setFields([]);
  };

  // HEADER COMPONENT
  const getTitle = (value, id) => {
    let json = [...componentsList];

    json.map((item) => {
      if (item.id == id) {
        item.title = value;
      }
    });

    setComponentsList(json);
  };

  const getContent = (value, id) => {
    let json = [...componentsList];

    json.map((item) => {
      if (item.id == id) {
        item.value = value;
      }
    });

    setComponentsList(json);
  };

  const getDescription = (value, id) => {
    let json = [...componentsList];

    json.map((item) => {
      if (item.id == id) {
        item.description = value;
      }
    });

    setComponentsList(json);
  };

  const getViewPdf = (value, id) => {
    let json = [...componentsList];

    json.map((item) => {
      if (item.id == id) {
        item.viewPdf = !value;
      }
    });

    setComponentsList(json);
  };

  const onMouseOver = (id, value) => {
    let json = [...regions];
    if (value == true) {
      //find in json by initialDocument

      json.map((item) => {
        if (item.id == id) {
          item.highlight = true;
        }
      });
    } else {
      json.map((item) => {
        if (item.id == id) {
          item.highlight = false;
        }
      });
    }
    setRegions(json);
  };

  const onMouseOverRegion = (id, value) => {
    // console.log("----->",id,value)
    let json = [...componentsList];
    if (value == true) {
      //find in json by initialDocument

      json.map((item) => {
        if (item.id == id) {
          item.highlightItem = true;
        }
      });
    } else {
      json.map((item) => {
        if (item.id == id) {
          item.highlightItem = false;
        }
      });
    }
    setComponentsList(json);
  };

  return (
    <>
      <LoadingComponent show={loading} text={loadingText} />
      <Modal
        open={showSettings}
        onClose={() => setShowSettings(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Settings
          </Typography>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <FormGroup>
              <SettingsDocPlace
                colorArray={colorArray}
                tagKey={Math.random().toString(36).substr(2, 9)}
                regions={regions}
                setFields={fields}
                deleteLabels={deleteLabels}
                deleteText={deleteText}
                getLabels={getLabels}
                selectLabel={selectLabel}
                deleteRegion={deleteRegion}
                order={handleOrder}
              />
            </FormGroup>
          </FormControl>
          <Button
            variant="contained"
            color="success"
            onClick={() => saveOptions()}
            sx={{ mt: 2, mr: 2 }}
          >
            Ok
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setShowSettings(false);
              setLabels([]);
              setFields([]);
            }}
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDelete}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action will delete all objects created for the current document
            and its pages. It will also remove all components created for the
            form. <br />
            <br /> Are you sure you want to continue?
          </Typography>

          <Button
            variant="contained"
            color="info"
            onClick={() => setOpen(false)}
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={() => deleteData(0)}
            sx={{ mt: 2, float: "right" }}
          >
            Yes Delete
          </Button>
        </Box>
      </Modal>

      <MetaTags>
        <title>Form template</title>
      </MetaTags>
      <Container fluid>
        <Title title={"Form template"} viewActive={false} />
      </Container>
      <WrapperLabeling>
        <ToastContainer autoClose={3000} />
        <Wrapper>
          <Grid container className="toolbarLabeling">
            <Grid item xs={2} md={2}>
              <IconButton
                color="primary"
                style={{ marginRight: "10px" }}
                title="Save"
                aria-label="Save"
                component="label"
                onClick={() => saveLabels()}
              >
                <SaveIcon />
              </IconButton>

              <IconButton
                onClick={() => setOpen(true)}
                color="error"
                title="Reset data"
                style={{ marginRight: "10px" }}
                aria-label="Delete "
                component="label"
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8} md={8} sx={{ textAlign: "left" }}></Grid>
            <Grid item xs={2} md={2} style={{ paddingRight: "20px" }}></Grid>
          </Grid>
        </Wrapper>
        <Grid container>
          <Grid className={classes.wrapperAside} item xs={2} md={2}>
            <h4> Components </h4>
            <Button
              color="primary"
              component="label"
              onClick={() => createRegion(1)}
              className="buttonForm"
              variant="outlined"
              size="large"
              title="Add header"
            >
              <TitleIcon /> Header
            </Button>
            <Button
              color="primary"
              component="label"
              onClick={() => createRegion(2)}
              className="buttonForm"
              variant="outlined"
              size="large"
              title="Add paragraph"
            >
              <ParagraphIcon /> Paragraph
            </Button>
            <Button
              color="primary"
              component="label"
              onClick={() => createRegion(3)}
              className="buttonForm"
              variant="outlined"
              size="large"
              title="Add date"
            >
              <DateIcon /> Date
            </Button>
            <Button
              color="primary"
              component="label"
              onClick={() => createRegion(4)}
              className="buttonForm"
              variant="outlined"
              size="large"
              title="Add text"
            >
              <TextIcon /> Text
            </Button>
            <Button
              color="primary"
              component="label"
              onClick={() => createRegion(5)}
              className="buttonForm"
              variant="outlined"
              size="large"
              title="Add number"
            >
              <NumberIcon /> Number
            </Button>
            <Button
              color="primary"
              component="label"
              onClick={() => createRegion(6)}
              className="buttonForm"
              variant="outlined"
              size="large"
              title="Add select"
            >
              <SelectIcon /> Select list
            </Button>
            <Button
              color="primary"
              component="label"
              onClick={() => createRegion(7)}
              className="buttonForm"
              variant="outlined"
              size="large"
              title="Add check box"
            >
              <CheckBoxIcon /> Check box
            </Button>
            <Button
              color="primary"
              component="label"
              onClick={() => createRegion(8)}
              className="buttonForm"
              variant="outlined"
              size="large"
              title="Add radio button"
            >
              <RadioButtonIcon /> Radio button
            </Button>

            <Button
              color="primary"
              component="label"
              onClick={() => createRegion(9)}
              className="buttonForm"
              variant="outlined"
              size="large"
              title="Add signature"
            >
              <SignatureIcon /> Signature
            </Button>
          </Grid>
          <Grid className={classes.wrapperAside} item xs={4} md={4}>
            <h4> Form </h4>
            <div className="documentShowcase">
              {componentsList.map((item, id) => (
                <>
                  {item.type === 1 && (
                    <section key={id + "header"} className="componentItem">
                      <HeaderDocPlaceForms
                        title={"Header"}
                        id={item.id}
                        getTitle={getTitle}
                        getDescription={getDescription}
                        viewPdf={getViewPdf}
                        _title={item.title}
                        _description={item.description}
                      />
                    </section>
                  )}
                </>
              ))}

              {componentsList.map((item, id) => (
                <>
                  {item.type === 2 && (
                    <section key={id} className="componentItem">
                      <Paragraph
                        callbackIsChecked={(value) =>
                          callbackIsChecked(value, item.id)
                        }
                        isChecked={item.isChecked}
                        title={"Paragraph"}
                        id={item.id}
                        getDescription={getContent}
                        viewPdf={getViewPdf}
                        handleDelete={handleDeleteComponent}
                        onMouseEnter={onMouseOver}
                        onMouseLeave={onMouseOver}
                        highlightItem={item.highlightItem}
                        content={item.value}
                        pdf={item.viewPdf}
                      />
                    </section>
                  )}

                  {item.type === 3 && (
                    <section key={id} className="componentItem">
                      <DatePickerForms
                        callbackIsChecked={(value) =>
                          callbackIsChecked(value, item.id)
                        }
                        isChecked={item.isChecked}
                        title={"Date"}
                        id={item.id}
                        getTitle={getTitle}
                        getDescription={getDescription}
                        viewPdf={getViewPdf}
                        disabled={true}
                        handleDelete={handleDeleteComponent}
                        disableFuture={false}
                        disablePast={false}
                        inputFormat={"MM/dd/yyyy"}
                        onMouseEnter={onMouseOver}
                        onMouseLeave={onMouseOver}
                        highlightItem={item.highlightItem}
                        _title={item.title}
                        _description={item.description}
                        pdf={item.viewPdf}
                      />
                    </section>
                  )}

                  {item.type === 4 && (
                    <section key={id} className="componentItem">
                      {" "}
                      <ShortTextDocPlace
                        callbackIsChecked={(value) =>
                          callbackIsChecked(value, item.id)
                        }
                        isChecked={item.isChecked}
                        highlightItem={item.highlightItem}
                        title={"Text"}
                        id={item.id}
                        getTitle={getTitle}
                        getDescription={getDescription}
                        viewPdf={getViewPdf}
                        handleDelete={handleDeleteComponent}
                        onMouseEnter={onMouseOver}
                        onMouseLeave={onMouseOver}
                        _title={item.title}
                        _description={item.description}
                        pdf={item.viewPdf}
                      />
                    </section>
                  )}

                  {item.type === 5 && (
                    <section key={id} className="componentItem">
                      {" "}
                      <ShortTextDocPlace
                        callbackIsChecked={(value) =>
                          callbackIsChecked(value, item.id)
                        }
                        isChecked={item.isChecked}
                        title={"Number"}
                        id={item.id}
                        getTitle={getTitle}
                        type={"number"}
                        getDescription={getDescription}
                        viewPdf={getViewPdf}
                        handleDelete={handleDeleteComponent}
                        onMouseEnter={onMouseOver}
                        onMouseLeave={onMouseOver}
                        _title={item.title}
                        _description={item.description}
                        pdf={item.viewPdf}
                        highlightItem={item.highlightItem}
                      />
                    </section>
                  )}
                  {item.type === 6 && (
                    <section key={id} className="componentItem">
                      <SelectListForms
                        callbackIsChecked={(value) =>
                          callbackIsChecked(value, item.id)
                        }
                        isChecked={item.isChecked}
                        title={"List"}
                        id={item.id}
                        getTitle={getTitle}
                        handleSettings={handleSettingsComponent}
                        getDescription={getDescription}
                        viewPdf={getViewPdf}
                        handleDelete={handleDeleteComponent}
                        options={item.data}
                        onMouseEnter={onMouseOver}
                        onMouseLeave={onMouseOver}
                        _title={item.title}
                        _description={item.description}
                        pdf={item.viewPdf}
                        highlightItem={item.highlightItem}
                      />
                    </section>
                  )}
                  {item.type === 7 && (
                    <section key={id} className="componentItem">
                      <CheckBox
                        callbackIsChecked={(value) =>
                          callbackIsChecked(value, item.id)
                        }
                        isChecked={item.isChecked}
                        title={"Checkbox"}
                        id={item.id}
                        getTitle={getTitle}
                        getDescription={getDescription}
                        viewPdf={getViewPdf}
                        handleDelete={handleDeleteComponent}
                        handleSettings={handleSettingsComponent}
                        options={item.data}
                        onMouseEnter={onMouseOver}
                        onMouseLeave={onMouseOver}
                        highlightItem={item.highlightItem}
                        _title={item.title}
                        _description={item.description}
                        pdf={item.viewPdf}
                      />
                    </section>
                  )}
                  {item.type === 8 && (
                    <section key={id} className="componentItem">
                      <RadioButtonForm
                        callbackIsChecked={(value) =>
                          callbackIsChecked(value, item.id)
                        }
                        isChecked={item.isChecked}
                        title={"Radio button"}
                        id={item.id}
                        getTitle={getTitle}
                        getDescription={getDescription}
                        viewPdf={getViewPdf}
                        handleDelete={handleDeleteComponent}
                        handleSettings={handleSettingsComponent}
                        options={item.data}
                        size={"small"} // small-medium
                        item={item.data}
                        row={true} // row or colum
                        onMouseEnter={onMouseOver}
                        onMouseLeave={onMouseOver}
                        highlightItem={item.highlightItem}
                        _title={item.title}
                        _description={item.description}
                        pdf={item.viewPdf}
                      />
                    </section>
                  )}
                  {item.type === 9 && (
                    <section key={id} className="componentItem">
                      <SignatureForm
                        callbackIsChecked={(value) =>
                          callbackIsChecked(value, item.id)
                        }
                        isChecked={item.isChecked}
                        title={"Signature"}
                        id={item.id}
                        getTitle={getTitle}
                        getDescription={getDescription}
                        viewPdf={getViewPdf}
                        handleDelete={handleDeleteComponent}
                        handleSettings={handleSettingsComponent}
                        options={item.data}
                        size={"small"} // small-medium
                        item={item.data}
                        row={true} // row or colum
                        onMouseEnter={onMouseOver}
                        onMouseLeave={onMouseOver}
                        highlightItem={item.highlightItem}
                        _title={item.title}
                        _description={item.description}
                        pdf={item.viewPdf}
                      />
                    </section>
                  )}
                </>
              ))}
            </div>
          </Grid>
          <Grid
            className={classes.wrapperAside}
            style={{ marginTop: "0px" }}
            xs={6}
            md={6}
          >
            <h4> Original PDF </h4>
            <div className="element">
              <Grid xs={9} md={9}>
                <div className="documentShowcase">
                  <ReactRegion
                    maxRegions={1000}
                    regions={regions}
                    onChange={onChange}
                    regionStyle={regionStyle}
                    regionRenderer={regionRenderer}
                    constraint
                    onClick={selectRegion}
                    onMouseEnter={onMouseOverRegion}
                    onMouseLeave={onMouseOverRegion}
                  >
                    <img
                      style={{ width: "100%", pointerEvents: "none" }}
                      src={currentDocument}
                      alt="Current document"
                    />
                  </ReactRegion>
                </div>
              </Grid>

              <Grid xs={3} md={3}>
                <div>
                  <DocumentListDocPlace
                    documents={documents}
                    deleteOCR={deleteOCR}
                    getDocument={getDocument}
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </WrapperLabeling>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingTree: state.loadingFolders,
    tree: state.folders,
  };
};

export default connect(mapStateToProps, {})(FormTemplate);
