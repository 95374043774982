import http from "./http-common";

export const editDocument = (body) => {
    let url =
      process.env.REACT_APP_HOST_API + `/automation/api/v1.0/Documents/EditDocument`;
    return http.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
        customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      },
    });
  };
  