import React, { useEffect, useState } from "react";
import Bookmark from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/material/IconButton';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
export function DocumentSinglePageDocPlace(props) {


    const [selected, setSelected] = useState();

    
    const setCurrentDocument = (page) => {
        props.document(page);
    };
    return (
        <>



           { (props.show === props.index) && (
           
            <div
                key={props.index}
                style={{ width: '100%', position: 'relative' }}
                className={selected == props.page.thumbnail ? 'documentSelected' : ''}
                onClick={() => setCurrentDocument(props.page)}

            >



                <img className="docImage" title={`Click to run OCR for ${props.page.fileName}`} src={props.page.thumbnail} alt={props.page.fileName} />
                <div className="documentTitle" ><span className="number" >#{props.page.parentIndex+1}</span> - {props.page.originalFileName}</div>
                {
                    (props.ocrDone) &&
                    <IconButton color="success" sx={{ position: 'absolute', marginTop: '-140px', marginLeft: '-24px' }} aria-label="OCR" title="OCR has been applied to this document.">
                        <Bookmark />
                    </IconButton>


                }
            </div>

            )}

        </>
    )
}

export default DocumentSinglePageDocPlace

