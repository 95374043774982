import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import metaDataColor from "../../../../assets/metaDataColor.svg";
import DocPlaceColor from "../../../../assets/DocPlaceColor.svg";
import {
  ContentUl,
  DivContainerG1,
  DivContainerG2,
  DivLogoDocPlace,
  DivTextG1,
  MetaDataDescription,
  NameAttachment,
  StyledLi,
  StyledUl,
  Title,
  useStyles,
} from "./style";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { MetadataForm } from "./resources/formMetaData";

export const DrawerMetaData = (props) => {
  const [openDrawerMeta, setOpenDrawerMeta] = React.useState(
    props.openDrawerMetaData
  );
  const classes = useStyles();

  useEffect(() => {
    setOpenDrawerMeta(props.openDrawerMetaData);
  }, [props.openDrawerMetaData]);

  const handleDrawerClose = () => {
    setOpenDrawerMeta(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    backgroundColor: "rgba(33, 150, 243, 0.52)",
  }));

  return (
    <React.Fragment>
      <Drawer
        anchor={"bottom"}
        slots={{ backdrop: CustomBackdrop }}
        open={openDrawerMeta}
        onClose={handleDrawerClose}
      >
        <Grid container>
          <Grid item xs={2.5} style={{ paddingTop: "15px" }}>
            <DivLogoDocPlace>
              <img
                alt="Logo"
                className={classes.logoDocPlace}
                src={DocPlaceColor}
              />
            </DivLogoDocPlace>
          </Grid>
          <Grid xs={6} style={{ paddingTop: "15px" }}>
            <DivContainerG2>
              <img
                alt="Logo"
                className={classes.logoAttach}
                src={metaDataColor}
              />
              <Title>Metadata list</Title>
            </DivContainerG2>
          </Grid>
          <Grid
            xs={3.5}
            style={{
              paddingTop: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title>Add metadata</Title>
          </Grid>
          <Grid
            item
            xs={2.5}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "15px 15px 15px 15px",
            }}
          >
            <DivContainerG1>
              <DivTextG1>
                aca abra texto para colocar lo que desee para describir lo que
                desee
              </DivTextG1>
            </DivContainerG1>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "15px 15px 15px 15px",
            }}
          >
            <ContentUl>
              <StyledUl>
                {props.metaData?.map((item, index) => (
                  <StyledLi
                    key={item.id}
                    className={index === item.length - 1 ? "last" : ""}
                  >
                    <IconButton size="standard">
                      <CancelIcon
                        style={{ color: "#006ac6" }}
                        fontSize="inherit"
                        onClick={() => {
                          props.deleteMetadataOpenModal(item);
                        }}
                      />
                    </IconButton>
                    <div className={classes.logoMetaData}>
                      <img
                        alt="Logo"
                        className={classes.logoAttach}
                        src={metaDataColor}
                      />
                    </div>
                    <div style={{ width: "auto" }}>
                      <NameAttachment>{item.name}</NameAttachment>
                      <MetaDataDescription>
                        {item.description}
                      </MetaDataDescription>
                    </div>
                  </StyledLi>
                ))}
              </StyledUl>
            </ContentUl>
          </Grid>
          <Grid
            item
            xs={3.5}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
            }}
          >
            <div
              style={{
                backgroundColor: "#f5f7fa",
                width: "100%",
                height: "206px",
                borderRadius: "8px",
              }}
            >
              <MetadataForm onSendMetadata={props.onSendMetadata} />
            </div>
          </Grid>
        </Grid>
      </Drawer>
    </React.Fragment>
  );
};
