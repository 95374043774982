import Button from "@mui/material/Button";
import React from "react";
import {
  ContainerSignatures,
  ImgSignature,
  Section1,
  Section2,
  UserSignature,
  UserSignatureType,
} from "../styles";

export default function SignatureCard(props) {
  const resendRequest = () => {
    props.resendRequest(props.signature?.signatory_email);
  };
  const type = props.signature.type === "INTERNAL" ? "DocPlace" : "External";
  
  return (
    <ContainerSignatures>
      <Section1>
        <div>
          <UserSignature>{props.signature.fullName}</UserSignature>
          <UserSignatureType>{type}</UserSignatureType>
        </div>
        {props.signature.have_signed === 0 &&
          <Button
            style={{ marginLeft: "auto", textTransform: "none" }}
            variant="contained"
            size="small"
            onClick={resendRequest}
          >
            Resend request
          </Button>
        }
      </Section1>
      <Section2>
        {props.signature.have_signed === 0 ? (
          <p>Signature pending...</p>
        ) : (
          <ImgSignature
            src={props?.signature?.signPath}
            alt=""
            style={{ margin: "0 auto", maxWidth: "100%" }}
          />
        )}
      </Section2>
    </ContainerSignatures>
  );
}
