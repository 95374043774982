import { makeStyles, useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { colors } from "../../components/theme";


export const SquareBox = styled.div`
  position: relative;
  width: 200px;
    height: 220px;
    background-color: rgb(238, 245, 251);
    border-radius: 5px;
    &:hover {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23199CE3FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      cursor: pointer;
    }
}
`;



export const BoxTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: blue;
  font-size: 20px;
  width: 100%;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgb(0, 106, 198);
   
    word-break: break-word;
    line-height: 1;
    
    text-overflow: ellipsis;
    overflow: hidden;

    div {
      margin-top: 20px;
      font-size: 14px;
    }
   
`;
export const DragAndDropText = styled.div`
      color: ${colors.white};
      font-size: 10px;
      word-break: break-word;
      width: 90%;
      line-height: 1;
      font-weight: 100;
      margin: -5px auto;
`;

export const TitleDocumentUpload = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${colors.white};
    margin-top: -15px;
    word-break: break-word;
    line-height: 1;
    padding: 0px 5px 2px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 24px;
`;
export const FileNewUpload = styled.article`
    width: 120px;
    height: 130px;
    background-color: ${colors.green} ;
    border-radius: 5px;
    margin-left: 9px;
    &:hover {
        background-color: ${colors.greenHover};
        border: 1px dashed ${colors.white};
        cursor:pointer;
    }
`;

export const Message = styled.section`
  width: 100%;
  min-height: 50px;
  background-color: ${colors.help};
  margin-bottom: 35px;
  margin-top: 0px;
  border-radius: 5px;
  padding: 12px;
  font-size: 14px;
`;

export const useStyles = makeStyles((theme) => ({
  buttons: {
    width: "100%",
    display: "inline-block",
    marginTop: "20px",
    height: "40px",
    color: "#560e1a",
  },
  titleTooltip: {
    backgroundColor: "#1876d2",
    color: "#fff !important",
    width: "100%",
    height: "auto",
    padding: "10px",
    fontSize: "13px !important",
    wordBreak: "break-all !important",
    lineHeight: "1 !important",
  },
  float_center: {
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gridAutoRows: "220px",
  },
  btnFilter: {
    float: "right",
    marginTop: "20px !important",
  },
  btnSave: {
    backgroundColor: "#1876d2 !important",
    margin: "16px 0px 18px 0px !important",
    color: "#fff !important",
    borderBottom: "2px solid #1876d2 !important",
    boxShadow: "0 0 0 !important",
  },
  btnReset: {
    backgroundColor: "red !important",
    margin: "16px 10px 18px 10px !important",
    color: "#fff !important",
    borderBottom: "2px solid red !important",
    boxShadow: "0 0 0 !important",
  },
  closeUpload: {
    backgroundColor: "red",
    color: "red !important",
    margin: "16px 0 0 0 !important",
    border: "1px solid red !important",
    float: "right",
  },
  helperText: {
    fontSize: "12px",
    backgroundColor: "#607d8b3d",
    padding: "5px",
    borderRadius: "3px",
  },
  closeOpt: {
    top: "11px",
    left: "11px",
    color: "white",
    width: "65px",
    cursor: "pointer",
    height: "31px",
    padding: "5px 0 0 8px",
    zIndex: "9999",
    position: "absolute",
    fontSize: "14px",
    backgroundColor: "#006ac6",
    borderRadius: "4px",
  },
  iconCloseFilter: {
    position: "absolute",
    cursor: "pointer",
    right: "0",
    top: "-22px",
    color: "#000",
    fontSize: "18px !important",
  },
}));
