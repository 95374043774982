import http from "./http-common";

/* Process send request emails to sign*/

export const requiredWorkflowProcessAttend= (body) => {
  let url =
    process.env.REACT_APP_HOST_API + `/automation/api/v1.0/RequiredWorkflowProcessAttend`;
  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};



/* Save Labeled data */

export const saveLabeledData = (document, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/WorkflowProcessRequest/CreateLabelData`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      documentId: document,
    },
  });
};


/* Get Labeled data */

export const getEsignatureData = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/WorkflowProcessRequest/GetLabelData`;
  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      documentId: documentId,
    },
  });
};

/* Delete Labeled data */

export const deleteLabeledData = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/WorkflowProcessRequest/DeleteLabelData`;
  return http.delete(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      documentId: documentId,
    },
  });
};

/* Update Labeled data */

export const updateLabeledData = (documentId, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/WorkflowProcessRequest/UpdateLabelData`;
  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      templateId: documentId,
    },
  });
};



//Approve Document
/* Get Labeled data */

export const getDepartments = (id) => {
    let url =
      process.env.REACT_APP_HOST_API +
      `/automation/api/v1.0/Team/GetTeams`;
    return http.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
        customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
        userId: id,
      },
    });
  };

  export const getEmployeesDepartments = (userid,id) => {
    let url =
      process.env.REACT_APP_HOST_API +
      `/automation/api/v1.0/TeamUsers/GetUsersByTeam/${id}`;
    return http.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
        customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
        userId: userid,
      },
    });
  };