import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import { Radio, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormControl from "@mui/material/FormControl";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  SelectTitle,
  useStyles,
  WrapperRadioButton,
} from "./style";
import { colors } from "../../../theme";

const RadioButtonFormView = (props) => {
  const classes = useStyles();
  return (
    <WrapperRadioButton>
      <Container>
        {props.type === "view" ? (
          <>
            <SelectTitle>{props.titleRadioB}</SelectTitle>
            <FormControl>
              <RadioGroup
                row={props.row}
                name={props.name}
                onChange={props.onChangeRadioB}
                value={props.radioOption?.label}
              >
                {props.item.map((value) => (
                  <FormControlLabel
                    key={value.order}
                    value={value.label}
                    disabled={value.disabled}
                    control={<Radio size={props.size} />}
                    label={value.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <section className={classes.subtitle}>
              {props.isChecked && (
                <div style={{ color: "red" }}>*Mandatory</div>
              )}
              {props.subTitleRadioB}
            </section>
          </>
        ) : (
          <>
            <ButtonContainer>
              <Button
                variant="text"
                onClick={props.onChangeNotSeeIntoPDF}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notSeeIntoPdfRadioB && colors.gray3,
                }}
                startIcon={<VisibilityOffIcon />}
              >
                Not see into PDF
              </Button>
            </ButtonContainer>
            <ContainerTextField>
              <TextField
                id="helpTextTitle"
                placeholder="Click to Enter Title"
                fullWidth={true}
                variant="standard"
                onChange={props.onChangeTitleRadioB}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                  },
                }}
              />
            </ContainerTextField>
            <FormControl disabled>
              <RadioGroup
                row={props.row}
                onChange={props.onChangeRadioB}
                value={props.radioOption?.label}
                name={props.name}
              >
                {props.item.map((value) => (
                  <FormControlLabel
                    key={value.id}
                    value={value}
                    control={<Radio size={props.size} />}
                    label={value.label}
                    disabled={value.disabled}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <ContainerTextField>
              <TextField
                id="helpTestDescription"
                placeholder="Click to Enter Description"
                fullWidth={true}
                variant="standard"
                onChange={props.onChangeSubTitleRadioB}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    fontWeight: "lighter",
                  },
                }}
              />
            </ContainerTextField>
            <ButtonContainerTools>
              <Button
                onClick={props.onChangeSettingRadioB}
                style={{
                  textTransform: "none",
                  marginRight: "20px",
                }}
                variant={"text"}
                startIcon={<SettingsSuggestIcon />}
              >
                Settings
              </Button>
              <Button
                onClick={props.onChangeDeleteRadioB}
                style={{
                  textTransform: "none",
                }}
                color="error"
                variant={"text"}
                startIcon={<DeleteForeverIcon />}
              >
                Delete
              </Button>
            </ButtonContainerTools>
          </>
        )}
      </Container>
    </WrapperRadioButton>
  );
};

export default RadioButtonFormView;
