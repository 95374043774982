import {
  API_ERROR_DETAIL_FOLDER,
  API_ERROR_DOCUMENT_PAGES,
  API_ERROR_SEARCH_ARCHIVES,
  DETAIL_FOLDER_LIST,
  DETAIL_FOLDER_LIST_SUCCESS,
  DOCUMENT_PAGES_LIST,
  DOCUMENT_PAGES_LIST_SUCCESS,
  DOCUMENT_PERMISSIONS,
  DOCUMENT_PERMISSIONS_ERROR,
  DOCUMENT_PERMISSIONS_SUCCESS,
  LIST_REQUIRE_SIGNATURE_DOCUMENT,
  LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA,
  LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR,
  LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS,
  RESEND_SIGNATURE_REQUEST,
  RESEND_SIGNATURE_REQUEST_CLEAR_DATA,
  RESEND_SIGNATURE_REQUEST_ERROR,
  RESEND_SIGNATURE_REQUEST_SUCCESS,
  SEARCH_ARCHIVES_LIST,
  SEARCH_ARCHIVES_LIST_SUCCESS,
  DOCUMENT_VERSIONS,
  DOCUMENT_VERSIONS_SUCCESS,
  DOCUMENT_VERSIONS_ERROR,
  DOCUMENT_VERSIONS_CLEAR_DATA,
  RESTORE_DOCUMENT_VERSION,
  RESTORE_DOCUMENT_VERSION_SUCCESS,
  RESTORE_DOCUMENT_VERSION_ERROR,
  RESTORE_DOCUMENT_VERSION_CLEAR_DATA,
} from "./actionTypes";

export const getDetailFolderList = (detailFolder, navigate) => {
  return {
    type: DETAIL_FOLDER_LIST,
    payload: { detailFolder, navigate },
  };
};
export const getDocumentPermissions = (id, navigate) => {
  return {
    type: DOCUMENT_PERMISSIONS,
    payload: {id, navigate},
  }
}
export const getDocumentPermissionsSuccess = success => {
  return {
    type: DOCUMENT_PERMISSIONS_SUCCESS,
    payload: success,
  }
}
export const getDocumentPermissionsError = error => {
  return {
    type: DOCUMENT_PERMISSIONS_ERROR,
    payload: error,
  }
}

export const getDocumentVersions = (id, navigate) => {
  return {
    type: DOCUMENT_VERSIONS,
    payload: {id, navigate},
  }
}
export const getDocumentVersionsSuccess = success => {
  return {
    type: DOCUMENT_VERSIONS_SUCCESS,
    payload: success,
  }
}
export const getDocumentVersionsError = error => {
  return {
    type: DOCUMENT_VERSIONS_ERROR,
    payload: error,
  }
}
export const getDocumentVersionsClearData = () => {
  return {
    type: DOCUMENT_VERSIONS_CLEAR_DATA,
    payload: {},
  }
}

export const restoreDocumentVersion = (id, navigate) => {
  return {
    type: RESTORE_DOCUMENT_VERSION,
    payload: {id, navigate},
  }
}
export const restoreDocumentVersionSuccess = success => {
  return {
    type: RESTORE_DOCUMENT_VERSION_SUCCESS,
    payload: success,
  }
}
export const restoreDocumentVersionError = error => {
  return {
    type: RESTORE_DOCUMENT_VERSION_ERROR,
    payload: error,
  }
}
export const restoreDocumentVersionClearData = () => {
  return {
    type: RESTORE_DOCUMENT_VERSION_CLEAR_DATA,
    payload: {},
  }
}


export const getDocumentPageList = (documentPages,page,size, navigate) => {
  return {
    type: DOCUMENT_PAGES_LIST,
    payload: {documentPages,page,size, navigate},
  }
}

export const detailFolderSuccess = detailFolder => {
  return {
    type: DETAIL_FOLDER_LIST_SUCCESS,
    payload: detailFolder,
  }
}

export const documentPageSuccess = documentPages => {
  return {
    type: DOCUMENT_PAGES_LIST_SUCCESS,
    payload: documentPages,
  }
}

export const apiErrorDocumentPage = error => {
  return {
    type: API_ERROR_DOCUMENT_PAGES,
    payload: error,
  }
}

export const apiErrorDetailFolder = error => {
  return {
    type: API_ERROR_DETAIL_FOLDER,
    payload: error,
  }
}

export const getSearchArchivesList = (searchArchives, navigate) => {
  return {
    type: SEARCH_ARCHIVES_LIST,
    payload: {searchArchives, navigate},
  }
}

export const searchArchivesSuccess = searchArchives => {
  return {
    type: SEARCH_ARCHIVES_LIST_SUCCESS,
    payload: searchArchives,
  }
}

export const apiErrorSearchArchives = error => {
  return {
    type: API_ERROR_SEARCH_ARCHIVES,
    payload: error,
  }
}

// List require signature for document
export const getListRequireSignatureDocument = (id) => {
  return {
    type: LIST_REQUIRE_SIGNATURE_DOCUMENT,
    payload: {id},
  }
}

export const getListRequireSignatureDocumentSuccess = success => {
  return {
    type: LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS,
    payload: success,
  }
}

export const getListRequireSignatureDocumentError = error => {
  return {
    type: LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR,
    payload: error
  }
}

export const listRequireSignatureDocumentClearData = () => {
  return {
    type: LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA,
    payload: {}
  };
};
//****---END---****

// Resend Signature request email
export const resendSignatureRequest = (data) => {
  return {
    type: RESEND_SIGNATURE_REQUEST,
    payload: {data},
  }
}

export const resendSignatureRequestSuccess = success => {
  return {
    type: RESEND_SIGNATURE_REQUEST_SUCCESS,
    payload: success,
  }
}

export const resendSignatureRequestError = error => {
  return {
    type: RESEND_SIGNATURE_REQUEST_ERROR,
    payload: error
  }
}

export const resendSignatureRequestClearData = () => {
  return {
    type: RESEND_SIGNATURE_REQUEST_CLEAR_DATA,
    payload: {}
  };
};

//****---END---****