import http from "./http-common";

export const updatePassword = (body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/security/api/v1.0/SystemUserPublic/${body.id}/Reset`;

  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
    },
  });
};

export const userUpdatePassword = (body) => {
  let url =
    process.env.REACT_APP_HOST_API + `/billing/Api/V1/Users/ChangePassword`;

  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
    },
  });
};

export const buildOcr = (onUploadProgress, body) => {
  let url =
    process.env.REACT_APP_HOST_API + "/automation/api/v1/Ocr/BuildOcrJson";

  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
    },
    onUploadProgress,
  });
};

export const uploadLabelsContent = (
  onUploadProgress,
  doctypeId,
  fileName,
  body
) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Ocr/SaveLabelsJsonContent`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
      doctypeId: doctypeId,
      fileName: fileName,
    },
  });
};

export const uploadFieldsContent = (onUploadProgress, doctypeId, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Ocr/SaveFieldsJsonContent`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
      doctypeId: doctypeId,
    },
  });
};

export const getUserIsRoot = (userId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/SystemUserRoles/UserIsRoot/${userId}`;

  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

export const getFields = (doctypeId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Doctype/Attributes/${doctypeId}`;

  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

export const fieldSave = (doctypeId, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Ocr/SaveDoctypeFieldsJson`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
      doctypeId: doctypeId,
    },
  });
};

export const trainOCR = (doctypeId, body) => {
  let url =
    process.env.REACT_APP_HOST_API + `/automation/api/v1.0/Ocr/CreateModel`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      doctypeId: doctypeId,
    },
  });
};

/* Save Labeled data */

export const saveLabeledData = (doctypeId, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Doctype/LabelingData/${doctypeId}`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

/* Get Labeled data */

export const getLabeledData = (doctypeId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Doctype/LabelingData/${doctypeId}`;
  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};
/* Delete Labeled data */

export const deleteLabeledData = (doctypeId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Doctype/LabelingData/${doctypeId}`;
  return http.delete(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

/* Update Labeled data */

export const updateLabeledData = (doctypeId, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Doctype/LabelingData/${doctypeId}`;
  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

export const getCanonicalPath = (archiveId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Archives/JsonCanonicalPath/${archiveId}`;
  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};
