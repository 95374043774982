import {
  SEARCH_DOCUMENT_LIST,
  SEARCH_DOCUMENT_LIST_SUCCESS,
  API_ERROR_SEARCH_DOCUMENT,
  SEARCH_FORM_LIST,
  SEARCH_FORM_LIST_SUCCESS,
  API_ERROR_SEARCH_FORM
} from "./actionTypes"

const initialState = {
  errorSearchDocument: null,
  responseSearchDocument: null,
  loadingSearchDocument: false,
  errorSearchForm: null,
  responseSearchForm: null,
  loadingSearchForm: false
}

const search = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_DOCUMENT_LIST:
      state = {
        ...state,
        errorSearchDocument: null,
        loadingSearchDocument: true,
      }
      break
    case SEARCH_DOCUMENT_LIST_SUCCESS:
      state = {
        ...state,
        errorSearchDocument: null,
        responseSearchDocument:action.payload,
        loadingSearchDocument: false,
      }
      break
    case API_ERROR_SEARCH_DOCUMENT:
      state = { ...state,
        errorSearchDocument: action.payload,
        loadingSearchDocument: false,
        responseSearchDocument:null
      }
      break
    case SEARCH_FORM_LIST:
      state = {
        ...state,
        errorSearchForm: null,
        loadingSearchForm: true,
      }
      break
    case SEARCH_FORM_LIST_SUCCESS:
      state = {
        ...state,
        errorSearchForm: null,
        responseSearchForm:action.payload,
        loadingSearchForm: false,
      }
      break
    case API_ERROR_SEARCH_FORM:
      state = { ...state,
        errorSearchForm: action.payload,
        loadingSearchForm: false,
        responseSearchForm:null
      }
      break
      

    default:
      state = { ...state }
      break
  }
  return state
}

export default search
