import React, { useEffect } from "react";
import { Wrapper } from "./style";

import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Delete from "@mui/icons-material/DeleteTwoTone";
import EditIcon from "@mui/icons-material/Edit";

export function GridDocPlace(props) {
  const [dataList, setDataList] = React.useState([]);

  useEffect(() => {
    if (props.data.length > 0) {
      console.log("DADA: ", props.data);
      setDataList(props.data);
    }
  }, [props.data]);

  const selectOption = (op, id, row) => {
    optionSelect({ action: op, id: id, row: row });
  };

  const optionSelect = (event) => {
    props.option(event);
  };

  const headCols = [
    {
      field: "name",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Name",
    },
    {
      field: "physicalName",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Physical Name",
    },
    {
      field: "description",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Description",
    },
    {
      field: "doctypeName",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "DocType",
    },
    {
      field: "originId",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Origin Id",
    },
    {
      field: "originTypeText",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Origin Type",
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      headerClassName: "MuiTableHead-root",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button onClick={() => selectOption("edit", params.id, params)}>
            <EditIcon />
            Edit
          </Button>
          <Button onClick={() => selectOption("remove", params.id, params)}>
            <Delete />
            Remove
          </Button>
        </>
      ),
    },
  ];
  return (
    <Wrapper>
      <DataGrid
        rows={dataList}
        columns={headCols}
        pageSize={10}
        rowsPerPageOptions={[5]}
        sortingOrder={["asc", "desc"]}
        getRowClassName={() => `MuiTableCell-row`}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
    </Wrapper>
  );
}

export default GridDocPlace;
