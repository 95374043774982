import { all, fork } from "redux-saga/effects";
import LoginSaga from "./auth/saga";
import UserSaga from "./user/saga";
import DocTypeSaga from "./docType/saga";
import DashboardSaga from "./dashboard/saga";
import TreeSaga from "./tree/saga";
import DetailFolderSaga from "./document/saga";
import ExplorerSaga from "./explorer/saga";
import InputMethodSaga from "./inputMethod/saga";
import Search from "./search/saga";
import Rol from "./rol/saga";
import UserValidateSaga from "./eSignature/saga";
import AllFormsRequestedSaga from "./Forms/saga";
import workFlowSaga from "./workFlow/saga";

export default function* rootSaga() {
  yield all([
    fork(LoginSaga),
    fork(UserSaga),
    fork(DocTypeSaga),
    fork(DashboardSaga),
    fork(TreeSaga),
    fork(DetailFolderSaga),
    fork(ExplorerSaga),
    fork(Search),
    fork(InputMethodSaga),
    fork(Rol),
    fork(UserValidateSaga),
    fork(AllFormsRequestedSaga),
    fork(workFlowSaga),
  ]);
}
