import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export const Wrapper = styled.div`
    width: 100%;
    height: 35px;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 20px;
`;



export const useStyles = makeStyles((theme) => ({
    iconSearch: {
        width: '18px',
        height: '18px',
        margin: '19px 16px 0',
        position: 'absolute',
        color:'#006ac6'
    },
    buttonSearch: {
        float: 'right',
        marginTop: '-45px !important',
        marginRight: '16px !important',
        backgroundColor: '#006ac6 !important'
    },
    input: {
        height: '40px',
        paddingTop: '18px'
    },
    baseSearch:{
        width: '100% !important',
        backgroundColor: '#fff !important',
        borderRadius: '4px !important',
        position: 'relative !important',
        height:'58px !important',
       
    }
    
  }));


  



