import * as React from "react";
import {DataGrid, GridPagination} from "@mui/x-data-grid";
import moment from "moment-timezone";
import {v4 as uuidv4} from "uuid";
import Button from "@mui/material/Button";
import PreviewIcon from "@mui/icons-material/Preview";

import {Wrapper} from "./style";

const headColSignature = [
  {
    headerName: "Document name",
    headerClassName: "MuiTableHead-root",
    field: "fileReferences",
    flex: 1,
    valueFormatter: (params) => {
      return `${params.value.originalFileName}`;
    },
  },
  {
    headerName: "Created date",
    headerClassName: "MuiTableHead-root",
    field: "createdAt",
    flex: 1,
    type: "date",
    valueFormatter: (params) => {
      return `${moment(params.value).format("MM/DD/YYYY")}`;
    },
  },
  {
    field: "props",
    headerName: "Options",
    sortable: false,
    headerClassName: "MuiTableHead-root",
    flex: 1,
    renderCell: (params) => (
      <>
        <Button
          title="Sign"
          onClick={() => (window.location.href = `/docViewer/${params.row.id}`)}
        >
          <PreviewIcon fontSize="medium" />
          <span style={{ marginLeft: "10px" }}>View document</span>
        </Button>
      </>
    ),
  },
];

const generateUniqueId = () => {
    return uuidv4();
};

const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
        field: "age",
        headerName: "Age",
        type: "number",
        width: 90
    },
    {
        field: "fullName",
        headerName: "Full name",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 160,
        valueGetter: (params) =>
            `${params.row.firstName || ""} ${params.row.lastName || ""}`
    }
];
const GridBoxMail = (props) => {
    const changePage = (page) => {
        props.changePage(page);
    }
    return (
        <Wrapper>
                <DataGrid
                    rows={props.rows}
                    columns={headColSignature}
                    rowCount={props.rowCount}
                    getRowId={(row) => row.id || generateUniqueId()}
                    pagination
                    pageSize={props.pageSize}
                    components={{
                        Pagination: GridPagination,
                    }}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        changePage(newPage);
                    }}
                />
        </Wrapper>
    )
}

export default GridBoxMail

// window.location.href = `/placeSignature/${params?.row.updatedBy}`
