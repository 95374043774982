import {
  TREE_LIST,
  TREE_LIST_SUCCESS,
  API_ERROR_TREE,
} from "./actionTypes"

const initialState = {
  errorTree: null,
  responseTree: null,
  responseTreeMove: null,
  isMoveAction: false,
  loadingTree: false
}

const tree = (state = initialState, action) => {
  
  switch (action.type) {
    case TREE_LIST:
      state = {
        ...state,
        errorTree: null,
        loadingTree: true,
        isMoveAction: action.payload.tree?.isMoveAction
      }
      break
    case TREE_LIST_SUCCESS:
      state = {
        ...state,
        errorTree: null,
        loadingTree: false,
      }

      if(state.isMoveAction){
        state.responseTreeMove = action.payload;
        state.isMoveAction = false;
      }else{
        state.responseTree = action.payload;
      }
      break
    case API_ERROR_TREE:
      state = { ...state,
        errorTree: action.payload,
        loadingTree: false,
        responseTree:null,
        responseTreeMove:null
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default tree
