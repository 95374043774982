import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import objectAssign from 'object-assign';
import style from './styles';
import CreateIcon from '@mui/icons-material/BorderColor';

import Initials from "@mui/icons-material/SortByAlpha";
import FullName from "@mui/icons-material/Person";
import Text from "@mui/icons-material/Abc";

import Date from "@mui/icons-material/DateRange";
import Time from "@mui/icons-material/AccessTime";

class Region extends Component {
	constructor(props) {
		super(props);
	}
	renderHandles() {
		return (
			<div>
				<div data-dir='se' style={style.RegionHandleSE} />
				<div data-dir='sw' style={style.RegionHandleSW} />
				<div data-dir='nw' style={style.RegionHandleNW} />
				<div data-dir='ne' style={style.RegionHandleNE} />
			</div>
		);
	}

	getInitials(string) {
		var names = string.split(' '),
			initials = names[0].substring(0, 1).toUpperCase();

		if (names.length > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	}
	render() {
		const localStyle = {
			width: this.props.width + '%',
			height: this.props.height + '%',
			left: `${this.props.x}%`,
			top: `${this.props.y}%`
		};
		const dataRenderArgs = {
			data: this.props.data,
			isChanging: this.props.changing,
			index: this.props.index
		};

		return (
			<>
				{this.props.type == 9 &&
					<div
						style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
						onClick={this.props.onClick}
						className="selectED"
						onMouseDown={this.props.onCropStart}
						onTouchStart={this.props.onCropStart}
						data-wrapper="wrapper"
						title={`Time stamp: ${this.props.data.label}`}
					><Time title={'Time stamp'} style={{ pointerEvents: 'none' }} />{this.props.data.label ? <div style={{ fontSize: '12px', pointerEvents: 'none' }}><b> mm-dd-yyyy HH:mm:ss </b><br /><span style={{ fontSize: '10px' }}>{(this.props.data.text)}</span> </div> : null}
						{this.renderHandles()}
						{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
					</div>
				}

				{this.props.type == 8 &&
					<div
						style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
						onClick={this.props.onClick}
						className="selectED"
						onMouseDown={this.props.onCropStart}
						onTouchStart={this.props.onCropStart}
						data-wrapper="wrapper"
						title={`Date: ${this.props.data.value}`}
					><Date title={'Date'} style={{ pointerEvents: 'none' }} />{this.props.data.label ? <div style={{ fontSize: '12px', pointerEvents: 'none' }}><b> mm-dd-yyyy </b><br /><span style={{ fontSize: '10px' }}>{(this.props.data.text)}</span> </div> : null}
						{this.renderHandles()}
						{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
					</div>
				}

				{this.props.type == 4 &&
					<div
						style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
						onClick={this.props.onClick}
						className="selectED"
						onMouseDown={this.props.onCropStart}
						onTouchStart={this.props.onCropStart}
						data-wrapper="wrapper"
						title={`Text: ${this.props.data.label}`}
					><Text title={'Text'} style={{ pointerEvents: 'none' }} />{this.props.data.freeText ? <div style={{ fontSize: '12px', pointerEvents: 'none' }}><b> {(this.props.data.freeText)}</b> <br /><span style={{ fontSize: '10px' }}>{(this.props.data.text)}</span></div> : null}
						{this.renderHandles()}
						{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
					</div>
				}
				{this.props.type == 3 &&
					<div
						style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
						onClick={this.props.onClick}
						className="selectED"
						onMouseDown={this.props.onCropStart}
						onTouchStart={this.props.onCropStart}
						data-wrapper="wrapper"
						title={`Full name: ${this.props.data.label}`}
					><FullName title={'Full name'} style={{ pointerEvents: 'none' }} />{this.props.data.label.length > 0 ? <div style={{ fontSize: '12px', pointerEvents: 'none', textTransform: 'Uppercase' }}><b> {(this.props.data.label)}</b><br /><span style={{ fontSize: '10px' }}>{(this.props.data.text)}</span> </div> : null}
						{this.renderHandles()}
						{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
					</div>
				}
				{this.props.type == 2 &&
					<div
						style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
						onClick={this.props.onClick}
						className="selectED"
						onMouseDown={this.props.onCropStart}
						onTouchStart={this.props.onCropStart}
						data-wrapper="wrapper"
						title={`Initials: ${this.props.data.label}`}
					><Initials title={'Initials'} style={{ pointerEvents: 'none' }} />{this.props.data.label.length > 0 ? <div style={{ fontSize: '12px', pointerEvents: 'none' }}><b> {this.getInitials(this.props.data.label)}</b><br /><span style={{ fontSize: '10px' }}>{(this.props.data.text)}</span> </div> : null}
						{this.renderHandles()}
						{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
					</div>
				}

				{this.props.type == 1 &&
					<div
						style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
						onClick={this.props.onClick}
						className="selectED"
						onMouseDown={this.props.onCropStart}
						onTouchStart={this.props.onCropStart}
						data-wrapper="wrapper"
						title={`eSignature: ${this.props.data.label} - Double click: delete`}
					><CreateIcon style={{ pointerEvents: 'none' }} />{this.props.data.label.length > 0 ? <div style={{ fontSize: '12px', pointerEvents: 'none' }}> <i>{this.props.data.label}</i> <br /><span style={{ fontSize: '10px' }}>{(this.props.data.text)}</span></div> : null}
						{this.renderHandles()}
						{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
					</div>
				}
				{this.props.type == 0 &&
					<div
						style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
						onClick={this.props.onClick}


						data-wrapper="wrapper"
						title={`Recipient: ${this.props.data.label}`}
					><CreateIcon style={{ pointerEvents: 'none' }} />{this.props.data.label.length > 0 ? <div style={{ fontSize: '12px', pointerEvents: 'none' }}> {this.props.data.label} </div> : null}


						{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
					</div>
				}
			</>

		);
	}
}
Region.propTypes = {
	x: PropTypes.number.isRequired,
	y: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	onCropStart: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
	handles: PropTypes.bool,
	changing: PropTypes.bool,
	dataRenderer: PropTypes.func,
	data: PropTypes.object,
	customStyle: PropTypes.object
};


export default Region;