import { call, put, takeEvery } from "redux-saga/effects"
import { LOGIN_USER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  CUSTOMER_USER,
  HASH_VALIDATE,
  /**register Esignature */
  REGISTER_CLEAR_DATA,
  REGISTER
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  forgetPasswordSuccess,
  forgetPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  hashValidateSuccess,
  hashValidateError,
  customerError,
  customerSuccess,
  /**Esignature register */
  registerClearData,
  registerError,
  registerSuccess,
} from "./actions"
import { toast } from 'react-toastify';

import {
  postLogin,
  postForgetPassword,
  postResetPassword,
  postHashValidate,
  getBillingCustomersNotFull,
  /**Esignature register */
  putEsignatureUser,
} from "../../helpers/call_api"


function* loginUser({ payload: { user } }) {
  try {
    const response = yield call(postLogin, user)
    yield put(loginSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}


function* getCustomers({ payload: { customer, navigate } }) {
  try {
    const response = yield call(getBillingCustomersNotFull, customer)
    yield put(customerSuccess(response))
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate('/login');
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(customerError(error))
  }
}

//forget password
function* forgetPassword({ payload: { user, navigate } }) {
  try {
    const response = yield call(postForgetPassword, user)
    yield put(forgetPasswordSuccess(response));
  } catch (error) {
    toast.error(error.message);
    yield put(forgetPasswordError(error));
  }
}

//reset password
function* resetPassword({ payload: { user, navigate } }) {
  try {
    const response = yield call(postResetPassword, user);
    yield put(resetPasswordSuccess(response));
  } catch (error) {
    toast.error(error.message);
    yield put(resetPasswordError(error));
  }
}

//hash validate
function* hashValidate({ payload: { user, navigate } }) {
  try {
    const response = yield call(postHashValidate, user);
    yield put(hashValidateSuccess(response));
  } catch (error) {
    toast.error(error.message);
    yield put(hashValidateError(error));
  }
}

/**Register esignature */
function* registerClearDataEsignature() {
  try {
    yield put(registerClearData())
  } catch (error) {
    yield put(registerError(error))
  }
}

function* registerEsignatureUser({ payload: { userId, user, navigate } }) {
  try {
    const response = yield call(putEsignatureUser, userId, user);
    yield put(registerSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate('/login');
      }
    }
    console.log(error);
    yield put(registerError(error));
  }
}
/**END */

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
  yield takeEvery(RESET_PASSWORD, resetPassword);
  yield takeEvery(HASH_VALIDATE, hashValidate);
  yield takeEvery(CUSTOMER_USER, getCustomers);
  /**esignature register */
  yield takeEvery(REGISTER_CLEAR_DATA, registerClearDataEsignature);
  yield takeEvery(REGISTER, registerEsignatureUser);
}

export default authSaga
