import React, { useEffect } from "react";
import { Wrapper } from "./style";

import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Delete from "@mui/icons-material/DeleteTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import moment from "moment-timezone";

export function GridDocPlace(props) {
  const selectOption = (op, id, row) => {
    optionSelect({ action: op, id: id, row: row });
  };

  const optionSelect = (event) => {
   // console.log(event);
    props.option(event);
  };

  const headCols = [
    {
      field: "name",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Name",
    },
    {
      field: "description",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Description",
    },
    {
      field: "ignoreOcr",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "OCR",
      valueGetter: (params) => `${params.row.ignoreOcr === 0 ? "OCR" : "Not supported"}`,
    },
    {
      field: "createdAt",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Creation date",
      type: "date",
      valueFormatter: (params) => { return `${moment(params.value).format("MM/DD/YYYY")}`},
   
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      headerClassName: "MuiTableHead-root",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button  title="Edit Attributes" onClick={() => selectOption("attributes", params.id, params)}>
            
            <EditIcon />
          </Button>
      
         
            <Button title="Edit" onClick={() => selectOption("edit", params.id, params)}>
            <EditAttributesIcon />
          
          </Button>
      

          
          <Button title="Remove" onClick={() => selectOption("remove", params.id, params)}>
            <Delete />
           
          </Button>
        </>
      ),
    },
  ];
  return (
    <Wrapper>
      <DataGrid
        rows={props.data}
        columns={headCols}
        pageSize={10}
        rowsPerPageOptions={[5]}
        sortingOrder={["asc", "desc"]}
        getRowClassName={() => `MuiTableCell-row`}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
      />
    </Wrapper>
  );
}

export default GridDocPlace;
