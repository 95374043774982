import { call, put, takeEvery } from "redux-saga/effects";
import {
  deleteAttachmentError,
  deleteAttachmentSuccess,
  deleteMetadataError,
  deleteMetadataSuccess,
  getAllAttachmentError,
  getAllAttachmentSuccess,
  getAllMetadataError,
  getAllMetadataSuccess,
  getRequiredProcessError,
  getRequiredProcessSuccess,
  postMetadataError,
  postMetadataSuccess,
} from "./actions";
import {
  DELETE_ATTACHMENT,
  DELETE_METADATA,
  GET_ALL_ATTACHMENT,
  GET_ALL_METADATA,
  GET_REQUIRED_PROCESS,
  POST_METADATA,
} from "./actionTypes";
import {
  deleteAttachmentAPI,
  deleteMetadataAPI,
  getAllDocumentAttachmentAPI,
  getAllRequiredWorkFlowProcessAPI,
  getDocumentMetadataAPI,
  postDocumentMetadataAPI,
} from "../../helpers/call_api";

//ATTACHMENT
function* getAllAttachment({ payload: { documentId } }) {
  try {
    const response = yield call(getAllDocumentAttachmentAPI, documentId.id);
    yield put(getAllAttachmentSuccess(response));
  } catch (error) {
    yield put(getAllAttachmentError(error));
  }
}

//DELETE_ATTACHMENT
function* deleteAttachment({ payload: { attachmentId } }) {
  try {
    const response = yield call(deleteAttachmentAPI, attachmentId.id);
    yield put(deleteAttachmentSuccess(response));
  } catch (error) {
    yield put(deleteAttachmentError(error));
  }
}

//REQUIRED_PROCESS
function* getRequiredProcess() {
  try {
    const response = yield call(getAllRequiredWorkFlowProcessAPI);
    yield put(getRequiredProcessSuccess(response));
  } catch (error) {
    yield put(getRequiredProcessError(error));
  }
}

//METADATA
function* getAllMetadata({ payload: { documentId } }) {
  try {
    const response = yield call(getDocumentMetadataAPI, documentId.id);
    yield put(getAllMetadataSuccess(response));
  } catch (error) {
    yield put(getAllMetadataError(error));
  }
}

function* postMetadata({ payload: { documentId, data } }) {
  // debugger;
  try {
    const response = yield call(postDocumentMetadataAPI, documentId, data);
    yield put(postMetadataSuccess(response));
  } catch (error) {
    yield put(postMetadataError(error));
  }
}

function* deleteMetadata({ payload: { metadataId } }) {
  try {
    const response = yield call(deleteMetadataAPI, metadataId.id);
    yield put(deleteMetadataSuccess(response));
  } catch (error) {
    yield put(deleteMetadataError(error));
  }
}

function* workFlowSaga() {
  yield takeEvery(GET_ALL_ATTACHMENT, getAllAttachment);
  yield takeEvery(GET_REQUIRED_PROCESS, getRequiredProcess);
  yield takeEvery(GET_ALL_METADATA, getAllMetadata);
  yield takeEvery(POST_METADATA, postMetadata);
  yield takeEvery(DELETE_ATTACHMENT, deleteAttachment);
  yield takeEvery(DELETE_METADATA, deleteMetadata);
}

export default workFlowSaga;
