import {
  INPUT_METHOD_LIST,
  INPUT_METHOD_LIST_SUCCESS,
  API_ERROR_INPUT_METHOD,
  INPUT_METHOD_POST,
  INPUT_METHOD_POST_SUCCESS,
  API_ERROR_INPUT_METHOD_POST,
  INPUT_METHOD_PUT,
  INPUT_METHOD_PUT_SUCCESS,
  API_ERROR_INPUT_METHOD_PUT,
  INPUT_METHOD_DELETE,
  INPUT_METHOD_DELETE_SUCCESS,
  API_ERROR_INPUT_METHOD_DELETE,
} from "./actionTypes"

const initialState = {
  errorInputMethod: null,
  responseInputMethod: null,
  loadingInputMethod: false,

  errorInputMethodPost: null,
  responseInputMethodPost: null,
  loadingInputMethodPost: false,

  errorInputMethodPut: null,
  responseInputMethodPut: null,
  loadingInputMethodPut: false,

  errorInputMethodDelete: null,
  responseInputMethodDelete: null,
  loadingInputMethodDelete: false,
}

const inputMethod = (state = initialState, action) => {
  switch (action.type) {
    case INPUT_METHOD_LIST:
      state = {
        ...state,
        errorInputMethod: null,
        loadingInputMethod: true,
      }
      break
    case INPUT_METHOD_LIST_SUCCESS:
      state = {
        ...state,
        errorInputMethod: null,
        responseInputMethod:action.payload,
        loadingInputMethod: false,
      }
      break
    case API_ERROR_INPUT_METHOD:
      state = { ...state,
        errorInputMethod: action.payload,
        loadingInputMethod: false,
        responseInputMethod:null
      }
      break
    case INPUT_METHOD_POST:
      state = {
        ...state,
        errorInputMethodPost: null,
        loadingInputMethodPost: true,
      }
      break
    case INPUT_METHOD_POST_SUCCESS:
      state = {
        ...state,
        errorInputMethodPost: null,
        responseInputMethodPost:action.payload,
        loadingInputMethodPost: false,
      }
      break
    case API_ERROR_INPUT_METHOD_POST:
      state = { ...state,
        errorInputMethodPost: action.payload,
        loadingInputMethodPost: false,
        responseInputMethodPost:null
      }
      break
    case INPUT_METHOD_PUT:
      state = {
        ...state,
        errorInputMethodPut: null,
        loadingInputMethodPut: true,
      }
      break
    case INPUT_METHOD_PUT_SUCCESS:
      state = {
        ...state,
        errorInputMethodPut: null,
        responseInputMethodPut:action.payload,
        loadingInputMethodPut: false,
      }
      break
    case API_ERROR_INPUT_METHOD_PUT:
      state = { ...state,
        errorInputMethodPut: action.payload,
        loadingInputMethodPut: false,
        responseInputMethodPut:null
      }
      break
    case INPUT_METHOD_DELETE:
      state = {
        ...state,
        errorInputMethodDelete: null,
        loadingInputMethodDelete: true,
      }
      break
    case INPUT_METHOD_DELETE_SUCCESS:
      state = {
        ...state,
        errorInputMethodDelete: null,
        responseInputMethodDelete:action.payload,
        loadingInputMethodDelete: false,
      }
      break
    case API_ERROR_INPUT_METHOD_DELETE:
      state = { ...state,
        errorInputMethodDelete: action.payload,
        loadingInputMethodDelete: false,
        responseInputMethodDelete:null
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default inputMethod
