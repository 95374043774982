import React, { useEffect, useState } from "react";
import { useStyles, Wrapper, WrapperLabeling } from "./style";
import { makeStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../../components/loading";
import { DocumentListDocPlace } from "./documentList";
import { FieldsDocPlace } from "./fields";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import ReactRegion from "./RegionSelectP";
import "./styles.css";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowLeft from "@mui/icons-material/ArrowLeft";

import CreateIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import UploadIcon from "@mui/icons-material/Upload";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import FingerPrintIcon from "@mui/icons-material/PlayArrow";
import initialDocument from "../../../../assets/initialDocument.png";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  getUploadedFiles,
  uploadFile,
} from "../../../../services/FileUploadService";
import {
  buildOcr,
  deleteLabeledData,
  fieldSave,
  getLabeledData,
  saveLabeledData,
  trainOCR,
  updateLabeledData,
  uploadFieldsContent,
  uploadLabelsContent,
} from "../../../../services/ocr";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const useStylesx = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  p: 4,
};

var firstLoad = true;
var running = false;
var currentDocId = -1;
var runAll = false;
var fieldsTags;
export function LabelingDocPlace(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [docTypeID, setDocTypeID] = React.useState("");
  const [regions, setRegions] = React.useState([]);
  const [document, setDocument] = useState("");
  const [startPage] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [labels, setLabels] = useState([]);
  const [fields, setFields] = useState([]);
  const [current, setCurrent] = useState("Not selected");
  const [documents, setDocuments] = useState([]);
  const [documentsComplete, setDocumentsComplete] = useState([]);

  const [loadingText, setLoadingText] = useState("Loading documents");
  const [deleteLabels, setDeleteLabels] = useState(false);
  const [deleteOCR, setDeleteOCR] = useState(false);
  const [counter, setCounter] = useState(0);
  const [currentLabelIndex, setCurrentLabelIndex] = useState(0);

  const [enableTrain, setEnableTrain] = useState(false);

  const [theDoc, setTheDoc] = useState({});

  const [updating, setUpdating] = useState(false);

  const [ocrShow, setOcrShow] = useState(false);

  const [ocrStatus, setOcrStatus] = useState(false);

  const [openSave, setOpenSave] = useState(false);

  const [doMove, setDoMove] = useState(false);

  const [currentDocument, setCurrentDocument] = useState(initialDocument);

  const [pages, setPages] = useState([]);

  const [saving, setSaving] = useState(true);

  /* Pager */

  const [totalPages, setTotalPages] = useState();

  //Contains all regions
  const [allRegions, setAllRegions] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [colorArray, setColorArray] = useState([
    "230, 25, 75",
    "60, 180, 75",
    "255, 225, 25",
    "0, 130, 200",
    "245, 130, 48",
    "145, 30, 180",
    "70, 240, 240",
    "240, 50, 230",
    "210, 245, 60",
    "250, 190, 212",
    "0, 128, 128",
    "220, 190, 255",
    "170, 110, 40",
    "255, 250, 200",
    "128, 0, 0",
    "170, 255, 195",
    "128, 128, 0",
    "255, 215, 180",
    "0, 0, 128",
    "128, 128, 128",
    "255, 255, 255",
    "0, 0, 0",
  ]);

  const handleClose = () => {
    setOpen(false);
    setOcrShow(false);
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const classesx = useStylesx();

  const [trainResult, setTrainResult] = React.useState([]);

  useEffect(() => {
    if (startPage) {
      //@params props.documentTypeID
      setLoading(true);
      getLabeledData(props.documentTypeID, (event) => { })
        .then((response) => {
          let itupdating = !isEmpty(response.data);

          if (itupdating) {
            let data = response.data;
            //console.log("Saved data already exists");
            //setLabels(response.data.labels)
            setRegions([]);

            setAllRegions(data.allRegions);
            setFields(data.labels);

            setDocument("");
            setCurrentDocument(initialDocument);
            setCurrent("Not selected");
            setDocuments(data.documents);

            setDocumentsComplete(data.documentsComplete);

            currentDocId = -1;
            setCounter(data.counter);
            setCurrentLabelIndex(data.currentLabelIndex);
            //setTheDoc(data.theDoc);

            if (data.documents.length == 0) {
              toast.warning(
                "There aren't saved documents to train the data. If you think this is an error, contact the administrator."
              );
            }
            let enable = false;
            let tempDocs = data.documents;
            for (var i = 0; i < tempDocs.length; i++) {
              enable = tempDocs[i].ocr;

              if (enable == false) break;
            }
            setEnableTrain(enable);
            setUpdating(true);
          }
          //No data, then get files.
          else {
            //console.log("No saved data");
            getUploadedFiles(true, props.documentTypeID, (event) => { })
              .then((response) => {
                setUpdating(false);
                let sampleFiles = response.data.sampleFiles;
                if (sampleFiles && sampleFiles.length > 0) {
                  let allRegionsSlots = 0;

                  sampleFiles.forEach((file) => {
                    //In case pages is null
                    if (file.pages) {
                      allRegionsSlots = allRegionsSlots + file.pages.length;
                    } else {
                      allRegionsSlots = allRegionsSlots + 1;
                    }
                  });

                  let docArray = new Array(allRegionsSlots).fill([]);

                  setAllRegions(docArray);

                  let allTheFiles = [];
                  let absoluteIndex = 0;
                  let parentIndex = 0;
                  let parentFileName = "";
                  let parentPath = "";
                  let originalFileName = "";

                  sampleFiles.forEach(function (itm) {
                    parentFileName = itm.fileName;
                    originalFileName = itm.fileOriginalName;
                    parentPath = itm.path;
                    itm.index = parentIndex;
                    itm.ocr = false;
                    itm.originalFileName = originalFileName;

                    itm.pages.forEach(function (itemPage) {
                      itemPage.ocr = false;
                      itemPage.parentIndex = parentIndex;

                      itemPage.absoluteIndex = absoluteIndex;
                      itemPage.parentFileName = parentFileName;
                      itemPage.parentPath = parentPath;
                      itemPage.originalFileName = originalFileName;

                      absoluteIndex++;
                      allTheFiles.push(itemPage);
                    });

                    parentIndex++;
                  });
                  setDocuments(allTheFiles);
                  setDocumentsComplete(sampleFiles);
                  //console.log("allThePages", allTheFiles);
                  //console.log("allTheDocuments", sampleFiles);
                  setDocument(sampleFiles[0].thumbnail);
                  setCurrentDocument(initialDocument);
                  setRegions([]);
                  currentDocId = -1;
                } else {
                  toast.warning(
                    "There aren't saved documents to train the data. If you think this is an error, contact the administrator."
                  );
                }


              })
              .catch((err) => {
                //notify(err , 'error');
                //setLoading(false);
              });
          }
        })
        .catch((error) => {
          setUpdating(false);
        })
        .finally(() => {
          setLoading(false);
        });

      //setLoading(false);

      firstLoad = false;
      if (props.documentTypeID) {
        setDocTypeID(props.documentTypeID);
      }
    }
  }, [startPage, props.documentTypeID]);
  useEffect(() => {
    try {
      let allRegionsTemp = [...allRegions];
      if (
        regions.length == 0 ||
        currentDocId == -1 ||
        allRegionsTemp.length > documents.length ||
        currentDocId >= documents.length
      ) {
        //console.log("Attempted to set regions to empty array or document", regions.length, allRegionsTemp.length, documents.length);
      } else {
        allRegionsTemp[currentDocId] = regions;
        setAllRegions(allRegionsTemp);
        //       console.log("***********************");
        //        console.log("currentDocId--->" + currentDocId);

        //        console.log("allRegions", allRegionsTemp);
        // console.log("Regions", regions);
        //       console.log("***********************");
      }
    } catch (error) {
      console.log("Error creating regions from OCR: " + error);
    }
  }, [regions]);

  const deleteRegion = (label) => {
    setSaving(false);
    resetRegionByLabel(label);
    if (label == current) {
      setCurrent("Not selected");
    }
  };

  const resetRegionByLabel = (label) => {
    let data;
    allRegions.map((iRegions) => {
      for (let item in iRegions) {
        if (iRegions[item].data.label == label) {
          data = iRegions[item].data;
          data.label = "";
          data.regionStyle = regionStyleStart;
          iRegions[item].data = data;
        }
      }
    });
  };
  const onChange = (regions) => {
    setRegions(regions);
  };
  const deleteText = (text, xlabel) => {
    setSaving(false);
    let nowRegions = [];
    let index = 0;
    allRegions.map((iRegions) => {
      if (index === currentDocId) {
        for (let item in iRegions) {
          //find Label
          let data = iRegions[item].data;
          if (typeof data.label !== "undefined") {
            if (data.label == xlabel && data.text === text) {
              data.label = "";
              data.regionStyle = regionStyleStart;
              iRegions[item].data = data;
            }
          }
        }
      }
      nowRegions.push(iRegions);
      index++;
    });

    setAllRegions(nowRegions);
  };
  const deleteRegionById = (id) => {
    let temp = [...allRegions];
    temp[currentDocId].splice(id, 1); // 2nd parameter means remove one item only
    setAllRegions(temp);
  };
  const selectRegion = (event, id, data, type) => {
    switch (event.detail) {
      case 1: {
        setSaving(false);
        //let regionP = regions[id];
        let regionP = [...regions];

        if (current !== "Not selected" && document !== "") {
          regionP[id].data = {
            label: current,
            text: regionP[id].data.text,
            boundingBoxes: regionP[id].data.boundingBoxes,
            width: regionP[id].data.width,
            height: regionP[id].data.height,
            pageNumber: regionP[id].data.pageNumber,
            parentFileName: regionP[id].data.parentFileName,
            regionStyle: regionStyle,
          };

          setRegions(regionP);
        } else {
          toast.warning(
            "To start labeling, select a label and a document first."
          );
        }
        break;
      }
      case 2: {
        if (type === 1) {
          const MySwal = withReactContent(Swal);
          MySwal.fire({
            title: "Are you sure you want to remove the created label?",
            text: "",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
          }).then((willDelete) => {
            if (willDelete.value) {
              deleteRegionById(id);
            }
          });
        }

        break;
        break;
      }
      case 3: {
        console.log("triple click");
        break;
      }
      default: {
        break;
      }
    }
  }; //cmx
  const createRegion = (label) => {
    setCounter(counter + 1);

    setRegions((regions) => [
      ...regions,
      {
        x: 10,
        y: 10,
        type: 1,
        width: 10,
        height: 6,
        data: {
          text: "empty-" + counter,
          boundingBox: [],
          boundingBoxes: [],
          width: 10,
          height: 6,
          label: label,
          pageNumber: 1,
          regionStyle: {
            background: "rgb(0,255,20,20%)",
          },
        },
      },
    ]);

    let temp = allRegions;
    temp[currentDocId] = regions;
    setAllRegions(temp);
  };

  const createLabels = () => {
    var labelJson = [];
    var fieldsJson = [];
    var fieldsJsonAll = [];

    var tWidth = 0;
    var tHeight = 0;
    setSaving(false);
    setLoadingText("Training DocType");
    setLoading(true);
    let promises = [];

    // Un  labelJson por cada document en documentsComplete

    documentsComplete.map((page, currentIndex) => {
      labelJson = [];
      //console.log("allRegions", allRegions);
      allRegions.map((iRegion, index) => {
        fieldsJson = [];
        tWidth = 0;
        tHeight = 0;

        if (!isEmpty(iRegion)) {
          let xdata;
          let ydata;
          for (let item in labels) {
            // if (item > 0) {

            let findLabel = iRegion.filter(
              (reg) => reg.data.label === labels[item].label
            );
            // console.log("findLabel", findLabel)
            if (!isEmpty(findLabel)) {
              try {
                //We just need the width and height once.
                if (tWidth == 0) {
                  tWidth = findLabel[0].data.width;
                  tHeight = findLabel[0].data.height;
                  xdata = tWidth / 100;
                  ydata = tHeight / 100;
                }

                //Adding labels
                let temp = [];
                let type = 0;
                findLabel.map((i) => {
                  //Type 1 has been added after OCR, hence need to calc the boundingboxes

                  if (i.type == 1) {
                    type = 1;
                    let rect = i;

                    temp.push({
                      page: i.data.pageNumber + 1,
                      text: i.data.text,
                      parentFileName: i.data.parentFileName,
                      boundingBoxes: [
                        [
                          (rect.x * xdata) / 100,
                          (rect.y * ydata) / 100,
                          (rect.x * xdata + rect.width * xdata) / 100,
                          (rect.y * ydata) / 100,
                          (rect.x * xdata + rect.width * xdata) / 100,
                          (rect.y * ydata + rect.height * ydata) / 100,
                          (rect.x * xdata) / 100,
                          (rect.y * ydata + rect.height * ydata) / 100,
                        ],
                      ],
                    });
                  } else {
                    temp.push({
                      page: i.data.pageNumber + 1,
                      parentFileName: i.data.parentFileName,
                      text: i.data.text,
                      boundingBoxes: [
                        [
                          i.data.boundingBoxes[0] / tWidth,
                          i.data.boundingBoxes[1] / tHeight,
                          i.data.boundingBoxes[2] / tWidth,
                          i.data.boundingBoxes[3] / tHeight,
                          i.data.boundingBoxes[4] / tWidth,
                          i.data.boundingBoxes[5] / tHeight,
                          i.data.boundingBoxes[6] / tWidth,
                          i.data.boundingBoxes[7] / tHeight,
                        ],
                      ],
                    });
                  }
                });

                if (type == 1) {
                  if (page.fileName == temp[0].parentFileName) {
                    labelJson.push({
                      label: labels[item].label
                        .replace(/\W+/g, "_")
                        .toLowerCase(),
                      value: temp,
                      labelType: "region",
                    });
                  }
                } else {
                  if (page.fileName == temp[0].parentFileName) {
                    labelJson.push({
                      label: labels[item].label
                        .replace(/\W+/g, "_")
                        .toLowerCase(),
                      value: temp,
                    });
                  }
                }
              } catch (error) {
                console.log(
                  "No values for this label: " + labels[item].label + error
                );
              }
            }
          }
        }
      });
      // console.log("Document: " + currentIndex + "  ", page.fileOriginalName, page.fileName);
      // console.log(labelJson)
      let labelsJSN = {
        $schema:
          "https://schema.cognitiveservices.azure.com/formrecognizer/2021-03-01/labels.json",
        document: page.fileName,
        labels: labelJson,
      };

      let p = new Promise((resolve, reject) => {
        uploadLabelsContent(
          true,
          props.documentTypeID,
          page.fileName,
          labelsJSN,
          (event) => { }
        )
          .then((response) => {
            resolve("OK");
          })
          .catch(() => {
            reject("Failed");
            toast.warning("Could not upload the file");
          });
      });
      promises.push(p);
    });

    fieldsJson = [];

    labelJson.map((labelMappedI) => {
      labels.map((labelI) => {
        if (
          labelMappedI.label === labelI.label.replace(/\W+/g, "_").toLowerCase()
        ) {
          fieldsJsonAll.push({
            fieldKey: labelI.label.replace(/\W+/g, "_").toLowerCase(),
            fieldType: labelI.type,
            fieldLabel: labelI.label,
            fieldFormat: labelI.fmt,
            origin: "ocr",
            optional: labelI.optional,
          });

          fieldsJson.push({
            fieldKey: labelI.label.replace(/\W+/g, "_").toLowerCase(),
            fieldType: labelI.type,
            fieldFormat: labelI.fmt,
            optional: labelI.optional,
          });
        }
      });
    });
    fieldsTags = fieldsJsonAll;

    labels.map((labelMappedI) => {
      let find = false;
      labelJson.map((labelI) => {
        if (
          labelMappedI.label.replace(/\W+/g, "_").toLowerCase() === labelI.label
        ) {
          find = true;
        }
      });

      if (!find) {
        fieldsJsonAll.push({
          fieldKey: labelMappedI.label.replace(/\W+/g, "_").toLowerCase(),
          fieldLabel: labelMappedI.label,
          fieldType: labelMappedI.type,
          fieldFormat: labelMappedI.fmt,
          origin: "custom",
        });
        find = false;
      }
    });

    let fieldsJSN = {
      $schema:
        "https://schema.cognitiveservices.azure.com/formrecognizer/2021-03-01/fields.json",
      fields: fieldsJson,
      definitions: {},
    };

    let p = new Promise((resolve, reject) => {
      uploadFieldsContent(true, props.documentTypeID, fieldsJSN, (event) => {
        //
      })
        .then((response) => {
          resolve("OK");
        })
        .catch((error) => {
          console.log("ERROR");
          reject("Failed");
        });
    });
    promises.push(p);

    Promise.all(promises)
      .then((success) => {
        setLoading(true);
        trainOCR(props.documentTypeID, [], (event) => {
          // cmx
        })
          .then((response) => {
            saveLabels();
            // console.log("OK --->", response)
            setTimeout(() => {
              setLoading(false);
              saveFields(fieldsJsonAll, props.documentTypeID);

              toast.success("DocType has been successfully trained");
              setTrainResult(response.data);
              setOcrShow(true);
            }, 6000);
          })
          .catch((error) => {
            setOcrShow(true);
            //console.log("Error:::", error)
            toast.error("DocType has error. Please fix and try again.");
          })
          .finally(() => {

          });

        //OUTPUT
      }) // never execute
      .catch((error) => {
        toast.error("DocType has error. Please fix and try again.");
        console.log(`Error ${error}`);
        setLoading(false);
      });
  };
  const mapLabel = (
    x,
    y,
    w,
    h,
    label,
    bb,
    width,
    height,
    box,
    pageNumber,
    parentFileName
  ) => {
    const xdata = width / 100;
    const ydata = height / 100;
    setRegions((regions) => [
      ...regions,
      {
        x: x / xdata,
        y: y / ydata,
        width: w / xdata - x / xdata,
        height: h / ydata - y / ydata,
        type: 0,
        data: {
          text: label,
          boundingBox: bb,
          boundingBoxes: box,
          width: width,
          height: height,
          pageNumber: pageNumber,
          parentFileName: parentFileName,
          label: "",
          regionStyle: {
            background: "rgba(0,106,198, 20%)",
          },
        },
      },
    ]);
  };

  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      return <div></div>;
    }
  };

  const saveFields = (fields, doctypeId) => {
    let body = fields;

    fieldSave(doctypeId, body, (event) => { })
      .then((response) => {
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error saving the fields: " + err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const regionStyleStart = {
    background: "rgba(0,106,198, 20%)",
  };
  const regionStyle = {
    background: "rgba(253,255,50, 20%)",
  };

  useEffect(() => {
    if (trainResult && trainResult.status == "succeeded") {
      let sd = Object.keys(trainResult.result.docTypes)[0];
      setOcrStatus(trainResult.result.docTypes[sd].fieldConfidence);
    }
  }, [trainResult]);
  const runOCR = () => {
    let doc = documentsComplete[0];
    // console.log(documentsComplete)
    //setAllRegions([]);
    setRegions([]);
    runAll = true;
    function callback(index) {
      //console.log("Next", index)
      doc = documentsComplete[index];
      getDocumentOCR(doc, callback, index);
    }
    getDocumentOCR(doc, callback, 0);
  };
  function replaceWithBr(fileOriginalName) {
    return fileOriginalName.replace(/\n/g, "<br />");
  }

  const getDocumentOCR = (document, callback, count) => {
    let pageNumber = 0;
    let _document;
    let _documents;

    let doc = document.pages;
    //console.log(document, doc)

    if (runAll == true) {
      setDeleteLabels(false);
      setDeleteOCR(false);
      setSaving(false);

      setLoadingText(
        <p
          dangerouslySetInnerHTML={{
            __html:
              replaceWithBr(`Processing document [${document.originalFileName}] 
      \nThis might take up to 5 minutes per document depending on the number of pages`),
          }}
        />
      );

      //console.log("INDEXES", document);

      setRegions([]);

      //RUN OCR AND ADD THE REGION TO ALL REGIONS
      if (document.ocr === false) {
        let body = {
          doctypeId: props.documentTypeID,
          fileName: document.fileName,
          fileUrl: document.path,
        };
        setLoading(true);

        buildOcr(true, body, (event) => { })
          .then((response) => {
            let data = response.data;
            let temp;

            //Create regions as pages has the document

            data.analyzeResult.pages.map((page) => {
              currentDocId = doc[pageNumber].absoluteIndex;
              page.words.map((i) => {
                temp = i.polygon;
                let bb = [
                  {
                    a: { x: temp[0], y: temp[1] },
                    b: { x: temp[2], y: temp[3] },
                    c: { x: temp[4], y: temp[5] },
                    d: { x: temp[6], y: temp[7] },
                  },
                ];
                mapLabel(
                  temp[0],
                  temp[1],
                  temp[4],
                  temp[7],
                  i.content,
                  bb,
                  page.width,
                  page.height,
                  temp,
                  pageNumber,
                  document.fileName
                );
              });
              _document = documents[document.index + pageNumber];
              _document.ocr = true;
              //console.log("Setting ocr to true", _document)
              /************* Update all pages in documents *************/
              _documents = documents;
              _documents[doc.absoluteIndex] = _document;

              //Update

              pageNumber++;
              setRegions([]);
            });

            //Setting the ocr info for this document

            setDocuments(_documents);
            /************* Update all Documents *************/
            let tempDocs = documentsComplete;
            tempDocs[document.index].ocr = true;
            //console.log("DOCUMENTS COMPLETE", tempDocs)
            tempDocs[document.index].pages.forEach((page, i) => {
              page.ocr = true;
            });
            //Update
            setDocumentsComplete(tempDocs);
          })
          .catch((err) => {
            console.log("Error: " + err);
            runAll = false;
          })
          .finally(() => {
            //console.log("OCR finished? ", runAll);
            if (runAll == true) {
              count++;
              //console.log("OCR continue!" + count + " of " + documentsComplete.length);
              if (count < documentsComplete.length) {
                callback(count);
              }
              if (count == documentsComplete.length) {
                runAll = false;
                setLoading(false);
              }
            } else {
              //ENTRACA

              setLoading(false);
            }
            //console.log("OCR finished! NETRA")

            //Enable train button
            let enable = false;
            for (var i = 0; i < documentsComplete.length; i++) {
              enable = documentsComplete[i].ocr;
              if (enable == false) break;
            }
            //console.log("enable", enable)
            setEnableTrain(enable);
            //console.log("--------------------");
          });
      } else {
        if (runAll == true) {
          count++;
          //console.log("OCR continue!" + count + " of " + documentsComplete.length);
          if (count < documentsComplete.length) {
            callback(count);
          }
          if (count == documentsComplete.length) {
            runAll = false;
            setLoading(false);
          }
        }
      }
    }
    //setRegions([]);
    //setRegions(allRegions[id]);
    //currentDocId = id;
    //setDocument(document);
    //setCurrentDocument(document);
  };
  const getDocument = (
    document,
    id,
    fileName,
    filePath,
    ocr,
    doc,
    fileOriginalName,
    pageNumber,
    parentFileName,
    callback,
    count,
    currentPageIndex
  ) => {
    setCurrentPage(pageNumber + 1);

    if (ocr == false && pageNumber == 0) {
      setDeleteLabels(false);
      setDeleteOCR(false);
      setSaving(false);

      setLoadingText(
        <p
          dangerouslySetInnerHTML={{
            __html:
              replaceWithBr(`Processing document [${doc.originalFileName}] 
      \nThis might take up to 5 minutes per document depending on the number of pages`),
          }}
        />
      );

      currentDocId = id;

      //console.log("INDEXES", currentDocId, doc.absoluteIndex);

      setRegions([]);

      //RUN OCR AND ADD THE REGION TO ALL REGIONS
      let body = {
        doctypeId: props.documentTypeID,
        fileName: doc.parentFileName,
        fileUrl: doc.parentPath,
      };
      setLoading(true);
      buildOcr(true, body, (event) => { })
        .then((response) => {
          let data = response.data;
          let temp;

          //Create regions as pages has the document

          data.analyzeResult.pages.map((page) => {
            page.words.map((i) => {
              temp = i.polygon;
              let bb = [
                {
                  a: { x: temp[0], y: temp[1] },
                  b: { x: temp[2], y: temp[3] },
                  c: { x: temp[4], y: temp[5] },
                  d: { x: temp[6], y: temp[7] },
                },
              ];
              mapLabel(
                temp[0],
                temp[1],
                temp[4],
                temp[7],
                i.content,
                bb,
                page.width,
                page.height,
                temp,
                pageNumber,
                doc.parentFileName
              );
            });
            pageNumber++;
            currentDocId = currentDocId + 1;

            setRegions([]);
          });

          //currentDocId = doc.absoluteIndex;
          //console.log(currentDocId, doc.absoluteIndex)
          //Setting the ocr info for this document
          let tempDoc = documents[doc.absoluteIndex];
          tempDoc.ocr = true;

          for (i = 0; i < pageNumber; i++) {
            tempDoc = documents[doc.absoluteIndex + i];
            tempDoc.ocr = true;
          }

          /************* Update all pages in documents *************/
          let tempDocs = documents;
          tempDocs[doc.absoluteIndex] = tempDoc;

          //Update
          setDocuments(tempDocs);

          /************* Update all Documents *************/
          tempDocs = documentsComplete;
          tempDocs[doc.parentIndex].ocr = true;
          //console.log("DOCUMENTS COMPLETE", tempDocs)
          tempDocs[doc.parentIndex].pages.forEach((page, i) => {
            page.ocr = true;
          });

          //Update
          setDocumentsComplete(tempDocs);

          //Enable train button
          let enable = false;
          for (var i = 0; i < tempDocs.length; i++) {
            enable = tempDocs[i].ocr;
            if (enable == false) break;
          }
          setEnableTrain(enable);
        })
        .catch((err) => {
          console.log("Error: " + err);
          let tempDoc = documents[doc.absoluteIndex];
          tempDoc.ocr = false;
          let tempDocs = documents;
          tempDocs[doc.absoluteIndex] = tempDoc;
          setDocuments(tempDocs);
          toast.error("Error running OCR for file: " + fileName);
        })
        .finally(() => {
          //console.log("OCR finished!");
          setLoading(false);
        });
    }
    setRegions([]);
    setRegions(allRegions[id]);
    currentDocId = id;
    setDocument(document);
    setCurrentDocument(document);

    setTotalPages(documentsComplete[doc.parentIndex].pages.length);
  };

  const selectLabel = (label, i) => {
    setSaving(false);
    setCurrentLabelIndex(i);
    setCurrent(label);
  };

  const getLabels = (labels, added) => {
    if (added) {
      setSaving(false);
    }
    setLabels(labels);
  };

  const deleteData = (opt) => {
    setSaving(false);
    setLoadingText("Deleting data.");
    setLoading(true);

    deleteLabeledData(props.documentTypeID, (event) => { })
      .then((response) => { })
      .catch((error) => {
        toast.error("Error deleting the data: " + error);
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
        window.location.reload(false);
      });
  };
  const closeLabels = () => {
    if (saving === false) {
      setOpenSave(true);
    } else {
      navigate("/documentType");
    }
  };

  const saveAndClose = () => {
    saveLabels(null, null, true);
  };

  /* Persist data */
  const saveLabels = (docdata, docArray, close, reload, docComplete) => {
    setLoadingText("Saving data");
    setLoading(true);
    let _documents, _allRegions, _documentsComplete;
    if (docdata) {
      _documents = docdata;
      _allRegions = docArray;
      _documentsComplete = docComplete;
    } else {
      _documents = [...documents];
      _allRegions = [...allRegions];
      _documentsComplete = [...documentsComplete];
    }

    let _document = document;

    if (document == "") {
      setDocument(_documents[0].thumbnail);
      _document = _documents[0].thumbnail;
    }
    let data = {
      region: regions,
      allRegions: _allRegions,
      labels: labels,
      document: _document,
      current: current,
      documents: _documents,
      currentDocId: currentDocId,
      counter: counter,
      documentsComplete: _documentsComplete,
      currentLabelIndex: currentLabelIndex,
      doc: theDoc,
    };

    if (updating === true) {
      updateLabeledData(props.documentTypeID, data, (event) => { })
        .then((response) => {
          toast.success("DocType changes has been updated");
          if (close) {
            navigate("/documentType");
          }
          setSaving(true);
        })
        .catch((error) => {
          const msj = error?.response?.data?.error?.messages
            .map((value) => value.message)
            .join(",");
          toast.error(msj ? msj : "Error updating the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          if (reload) {
            window.location.reload(false);
          }
        });
    } else {
      saveLabeledData(props.documentTypeID, data, (event) => { })
        .then((response) => {
          setUpdating(true);
          toast.success("DocType changes have been saved");
          if (close) {
            navigate("/documentType");
          }
          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error saving the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          if (reload) {
            window.location.reload(false);
          }
        });
    }
  };

  var docArray = [];
  var allDocuments = [];
  var theDocuments = [];
  var theDocumentsComplete = [];
  var theDocArray = [];
  const uploadTheFile = (files, current, max) => {
    let docIndex = theDocuments.length;
    let parentFileName, parentPath;
    if (current <= max) {
      setLoading(true);
      setLoadingText("Uploading document");
      uploadFile(files[current], true, docTypeID, (event) => { })
        .then((response) => {
          let uploadedFile = response.data;
          uploadedFile.ocr = false;
          theDocuments.push({
            doctypeId: uploadedFile.doctypeId,
            fileName: uploadedFile.fileName,
            fileOriginalName: uploadedFile.fileOriginalName,
            originalFileName: uploadedFile.fileOriginalName,
            ocr: false,
            page: 0,
            index: docIndex,
            pages: uploadedFile.pages,
            path: uploadedFile.path,
            thumbnail: uploadedFile.thumbnail,
          });
          /* */
          let allRegionsSlots = 0;
          parentFileName = uploadedFile.fileName;
          parentPath = uploadedFile.path;
          allRegionsSlots =
            allRegionsSlots + theDocuments[docIndex].pages.length;
          let allTheFiles = [];
          let absoluteIndex = 0;
          theDocuments.forEach(function (itm) {
            itm.pages.forEach(function (itemPage) {
              //itemPage.ocr = false;
              itemPage.absoluteIndex = absoluteIndex;
              if (!itemPage.parentFileName) {
                console.log("Adding parentFileName to page");
                itemPage.parentFileName = parentFileName;
                itemPage.parentPath = parentPath;
                itemPage.parentIndex = docIndex;
                itemPage.originalFileName = uploadedFile.fileOriginalName;
                itemPage.ocr = false;
                theDocArray.push([]);
              }
              absoluteIndex++;
              currentDocId = absoluteIndex;
              allTheFiles.push(itemPage);
            });
          });
          theDocumentsComplete = allTheFiles;
          toast.success(
            files[current].name + ": has been uploaded succesfully.",
            "success"
          );
        })
        .catch((err) => {
          toast.error("Error uploading the document: ", err);
        })
        .finally((err) => {
          setLoading(false);
          let nextCurrent = current + 1;
          //Save data

          if (nextCurrent > max) {
            //setDocuments(allTheFiles);
            //setDocumentsComplete(theDocuments);
            setDocuments(theDocumentsComplete);
            setDocumentsComplete(theDocuments);
            setAllRegions(theDocArray);
            setRegions([]);
            saveLabels(
              theDocumentsComplete,
              theDocArray,
              null,
              true,
              theDocuments
            );
          }
          uploadTheFile(files, nextCurrent, max);
        });
    } else {
      console.log("Done uploading");
    }
  };
  const handleUpload = (e) => {
    //let reader = new FileReader();
    setSaving(false);
    const files = e.target.files;
    theDocuments = [];
    theDocumentsComplete = [];
    theDocArray = [];
    if (files.length > 0) {
      theDocuments = [...documentsComplete];
      theDocumentsComplete = [...documents];
      theDocArray = [...allRegions];

      uploadTheFile(files, 0, files.length - 1);
    }
  };
  const move = (currentIndex) => {
    setCurrentPage(currentIndex + 1);
  };
  const change = (direction) => {
    //(currentPage+direction);
  };
  const getLabel = (item) => {
    let result = "";
    let res = fieldsTags.filter(function (data) {
      return data.fieldKey == item;
    });
    if (res.length > 0) {
      result = res[0].fieldLabel;
    } else {
      result = item;
    }
    return result;
  };
  /*  useEffect(() => {
    //console.log("useEffect", enableTrain);
  }, [enableTrain]);*/
  return (
    <>
      <LoadingComponent show={loading} text={loadingText} />

      {
        // Train OCR MODAL
      }

      <Modal
        open={ocrShow}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            DocType OCR result: {trainResult.status}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            The higher the estimated accuracy (highest value: 1) for each field,
            the better results the OCR will get when processing new documents
            for this DocType. If the score is low, try uploading another
            document and run the OCR again.
          </Typography>
          {trainResult && trainResult.status == "failed" ? (
            <div className="errorMessage">
              {trainResult.error.details[0].message}
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Estimated accuracy</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(ocrStatus).map(function (element, i) {
                  return (
                    <tr key={i}>
                      <td>{getLabel(element)}</td>
                      <td>{ocrStatus[element].toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <Button
            variant="contained"
            color="info"
            onClick={() => setOcrShow(false)}
            sx={{ mt: 2 }}
          >
            OK
          </Button>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reset data
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action will reset all labeled data for all the scanned
            documents. It will also remove all fields created. <br />
            <br /> Are you sure you want to continue?
          </Typography>

          <Button
            variant="contained"
            color="info"
            onClick={() => setOpen(false)}
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={() => deleteData(0)}
            sx={{ mt: 2, float: "right" }}
          >
            Yes Delete
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openSave}
        onClose={() => setOpenSave(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Unsaved data
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            There are changes in the DocType that have not been saved. <br />
          </Typography>
          <Button
            variant="contained"
            color="info"
            onClick={() => saveAndClose()}
            sx={{ mt: 2, marginRight: 2 }}
          >
            Save and Close
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              navigate("/documentType");
            }}
            sx={{ mt: 2, marginRight: 2 }}
          >
            Discard Changes
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpenSave(false);
            }}
            sx={{ mt: 2, float: "right" }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <WrapperLabeling>
        <Wrapper>
          <Grid container className="toolbarLabeling">
            <Grid item xs={2} md={2}>
              <input
                accept=""
                className={classesx.input}
                id="contained-button-file"
                multiple
                onChange={handleUpload}
                type="file"
              />
              <label htmlFor="contained-button-file">
                <IconButton
                  title="Upload document"
                  variant="contained"
                  size="small"
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  color="primary"
                  component="span"
                >
                  <UploadIcon />
                </IconButton>
              </label>
              <IconButton
                title="Run OCR on all documents"
                variant="contained"
                size="small"
                onClick={runOCR}
                style={{ marginRight: "10px" }}
                color="primary"
                component="span"
              >
                <AutoAwesomeMotionIcon />
              </IconButton>
              <IconButton
                disabled={saving}
                color="primary"
                style={{ marginRight: "10px" }}
                title="Save"
                aria-label="Save"
                component="label"
                onClick={() => saveLabels()}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                onClick={() => setOpen(true)}
                color="error"
                title="Reset data"
                style={{ marginRight: "10px" }}
                aria-label="Delete "
                component="label"
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8} md={8} sx={{ textAlign: "center" }}>
              {currentDocument !== initialDocument && (
                <span
                  className={"pager"}
                  sx={{ zIndex: "99999", fontSize: "10px" }}
                >
                  <IconButton
                    onClick={() => change(-1)}
                    color="success"
                    sx={{ zIndex: "99999", display: "none" }}
                    aria-label="Move left"
                    title="Left"
                  >
                    <ArrowLeft />
                  </IconButton>
                  {currentPage} of {totalPages}
                  <IconButton
                    onClick={() => change(1)}
                    color="success"
                    sx={{ zIndex: "99999", display: "none" }}
                    aria-label="Move right"
                    title="Right"
                  >
                    <ArrowRight />
                  </IconButton>
                </span>
              )}
            </Grid>
            <Grid item xs={2} md={2} style={{ paddingRight: "20px" }}>
              {enableTrain == true && (
                <Button
                  title="Train Document Type"
                  variant="contained"
                  size="small"
                  onClick={createLabels}
                  style={{ marginLeft: "10px", width: "100%" }}
                  color="success"
                  component="span"
                >
                  <FingerPrintIcon /> Train Doctype
                </Button>
              )}
            </Grid>
          </Grid>
        </Wrapper>
        <Grid container>
          <Grid className={classes.wrapperAside} item xs={2} md={2}>
            <DocumentListDocPlace
              documents={documentsComplete}
              deleteOCR={deleteOCR}
              getDocument={getDocument}
              move={move}
            />
          </Grid>
          <Grid className={classes.wrapperAside} item xs={8} md={8}>
            <div className="documentShowcase">
              <ReactRegion
                maxRegions={1000}
                regions={regions}
                onChange={onChange}
                regionStyle={regionStyle}
                regionRenderer={regionRenderer}
                constraint
                onClick={selectRegion}
              >
                <img
                  style={{ width: "100%", pointerEvents: "none" }}
                  src={currentDocument}
                  alt="Current document"
                />
              </ReactRegion>
            </div>
          </Grid>
          <Grid className={classes.wrapperAside} item xs={2} md={2}>
            <div style={{ padding: "10px" }}>
              <Button
                color="primary"
                aria-label="Create an empty label in this page"
                component="label"
                onClick={createRegion}
                style={{
                  marginBottom: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
                variant="contained"
                size="small"
                disabled={
                  current === "Not selected" ||
                  document === "" ||
                  currentDocId === -1
                }
              >
                <CreateIcon /> Add region
              </Button>
              <FieldsDocPlace
                colorArray={colorArray}
                tagKey={Math.random().toString(36).substr(2, 9)}
                regions={regions}
                docTypeID={props.documentTypeID}
                setFields={fields}
                deleteLabels={deleteLabels}
                deleteText={deleteText}
                getLabels={getLabels}
                selectLabel={selectLabel}
                deleteRegion={deleteRegion}
                move={move}
              />
            </div>
          </Grid>
        </Grid>
      </WrapperLabeling>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingTree: state.loadingFolders,
    tree: state.folders,
  };
};

export default connect(mapStateToProps, {})(LabelingDocPlace);
