import {
  REVIEWAL_LIST,
  REVIEWAL_LIST_SUCCESS,
  DOC_PERCENTAGE_LIST,
  DOC_PERCENTAGE_SUCCESS,
  DOC_SHARED_LIST,
  DOC_SHARED_SUCCESS,
  DOC_PD_LIST,
  DOC_PD_SUCCESS,
  API_ERROR_DOC_PD,
  API_ERROR_REVIEWAL,
  API_ERROR_DOC_PERCENTAGE,
  API_ERROR_DOC_SHARED,

} from "./actionTypes"

export const getReviewalList = (reviewal, navigate) => {
  return {
    type: REVIEWAL_LIST,
    payload: {reviewal, navigate},
  }
}

export const reviewalSuccess = reviewal => {
  return {
    type: REVIEWAL_LIST_SUCCESS,
    payload: reviewal,
  }
}

export const getDocPercentageList = (docPercentage, navigate) => {
  return {
    type: DOC_PERCENTAGE_LIST,
    payload: {docPercentage, navigate},
  }
}

export const docPercentageSuccess = docPercentage => {
  return {
    type: DOC_PERCENTAGE_SUCCESS,
    payload: docPercentage,
  }
}

export const getDocSharedList = (docShared, navigate) => {
  return {
    type: DOC_SHARED_LIST,
    payload: {docShared, navigate},
  }
}

export const docSharedSuccess = docShared => {
  return {
    type: DOC_SHARED_SUCCESS,
    payload: docShared,
  }
}

export const getDocPDList = (docPD, navigate) => {
  return {
    type: DOC_PD_LIST,
    payload: {docPD, navigate},
  }
}

export const docPDSuccess = docPD => {
  return {
    type: DOC_PD_SUCCESS,
    payload: docPD,
  }
}

export const apiErrorDocPD = error => {
  return {
    type: API_ERROR_DOC_PD,
    payload: error,
  }
}

export const apiErrorDocShared = error => {
  return {
    type: API_ERROR_DOC_SHARED,
    payload: error,
  }
}

export const apiErrorReviewal = error => {
  return {
    type: API_ERROR_REVIEWAL,
    payload: error,
  }
}

export const apiErrorDocPercentage = error => {
  return {
    type: API_ERROR_DOC_PERCENTAGE,
    payload: error,
  }
}
