import {
  ROL_LIST,
  ROL_LIST_SUCCESS,
  API_ERROR_ROL,

  ROL_POST,
  ROL_POST_SUCCESS,
  API_ERROR_ROL_POST,

  ROL_PUT,
  ROL_PUT_SUCCESS,
  API_ERROR_ROL_PUT,

  ROL_DELETE,
  ROL_DELETE_SUCCESS,
  API_ERROR_ROL_DELETE,


} from "./actionTypes"

const initialState = {
  errorRol: null,
  responseRol: null,
  loadingRol: false,

  errorRolPost: null,
  responseRolPost: null,
  loadingRolPost: false,

  errorRolPut: null,
  responseRolPut: null,
  loadingRolPut: false,

  errorRolDelete: null,
  responseRolDelete: null,
  loadingRolDelete: false,
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case ROL_LIST:
      state = {
        ...state,
        errorRol: null,
        loadingRol: true,
      }
      break
    case ROL_LIST_SUCCESS:
      state = {
        ...state,
        errorRol: null,
        responseRol:action.payload,
        loadingRol: false,
      }
      break
    case API_ERROR_ROL:
      state = { ...state,
        errorRol: action.payload,
        loadingRol: false,
        responseRol:null
      }
      break
    case ROL_POST:
      state = {
        ...state,
        errorRolPost: null,
        loadingRolPost: true,
      }
      break
    case ROL_POST_SUCCESS:
      state = {
        ...state,
        errorRolPost: null,
        responseRolPost:action.payload,
        loadingRolPost: false,
      }
      break
    case API_ERROR_ROL_POST:
      state = { ...state,
        errorRolPost: action.payload,
        loadingRolPost: false,
        responseRolPost:null
      }
      break
    case ROL_PUT:
      state = {
        ...state,
        errorRolPut: null,
        loadingRolPut: true,
      }
      break
    case ROL_PUT_SUCCESS:
      state = {
        ...state,
        errorRolPut: null,
        responseRolPut:action.payload,
        loadingRolPut: false,
      }
      break
    case API_ERROR_ROL_PUT:
      state = { ...state,
        errorRolPut: action.payload,
        loadingRolPut: false,
        responseRolPut:null
      }
      break
    case ROL_DELETE:
      state = {
        ...state,
        errorRolDelete: null,
        loadingRolDelete: true,
      }
      break
    case ROL_DELETE_SUCCESS:
      state = {
        ...state,
        errorRolDelete: null,
        responseRolDelete:action.payload,
        loadingRolDelete: false,
      }
      break
    case API_ERROR_ROL_DELETE:
      state = { ...state,
        errorRolDelete: action.payload,
        loadingRolDelete: false,
        responseRolDelete:null
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default user
