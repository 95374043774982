import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import objectAssign from 'object-assign';
import style from './styles';
import styles from './styles.css';
import TitleIcon from "@mui/icons-material/Title";
import ParagraphIcon from "@mui/icons-material/ViewHeadline";
import DateIcon from "@mui/icons-material/DateRange";
import TextIcon from "@mui/icons-material/TextFields";
import NumberIcon from "@mui/icons-material/FormatListNumbered";
import SelectIcon from "@mui/icons-material/Menu";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonIcon from "@mui/icons-material/RadioButtonChecked";
import SignatureIcon from "@mui/icons-material/Draw";
class Region extends Component {
	constructor(props) {
		super(props);
	}
	renderHandles() {
		return (
			<div>
				<div data-dir='se' style={style.RegionHandleSE} />
				<div data-dir='sw' style={style.RegionHandleSW} />
				<div data-dir='nw' style={style.RegionHandleNW} />
				<div data-dir='ne' style={style.RegionHandleNE} />
			</div>
		);
	}

	getInitials(string) {
		var names = string.split(' '),
			initials = names[0].substring(0, 1).toUpperCase();

		if (names.length > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	}
	 showType = (value) => {
		switch (value) {
			case 2:
			return "Paragraph";
		  case 3:
			return "Date";
		  case 4:
			return "Text";
		  case 5:
			return "Number";
		  case 6:
			return "List";
		  case 7:
			return "Checkbox";
		  case 8:
			return "Radio";
			case 9:
				return "Signature";
		  default:
			return "Text";
		}
	  };
	  showTypeIcon = (value) => {
		switch (value) {

		case 2:
				return <div style={{pointerEvents: 'none'}}><ParagraphIcon/></div> ;	
		  case 3:
			return <div style={{pointerEvents: 'none'}}><DateIcon/></div> ;
		  case 4:
			return <div style={{pointerEvents: 'none'}}><TextIcon /></div>;
		  case 5:
			return <div style={{pointerEvents: 'none'}}><NumberIcon /></div>;
		  case 6:
			return <div style={{pointerEvents: 'none'}}><SelectIcon /></div>;
		  case 7:
			return <div style={{pointerEvents: 'none'}}><CheckBoxIcon /></div>;
		  case 8:
			return <div style={{pointerEvents: 'none'}}><RadioButtonIcon /></div>;
			case 9:
				return <div style={{pointerEvents: 'none'}}><SignatureIcon /></div>;
		  default:
			return <div style={{pointerEvents: 'none'}}><TextIcon /></div>;

		}
	  };
	render() {
		const localStyle = {
			width: this.props.width + '%',
			height: this.props.height + '%',
			left: `${this.props.x}%`,
			top: `${this.props.y}%`,
			backgroundColor: 'rgba(0,0,0,0.3)',
		};
		const dataRenderArgs = {
			data: this.props.data,
			isChanging: this.props.changing,
			index: this.props.index
		};

		return (
			<>
			
			
					<div
						style={objectAssign({}, style.Region, localStyle, this.props.customStyle, this.props.data.regionStyle)}
						onClick={this.props.onClick}
						className={this.props.highlight ? 'highlighted' : 'docRegion'}
						onMouseDown={this.props.onCropStart}
						onTouchStart={this.props.onCropStart}
						onMouseEnter={this.props.onMouseEnter}
						onMouseLeave={this.props.onMouseLeave}
						data-wrapper="wrapper"
						title={ this.showType(this.props.data.label)}
					>{this.props.data.label.length > 0 ? <div style={{ fontSize: '12px', pointerEvents: 'none', textTransform: 'Uppercase' }}><b> {(this.props.data.label)}</b><br/><span style={{fontSize:'10px'}}>{(this.props.data.text)}</span> </div> : null}
						{this.renderHandles()}
						{this.showTypeIcon(this.props.data.label)}
						{this.props.dataRenderer ? this.props.dataRenderer(dataRenderArgs) : null}
					</div>
				
			
			</>

		);
	}
}
Region.propTypes = {
	x: PropTypes.number.isRequired,
	y: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	onCropStart: PropTypes.func.isRequired,
	onMouseEnter: PropTypes.func.isRequired,
	onMouseLeave: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
	handles: PropTypes.bool,
	changing: PropTypes.bool,
	dataRenderer: PropTypes.func,
	data: PropTypes.object,
	customStyle: PropTypes.object
};


export default Region;