import React, { useEffect, useState } from "react";
import Bookmark from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/material/IconButton';
export function DocumentListDocPlace(props) {

    const [documents, setDocuments] = useState([]);
    const [selected, setSelected] = useState();

    const [ocr, setOcr] = useState([]);

    var ocrDocs = []

    useEffect(() => {
        setDocuments(props.documents);
      
        props.documents.map((doc, i) => {
        
            if (doc.ocr == true) {
                //setSelected(doc.thumbnail);
                if (!ocr.includes(i)) {
                    setOcr((regions) => [
                        ...regions, i])
                }

            }
            
        })


    }, [props.documents]);

    useEffect(() => {
        if (props.deleteOCR === true) {
            setSelected('')
            setOcr([])
        }
    }, [props.deleteOCR]);

    const ocrDoc = (doc, id) => {
        if (!ocr.includes(id)) {
            setOcr((regions) => [
                ...regions, id])
        }

        setSelected(doc.thumbnail);
       
        props.getDocument(doc.thumbnail, id, doc.fileName, doc.path, doc.ocr,doc, doc.fileOriginalName)
    };

    return (
        <>
            <div className="documentList">
                {documents.map((doc, i) => (

                    <div
                        key={i}
                        style={{ width: '100%' }}
                        className={selected == doc.thumbnail ? 'documentSelected' : ''}
                        onClick={() => ocrDoc(doc, i)}
                    >

                        {
                            (ocr.includes(i) && props.documents[i].ocr == true) &&
                            <IconButton color="success" sx={{  marginTop: '0px', marginLeft: '-40px' }} aria-label="OCR" title="OCR has been applied to this document.">
                                <Bookmark />
                            </IconButton>


                        }
                        
                        <img className="docImage"  title={`Click to run OCR for ${doc.fileName}`} src={doc.thumbnail} alt={doc.fileName} />
                        <div className="documentTitle" ><span className="number" >{i+1}</span> - {doc.fileOriginalName}</div>
                    </div>

                ))}
            </div>
        </>
    )
}

export default DocumentListDocPlace

