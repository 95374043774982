import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    WrapperNew: {
        marginTop:'50px'
    }
}));


  



