import {
  DOCTYPE_LIST,
  DOCTYPE_LIST1,
  DOCTYPE_HEADER_POST,
  DOCTYPE_DELETE,
  DOCTYPE_DELETE_SUCCESS,
  DOCTYPE_UPDATE,
  DOCTYPE_UPDATE_SUCCESS,
  DOCTYPE_HEADER_SUCCESS,
  DOCTYPE_LIST_SUCCESS,
  DOCTYPE_LIST1_SUCCESS,
  DOCTYPE_UPLOAD_POST,
  DOCTYPE_UPLOAD_SUCCESS,
  DOCTYPE_ATTRIBUTES_LIST,
  DOCTYPE_ATTRIBUTES_LIST_SUCCESS,
  API_ERROR_DOCTYPE_ATTRIBUTES,
  API_ERROR_DOCTYPE_DELETE,
  API_ERROR_DOCTYPE_UPDATE,
  API_ERROR_DOCTYPE_HEADER,
  API_ERROR_DOCTYPE,
  API_ERROR_DOCTYPE1,
  API_ERROR_DOCTYPE_UPLOAD,
} from "./actionTypes";

const initialState = {
  errorDocTypeDelete: null,
  responseDocTypeDelete: null,
  loadingDocTypeDelete: false,
  errorDocTypeUpdate: null,
  responseDocTypeUpdate: null,
  loadingDocTypeUpdate: false,
  errorDocType: null,
  responseDocType: null,
  loadingDocType: false,
  errorDocType1: null,
  responseDocType1: null,
  loadingDocType1: false,
  errorDocTypeHeader: null,
  responseDocTypeHeader: null,
  loadingDocTypeHeader: false,
  errorDocTypeUpload: null,
  responseDocTypeUpload: null,
  loadingDocTypeUpload: false,
  errorDocTypeAttribute: null,
  responseDocTypeAttribute: null,
  loadingDocTypeAttribute: false,
};

const docType = (state = initialState, action) => {
  switch (action.type) {
    case DOCTYPE_HEADER_POST:
      state = {
        ...state,
        errorDocTypeHeader: null,
        loadingDocTypeHeader: true,
      };
      break;
    case DOCTYPE_LIST:
      state = {
        ...state,
        errorDocType: null,
        loadingDocType: true,
      };
      break;
    case DOCTYPE_DELETE:
      state = {
        ...state,
        errorDocTypeDelete: null,
        loadingDocTypeDelete: true,
      };
      break;
      case DOCTYPE_UPDATE:
        state = {
          ...state,
          errorDocTypeUpdate: null,
          loadingDocTypeUpdate: true,
        };
        break;
    case DOCTYPE_ATTRIBUTES_LIST:
      state = {
        ...state,
        errorDocTypeAttribute: null,
        loadingDocTypeAttribute: true,
      };
      break;
    case DOCTYPE_ATTRIBUTES_LIST_SUCCESS:
      state = {
        ...state,
        errorDocTypeAttribute: null,
        responseDocTypeAttribute: action.payload,
        loadingDocTypeAttribute: false,
      };
      break;
    case DOCTYPE_LIST1:
      state = {
        ...state,
        errorDocType1: null,
        loadingDocType1: true,
      };
      break;
    case DOCTYPE_DELETE_SUCCESS:
      state = {
        ...state,
        errorDocTypeDelete: null,
        responseDocTypeDelete: action.payload,
        loadingDocTypeDelete: false,
      };
      break;
      case DOCTYPE_UPDATE_SUCCESS:
        state = {
          ...state,
          errorDocTypeUpdate: null,
          responseDocTypeUpdate: action.payload,
          loadingDocTypeUpdate: false,
        };
        break;
    case DOCTYPE_HEADER_SUCCESS:
      state = {
        ...state,
        errorDocTypeHeader: null,
        responseDocTypeHeader: action.payload,
        loadingDocTypeHeader: false,
      };
      break;
    case DOCTYPE_LIST_SUCCESS:
      state = {
        ...state,
        errorDocType: null,
        responseDocType: action.payload,
        loadingDocType: false,
      };
      break;
    case DOCTYPE_LIST1_SUCCESS:
      state = {
        ...state,
        errorDocType1: null,
        responseDocType1: action.payload,
        loadingDocType1: false,
      };
      break;
    case API_ERROR_DOCTYPE_ATTRIBUTES:
      state = {
        ...state,
        errorDocTypeAttribute: action.payload,
        loadingDocTypeAttribute: false,
        responseDocTypeAttribute: null,
      };
      break;
    case API_ERROR_DOCTYPE_DELETE:
      state = {
        ...state,
        errorDocTypeDelete: action.payload,
        loadingDocTypeDelete: false,
        responseDocTypeDelete: null,
      };
      break;
      case API_ERROR_DOCTYPE_UPDATE:
        state = {
          ...state,
          errorDocTypeUpdate: action.payload,
          loadingDocTypeUpdate: false,
          responseDocTypeUpdate: null,
        };
        break;
    case API_ERROR_DOCTYPE_HEADER:
      state = {
        ...state,
        errorDocTypeHeader: action.payload,
        loadingDocTypeHeader: false,
        responseDocTypeHeader: null,
      };
      break;
    case API_ERROR_DOCTYPE:
      state = {
        ...state,
        errorDocType: action.payload,
        loadingDocType: false,
        responseDocType: null,
      };
      break;
    case API_ERROR_DOCTYPE1:
      state = {
        ...state,
        errorDocType1: action.payload,
        loadingDocType1: false,
        responseDocType1: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default docType;
