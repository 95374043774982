import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Editor } from "react-draft-wysiwyg";
import { colors } from "../../../../components/theme";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  Title,
  WrapperParagraph,
} from "./style";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const ParagraphView = (props) => {
  return (
    <WrapperParagraph>
      <Container>
        <>
          <Title>{props.title}</Title>
          <ButtonContainer>
            {props.viewPdf ? (
              <Button
                variant="text"
                onClick={props.onChangeNotSeeIntoPDF}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notSeeIntoPdfParagraph && colors.gray3,
                }}
                startIcon={<VisibilityOffIcon />}
              >
                Hide in PDF
              </Button>
            ) : (
              <Button
                variant="text"
                onClick={props.onChangeNotSeeIntoPDF}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notSeeIntoPdfParagraph && colors.gray3,
                }}
                startIcon={<VisibilityIcon />}
              >
                Show in PDF
              </Button>
            )}
          </ButtonContainer>
          <Editor
            editorState={props.editorState}
            content={props.content}
            value={props.content}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={props.onEditorStateChange}
          />
          <ButtonContainerTools>
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={props.isChecked}
                  onChange={props.changeChecked}
                />
              }
              label="Mandatory."
            />

            <Button
              onClick={props.onChangeDelete}
              style={{
                textTransform: "none",
              }}
              color="error"
              variant={"text"}
              startIcon={<DeleteForeverIcon />}
            >
              Delete
            </Button>
          </ButtonContainerTools>
        </>
      </Container>
    </WrapperParagraph>
  );
};

export default ParagraphView;
