import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useStyles } from "./style";

import { Container } from "reactstrap";
import TitleDocPlace from "../../components/title/title";
import SearchDocPlace from "../../components/search/search";
import GridDocPlace from "./components/grid/grid";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LoadingComponent from "../../components/loading";
import AddBoxIcon from "@mui/icons-material/AddBox";

import {
  deleteUserList,
  getUserArchiveRoles,
  getUserList,
} from "../../store/user/actions";
import NewItemDocPlace from "./components/new/new";
import { getDocTypeList1 } from "../../store/docType/actions";

/**DOC-1768 general values to access through the different calls to the API */
let counterFillRoles = 0;
let dataFillRoles = "";

const MasterTemplatePlace = (props) => {
  const notify = (msj, event) => toast[event](msj);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startPage] = useState(true);
  const [dataRols, setDataRols] = useState([]);
  const [doctypes, setDoctypes] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [doctypeId, setDoctypeId] = useState(null);
  const [open, setOpen] = useState(false);

  const [folderId, setFolderId] = useState("");
  const [folderName, setFolderName] = useState("");
  const [showNew, setShowNew] = React.useState(false);
  const [docTypesAPI, setDocTypesAPI] = useState([]);
  const [page] = useState(1);
  const [pageSize] = useState(1000);
  const [defaultData, setDefaultData] = React.useState("");

  const { responseUser, loadingUser } = useSelector((state) => ({
    responseUser: state.User.responseUser,
    loadingUser: state.User.loadingUser,
  }));
  const { responseUserDelete, loadingUserDelete } = useSelector((state) => ({
    responseUserDelete: state.User.responseUserDelete,
    loadingUserDelete: state.User.loadingUserDelete,
  }));
  const { responseUserArchiveRoles, loadingUserArchiveRoles } = useSelector(
    (state) => ({
      //DOCT-1768
      responseUserArchiveRoles: state.User.responseUserArchiveRoles,
      loadingUserArchiveRoles: state.User.loadingUserArchiveRoles,
    })
  );
  const { responseDocType1, errorDocType1, loading } = useSelector((state) => ({
    responseDocType1: state.DocType.responseDocType1,
    errorDocType1: state.DocType.errorDocType1,
    loading: state.DocType.loadingDocType1,
  }));

  useEffect(() => {
    dispatch(
      getDocTypeList1(
        {
          page: page,
          pageSize: pageSize,
          sortBy: "created_at",
          sortOrder: 2,
          filtered: false,
          masterTemplate: true,
        },
        navigate
      )
    );
  }, [startPage, page, pageSize]);

  useEffect(() => {
    if (responseDocType1 && responseDocType1.data.length > 0) {
      setDoctypes(responseDocType1.data);
      setDocTypesAPI(responseDocType1.data);
    }
  }, [responseDocType1]);

  useEffect(() => {
    if (responseUser && responseUser.data) {
      dataFillRoles = [...responseUser.data]; //setup initial data
      dataFillRoles.forEach((element) => {
        dispatch(getUserArchiveRoles(element?.id));
      }); //DOC-1768 for each row make the request to backend to get the archive roles.
      setDataRols(responseUser.data);
      setDocTypesAPI(responseUser.data);
    }
  }, [responseUser]);

  /**DOC-1768 this is a workaroung that is inneficient, we need to make changes in backend to get all the information in a single request. We consume the api for each result*/

  const fillRoles = () => {
    if (dataFillRoles && counterFillRoles < dataFillRoles.length) {
      let userID = dataFillRoles[counterFillRoles]?.id;
      if (userID) dispatch(getUserArchiveRoles(userID, navigate));
    } else {
      //finish work
      setDataRols(dataFillRoles);
    }
  };

  useEffect(() => {
    if (responseUserArchiveRoles && responseUserArchiveRoles.data.length > 0) {
      //get the userID on response
      let rUserID = responseUserArchiveRoles.data[0].systemUserId;
      let position = -1;
      dataFillRoles.map((item, index) => {
        if (item.id == rUserID) {
          position = index;
        }
      }); //search the corresponding index for the userID in the array of users
      if (position >= 0) {
        //if a match was found, fill out the data
        dataFillRoles[position].archiveRoles = responseUserArchiveRoles.data;
        let columnInfo = "";
        responseUserArchiveRoles.data.map((item) => {
          columnInfo = columnInfo.concat(
            columnInfo === ""
              ? item.archiveRole.name
              : ", " + item.archiveRole.name
          );
        });
        dataFillRoles[position].role = columnInfo;
      }
    }
  }, [responseUserArchiveRoles]);

  /**END DOC-1768 */

  useEffect(() => {
    if (responseUserDelete !== null) {
      dispatch(getUserList({ page: page, pageSize: pageSize }, navigate));
    }
  }, [responseUserDelete]);

  const findDocumentTypes = (term) => {
    setDoctypes(
      term
        ? docTypesAPI.filter((doc) => doc.name.toLowerCase().includes(term))
        : docTypesAPI
    );
  };

  const optionGrid = (value) => {
    if (value.action === "attributes") {
      /* Decide if we need to use the new or the legacy version for the labeling tool, based on the hardcoded date.*/
      console.log(window.location.hostname);
      let legacyDate = "2023-03-06T00:00:00";
      let d = new Date(value.row.row.createdAt);
      //Is dev? Todo: get the value from function
      if (window.location.hostname != "app.docplace.io") {
        legacyDate = "2023-01-18T00:00:00";
      }

      let d2 = new Date(legacyDate);

      let newVersion = d.getTime() > d2.getTime() ? true : false;

      navigate(
        "/documentType/detail/" +
          value.id +
          "/" +
          value.row.row.ignoreOcr +
          "/" +
          newVersion
      );
    }
    if (value.action === "edit") {
      setName(value.row.row.name);
      setDescription(value.row.row.description);
      setFolderId(value.row.row.targetArchiveId);
      setFolderName(value.row.row.targetFolderName);
      setDoctypeId(value.row.row.id);
      setOpen(true);
    }

    if (value.action === "remove") {
      deleteItem(value.id);
    }
  };

  const deleteItem = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Are you sure?",
      text: "",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        dispatch(deleteUserList(id, navigate));
      }
    });
  };

  const searchFile = (filterSearch) => {
    findDocumentTypes(filterSearch);
  };

  const refresh = () => {
    setDefaultData(null);
    dispatch(getUserList({ page: page, pageSize: pageSize }, navigate));
  };

  const closeModal = () => {
    setDefaultData(null);
    setShowNew(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DocPlace Master Scanner</title>
        </MetaTags>
        <Container fluid>
          <TitleDocPlace title={"Master Scanner"} viewActive={false} />
          <ToastContainer autoClose={3000} />
          <LoadingComponent
            show={loadingUser || loadingUserDelete || loading}
            text={
              loadingUser
                ? "Getting Master Scanner..."
                : "Deleting Master Scanner"
            }
          />
          <SearchDocPlace callSearch={searchFile.bind(this)} />
          <div className={classes.WrapperNew}>
            <Button
              onClick={() => {
                setShowNew(true);
              }}
              style={{ marginTop: "20px" }}
              size="small"
              color="primary"
              variant="contained"
            >
              <AddBoxIcon />
              <span>New</span>
            </Button>
          </div>
          <GridDocPlace
            data={doctypes ? doctypes : []}
            option={optionGrid.bind(this)}
          />
        </Container>
      </div>
      <NewItemDocPlace
        done={refresh.bind(0, this)}
        close={closeModal.bind(this)}
        default={defaultData}
        show={showNew}
      />
    </React.Fragment>
  );
};

export default MasterTemplatePlace;
