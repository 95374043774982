import { call, put, takeEvery } from "redux-saga/effects"
import {ROL_LIST,ROL_POST,ROL_PUT,ROL_DELETE} from "./actionTypes"
import {
          apiErrorRol, rolSuccess,
          apiErrorRolPost, rolPostSuccess,
          apiErrorRolPut, rolPutSuccess,
          apiErrorRolDelete, rolDeleteSuccess
} from "./actions"

import {
  getRol,
  postRol,
  putRol,
  deleteRol,

} from "../../helpers/call_api"
import {toast} from "react-toastify";


function* getRolList({ payload: { rol, navigate } }) {
  try {
    const response = yield call(getRol, rol)
    yield put(rolSuccess(response))
  } catch (error) {
    if(error.response.status){
      if(error.response.status===401){
        navigate('/login');
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
     yield put(apiErrorRol(error))
  }
}

function* postRolList({ payload: { rol, navigate } }) {
  try {
    const response = yield call(postRol, rol)
    toast.success('New Role was added');
    yield put(rolPostSuccess(response))
  } catch (error) {
    if(error.response.status){
      if(error.response.status===401){
        navigate('/login');
      }
    }

    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorRolPost(error))
  }
}

function* putRolList({ payload: { rol, navigate } }) {
  try {
    const response = yield call(putRol, rol.data, rol.id)
    toast.success('Role was updated');
    yield put(rolPutSuccess(response))
  } catch (error) {
    if(error.response.status){
      if(error.response.status===401){
        navigate('/login');
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorRolPut(error))
  }
}

function* deleteRolList({ payload: { rol, navigate } }) {
  try {
    const response = yield call(deleteRol, rol)
    toast.success('Role was deleted');
    yield put(rolDeleteSuccess(response))
  } catch (error) {
    if(error.response.status){
      if(error.response.status===401){
        navigate('/login');
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorRolDelete(error))
  }
}


function* authSaga() {
  yield takeEvery(ROL_LIST, getRolList)
  yield takeEvery(ROL_POST, postRolList)
  yield takeEvery(ROL_PUT, putRolList)
  yield takeEvery(ROL_DELETE, deleteRolList)
}

export default authSaga
