export const USER_LIST = "USER_LIST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const API_ERROR_USER = "API_ERROR_USER";

export const USER_POST = "USER_POST";
export const USER_POST_SUCCESS = "USER_POST_SUCCESS";
export const API_ERROR_USER_POST = "API_ERROR_USER_POST";

export const USER_PASSWORD = "USER_PASSWORD";
export const USER_PASSWORD_SUCCESS = "USER_PASSWORD_SUCCESS";
export const API_ERROR_USER_PASSWORD = "API_ERROR_USER_PASSWORD";

export const USER_PUT = "USER_PUT";
export const USER_PUT_SUCCESS = "USER_PUT_SUCCESS";
export const API_ERROR_USER_PUT = "API_ERROR_USER_PUT";

export const USER_DELETE = "USER_DELETE";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const API_ERROR_USER_DELETE = "API_ERROR_USER_DELETE";

export const ROLE_LIST = "ROLE_LIST";
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS";
export const API_ERROR_ROLE = "API_ERROR_ROLE";

export const USER_ASG_ROL_POST = "USER_ASG_ROL_POST";
export const USER_ASG_ROL_POST_SUCCESS = "USER_ASG_ROL_POST_SUCCESS";
export const API_ERROR_USER_ASG_ROL_POST = "API_ERROR_USER_ASG_ROL_POST";

export const USER_ASG_ROL_PUT = "USER_ASG_ROL_PUT";
export const USER_ASG_ROL_PUT_SUCCESS = "USER_ASG_ROL_PUT_SUCCESS";
export const API_ERROR_USER_ASG_ROL_PUT = "API_ERROR_USER_ASG_ROL_PUT";

/**DOC-1778 get assigned roles */
export const USER_ARCHIVE_ROLES = "USER_ARCHIVE_ROLES";
export const USER_ARCHIVE_ROLES_SUCCESS = "USER_ARCHIVE_ROLES_SUCCESS";
export const API_ERROR_USER_ARCHIVE = "API_ERROR_USER_ARCHIVE";
