export const ROL_LIST = "ROL_LIST"
export const ROL_LIST_SUCCESS = "ROL_LIST_SUCCESS"
export const API_ERROR_ROL = "API_ERROR_ROL"

export const ROL_POST = "ROL_POST"
export const ROL_POST_SUCCESS = "ROL_POST_SUCCESS"
export const API_ERROR_ROL_POST = "API_ERROR_ROL_POST"

export const ROL_PUT = "ROL_PUT"
export const ROL_PUT_SUCCESS = "ROL_PUT_SUCCESS"
export const API_ERROR_ROL_PUT = "API_ERROR_ROL_PUT"

export const ROL_DELETE = "ROL_DELETE"
export const ROL_DELETE_SUCCESS = "ROL_DELETE_SUCCESS"
export const API_ERROR_ROL_DELETE = "API_ERROR_ROL_DELETE"


