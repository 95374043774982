import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ListItemText from "@mui/material/ListItemText";
import { expressions, theme as TH } from "../components/theme";
import "./style.css";
import {
  ContainerUser,
  DivLogoEsignature,
  DivTextEsignature,
  SuperUser,
  useStyles,
  Version,
} from "./style";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Collapse from "@mui/material/Collapse";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import * as moment from "moment";
import GroupIcon from "@mui/icons-material/Group";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { getUserIsRoot, userUpdatePassword } from "../services/ocr";
import missingSignature from "../../src/assets/missingSignature.png";
import approvalProcess from "../../src/assets/Archivos.svg";
import approvalRequested from "../../src/assets/dataset.png";
import assignedForms from "../../src/assets/assignedForms.svg";
import signatureRequests from "../../src/assets/eSignatureRequests.png";
import trashBin from "../../src/assets/trashBin.svg";
import PermissionChecker from "../services/permissionsChecker";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import FeedIcon from "@mui/icons-material/Feed";
import ListAltIcon from "@mui/icons-material/ListAlt";
import masterTemplate from "../assets/masterTemplate.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const PrivateRoute = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  let auth = "";
  const [open, setOpen] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenuSignature, setOpenMenuSignature] = React.useState(true);
  const [openMenuForms, setOpenMenuForms] = React.useState(true);
  const [openMenuWF, setOpenMenuWF] = React.useState(true);
  const [openMenuEsignature, setOpenMenuEsignature] = React.useState(true);
  const [userIsRoot, setUserIsRoot] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [passwordChange, setPasswordChange] = React.useState(false);
  const [passwordRepeatChange, setPasswordRepeatChange] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordRepeat, setPasswordRepeat] = React.useState("");
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [passwordUpdating, setPasswordUpdating] = React.useState(false);
  const [fullName, setFullName] = React.useState(false);
  const [lastLogin, setLastLogin] = React.useState(false);
  const [email, setEmail] = React.useState(true);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const openMenuUser = Boolean(anchorEl);
  const handleClickUser = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [activeOption, setActiveOption] = React.useState({
    menu: "Dashboard",
  });

  useEffect(() => {
    if (localStorage.getItem("docplace")) {
      setIsAdmin(
        JSON.parse(localStorage.getItem("docplace")).superUser === 1
          ? true
          : false
      );
    }
  }, []);

  if (!JSON.parse(localStorage.getItem("docplace"))) {
    navigate("/login");
  } else {
    auth = JSON.parse(localStorage.getItem("docplace")).token ? true : false;
  }

  const callMenuOption = (opt) => {
    setActiveOption({ menu: opt });
    switch (opt) {
      case "Dashboard": {
        navigate("/dashboard");
        break;
      }
      case "Explorer": {
        navigate("/explorer");
        break;
      }
      case "Search": {
        navigate("/search");
        break;
      }
      case "Document Type": {
        navigate("/documentType");
        break;
      }
      case "Master Scanner": {
        navigate("/masterTemplate");
        break;
      }
      case "Input Method": {
        navigate("/inputMethod");
        break;
      }
      case "Users": {
        if (isAdmin) {
          navigate("/user");
        } else {
          navigate("/dashboard");
        }
        break;
      }
      case "Roles": {
        navigate("/rol");
        break;
      }
      case "Signature": {
        navigate("/signature");
        break;
      }
      case "Requested": {
        navigate("/eSignatureRequested");
        break;
      }
      case "Inbox": {
        navigate("/eSignatureInbox");
        break;
      }
      case "Dashboard eSignature": {
        navigate("/DashboardSignature");
        break;
      }
      case "PlaceSignature": {
        navigate("/place-signature");
        break;
      }
      case "ApprovalProcess": {
        navigate("/ApprovalProcess");
        break;
      }
      case "FormsTemplates": {
        navigate("/formsTemplate");
        break;
      }
      case "Forms Inbox": {
        navigate("/forms-inbox");
        break;
      }
      case "Templates": {
        navigate("/templates");
        break;
      }
      case "Assigned Forms": {
        navigate("/assigned-forms");
        break;
      }
      case "Trash Bin": {
        navigate("/trash-bin");
        break;
      }
      case "RequestedWF": {
        navigate("/requested-process");
        break;
      }
      case "EsignatureResquets": {
        navigate("/Esignature-requests");
        break;
      }
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleReset = () => {
    let systemUserRequest = {
      appId: "string",
      data: {
        confirmPassword: passwordRepeat,
        email: JSON.parse(localStorage.getItem("docplace")).email,
        password: password,
      },
    };
    setPasswordUpdating(true);

    userUpdatePassword(systemUserRequest, (event) => {})
      .then((response) => {
        let successMsg = "";

        if (passwordChanged) {
          successMsg = "Password reset successful";
          setPassword("");
          setPasswordRepeat("");
          setPasswordChanged(false);
          setPasswordUpdating(false);
        }

        if (successMsg)
          toast.success(successMsg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        setPasswordUpdating(false);
        setOpenModal(false);
      })
      .catch((err) => {
        toast.error(
          `There was an error resetting password. Make sure that the password wasn't already used, and try again.`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

        setPasswordUpdating(false);
      });
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  const handleClick2 = () => {
    setOpenMenu2(!openMenu2);
  };
  const handleClickMenuSignatures = () => {
    setOpenMenuSignature(!openMenuSignature);
  };

  const handleClickMenuForms = () => {
    setOpenMenuForms(!openMenuForms);
  };

  const handleClickMenuWF = () => {
    setOpenMenuWF(!openMenuWF);
  };

  const handleClickMenuEsignature = () => {
    setOpenMenuEsignature(!openMenuEsignature);
  };

  const handleClick3 = () => {
    setOpenMenu3(!openMenu3);
  };

  const handleLogout = () => {
    localStorage.removeItem("docplace");
    //Todo: replace with redux saga logout method
    localStorage.setItem("docplace-logout", true);
    navigate("/login");
  };
  const handleOpenModal = (label) => {
    setFullName(JSON.parse(localStorage.getItem("docplace")).fullName);
    setEmail(JSON.parse(localStorage.getItem("docplace")).email);
    setLastLogin(JSON.parse(localStorage.getItem("docplace")).lastLogin);
    setOpenModal(true);

    setPassword("");
    setPasswordRepeat("");
    setPasswordChanged(false);
    setPasswordUpdating(false);
  };
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    if (localStorage.getItem("docplace")) {
      getUserIsRoot(
        JSON.parse(localStorage.getItem("docplace")).userId,
        (event) => {}
      )
        .then((response) => {
          setUserIsRoot(response.data.data);
        })

        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return auth ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClickUser}
              size="medium"
              sx={{ ml: 2, position: "absolute", right: "40px" }}
              aria-controls={openMenuUser ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenuUser ? "true" : undefined}
            >
              <AccountCircle style={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenuUser}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 1,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem disableRipple onClick={handleOpenModal}>
              <Avatar /> Profile
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <Logout />
              &nbsp; Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader className={classes.Bg}>
          <DivLogoEsignature>
            <img alt="Logo" className={classes.logoDocPlace} src={TH.logo} />
            <PermissionChecker page={"eSignatureInbox"}>
              <DivTextEsignature>eSignature</DivTextEsignature>
            </PermissionChecker>
          </DivLogoEsignature>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon className={classes.color} />
            ) : (
              <ChevronLeftIcon className={classes.color} />
            )}
          </IconButton>
        </DrawerHeader>

        <div className="drawer">
          <PermissionChecker page={"trial"}>
            <List style={{ backgroundColor: "#eff3f7" }}>
              <ListItem key="trial">
                <ListItemIcon>
                  <AdminPanelSettingsIcon className={classes.colorBlue} />
                </ListItemIcon>
                <ListItemText
                  className={classes.colorBlue}
                  primary={
                    JSON.parse(localStorage.getItem("docplace")).customerId
                  }
                />
                <SuperUser> Free Trial </SuperUser>
              </ListItem>
            </List>
            <Divider />
          </PermissionChecker>
          <PermissionChecker page={"superUser"}>
            <List>
              <ListItem key="Super User">
                <ListItemIcon>
                  <AdminPanelSettingsIcon className={classes.colorBlue} />
                </ListItemIcon>
                <ContainerUser>
                  {JSON.parse(localStorage.getItem("docplace")).companyName
                    ? JSON.parse(localStorage.getItem("docplace")).companyName
                    : JSON.parse(localStorage.getItem("docplace")).customerId}
                </ContainerUser>
                <SuperUser> Super User </SuperUser>
              </ListItem>
            </List>
            <Divider />
          </PermissionChecker>
          {/*<PermissionChecker page={"dashboard"}>
            <List>
              <ListItem
                onClick={() => callMenuOption("Dashboard")}
                className={
                  activeOption.menu === "Dashboard" && classes.activeColor
                }
                button
                key="dashboard"
              >
                <ListItemIcon>
                  <DashboardIcon className={classes.colorBlue} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Dashboard"
                  />
                )}
              </ListItem>
            </List>
            <Divider />
          </PermissionChecker>

          */}
          <PermissionChecker page={"explorer"}>
            <List>
              <ListItem button key="documents" onClick={handleClick}>
                <ListItemIcon>
                  <InboxIcon className={classes.colorBlue} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Documents"
                  />
                )}
                {openMenu ? (
                  <ExpandLess className={classes.colorBlue} />
                ) : (
                  <ExpandMore className={classes.colorBlue} />
                )}
              </ListItem>
            </List>
            <Collapse in={openMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <PermissionChecker page={"explorer"}>
                  <ListItem
                    sx={{ pl: 4 }}
                    className={
                      activeOption.menu === "Explorer" && classes.activeColor
                    }
                    onClick={() => callMenuOption("Explorer")}
                  >
                    <ListItemIcon title="Explorer">
                      <SnippetFolderIcon className={classes.colorBlue} />
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        className={classes.colorBlue}
                        primary="Explorer"
                      />
                    )}
                  </ListItem>
                </PermissionChecker>
                <PermissionChecker page={"search"}>
                  <ListItem
                    sx={{ pl: 4 }}
                    onClick={() => callMenuOption("Search")}
                    className={
                      activeOption.menu === "Search" && classes.activeColor
                    }
                  >
                    <ListItemIcon>
                      <FindInPageIcon className={classes.colorBlue} />
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        className={classes.colorBlue}
                        primary="Search"
                      />
                    )}
                  </ListItem>
                </PermissionChecker>
              </List>
            </Collapse>
            <Divider />
          </PermissionChecker>
          <PermissionChecker page={"documentType"}>
            <List>
              <ListItem button key="setting" onClick={handleClick2}>
                <ListItemIcon>
                  <RoomPreferencesIcon className={classes.colorBlue} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Settings"
                  />
                )}
                {openMenu2 ? (
                  <ExpandLess className={classes.colorBlue} />
                ) : (
                  <ExpandMore className={classes.colorBlue} />
                )}
              </ListItem>
            </List>
            <Collapse in={openMenu2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <PermissionChecker page={"documentType"}>
                  <ListItem
                    sx={{ pl: 4 }}
                    className={
                      activeOption.menu === "Document Type" &&
                      classes.activeColor
                    }
                    onClick={() => callMenuOption("Document Type")}
                  >
                    <ListItemIcon>
                      <ViewQuiltIcon className={classes.colorBlue} />
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        className={classes.colorBlue}
                        primary="Document Type"
                      />
                    )}
                  </ListItem>
                </PermissionChecker>
                <PermissionChecker page={"documentType"}>
                  <ListItem
                    sx={{ pl: 4 }}
                    className={
                      activeOption.menu === "Master Scanner" &&
                      classes.activeColor
                    }
                    onClick={() => callMenuOption("Master Scanner")}
                  >
                    <img
                      src={masterTemplate}
                      alt="Master Scanner"
                      style={{
                        width: "28px",
                        height: "28px",
                        marginRight: "7px",
                      }}
                    />
                    {open && (
                      <ListItemText
                        className={classes.colorBlue}
                        primary="Master Scanner"
                      />
                    )}
                  </ListItem>
                </PermissionChecker>
                <PermissionChecker page={"inputMethod"}>
                  <ListItem
                    sx={{ pl: 4 }}
                    className={
                      activeOption.menu === "Input Method" &&
                      classes.activeColor
                    }
                    onClick={() => callMenuOption("Input Method")}
                  >
                    <ListItemIcon>
                      <CloudUploadIcon className={classes.colorBlue} />
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        className={classes.colorBlue}
                        primary="Input Method"
                      />
                    )}
                  </ListItem>
                </PermissionChecker>
              </List>
            </Collapse>
            <Divider />
          </PermissionChecker>
          {userIsRoot && isAdmin == true && (
            <PermissionChecker page={"user"}>
              <List>
                <ListItem button key="security" onClick={handleClick3}>
                  <ListItemIcon>
                    <AdminPanelSettingsIcon className={classes.colorBlue} />
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      className={classes.colorBlue}
                      primary="Security"
                    />
                  )}
                  {openMenu3 ? (
                    <ExpandLess className={classes.colorBlue} />
                  ) : (
                    <ExpandMore className={classes.colorBlue} />
                  )}
                </ListItem>
              </List>
              <Collapse in={openMenu3} timeout="auto" unmountOnExit>
                <PermissionChecker page={"user"}>
                  <List component="div" disablePadding>
                    <ListItem
                      sx={{ pl: 4 }}
                      className={
                        activeOption.menu === "Users" && classes.activeColor
                      }
                      onClick={() => callMenuOption("Users")}
                    >
                      <ListItemIcon>
                        <GroupIcon className={classes.colorBlue} />
                      </ListItemIcon>
                      {open && (
                        <ListItemText
                          className={classes.colorBlue}
                          primary="Users"
                        />
                      )}
                    </ListItem>
                  </List>
                </PermissionChecker>
                <PermissionChecker page={"rol"}>
                  <List component="div" disablePadding>
                    <ListItem
                      sx={{ pl: 4 }}
                      className={
                        activeOption.menu === "Roles" && classes.activeColor
                      }
                      onClick={() => callMenuOption("Roles")}
                    >
                      <ListItemIcon>
                        <VpnKeyIcon className={classes.colorBlue} />
                      </ListItemIcon>
                      {open && (
                        <ListItemText
                          className={classes.colorBlue}
                          primary="Roles"
                        />
                      )}
                    </ListItem>
                  </List>
                </PermissionChecker>
              </Collapse>
            </PermissionChecker>
          )}
          <Divider />
          <PermissionChecker page={"eSignature"}>
            <List>
              <ListItem button key="Forms" onClick={handleClickMenuEsignature}>
                <ListItemIcon>
                  <img
                    src={missingSignature}
                    alt="Sign"
                    style={{ width: "28px", height: "28px" }}
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="eSignature"
                  />
                )}
                {openMenuEsignature ? (
                  <ExpandLess className={classes.colorBlue} />
                ) : (
                  <ExpandMore className={classes.colorBlue} />
                )}
              </ListItem>
            </List>
            <Collapse in={openMenuEsignature} timeout="auto" unmountOnExit>
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "Requested" && classes.activeColor
                }
                onClick={() => callMenuOption("Requested")}
              >
                <ListItemIcon>
                  <AppRegistrationIcon className={classes.colorBlue} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Received"
                  />
                )}
              </ListItem>
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "EsignatureResquets" &&
                  classes.activeColor
                }
                onClick={() => callMenuOption("EsignatureResquets")}
              >
                <ListItemIcon>
                  <img
                    src={signatureRequests}
                    alt="Sign"
                    style={{ width: "23px", height: "23px" }}
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Requested"
                  />
                )}
              </ListItem>
            </Collapse>
            <Divider />
            <List>
              <ListItem button key="Forms" onClick={handleClickMenuForms}>
                <ListItemIcon>
                  <FeedIcon
                    style={{ width: "28px", height: "28px" }}
                    className={classes.colorBlue}
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText className={classes.colorBlue} primary="Forms" />
                )}
                {openMenuForms ? (
                  <ExpandLess className={classes.colorBlue} />
                ) : (
                  <ExpandMore className={classes.colorBlue} />
                )}
              </ListItem>
            </List>
            <Collapse in={openMenuForms} timeout="auto" unmountOnExit>
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "Forms Inbox" && classes.activeColor
                }
                onClick={() => callMenuOption("Forms Inbox")}
              >
                <ListItemIcon>
                  <InboxIcon className={classes.colorBlue} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Forms Inbox"
                  />
                )}
              </ListItem>

              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "Templates" && classes.activeColor
                }
                onClick={() => callMenuOption("Templates")}
              >
                <ListItemIcon>
                  <ListAltIcon className={classes.colorBlue} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Templates"
                  />
                )}
              </ListItem>
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "Assigned Forms" && classes.activeColor
                }
                onClick={() => callMenuOption("Assigned Forms")}
              >
                <ListItemIcon>
                  <img
                    src={assignedForms}
                    alt="AssignedForms"
                    style={{ width: "20px", height: "20px" }}
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Assigned Forms"
                  />
                )}
              </ListItem>
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "Trash Bin" && classes.activeColor
                }
                onClick={() => callMenuOption("Trash Bin")}
              >
                <ListItemIcon>
                  <img
                    src={trashBin}
                    alt="TrashBin"
                    style={{ width: "20px", height: "20px" }}
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Trash Bin"
                  />
                )}
              </ListItem>
            </Collapse>
            <Divider />
            <List>
              <ListItem button key="WorkFlow" onClick={handleClickMenuWF}>
                <ListItemIcon>
                  <img
                    src={approvalRequested}
                    alt="Sign"
                    style={{
                      width: "31px",
                      height: "31px",
                      marginLeft: "-3px",
                    }}
                  />
                </ListItemIcon>

                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Approval process"
                  />
                )}
                {openMenuWF ? (
                  <ExpandLess className={classes.colorBlue} />
                ) : (
                  <ExpandMore className={classes.colorBlue} />
                )}
              </ListItem>
            </List>
            <Collapse in={openMenuWF} timeout="auto" unmountOnExit>
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "RequestedWF" && classes.activeColor
                }
                onClick={() => callMenuOption("RequestedWF")}
              >
                <ListItemIcon>
                  <img
                    src={approvalProcess}
                    alt="Sign"
                    style={{
                      width: "35px",
                      height: "35px",
                      marginLeft: "-2px",
                    }}
                  />
                  {/*<AppRegistrationIcon className={classes.colorBlue} />*/}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Requested"
                  />
                )}
              </ListItem>
            </Collapse>
          </PermissionChecker>
        </div>
        <div>
          <PermissionChecker page={"DashboardSignature"}>
            <List>
              <ListItem
                onClick={() => callMenuOption("Dashboard eSignature")}
                className={
                  activeOption.menu === "Dashboard eSignature" &&
                  classes.activeColor
                }
                button
                key="Dashboard eSignature"
              >
                <ListItemIcon>
                  <DashboardIcon className={classes.colorBlue} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Dashboard eSignature"
                  />
                )}
              </ListItem>
            </List>
            <Divider />
          </PermissionChecker>
          <PermissionChecker page={"eSignatureInbox"}>
            <List>
              <ListItem
                button
                key="eSingnature"
                onClick={handleClickMenuSignatures}
              >
                <ListItemIcon>
                  <img
                    src={missingSignature}
                    alt="MissingSignature"
                    className={classes.missingSignature}
                    style={{ width: "28px", height: "28px" }}
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="eSingnature"
                  />
                )}
                {openMenuSignature ? (
                  <ExpandLess className={classes.colorBlue} />
                ) : (
                  <ExpandMore className={classes.colorBlue} />
                )}
              </ListItem>
            </List>
            <Collapse in={openMenuSignature} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <PermissionChecker page={"eSignatureInbox"}>
                  <ListItem
                    sx={{ pl: 4 }}
                    className={
                      activeOption.menu === "Inbox" && classes.activeColor
                    }
                    onClick={() => callMenuOption("Inbox")}
                  >
                    <ListItemIcon>
                      <InboxIcon className={classes.colorBlue} />
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        className={classes.colorBlue}
                        primary="Inbox"
                      />
                    )}
                  </ListItem>
                </PermissionChecker>
                <PermissionChecker page={"eSignatureE"}>
                  <ListItem
                    sx={{ pl: 4 }}
                    className={
                      activeOption.menu === "Requested" && classes.activeColor
                    }
                    onClick={() => callMenuOption("Requested")}
                  >
                    <ListItemIcon>
                      <AppRegistrationIcon className={classes.colorBlue} />
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        className={classes.colorBlue}
                        primary="Received"
                      />
                    )}
                  </ListItem>
                  <ListItem
                    sx={{ pl: 4 }}
                    className={
                      activeOption.menu === "EsignatureResquets" &&
                      classes.activeColor
                    }
                    onClick={() => callMenuOption("EsignatureResquets")}
                  >
                    <ListItemIcon>
                      <img
                        src={signatureRequests}
                        alt="Sign"
                        style={{ width: "23px", height: "23px" }}
                      />
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        className={classes.colorBlue}
                        primary="Requested"
                      />
                    )}
                  </ListItem>
                </PermissionChecker>
              </List>
            </Collapse>
            <Divider />
            <List>
              <ListItem button key="Forms" onClick={handleClickMenuForms}>
                <ListItemIcon>
                  <FeedIcon
                    style={{ width: "28px", height: "28px" }}
                    className={classes.colorBlue}
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText className={classes.colorBlue} primary="Forms" />
                )}
                {openMenuForms ? (
                  <ExpandLess className={classes.colorBlue} />
                ) : (
                  <ExpandMore className={classes.colorBlue} />
                )}
              </ListItem>
            </List>
            <Collapse in={openMenuForms} timeout="auto" unmountOnExit>
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "Forms Inbox" && classes.activeColor
                }
                onClick={() => callMenuOption("Forms Inbox")}
              >
                <ListItemIcon>
                  <InboxIcon className={classes.colorBlue} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Forms Inbox"
                  />
                )}
              </ListItem>
              <Divider />
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "Templates" && classes.activeColor
                }
                onClick={() => callMenuOption("Templates")}
              >
                <ListItemIcon>
                  <ListAltIcon className={classes.colorBlue} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Templates"
                  />
                )}
              </ListItem>
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "Assigned Forms" && classes.activeColor
                }
                onClick={() => callMenuOption("Assigned Forms")}
              >
                <ListItemIcon>
                  <img
                    src={assignedForms}
                    alt="AssignedForms"
                    style={{ width: "20px", height: "20px" }}
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Assigned Forms"
                  />
                )}
              </ListItem>
              <ListItem
                sx={{ pl: 4 }}
                className={
                  activeOption.menu === "Trash Bin" && classes.activeColor
                }
                onClick={() => callMenuOption("Trash Bin")}
              >
                <ListItemIcon>
                  <img
                    src={trashBin}
                    alt="TrashBin"
                    title="Trash Bin"
                    style={{ width: "20px", height: "20px" }}
                  />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={classes.colorBlue}
                    primary="Trash Bin"
                    title="Trash Bin"
                  />
                )}
              </ListItem>
            </Collapse>
            <Divider />
          </PermissionChecker>
        </div>
        <Version>Version: 5.0.1</Version>
      </Drawer>
      <Box
        style={{ backgroundColor: "#eff3f7" }}
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            User profile
          </Typography>

          <Typography component={"div"} id="modal-modal-format" sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                style={{ width: "100%", marginBottom: "8px" }}
                id="fullName"
                disabled
                defaultValue={fullName}
                label="Full name"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                style={{ width: "100%", marginBottom: "8px" }}
                id="email"
                disabled={true}
                defaultValue={email}
                label="Email"
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                style={{ width: "100%", marginBottom: "8px" }}
                id="lastLogin"
                disabled
                defaultValue={moment(lastLogin).format("llll")}
                label="Last login"
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                style={{ width: "100%", marginBottom: "8px" }}
                id="passwordUser"
                type="password"
                label="New password"
                onChange={(value) => {
                  setPassword(value.target.value);
                  setPasswordChanged(true);
                  setPasswordChange(expressions.password.test(password));
                }}
                value={password}
              />
              {passwordChange == false && (
                <small>
                  Your new password must be at least 8 characters length and
                  contain uppercase, lowercase characters, and at least one
                  number
                </small>
              )}

              <TextField
                style={{ width: "100%", marginBottom: "8px" }}
                id="passwordUserRepeat"
                type="password"
                label="Password Repeat"
                onChange={(value) => {
                  setPasswordRepeat(value.target.value);
                  setPasswordRepeatChange(password !== value.target.value);
                }}
                value={passwordRepeat}
              />
              {passwordRepeatChange && <small>Password doesn't match</small>}
            </FormControl>
          </Typography>
          <Button
            variant="contained"
            color="success"
            hidden={!passwordChanged && password === ""}
            disabled={
              password === "" ||
              password !== passwordRepeat ||
              password.length < 4 ||
              passwordUpdating == true ||
              expressions.password.test(password) == false
            }
            onClick={handleReset}
            sx={{ mt: 2, mr: 2 }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpenModal(false)}
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </Box>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
