import React, {useState, useEffect } from "react";
import {TitleDocumentUpload, FileNewUpload, useStyles, DragAndDropText} from './style';
import Dropzone from "react-dropzone";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import fileDoc from "../../../../assets/file-doc.png";
import fileImage from "../../../../assets/file-jpg.png";
import filePdf from "../../../../assets/file-pdf.png";
import fileExcel from "../../../../assets/file-excel.png";
import fileZip from "../../../../assets/file-zip.png";
import fileOther from "../../../../assets/file-other.png";
import fileWord from "../../../../assets/file-word.png";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

export function FileUploadDocPlace(props) {
    const classes = useStyles();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFiles, setCurrentFiles] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [fileInfos, setFileInfos] = useState([]);

    useEffect(() => {
        if(selectedFiles.length > 0){
            props.uploadF(selectedFiles);
        }
    }, [selectedFiles]);

    useEffect(() => {
        if(props.closeUp){
            setSelectedFiles([]);
        }
    }, [props.closeUp]);

    const upload = () => {
        let _progressInfos = [];
        for (let i = 0; i < selectedFiles.length; i++) {

            _progressInfos.push(selectedFiles[i]);
        }
        props.uploadF(_progressInfos);
    };

    const deleteItem = (id) => {
        let d = selectedFiles;
        d.splice(id, 1);
        setSelectedFiles(d);
        props.uploadF(selectedFiles);
    }

    const onDrop = (files) => {
        if (files.length > 0) {
            setSelectedFiles(files);
        }
        props.uploadF(files);
    };

    return (
        <div>
            <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps({ className: selectedFiles.length > 0 ? classes.dropZoneComplete : classes.dropzone })}>
                            <input {...getInputProps()} />
                            {selectedFiles &&
                                selectedFiles.length ? (
                                    <div>


                                        {selectedFiles.length > 50
                                            ? `${selectedFiles.length} files`
                                            :  selectedFiles.map((file, index) => (
                                                    <div className={classes.dropBox} key={index}>
                                                        <img alt={'DocPlace'}
                                                             src={
                                                                 file.name.search("xls") > 0 ||
                                                                 file.name.search("xlsx") > 0
                                                                     ? fileExcel
                                                                     :
                                                                     file.name.search("doc") > 0 ||
                                                                     file.name.search("docx") > 0
                                                                        ? fileWord
                                                                        :
                                                                         file.name.search("zip") > 0 ||
                                                                         file.name.search("rar") > 0
                                                                            ? fileZip
                                                                            : file.name.search("pdf") > 0
                                                                                ? filePdf
                                                                                : file.name.search("txt") > 0
                                                                                    ? fileDoc
                                                                                    : file.name.search("jpeg") > 0 ||
                                                                                      file.name.search("jpg") > 0 ||
                                                                                      file.name.search("jfif") > 0 ||
                                                                                      file.name.search("png") > 0 ||
                                                                                      file.name.search("pjpeg") > 0 ||
                                                                                      file.name.search("gif") > 0 ||
                                                                                      file.name.search("gif") > 0
                                                                                        ? fileImage
                                                                                        :fileOther
                                                             } />
                                                        <div style={{lineHeight:'1'}}>{file.name}</div>
                                                        <div style={{fontWeight:'9000'}}>{(file.size / 1000000).toFixed(2)} MB</div>
                                                    </div>
                                                ))
                                        }
                                    </div>
                            ) : (
                                <FileNewUpload>
                                    <CloudUploadIcon className={classes.iconUploadFile}/>

                                    <TitleDocumentUpload>Convert your pdf to an online form</TitleDocumentUpload>
                                    <DragAndDropText> Upload pdf form</DragAndDropText>

                                </FileNewUpload>
                            )}
                        </div>
                    </section>
                )}
            </Dropzone>
        </div>
    )
}

export default FileUploadDocPlace
