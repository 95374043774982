import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import "./docviewer-style.scss";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import RestoreIcon from "@mui/icons-material/RestartAlt";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import Upload from "@mui/icons-material/Upload";
import SaveIcon from "@mui/icons-material/Save";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import PageDocumentSlider from "./components/doc-slider-page/doc-slider-page";
import Button from "@mui/material/Button";
import Title from "../../components/title/title";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../components/loading";
import Drawer from "@material-ui/core/Drawer";
import { WrapperDocPlace } from "./components/wrapper/style";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { LiMenu, LiRename, Message, useStyles } from "./style";
import { postDeleteDoc, postDocDownload } from "../../store/explorer/actions";
import { editDocument } from "../../services/docService";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import PrintIcon from "@mui/icons-material/Print";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ViewIcon from "@mui/icons-material/Visibility";
import HistoryIcon from "@mui/icons-material/History";
import {
  getDetailFolderList,
  getDocumentPageList,
  getDocumentVersions,
  restoreDocumentVersion,
} from "../../store/document/actions";
import OptionMenuDocPlace from "../Explorer/components/files/optionsMenu";
import { confirmAlert } from "react-confirm-alert";
import TextField from "@material-ui/core/TextField";

//DOC-1520
import { getTreeList } from "../../store/tree/actions";
import { ShareDocPlace } from "../Explorer/components/files/share";
import { MoveDocPlace } from "../Explorer/components/files/move";
import { RenameDocPlace } from "../Explorer/components/files/raname";
import { ReprocessDocPlace } from "../Explorer/components/files/reprocess";
import InfoIcon from "@mui/icons-material/Info";
import { Alert } from "@mui/material";
//*** end DOC-1520 */
//DOC-2071
import ButtonOptions from "./components/button-options/button-options";
import SignatureDrawerList from "./components/signature-drawer-list/signature-drawer-list";
import DrawIcon from "@mui/icons-material/Draw";
import PermissionChecker from "../../services/permissionsChecker";
import ShareIcon from "@mui/icons-material/Share";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GetAppIcon from "@mui/icons-material/GetApp";
import Swal from "sweetalert2";
// end DOC-2071
import { uploadAndAppendDocument } from "../../services/FileUploadService";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FileUploadDocPlace from "../DocViewer/components/fileUpload/fileUpload";
import Tooltip from "@mui/material/Tooltip";

let textPanelRef = "";
let highlightPanelRef = "";
let shapePanelRef = "";
let deleteWord = "";
const plan = JSON.parse(localStorage.getItem("docplace"))?.plan?.id;
var pagesState = [];
const DocViewerDocPlace = (props) => {
  const notify = (msj, event) => toast[event](msj);
  const classes = useStyles();
  const params = useParams();
  const { isSignerRequests } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startPage, setStartPage] = useState(true);
  const [item, setItem] = React.useState("");
  const [pageSelected, setPageSelected] = React.useState(0);
  const [pageId, setPageId] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(1000);
  const [showOption, setShowOption] = React.useState(false);
  const [showMove, setShowMove] = React.useState(false);
  const [showShare, setShowShare] = React.useState(false);
  const [showRename, setShowRename] = React.useState(false);
  const [showNewFolder, setShowNewFolder] = React.useState(false);
  const [showZoom, setShowZoom] = React.useState(false);
  const [permissions, setPermissions] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [userType, setUserType] = React.useState([]);
  const [updateAllFiles, setUpdateAllFiles] = React.useState(false);
  const [pages, setPages] = React.useState([]);
  const [rotation, setRotation] = useState(0);
  const [totalFiles, setTotalFiles] = React.useState([]);
  const [showVersions, setShowVersions] = useState(false);
  const [versions, setVersions] = React.useState([]);
  const [versionId, setVersionId] = React.useState("");

  const [documentIn, setDocumentIn] = React.useState(null);
  const [isVersion, setIsVersion] = React.useState(false);
  const updateFile = (files) => {
    setTotalFiles(files);
  };
  // Usage:
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  let typePlan = [];
  if (JSON.parse(localStorage.getItem("docplace")).plan) {
    typePlan.push(JSON.parse(localStorage.getItem("docplace")).plan);
  }

  const { responseDocumentPages, errorDocumentPages, loading } = useSelector(
    (state) => ({
      responseDocumentPages: state.DetailFolder.responseDocumentPages,
      errorDocumentPages: state.DetailFolder.errorDocumentPages,
      loading: state.DetailFolder.loadingDocumentPages,
    })
  );

  /**DOC 1520 */
  const [mapPath, setMapPath] = useState([]);
  const { responseTree, errorTree, loading3 } = useSelector((state) => ({
    responseTree: state.Tree.responseTree,
    errorTree: state.Tree.errorTree,
    loading3: state.Tree.loadingTree,
  }));
  const [tree, setTree] = useState({
    label: "",
    value: "",
    parent: "",
  });
  const { responseDeleteDoc, errorDeleteDoc, loading6 } = useSelector(
    (state) => ({
      responseDeleteDoc: state.Explorer.responseDeleteDoc,
      errorDeleteDoc: state.Explorer.errorDeleteDoc,
      loading6: state.Explorer.loadingDeleteDoc,
    })
  );

  const { responseVersions, errorVersions, loadingVersions } = useSelector(
    (state) => ({
      responseVersions: state.DetailFolder.responseDocumentVersions,
      errorVersions: state.DetailFolder.errorDocumentVersions,
      loadingVersions: state.DetailFolder.loadingDocumentVersions,
    })
  );

  const { responseRestoreVersion, errorRestoreVersion, loadingRestoreVersion } =
    useSelector((state) => ({
      responseRestoreVersion: state.DetailFolder.responseRestoreDocumentVersion,
      errorRestoreVersion: state.DetailFolder.errorRestoreDocumentVersion,
      loadingRestoreVersion: state.DetailFolder.loadingRestoreDocumentVersion,
    }));

  const [pageSize, setPageSize] = useState(200);
  const [inMove, setInMove] = useState(false);
  const [users, setUsers] = useState("");
  const [showReprocess, setShowReprocess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { responseDocumentPermissions, loadingDocumentPermissions } =
    useSelector((state) => ({
      responseDocumentPermissions:
        state.DetailFolder.responseDocumentPermissions,
      loadingDocumentPermissions: state.DetailFolder.loadingDocumentPermissions,
    }));
  // Doc-2071
  const [openSignatureDrawer, setOpenSignatureDrawer] = React.useState(false);
  // end Doc-2071

  //end DOC-1520
  useEffect(() => {
    if (responseDocumentPermissions) {
      setPermissions(responseDocumentPermissions);
    }
  }, [responseDocumentPermissions]);

  useEffect(() => {
    if (params.id !== "null" && params.id) {
      //    if (!responseDocumentPages) {
      if (startPage === true) {
        dispatch(getDocumentPageList({ id: params.id }, page, size, navigate));
        dispatch(getDocumentVersions(params.id, navigate));
      }
      //  }
    } else {
      navigate("/explorer");
    }
  }, [startPage]);

  const handleScroll = (e) => {
    /*  const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        if (responseDocumentPages.data.fileReferences.totalPages > size) {
          setSize(size + 10);
        }
      } */
  };

  useEffect(() => {
    if (responseDocumentPages && responseDocumentPages.data) {
      setStartPage(false);
      setItem(responseDocumentPages.data);

      pagesState = [];
      setPages([]);

      //Is an older version?
      let data = JSON.parse(localStorage.getItem("docplace-document-version"));

      if (data) {
        setIsVersion(true);
        setDocumentIn(data);
        data.fileReferences.pdfFilePages.map((page, idx) => {
          updatePages(idx, "add", page, data.fileReferences.pages[idx]);
        });
      } else {
        setIsVersion(false);
        setDocumentIn(responseDocumentPages.data);
        data = responseDocumentPages.data;
      }

      data.fileReferences.pdfFilePages.map((page, idx) => {
        updatePages(
          idx,
          "add",
          page,
          responseDocumentPages.data.fileReferences.pages[idx]
        );
      });
    }
  }, [responseDocumentPages]);

  //() => setOpen(false)
  const append = () => {
    setOpen(true);
  };
  const refresh = () => {
    window.location.reload();
    //dispatch(getDocumentPageList({ id: params.id }, page, size, navigate));
  };
  const findPage = (id) => {
    let page = pagesState.find((item) => item.id === id);
    return page;
  };
  const rotateLeft = () => {
    let page = findPage(pageId);
    if (page) {
      let rotate = page.rotate - 90;
      if (rotate < 0) rotate = 270;

      updatePages(pageId, "rotate", rotate);
      setRotation(rotate);
    }
  };

  const rotateRight = () => {
    let page = findPage(pageId);
    if (page) {
      let rotate = page.rotate + 90;
      if (rotate > 270) rotate = 0;

      updatePages(pageId, "rotate", rotate);
      setRotation(rotate);
    }
  };

  const deletePage = (id) => {
    if (
      Swal.fire({
        title: "Delete page",
        text: "Are you sure you want to delete this page?",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          updatePages(id, "delete");
          setPageSelected(0);
        }
      })
    ) {
      return null;
    }
  };

  const moveUp = (id, idx) => {
    let page = findPage(id);
    if (page) {
      let index = pagesState.findIndex((item) => item.id === id);
      if (index > -1) {
        let item = pagesState[index - 1];
        pagesState[index - 1] = page;
        pagesState[index] = item;
        setPageSelected(idx);
        setPages(pagesState);
      }
    }
  };

  const moveDown = (id, idx) => {
    let page = findPage(id);
    if (page) {
      let index = pagesState.findIndex((item) => item.id === id);
      if (index < pagesState.length - 1) {
        let item = pagesState[index + 1];
        pagesState[index + 1] = page;
        pagesState[index] = item;
        setPageSelected(idx);
        setPages(pagesState);
      }
    }
  };

  const updatePages = (id, action, value, thumb) => {
    if (action == "add") {
      pagesState.push({ id: id, UrlPdf: value, rotate: 0, thumb: thumb });
    }
    if (action == "delete") {
      pagesState = pagesState.filter((item) => item.id !== id);
    }
    if (action == "rotate") {
      let item = pagesState.find((x) => x.id == id);
      if (item) {
        item.rotate = value;
      }
    }
    setPages(pagesState);
  };
  /*
  useEffect(() => {
    dispatch(getDocumentPageList({ id: params.id }, page, size, navigate));
  }, [size]);


  useEffect(() => {
    if (size > 10)
      dispatch(getDocumentPageList({ id: params.id }, page, size, navigate));
  }, [size]);
*/

  useEffect(() => {
    setUserType(typePlan?.map((user) => user.id));
  }, []);

  // const back = () => {
  //   if (plan === "1" || plan === "6" || plan === null) {
  //     navigate("/explorer/" + item?.fkArchive?.id);
  //     return false;
  //   } else {
  //     navigate("/eSignatureInbox");
  //   }
  // };

  const back = () => {
    const plans = ["1", "6", "2", null];
    if (plans.includes(plan) && isSignerRequests !== "true") {
      navigate("/explorer/" + item?.fkArchive?.id);
    } else if (isSignerRequests === "true") {
      navigate("/Esignature-requests");
    } else {
      navigate("/eSignatureInbox");
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowOption(open);
  };

  const openZoom = () => {
    setShowZoom(!showZoom);
  };

  const loadTextPanel = () => {
    this.stopFreeDrawing();
    let currentElementFromCanvas = this.canvas.getActiveObject();

    if (!currentElementFromCanvas) {
      currentElementFromCanvas = this.createTextBox();
    }

    this.setState({
      ...this.state,
      showPanel: true,
      currentObjectType: "text",
      currentObjectSelected: currentElementFromCanvas,
      targetRef: textPanelRef,
    });
  };

  const loadHighlightPanel = () => {
    this.setState({
      ...this.state,
      showPanel: true,
      currentObjectType: "highlight",
      targetRef: highlightPanelRef,
    });
  };

  const loadShapePanel = () => {
    this.stopFreeDrawing();
    this.setState({
      ...this.state,
      showPanel: true,
      currentObjectType: "rect",
      targetRef: shapePanelRef,
    });
  };

  const reload = () => {
    this.requestPageForDocuments(this.props.match.params);
    this.getAllDocumentPages();
  };

  const openOption = () => {
    setShowOption(true);
  };
  function getMimeType(url) {
    // Extract the file extension from the URL
    const ext = url.split(".").pop();

    // Map file extensions to MIME types
    const mimeTypes = {
      // Image
      bmp: "image/bmp",
      gif: "image/gif",
      jpeg: "image/jpeg",
      jpg: "image/jpeg",
      png: "image/png",
      svg: "image/svg+xml",
      tif: "image/tiff",
      tiff: "image/tiff",
      webp: "image/webp",

      // Document
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      odp: "application/vnd.oasis.opendocument.presentation",
      ods: "application/vnd.oasis.opendocument.spreadsheet",
      odt: "application/vnd.oasis.opendocument.text",
      pdf: "application/pdf",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      rtf: "application/rtf",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      zip: "application/zip",
    };

    // Return the corresponding MIME type or an empty string if the extension is not recognized
    return mimeTypes[ext.toLowerCase()] || "";
  }

  const viewFile = async (url) => {
    // Reverse proxy, the best option is to correctly set the CORS rules in AZURE.
    fetch(url, {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }) // FETCH BLOB FROM IT
      .then((response) => response.blob())
      .then((blobu) => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        var blob = new Blob([blobu], { type: getMimeType(url) }); //this makes the magic
        var blobURL = URL.createObjectURL(blob);

        var iframe = document.createElement("iframe"); //load content in an iframe to print later

        document.body.appendChild(iframe);

        iframe.style.display = "none";
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
            setShowLoading(false);
          }, 1);
        };
      })
      .catch((err) => {
        toast.error(`There was an error reading the file ` + err);
        setShowLoading(false);
      });
  };

  const printOption = (page) => {
    setShowLoading(true);
    let fileToPrint = null;
    if (page) {
      fileToPrint = page;
      //"Print the page"
    } else {
      fileToPrint = item.fileReferences.pdf;
      //"Print the document"
    }
    try {
      var URL = fileToPrint;
      viewFile(URL);
    } catch (error) {
      toast.error(`There was an error printing the file ` + error);
      setShowLoading(false);
    }
  };

  const saveDocumentVersion = () => {
    fetch(item.fileReferences.file)
      .then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = item.fileReferences.file;
          alink.click();
        });
      })
      .catch((e) => {
        toast.error(`There was an error saving the document ` + e);
      });
  };

  const save = (upload) => {
    setShowLoading(true);
    let bpages = [];

    pages.map((page, idx) => {
      bpages.push({
        UrlPdf: page.UrlPdf,
        Action: "ROTATE",
        Grades: page.rotate,
      });
    });
    let body = {
      documentId: params.id,
      TypeAction: "Edit",
      pages: bpages,
    };

    editDocument(body, (event) => {})
      .then((response) => {
        toast.success("Document has been saved.");
        if (upload === true) {
          saveUpload();
        } else {
          setShowLoading(false);
          refresh();
        }
      })
      .catch((error) => {
        toast.error(
          "There is an error saving the document. Please contact your administrator." +
            error
        );
        setShowLoading(false);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const saveDocumentVersions = () => {
    if (this.props.pages === null) {
      return;
    }

    // save current page before get all document pages !
    try {
      this.saveCurrentCanvasPage();
    } catch (e) {}

    let id = this.state.currentDocumentId;
    const item = new Map(JSON.parse(localStorage.getItem(id)));

    if (item == null) {
      return;
    }

    // retrieve all pages
    let blobArray = null;

    // create blob array (promises)
    try {
      blobArray = this.props.pages.map((pageItem, index) => {
        // json canvas document storage ID
        const docPageKey = id + "_" + index;
        let docPageCanvasData = localStorage.getItem(docPageKey);

        // if pages has a data storage
        if (docPageCanvasData !== null) {
          // get data from json storage
          return this.loadJSONToCanvas(index, docPageCanvasData);
        } else {
          // get data form original page image
          return this.loadURLFromJSONToCanvas(item, index);
        }
      });
    } catch (e) {}

    // send files array to server POST
    if (blobArray == null) {
    } else {
      // send request only if we have all tasks completed
      Promise.all([...blobArray]).then((responseArray) => {
        try {
          this.uploadBlobFiles(responseArray);
        } catch (e) {
          toast.error(`There was an error: Error on upload blob files`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    }
  };

  const getDocumentPage = (pageNumber, id) => {
    if (pageNumber >= 0) {
      let page = findPage(id);
      if (page) {
        setRotation(page.rotate);
      }
      setPageSelected(pageNumber);
      setPageId(id);
    }
  };

  /**I still don't fully understand what is supposed to update here, according to the other code it seems to modify a type of files count, I assume that is
   * only applicable to the explorer
   */
  const callUpdateContent = (content) => {};

  /**After a successfull comment, we need to update all the information of the page */
  const callAddComment = (comment) => {
    refresh();
  };

  /**DOC-1705 download file, replicating explorer methods to docviewer */
  const { responseDocDownload, loadingDocDownload } = useSelector((state) => ({
    responseDocDownload: state.Explorer.responseDocDownload,
    loadingDocDownload: state.Explorer.loadingDocDownload,
  }));

  useEffect(() => {
    if (responseDocDownload) {
      const blob = new Blob([responseDocDownload], {
        type: "application/octetstream",
      });
      const link = document.createElement("a");
      // create a blobURI pointing to our Blob
      const lastIndex = item?.fkArchive?.name?.lastIndexOf(".");
      const nameSplit = item?.fkArchive
        ? item?.fkArchive?.name?.slice(0, lastIndex)
        : "";
      link.href = URL.createObjectURL(blob);
      link.download = item.fkArchive
        ? item.fkArchive?.originalExtension
          ? nameSplit + item.fkArchive.originalExtension
          : item.fkArchive?.name
        : "";
      // some browser needs the anchor to be in the doc
      if (link.download) {
        document.body.append(link);
        link.click();
      }
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
  }, [responseDocDownload]);

  useEffect(() => {}, [loadingDocDownload]);

  /**End of DOC-1705 */

  const optionMenu = (opt) => {
    switch (opt) {
      case "download":
        {
          if (item.fkArchive?.originalExtension) {
            let type = item.fkArchive?.originalExtension
              .toUpperCase()
              .replace(".", "");
            dispatch(
              postDocDownload(
                {
                  archiveId: item.fkArchive.id,
                  fileName: item.fileReferences.originalFileName
                    ? item.fileReferences.originalFileName
                    : item.fkArchive.name,
                  type: type,
                },
                navigate
              )
            );
            setShowOption(false);
          } else {
            notify(
              "Something is wrong, is not possible download the document!",
              "error"
            );
          }

          return null;
        }
        break;
      case "move":
        {
          getInitialTree(item);
          setShowMove(true);
          setShowOption(false);
          setInMove(true);
        }
        break;
      case "rename":
        {
          setShowRename(true);
          setShowOption(false);
          setInMove(true);
        }
        break;
      case "delete":
        {
          deleteItem();
          setShowOption(false);
          setInMove(true);
        }
        break;
      case "share":
        {
          setShowShare(true);
          setShowOption(false);
        }
        break;
      case "reprocess":
        {
          setShowReprocess(true);
          setShowOption(false);
          setInMove(true);
        }
        break;
      case "fileLocation":
        {
          back();
          setShowOption(false);
        }
        break;
      default: {
      }
    }
    return null;
  };

  /**build on the fly the message to delete the file */
  const deleteItem = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deleteConfirm custom-ui">
            <h1 style={{ margin: "-43px 0px 0px 0px" }}>Are you sure?</h1>
            <p>
              Write the text "DELETE" in the following box and confirm the
              deletion of the item. The item will be moved to the trash bin and
              remain there for 30 days before being permanently deleted
            </p>

            <TextField
              style={{
                width: "100%",
                backgroundColor: "#fff",
                marginBottom: "26px",
              }}
              id="delete"
              variant="filled"
              onChange={(e) => (deleteWord = e.target.value)}
              label="Write DELETE"
            />

            <Button className={classes.btnCancel} autoFocus onClick={onClose}>
              NO
            </Button>

            <Button
              className={classes.btnDelete}
              autoFocus
              onClick={() => {
                if (deleteWord === "DELETE") {
                  callDelete();
                  onClose();
                } else {
                  toast.error("Wrong Key!");
                }
              }}
            >
              Yes, Delete it!
            </Button>
          </div>
        );
      },
    });
  };

  /**
   * DOC 1520
   */

  //Move functions
  /** navigates up in the tree */
  const backTree = (item) => {
    let newMap = [];
    let end = mapPath.length;
    let origin = mapPath[mapPath.length - 1].parent;
    dispatch(
      getTreeList({ parentArchiveId: item.value, direction: "UP" }, navigate)
    );
    if (!item.value) setTree([]);
    mapPath.map((map, idx) => {
      if (map.id != mapPath[end - 1].id) {
        newMap.push(map);
      }
    });
    dispatch(
      getDetailFolderList(
        {
          parentArchiveId:
            newMap.length > 0 ? newMap[newMap.length - 1].id : origin,
          page: 1,
          pageSize: pageSize,
        },
        navigate
      )
    );
    setMapPath(newMap);
    setPage(1);
  };

  /**Opens the move modal*/
  const callMove = (moveFile) => {
    setShowMove(false);
    if (inMove) {
      setTimeout(() => {
        setInMove(false);
        window.history.go(-1);
      }, 4000);
    }
  };

  /**closed action */
  const closeModal = () => {
    setShowMove(false);
    setInMove(false);
    setShowShare(false);
    setShowRename(false);
    setShowReprocess(false);
    setInMove(false);
  };

  /**Get the current map from the url params, because the map is build through the navigation in the explorer,  */
  const getInitialTree = (item) => {
    let map = []; //DOC 1705 always set to root
    setMapPath(map);
    dispatch(
      getTreeList(
        {
          parentArchiveId: map.length > 0 ? map[map.length - 1].id : "",
          direction: "",
        },
        navigate
      )
    );
  };

  /**Action after a successfull share */
  const callShare = (shareFiles) => {
    setShowShare(false);
    notify("The file was successfully shared", "success");
    //props.shareFile({ shareFiles: shareFiles, item: item });
  };

  /**After a successfull remane */
  const callRename = (renameFiles) => {
    setShowRename(false);
    refresh();
  };

  /**Call reprocess image */
  const callReprocess = (reprocessFiles) => {
    setShowReprocess(false);
  };

  /**Call for delete the current file */
  const callDelete = () => {
    let id = [];
    setPage(1);
    id.push(item.folder ? item.id : item.fkArchive.id);
    dispatch(postDeleteDoc(id, navigate));
  };

  /**Hook to return to explorer after the successfull erase of the current document, takes 4 seconds to read the successfull message box */
  useEffect(() => {
    if (responseDeleteDoc && inMove) {
      setTimeout(() => {
        setInMove(false);
        window.history.go(-1);
      }, 4000);
    }
  }, [responseDeleteDoc]);

  const saveUpload = () => {
    setShowLoading(true);
    totalFiles.map((file) => {
      //setUploaded(true);
      //setMessageText("Uploading document");
      uploadAndAppendDocument(file, true, null, null, params.id, (event) => {
        //showLoading8(true);
      })
        .then((response) => {
          notify("File has been uploaded and appended.", "success");
          //setCloseUploadFiles(true);
          setOpen(false);
          setShowLoading(true);
          //function to reload window in 3 seconds
          refresh();
        })
        .catch((err) => {
          setShowLoading(true);
          setOpen(false);
          toast.error(err?.response?.data?.error?.messages[0].message);
        })
        .finally(() => {
          //setShowLoading(false);
        });
    });

    // setUpDocType({});
  };

  useEffect(() => {
    if (responseVersions && responseVersions.data) {
      setVersions(responseVersions.data);
    }
  }, [responseVersions]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    p: 4,
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    bgcolor: "background.paper",
    p: 4,
  };

  const RestoreModal = ({ open, onClose, onConfirm }) => {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to restore this version?
          </Typography>
          <Typography
            component={"div"}
            id="modal-modal-description"
            sx={{ mt: 2, float: "right" }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={onConfirm}
              sx={{ ml: 2 }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ ml: 2 }}
              onClick={onClose}
            >
              No
            </Button>
          </Typography>
        </Box>
      </Modal>
    );
  };

  const viewVersion = (version) => {
    localStorage.setItem("docplace-document-version", JSON.stringify(version));
    setShowVersions(false);
    navigate(0);
  };

  const goDefault = () => {
    localStorage.removeItem("docplace-document-version");
    navigate(0);
  };

  const setDefault = (id) => {
    setVersionId(id);
    setShowRestoreModal(true);
  };

  const restoreVersion = () => {
    let body = {
      documentId: params.id,
      versionId: versionId,
    };
    setShowLoading(true);
    dispatch(restoreDocumentVersion(body, navigate));
  };

  useEffect(() => {
    if (responseRestoreVersion) {
      //notify("The older version has been restored and set as default.", "success");
      setShowRestoreModal(false);
      setShowVersions(false);
      localStorage.removeItem("docplace-document-version");
      setTimeout(() => {
        navigate(0);
        setShowLoading(false);
      }, 3000);
    }
  }, [responseRestoreVersion]);

  useEffect(() => {
    if (errorRestoreVersion) {
      setShowLoading(false);
      notify("There was an error restoring the older version.", "error");
      setShowRestoreModal(false);
      //refresh();
    }
  }, [errorRestoreVersion]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DocPlace Doc Viewer</title>
        </MetaTags>
        <Container fluid>
          <Title title={"Doc Viewer"} viewActive={false} />
          <ToastContainer autoClose={3000} />
          <LoadingComponent show={loading} text={"Getting Document Data..."} />
          <LoadingComponent show={showLoading} text={"Processing..."} />
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Upload and append
              </Typography>
              <Typography
                component={"div"}
                id="modal-modal-description"
                sx={{ mt: 2 }}
              >
                <Message>
                  Select a document from your computer to upload. You can also
                  drag and drop the file.
                </Message>
              </Typography>

              <FileUploadDocPlace
                uploadF={updateFile.bind(this)}
                updateAllFiles={updateAllFiles}
              ></FileUploadDocPlace>

              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  save(true);
                }}
                sx={{ mt: 2 }}
                style={{ float: "right" }}
              >
                Append
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpen(false)}
                sx={{ mt: 2, mr: 2 }}
                style={{ float: "right" }}
              >
                Cancel
              </Button>
            </Box>
          </Modal>
          <RestoreModal
            open={showRestoreModal}
            onClose={() => setShowRestoreModal(false)}
            onConfirm={() => {
              // Perform restore logic here
              // ...
              restoreVersion();
              setShowRestoreModal(false);
            }}
          />
          <Modal
            open={showVersions}
            onClose={() => setShowVersions(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Older Versions
              </Typography>
              <Typography
                component={"div"}
                id="modal-modal-description"
                sx={{ mt: 2 }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>Version</th>
                      <th>Creation date</th>
                      <th>View</th>
                      <th>Restore</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versions.map(function (element, i) {
                      const formattedDate = new Date(
                        element.createdAt
                      ).toLocaleDateString();
                      return (
                        <tr key={i}>
                          <td>{element.version}</td>
                          <td>{formattedDate}</td>
                          <td>
                            {" "}
                            <Button
                              onClick={() => viewVersion(element)}
                              sx={{ mt: 2 }}
                              title="View this version"
                              style={{ textAlign: "center" }}
                            >
                              <ViewIcon />
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() => setDefault(element.id)}
                              sx={{ mt: 2 }}
                              title="Restore this version"
                              style={{ textAlign: "center" }}
                            >
                              <RestoreIcon />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowVersions(false)}
                sx={{ mt: 2 }}
                style={{ float: "right" }}
              >
                Close
              </Button>
            </Box>
          </Modal>

          <WrapperDocPlace className="docViewer">
            <Drawer
              anchor="bottom"
              open={showOption}
              onClose={toggleDrawer(false)}
            >
              <div onClick={toggleDrawer(false)} className={classes.closeOpt}>
                CLOSE
              </div>
              <OptionMenuDocPlace
                notShowOpen={true}
                done={refresh.bind(this)}
                addComment={callAddComment.bind(this)}
                updateFile={callUpdateContent.bind(this)}
                click={optionMenu.bind(this)}
                item={item ? item : null}
                permissions={permissions}
              />
            </Drawer>
            <nav>
              <ul style={{ position: "relative" }}>
                <li className="backBtn">
                  <Button
                    style={{ padding: "9px 8px" }}
                    className="btnSearchSimple2"
                    color="primary"
                    onClick={back}
                    startIcon={<ArrowBackIosNewIcon />}
                  >
                    Back
                  </Button>
                </li>
                {isVersion === false && (
                  <>
                    <PermissionChecker page={"explorer"}>
                      <li className="itemGroup">
                        <Tooltip title={"Rotate left"}>
                          <button
                            title={"Rotate left"}
                            type="button"
                            onClick={rotateLeft}
                            className="btn-doc btn-doc-primary"
                          >
                            <RotateLeftIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title={"Rotate right"}>
                          <button
                            title={"Rotate right"}
                            type="button"
                            onClick={rotateRight}
                            className="btn-doc btn-doc-primary"
                          >
                            <RotateRightIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title={"Move up"}>
                          <button
                            disabled={pageSelected === 0}
                            type="button"
                            title={"Move up"}
                            onClick={() => moveUp(pageId, pageSelected - 1)}
                            className="btn-doc btn-doc-primary"
                          >
                            <MoveUpIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title={"Move down"}>
                          <button
                            disabled={pageSelected === pages.length - 1}
                            type="button"
                            title={"Move down"}
                            onClick={() => moveDown(pageId, pageSelected + 1)}
                            className="btn-doc btn-doc-primary"
                          >
                            <MoveDownIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title={"Delete page"}>
                          <button
                            disabled={pages.length === 1}
                            type="button"
                            title="Delete page"
                            onClick={() => deletePage(pageId)}
                            className="btn-doc btn-doc-primary"
                          >
                            <DeleteIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title={"Zoom in"}>
                          <button
                            title={"Zoom in"}
                            type="button"
                            className="btn-doc btn-doc-primary"
                            onClick={openZoom.bind(this)}
                          >
                            <ZoomInIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title={"Refresh"}>
                          <button
                            type="button"
                            title={"Refresh"}
                            className="btn-doc btn-doc-primary"
                            onClick={refresh.bind(this)}
                          >
                            <RestartAltIcon />
                          </button>
                        </Tooltip>
                      </li>
                      <li className="itemGroup sp">
                        <Tooltip title={"Print document"}>
                          <button
                            type="button"
                            title="Print document"
                            className="btn-doc btn-doc-primary"
                            onClick={() => printOption(null)}
                          >
                            <PrintIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title={"Print the current page"}>
                          <button
                            type="button"
                            title="Print the current page"
                            className="btn-doc btn-doc-primary"
                            onClick={() =>
                              printOption(
                                documentIn.fileReferences.pdfFilePages[
                                  pageSelected
                                ]
                              )
                            }
                          >
                            <FileCopyIcon />
                          </button>
                        </Tooltip>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          position: "relative",
                          margin: "8px 15px 0 0",
                          float: "right",
                          gap: "5px",
                        }}
                      >
                        <Tooltip title={"Upload and append"}>
                          <button
                            type="button"
                            title={"Upload and append"}
                            className="btn-doc-new btn-doc-primary"
                            onClick={append.bind(this)}
                          >
                            <Upload />
                          </button>
                        </Tooltip>
                        <Tooltip title={"Open File Location"}>
                          <button
                            type="button"
                            className="btn-doc-new btn-doc-primary"
                            onClick={back}
                            title={"Open File Location"}
                          >
                            <DriveFolderUploadIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title={"Save"}>
                          <button
                            type="button"
                            title={"Save"}
                            className="btn-doc-new btn-doc-primary"
                            onClick={save.bind(this)}
                          >
                            <SaveIcon />
                          </button>
                        </Tooltip>

                        <Tooltip title={"Document versions"}>
                          <button
                            color="secondary"
                            className="btn-doc-new btn-doc-primary"
                            onClick={() => setShowVersions(true)}
                            title="Document versions"
                            disabled={versions.length === 0}
                          >
                            <HistoryIcon />
                          </button>
                        </Tooltip>
                        <div>
                          <ButtonOptions
                            setOpen={setOpenSignatureDrawer}
                            printOption={printOption}
                            openOption={openOption.bind(this)}
                            documentId={params.id}
                          />
                        </div>
                      </li>
                    </PermissionChecker>
                    <PermissionChecker page={"eSignatureInbox"}>
                      <LiRename>
                        <Button
                          color="primary"
                          onClick={() => {
                            optionMenu("rename");
                          }}
                          startIcon={<BorderColorIcon />}
                          sx={{ textTransform: "none" }}
                        >
                          Rename
                        </Button>
                      </LiRename>
                      <LiMenu>
                        <Button
                          color="primary"
                          onClick={() => {
                            optionMenu("share");
                          }}
                          startIcon={<ShareIcon />}
                          sx={{ textTransform: "none" }}
                        >
                          Share
                        </Button>
                      </LiMenu>
                      <LiMenu>
                        <Button
                          color="primary"
                          onClick={() => {
                            optionMenu("download");
                          }}
                          startIcon={<GetAppIcon />}
                          sx={{ textTransform: "none" }}
                        >
                          Download
                        </Button>
                      </LiMenu>
                      <LiMenu>
                        <Button
                          color="error"
                          onClick={() => {
                            optionMenu("delete");
                          }}
                          startIcon={<DeleteForeverIcon />}
                          sx={{ textTransform: "none" }}
                        >
                          Delete
                        </Button>
                      </LiMenu>
                      <li
                        style={{
                          float: "right",
                          marginRight: "15px",
                        }}
                      >
                        <div
                          className="eSignatureAlone"
                          onClick={() => setOpenSignatureDrawer(true)}
                        >
                          <DrawIcon
                            style={{
                              fontSize: "18px",
                              margin: "3px 6px 0 6px",
                            }}
                          />
                          <div className="eSignatureText">eSignature</div>
                        </div>
                      </li>
                    </PermissionChecker>

                    <li>
                      <Button
                        className="save"
                        style={{
                          display: "none",
                        }}
                        onClick={save.bind(this)}
                        startIcon={<SaveIcon />}
                      ></Button>
                    </li>
                  </>
                )}
              </ul>

              {/*<ul className="btnPrintNavDocument">
                <li>
                  <Button
                    className="save"
                    style={{
                      display: "none"
                    }}
                    onClick={saveDocumentVersion.bind(this)}
                    startIcon={<SaveIcon />}
                  >
                    DOWNLOAD
                  </Button>
                </li>
              </ul> */}
            </nav>

            {isVersion && (
              <Alert
                icon={<InfoIcon fontSize="inherit" />}
                severity="warning"
                style={{}}
              >
                You are viewing an read-only older version ({documentIn.version}
                ) of this file from{" "}
                {new Date(documentIn.createdAt).toLocaleDateString()}. Click on
                the{" "}
                <span onClick={goDefault} className="restore">
                  here
                </span>{" "}
                to open the latest version or{" "}
                <span
                  onClick={() => setDefault(documentIn.id)}
                  className="restore"
                >
                  here
                </span>{" "}
                to set this version as the latest version.
              </Alert>
            )}

            {responseDocumentPages && (
              <article className="content">
                <section className="left">
                  <div className="div" onScroll={(e) => handleScroll(e)}>
                    <PageDocumentSlider
                      data={responseDocumentPages ? pages : []}
                      retrivePageHandle={getDocumentPage.bind(this)}
                      deletePage={deletePage.bind()}
                      moveUp={moveUp.bind(this)}
                      moveDown={moveDown.bind(this)}
                      rotateLeft={rotateLeft.bind(this)}
                      rotateRight={rotateRight.bind(this)}
                    />
                  </div>
                </section>
                <section className="right">
                  <Drawer
                    anchor={"right"}
                    open={openSignatureDrawer}
                    onClose={() => setOpenSignatureDrawer(false)}
                  >
                    <SignatureDrawerList
                      data={item.id}
                      closeSignature={() => setOpenSignatureDrawer(false)}
                    />
                  </Drawer>
                  <div className="div">
                    <div
                      className={!showZoom ? "" : "none"}
                      style={{ transform: `rotate(${rotation}deg)` }}
                    >
                      <img
                        src={pages ? pages[pageSelected]?.thumb : ""}
                        className={classes.thumbnail}
                        alt=""
                      />
                    </div>

                    <div className={!showZoom ? "none" : ""}>
                      <TransformWrapper
                        initialScale={1}
                        initialPositionX={200}
                        initialPositionY={100}
                      >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                          <React.Fragment>
                            <div
                              className="tools"
                              style={{ textAlign: "left" }}
                            >
                              <button
                                type="button"
                                className="buttonZoomW btn-doc btn-doc-primary"
                                ref={(ref) => (textPanelRef = ref)}
                                onClick={() => zoomIn()}
                              >
                                <ZoomInIcon />
                              </button>
                              <button
                                type="button"
                                className="buttonZoomW btn-doc btn-doc-primary"
                                ref={(ref) => (textPanelRef = ref)}
                                onClick={() => zoomOut()}
                              >
                                <ZoomOutIcon />
                              </button>
                              <button
                                type="button"
                                className="buttonZoomW btn-doc btn-doc-primary"
                                ref={(ref) => (textPanelRef = ref)}
                                title="Reset Zoom"
                                onClick={() => resetTransform()}
                              >
                                <DeleteSweepIcon />
                              </button>
                            </div>

                            <TransformComponent>
                              <img
                                style={{ width: "100%" }}
                                src={
                                  documentIn
                                    ? documentIn.fileReferences.pages[
                                        pageSelected
                                      ]
                                    : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                }
                                alt="test"
                              />
                            </TransformComponent>
                          </React.Fragment>
                        )}
                      </TransformWrapper>
                    </div>
                  </div>
                </section>
              </article>
            )}
          </WrapperDocPlace>
        </Container>
      </div>
      <MoveDocPlace
        backTree={backTree.bind(this)}
        treePrevius={mapPath ? mapPath : []}
        move={callMove.bind(this)}
        tree={responseTree ? responseTree : []}
        close={closeModal.bind(this)}
        itemMove={item}
        show={showMove}
      />
      <ShareDocPlace
        share={callShare.bind(this)}
        itemShare={item}
        users={users}
        close={closeModal.bind(this)}
        show={showShare}
      />
      <RenameDocPlace
        rename={callRename.bind(this)}
        itemRename={item}
        close={closeModal.bind(this)}
        show={showRename}
      />
      <ReprocessDocPlace
        reprocess={callReprocess.bind(this)}
        itemReprocess={item}
        close={closeModal.bind(this)}
        show={showReprocess}
      />
    </React.Fragment>
  );
};

export default DocViewerDocPlace;
