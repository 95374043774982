import styled from "styled-components";
import { colors } from "../../../components/theme";

export const WrapperForm = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  background-color: ${colors.ground};
  margin-top: 20px;
  overflow: hidden;
`;

export const NavForm = styled.nav`
  border-radius: 5px;
  background-color: #4546460a !important;
  width: 100%;
`;

export const UlForm = styled.ul`
  position: relative;
  list-style-type: none;
  display: inline-block;
  width: 100%;
  height: 20px !important;
`;
export const LiBackForm = styled.li`
  display: inline-block;
  padding-left: 15px !important;
  float: left;
  margin: 6px 0 0 0 !important;
`;

export const LiDownload = styled.li`
  display: inline !important;
  margin-right: 35px !important;
  float: right !important;
  margin-top: 6px !important;
`;
export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 4px;

  margin-top: 0px;
  margin-bottom: 10px;
`;
