import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import TagIcon from "@mui/icons-material/LocalOffer";
import TagMenu from "@mui/icons-material/KeyboardArrowDown";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {updateLabeledData} from "../../../../services/ocr";
import {toast} from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

export function FieldsDocPlace(props) {
  const classes = useStyles();
  const [labels, setLabels] = useState([{ label: "" }]);
  const [label, setLabel] = useState("");
  const [modalLabel, setModalLabel] = useState("");

  const [fmtCurrency, setFmtCurrency] = useState(false);

  const [optional, setOptional] = useState(false);


  const [fmtDate, setFmtDate] = useState(false);
  const [fmtString, setFmtString] = useState(true);

  const [currentLabel, setCurrentLabel] = useState("");
  const [colorArray, setColorArray] = useState(props.colorArray);

  const [labelType, setLabelType] = React.useState("string");
  const [labelFormat, setLabelFormat] = React.useState("not-specified");

  const [open, setOpen] = React.useState(false);

  const handleOpen = (label) => {
    setModalLabel(label.label);
    setLabelType(label.type);
    setLabelFormat(label.fmt);
    setOptional(label.optional);
    setFmtString(label.type === "string");
    setFmtDate(label.type === "date");
    setFmtCurrency(label.type === "number");

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChangeSelect = (event) => {
    setFmtString(event.target.value === "string");
    setFmtDate(event.target.value === "date");
    setFmtCurrency(event.target.value === "number");
    setLabelType(event.target.value);
  };

  const handleFormatChange = (event) => {
    setLabelFormat(event.target.value);
  };

  useEffect(() => {
    props.getLabels(labels);
  }, [labels]);

  useEffect(() => {
    setLabels(props.setFields);
  }, [props.setFields]);

  useEffect(() => {
    if (props.deleteLabels === true) setLabels([{ label: "" }]);
  }, [props.deleteLabels]);

  const getSnakeCase = (value) => {
    let v = value
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("_");

    return v;
  };

  const handleChange = (event) => {
    if (event.target.value.length < 31) setLabel(event.target.value);
  };

  const handleChangeOptional = (event) => {
    setOptional(event.target.checked)
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addLabel();
      e.preventDefault(); // Let's stop this event.
      e.stopPropagation();
    }
  };
  const selectLabel = (label, i) => {
    setCurrentLabel(label);
    props.selectLabel(label, i);
  };

  const addLabel = () => {
    let newLabel = labels;
    let data = {
      label: label,
      type: "string",
      fmt: "not-specified",
      text: [],
    };
    newLabel.push(data);
    setLabel("");
    props.getLabels(labels, true);
  };

  // const deleteLabel = (txt) => {
  //   let newLabel = labels;
  //   newLabel = newLabel.filter((e) => e.label !== txt);
  //   setLabels(newLabel);
  //   setLabel("");
  //   handleClose();
  //
  //   deleteAllTags(txt);
  //   props.deleteRegion(txt);
  // };

  const deleteLabel = async (txt) => {
    let data = {
      region: props.regions,
      allRegions: [],
      labels: labels,
      document: '',
      current: '',
      documents: [],
      currentDocId: -1,
      counter: 0,
      currentLabelIndex: label.length,
      doc: {},
    };
    return  updateLabeledData(props.docTypeID, data)
    .then((response) => {
      toast.success("DocType changes have been updated");
      let newLabel = labels;
      newLabel = newLabel.filter((e) => e.label !== txt);
      setLabels(newLabel);
      setLabel("");
      handleClose();
      deleteAllTags(txt);
      props.deleteRegion(txt);
    })
    .catch((error) => {
      toast.error(error.response.data.error.messages[0].message);
      handleClose();
    })
  };

  const updateLabelType = (label) => {
    //Find index of specific object using findIndex method.
    let objIndex = labels.findIndex((obj) => obj.label == label);
    labels[objIndex].type = labelType;
    labels[objIndex].fmt = labelFormat;
    labels[objIndex].optional = optional;
    props.getLabels(labels, true);
    setOpen(false);
  };

  const isSelected = (text) => {
    try {
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (freelancer) {
          return freelancer.data.label == text;
        });
        //We found the match, hence it has been selected
        return typeof result[0] !== "undefined";
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteText = (text, label) => {
    props.deleteText(text, label);
  };

  const deleteAllTags = (label) => {
    try {
      //console.log(label)
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (item) {
          return item.data.label == label;
        });
        //We found the match, hence it has been selected
        result.map((item, i) => {
          deleteText(item.data.text, label);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const trimLabel = (label, limit) => {
    var dots = "...";
    if (label.length > limit) {
      label = label.substring(0, limit) + dots;
    }

    return label;
  }


  const showText = (label) => {
    try {
      //console.log(label)
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (item) {
          return item.data.label == label;
        });
        //We found the match, hence it has been selected
        return (
          <>
            {result.map((item, i) => (
              <span
                key={i + 9999}
                onClick={() => deleteText(item.data.text, label)}
                className={classes.textListItem}
              >
                {" "}
                {item.data.text}
              </span>
            ))}{" "}
          </>
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Tag: {modalLabel}
          </Typography>
          <Typography
            component={"div"}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Value type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={labelType}
                label="Value type"
                onChange={handleChangeSelect}
              >
                <MenuItem value={"string"}>string</MenuItem>
                <MenuItem value={"number"}>number</MenuItem>
                <MenuItem value={"date"}>date</MenuItem>
                <MenuItem value={"time"}>time</MenuItem>
                <MenuItem value={"integer"}>integer</MenuItem>
                <MenuItem value={"selectionMark"}>selectionMark</MenuItem>
              </Select>
            </FormControl>
          </Typography>

          <Typography component={"div"} id="modal-modal-format" sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Format</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={labelFormat}
                label="Value type"
                onChange={handleFormatChange}
              >
                <MenuItem value={"not-specified"}>
                  Default (not specified)
                </MenuItem>
                {fmtString && (
                  <MenuItem value={"no-whitespaces"}>No whitespaces</MenuItem>
                )}
                {fmtString && (
                  <MenuItem value={"alphanumeric"}>Alphanumeric</MenuItem>
                )}
                {fmtCurrency && (
                  <MenuItem value={"currency"}>Currency</MenuItem>
                )}
                {fmtDate && <MenuItem value={"dmy"}>DMY</MenuItem>}
                {fmtDate && <MenuItem value={"mdy"}>MDY</MenuItem>}
                {fmtDate && <MenuItem value={"ymd"}>YMD</MenuItem>}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>

              <FormGroup>
                <FormControlLabel control={<Checkbox
                  defaultValue={optional}
                  checked={optional}
                  onChange={handleChangeOptional}
                  inputProps={{ 'aria-label': 'controlled' }}
                />} label="Optional" />

              </FormGroup>


            </FormControl>
          </Typography>



          <Button
            variant="contained"
            color="success"
            onClick={() => updateLabelType(modalLabel)}
            sx={{ mt: 2, mr: 2 }}
          >
            Ok
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
            sx={{ mt: 2 }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="info"
            onClick={() => deleteLabel(modalLabel)}
            sx={{ mt: 2, float: "right" }}
          >
            Delete
          </Button>
        </Box>
      </Modal>
      <div className="addLabel" key={props.tagKey}>
        <Paper
          component="form"
          square
          sx={{ p: "1px", display: "flex", alignItems: "center" }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            className={classes.ltInput}
            placeholder="Add label"
            autoFocus
            inputProps={{ "aria-label": "add label" }}
            value={label}
            onChange={handleChange}
            onKeyDown={handleKeyDown}

          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          {/**DOC-1719 clicking in the icon add the current label */}
          <IconButton
            color="primary"
            sx={{ p: "10px" }}
            aria-label="tag"
            onClick={addLabel}
          >
            <TagIcon />
          </IconButton>
        </Paper>

        <div className="labelsList" key={96856}>
          {labels.map(
            (text, i) =>
              text.label !== "" && (
                <div key={i + 100}>
                  <Paper
                    component="form"
                    className={
                      currentLabel == text.label
                        ? classes.ltLabelSelected
                        : classes.ltLabelUnselected
                    }
                    key={i}
                    variant="outlined"
                    square
                    sx={{ p: "1px 1px", display: "flex", alignItems: "left" }}
                  >
                    <div
                      className="bm"
                      style={{ backgroundColor: "#006ac6" }}
                    ></div>
                    <div
                      sx={{ ml: 1, flex: 1 }}
                      title={`${text.label} [${text.type} - ${text.fmt}]`}
                      onClick={() => selectLabel(text.label, i)}
                      className={
                        isSelected(text.label)
                          ? classes.ltSelected
                          : classes.ltUnSelected
                      }
                      key={i}
                    >

                      {" "}
                      {trimLabel(text.label, 20)}
                    </div>

                    <IconButton
                      onClick={() => handleOpen(text)}
                      color="primary"
                      sx={{ p: "2px" }}
                    >
                      <TagMenu sx={{ p: "2px" }} key={i + 200} />
                    </IconButton>
                  </Paper>
                  <div className={classes.textList} key={i + 800}>
                    {showText(text.label)}{" "}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
}

export default FieldsDocPlace;
