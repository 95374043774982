import React from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  ContainerTextField,
  DivHelperTextTitle,
  useStyles,
  WrapperHeader,
  CTitle
} from "./style";
import { colors } from "../../../../components/theme";

const HeaderViewDocPlace = (props) => {
  const classes = useStyles();

  return (
    <WrapperHeader>
      <Container>
      <CTitle>{props.title}</CTitle>
          <>
            <ButtonContainer>
              {props.viewPdf ? (
              <Button
                variant="text"
                onClick={props.onChangeViewPdf}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notIntoPdfHeader && colors.gray3,
                }}
                startIcon={<VisibilityOffIcon />}
              >
                Hide in PDF
              </Button>) : (
              <Button
                variant="text"
                onClick={props.onChangeViewPdf}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notIntoPdfHeader && colors.gray3,
                }}
                startIcon={<VisibilityIcon />}
              >
                Show in PDF
              </Button>)}
            </ButtonContainer>
         
            <TextField
              className={classes.headerStyle}
              id="header"
              label="Header title"
              placeholder="Enter yout Title"
              variant="standard"
              fullWidth={true}
              required={true}
              onChange={props.onChangeTitle}
              value={props._title}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "2rem",
                  fontWeight: "bold",
                },
              }}
            />
            <TextField
              id="description"
              label="Header description"
              placeholder="Enter yout Description"
              variant="standard"
              value={props._description}
              fullWidth={true}
              required={true}
              onChange={props.onChangeDescription}
              InputProps={{
                disableUnderline: true,
              }}
            />
           
          
          </>
      </Container>
    </WrapperHeader>
  );
};

export default HeaderViewDocPlace;
