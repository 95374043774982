import React, { useEffect, useState } from "react";
import { NotePath, useStyles, WpFileWatcher, WpForm } from "./style";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import PathTree from "../detalle/pathTree";
import InputDocPlace from "../../../../components/form/input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../../components/loading";
import { uploadFile } from "../../../../services/FileUploadService";
import { postDocTypeHeader } from "../../../../services/docTypeService";

export function FormWrapperDocPlace(props) {
  const classes = useStyles();
  const notify = (msj, event) => toast[event](msj);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading3, setLoading3] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingUP, setLoadingUP] = useState(0);

  const [startPage] = useState(true);
  const [name, setName] = useState({ value: "", error: false });
  const [description, setDescription] = useState({ value: "", error: false });
  const [fileWatcher, setFileWatcher] = useState(true);
  const [files, setFiles] = useState([]);
  const [path, setPath] = useState({ value: "", error: false });
  const [responseUpdate, setResponseUpdate] = useState("");
  const [emptyPath, setEmptyPath] = useState(false);

  const { responseTree, loading } = useSelector((state) => ({
    responseTree: state.Tree.responseTree,
    loading: state.Tree.loadingTree,
  }));

  useEffect(() => {
    if (props.files) {
      setFiles(props.files);
    }
  }, [props.files]);

  useEffect(() => {
    if (responseUpdate) {
      if (files.length >= loadingUP) {
        setLoading3(false);
        props.callBack(responseUpdate.id);
      }
      setLoadingUP(loadingUP + 1);
    }
  }, [responseUpdate]);

  const createDocType = () => {
    if (
      name.value &&
      description.value &&
      (fileWatcher ? path.value : !path.value)
    ) {
      setEmptyPath(false);
      let data = {
        customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
        name: name.value,
        description: description.value,
        targetArchiveId: fileWatcher ? path.value.value : null,
        ignoreOcr: props.masterTemplate ? false : props.outFileWatcher,
        masterTemplate: !!props.masterTemplate,
      };

      setLoading2(true);

      postDocTypeHeader(data, (event) => {})
        .then((response) => {
          setLoading2(false);
          if (response.data && response.data.id) {
            if (props.outFileWatcher) {
              props.callBack(response.data.id);
            }

            if (files.length > 0) {
              setLoading3(true);
              let totalUploadFile = files.length;
              let countSendFile = 0;
              files.map((file, index) => {
                uploadFile(file, true, response.data.id, (event) => {
                  setLoading3(true);
                })
                  .then((response1) => {
                    countSendFile++;
                    notify(file.name + " uploaded", "success");
                    if (countSendFile >= totalUploadFile) {
                      setResponseUpdate(response.data);
                    }
                  })
                  .catch((err) => {
                    notify(err, "error");
                  });
              });
            }
            setFiles([]);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.messages[0].message);
          setLoading2(false);
        })
        .finally(() => {
          setLoading2(false);
        });
    } else {
      if (!name.value || !description.value || !path.value) {
        if (!name.value) {
          setName({ value: name.value, error: true });
        }
        if (!description.value) {
          setDescription({ value: description.value, error: true });
        }
        if (!path.value) {
          setEmptyPath(true);
        }
      }
      toast.error("Please enter all the required fields");
    }
  };

  return (
    <>
      <LoadingComponent
        show={loading || loading3 || loading2}
        text={"Creating Document Type..."}
      />
      <WpForm>
        <div className="container">
          <div className="row" style={{ marginTop: "22px" }}>
            <div className={classes.textRequired}></div>
            <div className="col-sm-6">
              <InputDocPlace
                id={"name"}
                field={name}
                setField={setName}
                required={true}
                type="text"
                label="Name"
                maxLength={40}
              />
            </div>
            <div className="col-sm-6">
              <WpFileWatcher>
                File Watcher uploads{" "}
                <Switch
                  defaultChecked
                  onChange={(v) => {
                    setFileWatcher(v.currentTarget.checked);
                  }}
                />
              </WpFileWatcher>
            </div>
            {fileWatcher ? (
              <div className="col-sm-12">
                <div>* Default Folder</div>
                <NotePath>
                  This is the location where your files will be uploaded when
                  processed by the File Watcher
                </NotePath>
                <PathTree
                  emptyPath={emptyPath}
                  style={{ marginTop: "30px" }}
                  tree={responseTree ? responseTree : []}
                  hidenReservedFolder={true}
                  callBack={(v) => {
                    setPath({
                      value: v,
                      error: false,
                    });
                  }}
                />
              </div>
            ) : null}

            <div className="col-sm-12">
              <InputDocPlace
                id={"description"}
                field={description}
                setField={setDescription}
                required={true}
                type="text"
                label="Description"
                maxLength={200}
              />
            </div>
          </div>
          {5 - files.length <= 0 ? (
            <Button
              onClick={createDocType.bind(this)}
              className={classes.nextButton}
              variant="contained"
              color="success"
            >
              Next Step
            </Button>
          ) : (
            props.outFileWatcher && (
              <Button
                onClick={createDocType.bind(this)}
                className={classes.nextButton}
                variant="contained"
                color="success"
              >
                Next Step
              </Button>
            )
          )}
        </div>
      </WpForm>
    </>
  );
}

export default FormWrapperDocPlace;
