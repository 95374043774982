import styled from 'styled-components';
import {colors} from "../../../../components/theme";

import { makeStyles, useTheme } from '@material-ui/core/styles';

export const SquareBox = styled.div `
  position: relative;
  width: 200px;
    height: 220px;
    background-color: rgb(238, 245, 251);
    border-radius: 5px;
    &:hover {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23199CE3FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      cursor: pointer;
    }
}
`;


export const BoxTitle = styled.div `
  position: absolute;
  top: 130px;
 margin: 0 auto;
  padding: 0 10px;
  color: #006ac6;
  width: 100%;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgb(0, 106, 198);
    
    word-break: break-word;
    line-height: 1;
    text-overflow: ellipsis;
  max-width: 200px;
    text-overflow: ellipsis;
  
    div {
      margin-top: 20px;
      font-size: 14px;
    }
   
`;
export const BoxThumbnail = styled.div `
position: absolute;
top: 35%;
left: 50%;
transform: translate(-50%, -35%);
width: 40px;
height: 65px;
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`;
export const BoxShipments = styled.div `
position: absolute;
top: 170px;   
left: 96px;
font-size: 14px;
color: #006ac6;
span{
  font-weight: bold;
}
`;
export const BoxCompleted = styled.div `
position: absolute;
top: 190px;   
left: 96px;
color: #006ac6;
font-size: 14px;
span{
  font-weight: bold;
}
`;
export const BoxDate = styled.div `
  position: absolute;
  top: 10px;
  left: 10px;
  color: #006ac6;
  font-size: 20px;
  width: 120px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgb(0, 106, 198);
   
    word-break: break-word;
    line-height: 1;
    
    text-overflow: ellipsis;
    overflow: hidden;
   
`;
export const BurgerIcon = styled.label `
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: transparent;
  div {
    width: 30px;
    height: 5px;
    background-color: #333;
  }
`;

export const PopupMenu = styled.div `
  display: ${
    (props) => (props.show ? 'block' : 'none')
};
  position: absolute;

  background-color: #fff;
  border: 1px solid rgb(238, 245, 251);
  padding: 10px;
  width: 200px;
  height: 220px;
    border-radius: 5px;
    .popmenu {
     
    margin-top: 50px;
    margin-left: 40px;

    font-size: 12px;
padding-left: 0px;
    text-align: left;
    cursor: pointer;
    a {
      text-decoration: none;
      color: #006ac6;

      :hover {
        font-weight: bold;
      }

    }
    .line {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      color: #006ac6;
      div {
        margin-right: 10px;
      }

      :hover {
        font-weight: bold;
      } 
      

    }
  
}
`;

export const useStyles = makeStyles((theme) => ({
  rowClear: {
      margin: 0,
      padding: 0,
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden'
  },
  templateLabeling: {
      backgroundColor: '#fff',
      overflowY: 'auto',
      height: '100%',
      position: 'relative',
      overflowX: 'hidden'
  },
  attributeLabeling: {
      backgroundColor: '#fff',
      overflowY: 'auto',
      height: '100%',
  },
  Labeling: {
      backgroundColor: '#fff'
  },
  newTemplate: {
      width: '100%',
      backgroundColor: '#006ac6 !important',
      margin: '15px auto !important',
      textAlign: 'center',
      display: 'block',
      lineHeight: '1 !important'
  },
  optionMenu: {
      float: 'left',
      width: '50%',
      height: '40px',
      backgroundColor: '#607d8b',
      textAlign: 'center',
      paddingTop: '9px',
      color: '#fff',
      cursor: 'pointer',
      margin: '0'
  },
  optionWrapper: {
      position: "fixed",
      bottom: 0,
      width: '14%',
      overflowX: 'hidden'
  },
  deleteAttribute: {
      right: '-28px',
      position: 'absolute',
      color: 'red',
      top: '-12px',
      cursor: 'pointer'
  },
  wrapperAside: {
      height: 'calc(100vh - 144px)',
      overflowY: 'auto'
  },
  wrapperTools: {
      height: 'auto',
      overflowY: 'auto'
  },
   ltLabelUnselected: {
      marginTop: '0px',
      cursor: 'pointer',
      with: '100%',
      textAlign: 'left',
      textTransform: 'capitalize',
      width: '100%',
      border: 'none !important',
      // boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      padding: '4px  !important',
      paddingLeft: '10px !important'
  },
  ltLabelSelected: {
      marginTop: '0px',
      cursor: 'pointer',
      with: '100%',
      textAlign: 'left',
      textTransform: 'capitalize',
      backgroundColor: 'rgba(253,255,50, 50%) !important',
      width: '100%',
      border: 'none !important',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      padding: '4px  !important',
      paddingLeft: '10px !important'
      
  },
  ltUnSelected: {
      color: '#646464 !important',
      cursor: 'pointer',
      textAlign: 'left  !important',
      textTransform: 'none !important',
      justifyContent: 'left !important',
      width: '100%',
      paddingTop: '2px',
      fontSize: '15px'

  },
  ltSelected: {
      color: '#006ac6 !important',
      fontWeight: 'bold',
      cursor: 'pointer',
      width: '100%',
      textAlign: 'left !important',
      textTransform: 'none  !important',
      justifyContent: 'left !important',
      paddingTop: '2px',
      fontSize: '15px'
  },
  textList: {
      backgroundColor: 'white',
      width: '100%',
      fontSize: '14px',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      textAlign: 'left',
      paddingLeft: '8px',
      cursor: 'pointer'
  },
  textListItem: {
      display: 'inline-block',
      color: 'gray',
      marginRight: '4px',
      fontSize: '12px',
      padding: '4px'
  },
  ltInput: {
      marginTop: '0px !important',
      fontSize: '14px !important',
      padding: '4px',
      border: '1px solid lightgray',
      borderRadius: '4px'
 
  }
}));







