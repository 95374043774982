import { call, put, takeEvery } from "redux-saga/effects";
import {
  INPUT_METHOD_LIST,
  INPUT_METHOD_POST,
  INPUT_METHOD_PUT,
  INPUT_METHOD_DELETE,
} from "./actionTypes";

import {
  apiErrorInputMethod,
  inputMethodSuccess,
  apiErrorInputMethodPost,
  inputMethodPostSuccess,
  apiErrorInputMethodPut,
  inputMethodPutSuccess,
  apiErrorInputMethodDelete,
  inputMethodDeleteSuccess,
} from "./actions";

import { toast } from "react-toastify";

import {
  getInputMethod,
  postInputMethodAPI,
  deleteInputMethodAPI,
  putInputMethodAPI,
} from "../../helpers/call_api";
import { apiErrorRolDelete, rolDeleteSuccess } from "../rol/actions";
import { apiErrorUserPut, userPutSuccess } from "../user/actions";

function* getInputMethodList({ payload: { inputMethod, navigate } }) {
  try {
    const response = yield call(getInputMethod, inputMethod);
    yield put(inputMethodSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    yield put(apiErrorInputMethod(error));
  }
}

function* postInputMethod({ payload: { inputMethod, navigate } }) {
  try {
    const response = yield call(postInputMethodAPI, inputMethod);
    yield put(inputMethodPostSuccess(response));
    toast.success("Input Method was created!");
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorInputMethodPost(error));
  }
}

function* deleteInputMethod({ payload: { inputMethod, navigate } }) {
  try {
    const response = yield call(deleteInputMethodAPI, inputMethod);
    toast.success("Input Method was deleted");
    yield put(inputMethodDeleteSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorInputMethodDelete(error));
  }
}

function* putInputMethod({ payload: { inputMethod, navigate } }) {
  try {
    const response = yield call(
      putInputMethodAPI,
      inputMethod.id,
      inputMethod.data
    );
    toast.success("Input Method was updated");
    yield put(inputMethodPutSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorInputMethodPut(error));
  }
}

function* authSaga() {
  yield takeEvery(INPUT_METHOD_LIST, getInputMethodList);
  yield takeEvery(INPUT_METHOD_POST, postInputMethod);
  yield takeEvery(INPUT_METHOD_DELETE, deleteInputMethod);
  yield takeEvery(INPUT_METHOD_PUT, putInputMethod);
}

export default authSaga;
