import React from "react";

import { WrapperDatePicker } from "./style";
import DatePickerFormView from "./view";

const DatePickerForms = ({
  callbackIsChecked,
  isChecked,
  type,
  field,
  setField,
  label,
  inputFormat,
  getHelperText,
  getDescriptionText,
  helperText,
  descriptionText,
  getContentDataPicker,
  content,
  disabled,
  disableFuture,
  disablePast,
  notSeeIntoPDFDp,
  deleteDateP,
  settingDateP,
  notSeeIntoPdfDp,
  id,
}) => {
  const changeChecked = (event) => {
    callbackIsChecked(event.target.checked);
  };

  const setDate = (e) => {
    setField({ value: e });
  };

  const onChangeHelperText = (value) => {
    getHelperText(value.target.value);
  };

  const onChangeDescriptionText = (value) => {
    getDescriptionText(value.target.value);
  };

  const onChangeNotSeeIntoPDFDp = () => {
    notSeeIntoPDFDp((prevNotSeeIntoPDF) => !prevNotSeeIntoPDF);
  };

  const onChangeDeleteDateP = () => {
    deleteDateP(true);
  };

  const onChangeSettingDateP = () => {
    settingDateP(true);
  };

  return (
    <WrapperDatePicker>
      <DatePickerFormView
        changeChecked={changeChecked}
        isChecked={isChecked}
        type={type}
        id={id}
        field={field}
        label={label}
        inputFormat={inputFormat}
        helperText={helperText}
        descriptionText={descriptionText}
        onChangeHelperText={onChangeHelperText}
        onChangeDescriptionText={onChangeDescriptionText}
        onChangeNotSeeIntoPDFDp={onChangeNotSeeIntoPDFDp}
        onChangeSettingDateP={onChangeSettingDateP}
        onChangeDeleteDateP={onChangeDeleteDateP}
        notSeeIntoPdfDp={notSeeIntoPdfDp}
        getContentDataPicker={getContentDataPicker}
        content={content}
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        setDate={setDate}
      />
    </WrapperDatePicker>
  );
};

export default DatePickerForms;
