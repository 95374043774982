import React from "react";
import HeaderViewDocPlace from "./view";
import { useState } from "react";

const HeaderDocPlaceForms = ({
  title,

  id,

  getTitle,
  getDescription,
  viewPdf,
  _title,
  _description
}) => {
  const [show, setShow] = useState(false);

  const onChangeTitle = (value) => {

    getTitle(value.target.value,id);
  };
  const onChangeDescription = (value) => {
    getDescription(value.target.value,id);

  };

  const onChangeViewPdf = () => {
    setShow(!show);
    viewPdf(show);
  };

  

  return (
    <HeaderViewDocPlace
      title = {title}
      onChangeTitle={onChangeTitle}
      onChangeDescription={onChangeDescription}
      onChangeViewPdf={onChangeViewPdf}
      viewPdf={show}
      _title={_title}
      _description={_description}
    />
  );
};

export default HeaderDocPlaceForms;
