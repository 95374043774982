import http from "./http-common";

/* Get Labeled data */

export const getFormTemplate = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/GetFormTemplate/${documentId}`;
  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

export const getShippedForms = (body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/GetAllRequestDocumentUser`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};
export const getShippedForms2 = (body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/SearchRequestedDocuments?page=1&pageSize=2`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

export const getData = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/FormTemplateJsonById`;

  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,

      formTemplateId: documentId,
    },
  });
};

export const publishForm = (body) => {
  let url =
    process.env.REACT_APP_HOST_API + `/automation/api/v1.0/Forms/RequestForm`;
  return http.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

/* Delete Labeled data */

export const deleteFormData = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API + `/automation/api/v1.0/Forms/UpdateForm`;
  return http.put(url, [], {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      formTemplateId: documentId,
    },
  });
};

/* Update Labeled data */

export const updateData = (documentId, body) => {
  let url =
    process.env.REACT_APP_HOST_API + `/automation/api/v1.0/Forms/UpdateForm`;
  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      formTemplateId: documentId, //"04bd4225-fbb6-4d2c-bd6f-3f01adff926f"
    },
  });
};

export const updateDataRequest = (documentId, body) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/UpdateFormContentRequest`;
  return http.put(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      requestFormID: documentId, //"04bd4225-fbb6-4d2c-bd6f-3f01adff926f"
    },
  });
};

export const saveFormCompleted = (documentId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/forms/ProcessDocumentByRequest/${documentId}`;
  return http.post(
    url,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
        customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
        formTemplateId: documentId, //"04bd4225-fbb6-4d2c-bd6f-3f01adff926f"
      },
    }
  );
};

export const changeStatus = (documentId,status) => {
  let url =
    process.env.REACT_APP_HOST_API +
    `/automation/api/v1.0/Forms/ChangeStatus?id=${documentId}`;
  return http.put(
    url,
    {status:status},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
        customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      
      },
    }
  );
};

