import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Message, useStyles, WrapperForm } from "./style";
import LoadingComponent from "../../../../components/loading";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { expressions } from "../../../../components/theme";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import {
  postUserAsgRolList,
  postUserList,
  putUserAsgRolList,
  putUserList,
} from "../../../../store/user/actions";
import { getRolList } from "../../../../store/rol/actions";
import { WrapperFormOutOCR } from "../../../DocumentType/components/detalle/style";
import FormWrapperDocPlace from "../../../DocumentType/components/detalle/formWrapper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export function NewItemDocPlace(props) {
  const classes = useStyles();
  const [showNew, setShowNew] = useState(false);
  const [name, setName] = useState({ value: "", error: false });
  const [listTree, setListTree] = React.useState([]);
  const [description, setDescription] = useState({ value: "", error: false });
  const [email, setEmail] = useState({ value: "", error: false });
  const [password, setPassword] = useState({ value: "", error: false });
  const [passwordRepeat, setPasswordRepeat] = useState({
    value: "",
    error: false,
  });

  const [originID, setOriginID] = useState({ value: "", error: false });
  const [originType, setOriginType] = useState({ value: "", error: false });
  const [defaultData, setDefaultData] = React.useState("");

  const [rolesSystem, setRolesSystem] = useState({ value: "", error: false });

  const [roles, setRoles] = useState({ value: "", error: false });
  const [rolesList, setRolesList] = useState([]);

  const notify = (msj, event) => toast[event](msj);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { responseUserPost, loadingUserPost } = useSelector((state) => ({
    responseUserPost: state.User.responseUserPost,
    loadingUserPost: state.User.loadingUserPost,
  }));
  const { responseUserPut, loadingUserPut } = useSelector((state) => ({
    responseUserPut: state.User.responseUserPut,
    loadingUserPut: state.User.loadingUserPut,
  }));

  const { responseDocType1, errorDocType1, loading3 } = useSelector(
    (state) => ({
      responseDocType1: state.DocType.responseDocType1,
      errorDocType1: state.DocType.errorDocType1,
      loading3: state.DocType.loadingDocType1,
    })
  );
  const { responseRole, loadingRole } = useSelector((state) => ({
    responseRole: state.User.responseRole,
    loadingRole: state.User.loadingRole,
  }));
  const { responseRol, loadingRol } = useSelector((state) => ({
    responseRol: state.Rol.responseRol,
    loadingRol: state.Rol.loadingRol,
  }));

  useEffect(() => {
    if (responseRol) {
      let ob = [];
      {
        responseRol.data.map((doctype) =>
          ob.push({ value: doctype.id, label: doctype.name })
        );
      }
      setRolesList(ob);
    }
  }, [responseRol]);

  useEffect(() => {
    setDefaultData(props.default);
    if (props.default) {
      //updating the user,
      setName({ value: props.default.fullName, error: false });
      setEmail({ value: props.default.email, error: false });
      setDescription({ value: props.default.description, error: false });
      let current = [];
      if (props.default.archiveRoles) {
        props.default.archiveRoles.map((item) => {
          current.push({
            label: item.archiveRole.name,
            value: item.archiveRole.id,
          });
        });
      }
      setRoles({ value: current, error: false });
    } else {
      clearForm();
    }
  }, [props.default]);

  useEffect(() => {
    setShowNew(props.show);
    dispatch(getRolList({ page: 0, pageSize: 0 }, navigate));
    //dispatch(getDocTypeList1({page:0,pageSize:1000}, navigate));
  }, [props.show]);

  useEffect(() => {
    if (responseUserPost?.id) {
      let valores = [];
      roles.value.map((item) => {
        valores.push(item.value);
      });
      let obj2 = {
        ArchiveRoleIds: valores,
        ArchiveRoleId: null,
        SystemUserId: responseUserPost.id,
      };
      dispatch(postUserAsgRolList(obj2, navigate));
      clearForm();
      close();
      props.done(true);
    }
  }, [responseUserPost]);

  useEffect(() => {
    if (responseUserPut) {
      clearForm();
      close();
      props.done(true);
    }
  }, [responseUserPut]);

  const close = () => {
    props.close();
  };

  const clearForm = () => {
    setName({ value: "", error: false });
    setPassword({ value: "", error: false });
    setPasswordRepeat({ value: "", error: false });
    setEmail({ value: "", error: false });
    setRolesSystem({ value: "", error: false });
    setRoles({ value: "", error: false });
  };

  const newAcction = () => {
    if (!isFieldsError()) {
      if (defaultData) {
        //update
        let obj = {
          avatar: "",
          archiveRoleId: roles.value[0].value,
          fullName: name.value,
          systemRoleId: "a82291b5-c9c7-4b3a-9b2e-57dda9d4cdd4", //DOC-1765 burn to root, the right way should be doing it in the API itself, but the current one needs this field
        };
        let valores = [];
        roles.value.map((item) => {
          valores.push(item.value);
        });
        let obj2 = {
          ArchiveRoleIds: valores,
          ArchiveRoleId: null,
          SystemUserId: defaultData?.id,
        };
        dispatch(putUserList({ id: defaultData.id, data: obj }, navigate));
        dispatch(putUserAsgRolList(obj2, navigate));
      } else {
        //new
        let obj = {
          archiveRoleId: roles.value[0].value,
          email: email.value,
          fullName: name.value,
          password: password.value,
          systemRoleId: "a82291b5-c9c7-4b3a-9b2e-57dda9d4cdd4", //DOC-1765 burn to root, the right way should be doing it in the API itself, but the current one needs this field
        };
        dispatch(postUserList(obj, navigate)); //
      }
    }
  };

  const isFieldsError = () => {
    if (defaultData) {
      if (
        !name.error &&
        name.value &&
        !email.error &&
        email.value &&
        roles.value.length > 0 &&
        !roles.error
      ) {
        return false;
      } else {
        notify("Please enter all the required information", "error");
        return true;
      }
    } else {
      if (password.value != passwordRepeat.value) {
        notify("Password doesnt match", "error");
        return true;
      }
      if (expressions.password.test(password.value)) {
        if (
          !name.error &&
          name.value &&
          !email.error &&
          email.value &&
          roles.value.length > 0 &&
          !roles.error
        ) {
          return false;
        } else {
          notify("Please enter all the required information", "error");
          return true;
        }
      } else {
        notify(
          "The password must be at least 8 characters length and contain uppercase, lowercase characters, and at least one number",
          "error"
        );
        return true;
      }
    }
  };

  const setFormData = () => {
    close();
  };

  return (
    <Dialog
      fullScreen
      open={showNew}
      onClose={close}
      TransitionComponent={Transition}
    >
      <LoadingComponent
        show={
          loadingUserPost ||
          loadingUserPut ||
          loading3 ||
          loadingRole ||
          loadingRol
        }
        text={
          defaultData
            ? "Updating..."
            : loading3
            ? "Getting Master Scanner"
            : "Creating..."
        }
      />
      <AppBar sx={{ position: "relative" }}>
        <Toolbar style={{ backgroundColor: "#006ac6" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {defaultData ? "Update Master Scanner" : "New Master Scanner"}
          </Typography>
          <Button autoFocus color="inherit" onClick={newAcction}>
            {defaultData ? "Update" : "Create"}
          </Button>
        </Toolbar>
      </AppBar>
      <article style={{ margin: "30px" }}>
        <Message>
          To add a new master scanner, all fields must be entered.
        </Message>
        <WrapperForm>
          <WrapperFormOutOCR>
            <FormWrapperDocPlace
              tree={listTree}
              callBack={setFormData.bind(this)}
              outFileWatcher={true}
              masterTemplate={true}
              files={[]}
            />
          </WrapperFormOutOCR>
        </WrapperForm>
      </article>
    </Dialog>
  );
}

export default NewItemDocPlace;
