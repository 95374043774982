//GET_REQUIRED_PROCESS
export const GET_REQUIRED_PROCESS = "GET_REQUIRED_PROCESS";
export const GET_REQUIRED_PROCESS_SUCCESS = "GET_REQUIRED_PROCESS_SUCCESS";
export const GET_REQUIRED_PROCESS_ERROR = "GET_REQUIRED_PROCESS_ERROR";
export const GET_REQUIRED_PROCESS_CLEAR_DATA =
  "GET_REQUIRED_PROCESS_CLEAR_DATA";
// ****---END---****

//ATTACHMENT
export const GET_ALL_ATTACHMENT = "GET_ALL_ATTACHMENT";
export const GET_ALL_ATTACHMENT_SUCCESS = "GET_ALL_ATTACHMENT_SUCCESS";
export const GET_ALL_ATTACHMENT_ERROR = "GET_ALL_ATTACHMENT_ERROR";
export const GET_ALL_ATTACHMENT_CLEAR_DATA = "GET_ALL_ATTACHMENT_CLEAR_DATA";

//DELETE_ATTACHMENT
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_ERROR = "DELETE_ATTACHMENT_ERROR";
export const DELETE_ATTACHMENT_CLEAR_DATA = "DELETE_ATTACHMENT_CLEAR_DATA";

// ****---END---****

//METADATA
export const GET_ALL_METADATA = "GET_ALL_METADATA";
export const GET_ALL_METADATA_SUCCESS = "GET_ALL_METADATA_SUCCESS";
export const GET_ALL_METADATA_ERROR = "GET_ALL_METADATA_ERROR";
export const GET_ALL_METADATA_CLEAR_DATA = "GET_ALL_METADATA_CLEAR_DATA";

export const POST_METADATA = "POST_METADATA";
export const POST_METADATA_SUCCESS = "POST_METADATA_SUCCESS";
export const POST_METADATA_ERROR = "POST_METADATA_ERROR";
export const POST_METADATA_CLEAR_DATA = "POST_METADATA_CLEAR_DATA";

//DELETE_METADATA
export const DELETE_METADATA = "DELETE_METADATA";
export const DELETE_METADATA_SUCCESS = "DELETE_METADATA_SUCCESS";
export const DELETE_METADATA_ERROR = "DELETE_METADATA_ERROR";
export const DELETE_METADATA_CLEAR_DATA = "DELETE_METADATA_CLEAR_DATA";
// ****---END---****
