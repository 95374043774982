import React from "react";
import Select from "react-select";
import { useStyles } from "./style";
import "../../App.css";

const SelectDropdownDocPlace = ({
  field,
  setField,
  label,
  helperText,
  id,
  required,
  defaultList,
  arrayList,
  isMulti,
}) => {
  const classes = useStyles();

  return (
    <>
      <Select
        id={id}
        defaultValue={defaultList}
        isMulti={isMulti}
        styles={{ zIndex: "9999" }}
        name={label}
        options={arrayList}
        required={required}
        placeholder={label}
        onChange={(newValue) => {
          setField({ ...field, value: newValue });
        }}
        className={classes.selectControl + " basic-multi-select"}
        classNamePrefix="select"
      />
      {helperText && <p className={classes.helperText}>{helperText}</p>}
    </>
  );
};

export default SelectDropdownDocPlace;
