import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  CUSTOMER_USER,
  CUSTOMER_SUCCESS,
  CUSTOMER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  FORGET_PASSWORD,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  HASH_VALIDATE,
  HASH_VALIDATE_ERROR,
  HASH_VALIDATE_SUCCESS,
  RESET_APP,
  /**esignature register action */
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_CLEAR_DATA,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const customerUser = (customer, history) => {
  return {
    type: CUSTOMER_USER,
    payload: { customer, history },
  }
}

export const customerSuccess = customer => {
  return {
    type: CUSTOMER_SUCCESS,
    payload: customer,
  }
}

export const customerError = error => {
  if (error['message'] === "Cannot read properties of undefined (reading 'push')") {
    return null
  } else {
    return {
      type: CUSTOMER_ERROR,
      payload: error,
    }
  }

}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  if (error['message'] === "Cannot read properties of undefined (reading 'push')") {
    return null
  } else {
    return {
      type: API_ERROR,
      payload: error,
    }
  }

}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

/*Send password through email */
export const forgetPassword = (user, navigate) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, navigate },
  }
}
export const forgetPasswordSuccess = user => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: user,
  }
}

export const forgetPasswordError = error => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: error,
  }
}

/*reset password */
export const resetPassword = (user, navigate) => {
  return {
    type: RESET_PASSWORD,
    payload: { user, navigate },
  }
}
export const resetPasswordSuccess = user => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: user,
  }
}

export const resetPasswordError = error => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: error,
  }
}
/*hash validate */
export const hashValidate = (user, navigate) => {
  return {
    type: HASH_VALIDATE,
    payload: { user, navigate },
  }
}
export const hashValidateSuccess = user => {
  return {
    type: HASH_VALIDATE_SUCCESS,
    payload: user,
  }
}

export const hashValidateError = error => {
  return {
    type: HASH_VALIDATE_ERROR,
    payload: error,
  }
}
export const resetApp = () => {
  return {
    type: RESET_APP,
    payload: {},
  }
}


/**REGISTER ESIGNATURE USER */
export const register = (userId, user, navigate) => {
  return {
    type: REGISTER,
    payload: { userId, user, navigate },
  }
}

export const registerSuccess = user => {
  return {
    type: REGISTER_SUCCESS,
    payload: user,
  }
}

export const registerError = error => {
  return {
    type: REGISTER_ERROR,
    payload: error,
  }
}

export const registerClearData = () => {
  return {
    type: REGISTER_CLEAR_DATA,
    payload: {}
  };
};

/**END register esignature user */
