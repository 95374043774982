import {
  ROL_LIST,
  ROL_LIST_SUCCESS,
  API_ERROR_ROL,

  ROL_POST,
  ROL_POST_SUCCESS,
  API_ERROR_ROL_POST,

  ROL_PUT,
  ROL_PUT_SUCCESS,
  API_ERROR_ROL_PUT,

  ROL_DELETE,
  ROL_DELETE_SUCCESS,
  API_ERROR_ROL_DELETE,


} from "./actionTypes"

export const getRolList = (rol, navigate) => {
  return {
    type: ROL_LIST,
    payload: {rol, navigate},
  }
}

export const rolSuccess = rol => {
  return {
    type: ROL_LIST_SUCCESS,
    payload: rol,
  }
}

export const apiErrorRol = error => {
  return {
    type: API_ERROR_ROL,
    payload: error,
  }
}


export const postRolList = (rol, navigate) => {
  return {
    type: ROL_POST,
    payload: {rol, navigate},
  }
}

export const rolPostSuccess = rol => {
  return {
    type: ROL_POST_SUCCESS,
    payload: rol,
  }
}

export const apiErrorRolPost = error => {
  return {
    type: API_ERROR_ROL_POST,
    payload: error,
  }
}


export const putRolList = (rol, navigate) => {
  return {
    type: ROL_PUT,
    payload: {rol, navigate},
  }
}

export const rolPutSuccess = rol => {
  return {
    type: ROL_PUT_SUCCESS,
    payload: rol,
  }
}

export const apiErrorRolPut = error => {
  return {
    type: API_ERROR_ROL_PUT,
    payload: error,
  }
}


export const deleteRolList = (rol, navigate) => {
  return {
    type: ROL_DELETE,
    payload: {rol, navigate},
  }
}

export const rolDeleteSuccess = rol => {
  return {
    type: ROL_DELETE_SUCCESS,
    payload: rol,
  }
}

export const apiErrorRolDelete = error => {
  return {
    type: API_ERROR_ROL_DELETE,
    payload: error,
  }
}