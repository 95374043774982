import {
  REVIEWAL_LIST,
  REVIEWAL_LIST_SUCCESS,
  DOC_PERCENTAGE_LIST,
  DOC_PERCENTAGE_SUCCESS,
  DOC_SHARED_LIST,
  DOC_SHARED_SUCCESS,
  DOC_PD_LIST,
  DOC_PD_SUCCESS,
  API_ERROR_DOC_PD,
  API_ERROR_REVIEWAL,
  API_ERROR_DOC_PERCENTAGE,
  API_ERROR_DOC_SHARED,
} from "./actionTypes"

const initialState = {
  errorReviewal: null,
  responseReviewal: null,
  loadingReviewal: false,
  errorDocPercentage: null,
  responseDocPercentage: null,
  loadingDocPercentage: false,
  errorDocShared: null,
  responseDocShared: null,
  loadingDocShared: false,
  errorDocPD: null,
  responseDocPD: null,
  loadingDocPD: false,
}

const reviewal = (state = initialState, action) => {
  switch (action.type) {
    case REVIEWAL_LIST:
      state = {
        ...state,
        errorReviewal: null,
        loadingReviewal: true,
      }
      break
    case REVIEWAL_LIST_SUCCESS:
      state = {
        ...state,
        errorReviewal: null,
        responseReviewal:action.payload,
        loadingReviewal: false,
      }
      break
    case DOC_PERCENTAGE_LIST:
      state = {
        ...state,
        errorDocPercentage: null,
        loadingDocPercentage: true,
      }
      break
    case DOC_PERCENTAGE_SUCCESS:
      state = {
        ...state,
        errorDocPercentage: null,
        responseDocPercentage:action.payload,
        loadingDocPercentage: false,
      }
      break
    case DOC_SHARED_LIST:
      state = {
        ...state,
        errorDocShared: null,
        loadingDocShared: true,
      }
      break
    case DOC_SHARED_SUCCESS:
      state = {
        ...state,
        errorDocShared: null,
        responseDocShared:action.payload,
        loadingDocShared: false,
      }
      break
    case DOC_PD_LIST:
      state = {
        ...state,
        errorDocPD: null,
        loadingDocPD: true,
      }
      break
    case DOC_PD_SUCCESS:
      state = {
        ...state,
        errorDocPD: null,
        responseDocPD:action.payload,
        loadingDocPD: false,
      }
      break
    case API_ERROR_DOC_PD:
      state = { ...state,
        errorDocPD: action.payload,
        loadingDocPD: false,
        responseDocPD:null
      }
      break
    case API_ERROR_DOC_SHARED:
      state = { ...state,
        errorDocShared: action.payload,
        loadingDocShared: false,
        responseDocShared:null
      }
      break
    case API_ERROR_DOC_PERCENTAGE:
      state = { ...state,
        errorDocPercentage: action.payload,
        loadingDocPercentage: false,
        responseDocPercentage:null
      }
      break
    case API_ERROR_REVIEWAL:
      state = { ...state,
        errorReviewal: action.payload,
        loadingReviewal: false,
        responseReviewal:null
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default reviewal
