import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@material-ui/core/Box";
import { Container, ContainerButtons, SubTitle, Title } from "./style";
import Button from "@mui/material/Button";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteIcon from "@mui/icons-material/Delete";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/material/styles";
import { css } from "styled-components";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 3,
};

export const ModalConfirmDelete = (props) => {
  const CustomBackdrop = styled(Backdrop)(
    ({ theme }) => css`
      background-color: rgba(255, 0, 0, 0.54) !important;
    `
  );

  return (
    <Modal
      open={props.openModalConfirm}
      onClose={() => {
        props.setOpenModalConfirm(false);
      }}
      slots={{ backdrop: CustomBackdrop }}
    >
      <Box sx={style}>
        <Container>
          <Title>Are you sure?</Title>
          <SubTitle>
            {`You’re about to delete the document attachment `}
            <strong>{props.attachmentItem?.fileName}</strong>
            {`.`}
          </SubTitle>
          <ContainerButtons>
            <Button
              variant="outlined"
              color={"primary"}
              startIcon={<DoDisturbIcon />}
              onClick={() => {
                props.setOpenModalConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color={"error"}
              startIcon={<DeleteIcon />}
              onClick={() => {
                props.confirmDeleteAttachment(props.attachmentItem);
              }}
            >
              Delete
            </Button>
          </ContainerButtons>
        </Container>
      </Box>
    </Modal>
  );
};
