import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useStyles } from "./style";

import { Container } from "reactstrap";
import TitleDocPlace from "../../components/title/title";
import SearchDocPlace from "../../components/search/search";
import GridDocPlace from "./components/grid/grid";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LoadingComponent from "../../components/loading";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { getRolList, deleteRolList } from "../../store/rol/actions";
import NewItemDocPlace from "./components/new/new";

const DocumentTypeDocPlace = (props) => {
  const notify = (msj, event) => toast[event](msj);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startPage] = useState(true);
  const [dataRols, setDataRols] = useState([]);
  const [showNew, setShowNew] = React.useState(false);
  const [docTypesAPI, setDocTypesAPI] = useState([]);
  const [page] = useState(1);
  const [pageSize] = useState(1000);
  const [defaultData, setDefaultData] = React.useState("");

  const { responseRol, loadingRol } = useSelector((state) => ({
    responseRol: state.Rol.responseRol,
    loadingRol: state.Rol.loadingRol,
  }));
  const { responseRolDelete, loadingRolDelete } = useSelector((state) => ({
    responseRolDelete: state.Rol.responseRolDelete,
    loadingRolDelete: state.Rol.loadingRolDelete,
  }));

  useEffect(() => {
    dispatch(getRolList({ page: page, pageSize: pageSize }, navigate));
  }, [startPage, page, pageSize]);

  useEffect(() => {
    if (responseRol && responseRol.data) {
      setDataRols(responseRol.data);
      setDocTypesAPI(responseRol.data);
    }
  }, [responseRol]);

  useEffect(() => {
    if (responseRolDelete !== null) {
      dispatch(getRolList({ page: page, pageSize: pageSize }, navigate));
    }
  }, [responseRolDelete]);

  const findDocumentTypes = (term) => {
    setDataRols(
      term
        ? docTypesAPI.filter((doc) => doc.name.toLowerCase().includes(term))
        : docTypesAPI
    );
  };

  const optionGrid = (value) => {
    if (value.action === "edit") {
      setDefaultData(value.row.row);
      setShowNew(true);
    } else {
      deleteItem(value.id);
    }
  };

  const deleteItem = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Are you sure?",
      text: "",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        dispatch(deleteRolList(id, navigate));
      }
    });
  };

  const searchFile = (filterSearch) => {
    findDocumentTypes(filterSearch);
  };

  const refresh = () => {
    setDefaultData(null);
    dispatch(getRolList({ page: page, pageSize: pageSize }, navigate));
  };

  const closeModal = () => {
    setDefaultData(null);
    setShowNew(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DocPlace Roles</title>
        </MetaTags>
        <Container fluid>
          <TitleDocPlace title={"Roles"} viewActive={false} />
          <ToastContainer autoClose={3000} />
          <LoadingComponent
            show={loadingRol || loadingRolDelete}
            text={loadingRol ? "Getting Roles..." : "Deleting Rol"}
          />
          <SearchDocPlace callSearch={searchFile.bind(this)} />
          <div className={classes.WrapperNew}>
            <Button
              onClick={() => {
                setDefaultData("");
                setShowNew(true);
              }}
              style={{ marginTop: "20px" }}
              size="small"
              color="primary"
              variant="contained"
            >
              <AddBoxIcon />
              <span>New</span>
            </Button>
          </div>
          <GridDocPlace
            data={dataRols ? dataRols : []}
            option={optionGrid.bind(this)}
          />
        </Container>
      </div>
      <NewItemDocPlace
        done={refresh.bind(0, this)}
        close={closeModal.bind(this)}
        default={defaultData}
        show={showNew}
      />
    </React.Fragment>
  );
};

export default DocumentTypeDocPlace;
