import React, { useState } from "react";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

import ParagraphView from "./view";

const Paragraph = ({
  getContent = () => {},
  content,
  type,
  notSeeIntoPDF,
  notSeeIntoPdfParagraph,
  deleteParagraph,
  settingParagraph,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onChangeNotSeeIntoPDF = () => {
    notSeeIntoPDF((prevNotSeeIntoPDF) => !prevNotSeeIntoPDF);
  };

  const onChangeSetting = () => {
    settingParagraph(true);
  };

  const onChangeDelete = () => {
    deleteParagraph(true);
  };

  const onEditorStateChange = (editorState) => {
    if (editorState) {
      getContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
    setEditorState(editorState);
  };
  return (
    <React.Fragment>
      <ParagraphView
        type={type}
        content={content}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        onChangeNotSeeIntoPDF={onChangeNotSeeIntoPDF}
        onChangeSetting={onChangeSetting}
        onChangeDelete={onChangeDelete}
        notSeeIntoPdfParagraph={notSeeIntoPdfParagraph}
      />
    </React.Fragment>
  );
};

export default Paragraph;
