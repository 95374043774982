export const DETAIL_FOLDER_LIST = "DETAIL_FOLDER_LIST"
export const DOCUMENT_PAGES_LIST = "DOCUMENT_PAGES_LIST"
export const DOCUMENT_PAGES_LIST_SUCCESS = "DOCUMENT_PAGES_LIST_SUCCESS"
export const API_ERROR_DOCUMENT_PAGES = "API_ERROR_DOCUMENT_PAGES"
export const DETAIL_FOLDER_LIST_SUCCESS = "DETAIL_FOLDER_LIST_SUCCESS"
export const API_ERROR_DETAIL_FOLDER = "API_ERROR_DETAIL_FOLDER"
export const SEARCH_ARCHIVES_LIST = "SEARCH_ARCHIVES_LIST"
export const SEARCH_ARCHIVES_LIST_SUCCESS = "SEARCH_ARCHIVES_LIST_SUCCESS"
export const API_ERROR_SEARCH_ARCHIVES = "API_ERROR_SEARCH_ARCHIVES"
export const DOCUMENT_PERMISSIONS = "DOCUMENT_PERMISSIONS"
export const DOCUMENT_PERMISSIONS_SUCCESS = "DOCUMENT_PERMISSIONS_SUCCESS"
export const DOCUMENT_PERMISSIONS_ERROR = "DOCUMENT_PERMISSIONS_ERROR"
export const DOCUMENT_VERSIONS = "DOCUMENT_VERSIONS"
export const DOCUMENT_VERSIONS_SUCCESS = "DOCUMENT_VERSIONS_SUCCESS"
export const DOCUMENT_VERSIONS_ERROR = "DOCUMENT_VERSIONS_ERROR"
export const DOCUMENT_VERSIONS_CLEAR_DATA = "DOCUMENT_VERSIONS_CLEAR_DATA"

export const RESTORE_DOCUMENT_VERSION = "RESTORE_DOCUMENT_VERSION"
export const RESTORE_DOCUMENT_VERSION_SUCCESS = "RESTORE_DOCUMENT_VERSION_SUCCESS"
export const RESTORE_DOCUMENT_VERSION_ERROR = "RESTORE_DOCUMENT_VERSION_ERROR"
export const RESTORE_DOCUMENT_VERSION_CLEAR_DATA = "RESTORE_DOCUMENT_VERSION_CLEAR_DATA"

// List require signature for document
export const LIST_REQUIRE_SIGNATURE_DOCUMENT = "LIST_REQUIRE_SIGNATURE_DOCUMENT"
export const LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS = "LIST_REQUIRE_SIGNATURE_DOCUMENT_SUCCESS"
export const LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR = "LIST_REQUIRE_SIGNATURE_DOCUMENT_ERROR"
export const LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA = "LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA"
// END

// Resend Signature request email
export const RESEND_SIGNATURE_REQUEST = "RESEND_SIGNATURE_REQUEST"
export const RESEND_SIGNATURE_REQUEST_SUCCESS = "RESEND_SIGNATURE_REQUEST_SUCCESS"
export const RESEND_SIGNATURE_REQUEST_ERROR = "RESEND_SIGNATURE_REQUEST_ERROR"
export const RESEND_SIGNATURE_REQUEST_CLEAR_DATA = "RESEND_SIGNATURE_REQUEST_CLEAR_DATA"
//****--END--****

