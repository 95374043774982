export const DOCTYPE_LIST = "DOCTYPE_LIST";
export const DOCTYPE_LIST_SUCCESS = "DOCTYPE_LIST_SUCCESS";

export const DOCTYPE_ATTRIBUTES_LIST = "DOCTYPE_ATTRIBUTES_LIST";
export const DOCTYPE_ATTRIBUTES_LIST_SUCCESS =
  "DOCTYPE_ATTRIBUTES_LIST_SUCCESS";

export const DOCTYPE_DELETE = "DOCTYPE_DELETE";
export const DOCTYPE_DELETE_SUCCESS = "DOCTYPE_DELETE_SUCCESS";

export const DOCTYPE_UPDATE = "DOCTYPE_UPDATE";
export const DOCTYPE_UPDATE_SUCCESS = "DOCTYPE_UPDATE_SUCCESS";

export const DOCTYPE_HEADER_POST = "DOCTYPE_HEADER_POST";
export const DOCTYPE_HEADER_SUCCESS = "DOCTYPE_HEADER_SUCCESS";

export const DOCTYPE_UPLOAD_POST = "DOCTYPE_UPLOAD_POST";
export const DOCTYPE_UPLOAD_SUCCESS = "DOCTYPE_UPLOAD_SUCCESS";

export const DOCTYPE_LIST1 = "DOCTYPE_LIST1";
export const DOCTYPE_LIST1_SUCCESS = "DOCTYPE_LIST1_SUCCESS";

export const API_ERROR_DOCTYPE_ATTRIBUTES = "API_ERROR_DOCTYPE_ATTRIBUTES";
export const API_ERROR_DOCTYPE_DELETE = "API_ERROR_DOCTYPE_DELETE";
export const API_ERROR_DOCTYPE_UPDATE = "API_ERROR_DOCTYPE_UPDATE";
export const API_ERROR_DOCTYPE = "API_ERROR_DOCTYPE";
export const API_ERROR_DOCTYPE1 = "API_ERROR_DOCTYPE1";
export const API_ERROR_DOCTYPE_HEADER = "API_ERROR_DOCTYPE_HEADER";
export const API_ERROR_DOCTYPE_UPLOAD = "API_ERROR_DOCTYPE_UPLOAD";
