import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Step1 from "./Components/step1";
import Step3 from "./Components/step3";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { register } from "../../store/auth/actions";
import Loading from "../../components/loading";
import character from "../../assets/character.png";
import plant from "../../assets/plant.png";
import { uploadSignature } from "../../services/FileUploadService";

const steps = ["", "", "All set"]; //

const RegisterSteps = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const [dataStep1, setStep1] = useState({
    /**General information step */ fullName: "",
    nationality: "",
    email: "",
    userId: "",
  });
  const [dataStep2] = useState({
    /**validation ID step */ documentType: "",
    idNumber: "",
    iDate: "",
    eDate: "",
    skipped: true, //true to skip the STEP 2, //change to false, when add the validation ID again
  });
  const [dataStep3, setStep3] = useState({
    /**Signature step */ tycAccepted: false,
    sign: "",
    signText: "",
    rawSign: "",
  });

  const { errorRegister, responseRegister, loadingRegister } = useSelector(
    (state) => ({
      /**Creating esignature user */ errorRegister: state.Login.errorRegister,
      responseRegister: state.Login.responseRegister,
      loadingRegister: state.Login.loadingRegister,
    })
  );

  /**BEGIN Navigation */
  const back = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const next = () => {
    if (activeStep < steps.length) setActiveStep(activeStep + 1);
  };
  /**END navigation */

  /**Create esignature user, we validate the values before reach to this point*/
  useEffect(() => {
    if (responseRegister) {
      //success
      /**convert the canvas into a file */
      uploadSignature(
        convertToImg(dataStep3.rawSign),
        dataStep1.fullName,
        dataStep1.email,
        (event) => {
          //setLoading8(true);
        }
      )
        .then((response) => {
          toast.success("Esignature registered successfully!");
        })
        .catch((error) => {
          toast.error(
            "Esignature info success, but signature failed! Error: " +
              error?.response?.data?.error?.messages[0]?.message
          );
        });
      next();
    }
  }, [responseRegister]);

  useEffect(() => {
    if (errorRegister) {
      toast.error(
        "System responded with: " +
          errorRegister?.response?.data?.error?.messages[0]?.message
      );
    }
  }, [errorRegister]);

  /**Converting the base 64 to blob image */
  const convertToImg = (base64Image) => {
    const byteCharacters = atob(
      base64Image.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: "image/png" });
  };

  /**Call the api to create the user, it is based in 2 steps,
   * 1) complement the information of the internal user
   * 2) call the api to store the signature.
   */
  const createUser = () => {
    let user = {
      nationality: dataStep1.nationality.value, //
      accept_terms_condition: 1, //they can't reach to here without accepting terms and conditions
    };
    dispatch(register(dataStep1.userId, user, navigate)); //ready to call the admin,
  };

  /**Navigate to login */
  const navigateDashboard = () => {
    navigate("/eSignatureRequested");
  };

  return (
    <Grid container spacing={3} sx={{ minHeight: "640px" }}>
      <Grid item xs={0} sm={3} md={3} lg={4}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: { xs: "none", md: "block" },
          }}
        >
          <img
            src={plant}
            alt="Plant"
            style={{
              height: "200px",
              bottom: "0px",
              right: "0px",
              position: "absolute",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
          <Box sx={{ width: "100%", marginTop: "30px" }}>
            <ToastContainer />
            <Loading show={loadingRegister} text="Creating Esignature user" />
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              <Box sx={{ marginTop: "30px" }}>
                {activeStep === 0 && (
                  <Step1
                    back={back}
                    next={next}
                    data={dataStep1}
                    isFromDocPlace={true}
                    setData={setStep1}
                  />
                )}
                {activeStep === 1 && (
                  <Step3
                    back={back}
                    next={next}
                    data={dataStep3}
                    setData={setStep3}
                    createUser={createUser}
                  />
                )}
                {activeStep === 2 && (
                  <Box textAlign="center">
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{ mt: 2, mr: 2 }}
                      onClick={navigateDashboard}
                      color="success"
                    >
                      Go to eSignature
                    </Button>
                  </Box>
                )}
              </Box>
            </React.Fragment>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={0} sm={3} md={3} lg={4}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: { xs: "none", md: "block" },
          }}
        >
          <img
            src={character}
            alt="Character"
            style={{
              height: "400px",
              bottom: "0px",
              left: "0px",
              position: "absolute",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegisterSteps;
