import React, {useState, useEffect} from "react";
import Button from '@mui/material/Button';
import {
    useStyles
} from './style';
import TextField from '@material-ui/core/TextField';
import LoadingComponent from "../../../../components/loading";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {postRenameDoc} from "../../../../store/explorer/actions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export function RenameDocPlace(props,prev) {
    const classes = useStyles();
    const [name, setName] = React.useState('');
    const [showNew, setShowNew] = useState(false);
    const [item, setItem] = useState(false);

    const notify = (msj,event) => toast[event](msj);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [invalidName, setInvalidName] = useState(false);
    const { responseRenameDoc, errorRenameDoc,loading } = useSelector(state => ({
        responseRenameDoc: state.Explorer.responseRenameDoc,
        errorRenameDoc: state.Explorer.errorRenameDoc,
        loading: state.Explorer.loadingRenameDoc,
    }))


    useEffect(() => {
        setItem(props.itemRename);
        setShowNew(props.show)
    }, [props.show]);
    useEffect(() => {
        if(responseRenameDoc !== null){
            props.rename(name);
            close();
        }
    }, [responseRenameDoc]);


    const close = () =>{
        setInvalidName(false);
         props.close();
    }

    const renameFile = () => {
        var format = /[\\/<>":|?*]/;
        if (format.test(name)) {
            setInvalidName(true)
        } else {
            setInvalidName(false)
            let obj = {
                name: name,
            }
            dispatch(postRenameDoc({ data: obj, id: item.fkArchive.id }, navigate));
        }
    }


    return (
        <BootstrapDialog
            onClose={() =>{
                close()
            }}
            style={{overflow:'hidden'}}
            aria-labelledby="customized-dialog-title"
            open={showNew}
        >
            <LoadingComponent show={false} text={"Renaming Item..."} />
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() =>{
                close()
            }}>
                Rename Folder
            </BootstrapDialogTitle>
            <DialogContent style={{width:'500px',overflow:'hidden'}} dividers>
                <TextField
                    style={{width:'100%'}}
                    id="name"
                    variant="outlined"
                    error={invalidName}
                    helperText={invalidName && 'Invalid character for folder name'}
                    defaultValue={props.itemRename.folder ? props.itemRename.name : props.itemRename.fkArchive ? props.itemRename.fkArchive.name : ''}
                    onChange={e => setName(e.target.value)}
                    label="Name" />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={close.bind(this)} className={classes.buttonModelCancel}>CANCEL</Button>
                <Button variant="outlined" onClick={renameFile.bind(this)} className={classes.buttonModelOk}>SAVE</Button>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default RenameDocPlace
