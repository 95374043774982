import { call, put, takeEvery } from "redux-saga/effects";
import {
  DOCTYPE_ATTRIBUTES_LIST,
  DOCTYPE_DELETE,
  DOCTYPE_HEADER_POST,
  DOCTYPE_LIST,
  DOCTYPE_LIST1,
  DOCTYPE_UPDATE,
} from "./actionTypes";
import {
  apiErrorDocType,
  apiErrorDocType1,
  apiErrorDocTypeAttribute,
  apiErrorDocTypeDelete,
  apiErrorDocTypeHeader,
  apiErrorDocTypeUpdate,
  docTypeAttributeSuccess,
  docTypeDeleteSuccess,
  docTypeHeaderSuccess,
  docTypeSuccess,
  docTypeSuccess1,
  docTypeUpdateSuccess,
} from "./actions";

import {
  deleteDocumentTypeAPI,
  getDocType,
  getDocType1,
  getDocTypeAttributeAPI,
  postDocumentTypeHeader,
  updateDocumentTypeAPI,
} from "../../helpers/call_api";
import { toast } from "react-toastify";

function* deleteDocType({ payload: { docTypeDelete, navigate } }) {
  try {
    const response = yield call(deleteDocumentTypeAPI, docTypeDelete.id);
    toast.success("DocType was deleted");
    yield put(docTypeDeleteSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocTypeDelete(error));
  }
}

function* updateDocType({ payload: { id, docTypeUpdate, navigate } }) {
  console.log("id", id);
  try {
    const response = yield call(updateDocumentTypeAPI, id, docTypeUpdate);

    toast.success("DocType was updated");
    yield put(docTypeUpdateSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocTypeUpdate(error));
  }
}

function* postDocTypeHeader({ payload: { docTypeHeader, navigate } }) {
  try {
    const response = yield call(postDocumentTypeHeader, docTypeHeader);
    toast.success("DocType was created");
    yield put(docTypeHeaderSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocTypeHeader(error));
  }
}

function* resetDocTypeHeader({ payload: { navigate } }) {
  try {
    yield put(docTypeHeaderSuccess("Nafa"));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocTypeHeader(error));
  }
}

function* getDocTypeAttributeList({ payload: { docTypeATR, navigate } }) {
  try {
    const response = yield call(getDocTypeAttributeAPI, docTypeATR.id);
    yield put(docTypeAttributeSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocTypeAttribute(error));
  }
}

function* getDocTypeList({ payload: { docType, navigate } }) {
  try {
    const response = yield call(
      getDocType,
      docType.from,
      docType.to,
      docType.period
    );
    yield put(docTypeSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocType(error));
  }
}

function* getDocTypeList1({ payload: { docType1, navigate } }) {
  try {
    const response = yield call(
      getDocType1,
      docType1.page,
      docType1.pageSize,
      docType1.sortBy,
      docType1.sortOrder,
      docType1?.filtered,
      docType1?.masterTemplate
    );
    yield put(docTypeSuccess1(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocType1(error));
  }
}

function* authSaga() {
  yield takeEvery(DOCTYPE_LIST, getDocTypeList);
  yield takeEvery(DOCTYPE_LIST1, getDocTypeList1);
  yield takeEvery(DOCTYPE_HEADER_POST, postDocTypeHeader);
  yield takeEvery(DOCTYPE_DELETE, deleteDocType);
  yield takeEvery(DOCTYPE_UPDATE, updateDocType);
  yield takeEvery(DOCTYPE_ATTRIBUTES_LIST, getDocTypeAttributeList);
}

export default authSaga;
