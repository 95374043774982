import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import moment from "moment-timezone";
import { Wrapper } from "./style";
import PreviewIcon from "@mui/icons-material/Preview";
import SummarizeIcon from "@mui/icons-material/Summarize";

const headColSignature = [
  {
    field: "CreatedAt",
    flex: 1,
    headerClassName: "MuiTableHead-root",
    headerName: "Forms request date",
    type: "date",
    valueFormatter: (params) => {
      return `${moment(params.value).format("MM/DD/YYYY")}`;
    },
  },
  {
    field: "DocumentName",
    flex: 1,
    headerClassName: "MuiTableHead-root",
    headerName: "Forms",
  },
  {
    field: "Completed",
    flex: 1,
    headerClassName: "MuiTableHead-root",
    headerName: "Status",
    renderCell: (params) => {
      return params.value === 0 ? "Incomplete" : "Completed";
    },
  },
  {
    field: "row",
    headerName: "Options",
    sortable: false,
    headerClassName: "MuiTableHead-root",
    flex: 1,
    renderCell: (params) => (
      <>
        {params.row.Completed === 1 ? (
          <Button
            title="Sign"
            style={{ textTransform: "none" }}
            onClick={() =>
              (window.location.href = `/completedForm/${params.row.FkFormsTemplate}`)
            }
          >
            <PreviewIcon
              fontSize="medium"
              style={{ width: "28px", height: "28px", marginRight: "10px" }}
            />
            <span style={{ marginLeft: "10px" }}>View</span>
          </Button>
        ) : (
          <Button
            title="Sign"
            style={{ textTransform: "none" }}
            onClick={() =>
              (window.location.href = `/formsViewer/${params.row.FkFormsTemplate}/${params.row.Id}`)
            }
          >
            <SummarizeIcon
              fontSize="medium"
              style={{ width: "28px", height: "28px", marginRight: "10px" }}
            />
            <span style={{ marginLeft: "10px" }}>To complete</span>
          </Button>
        )}
      </>
    ),
  },
];

export function GridAssignedForms(props) {
  return (
    <Wrapper>
      <DataGrid
        rows={props.rows}
        columns={headColSignature}
        getRowClassName={() => `MuiTableCell-row`}
        initialState={{
          sorting: {
            sortModel: [{ field: "Completed", sort: "asc" }],
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 50 },
          },
        }}
        pageSizeOptions={[25, 50]}
      />
    </Wrapper>
  );
}

export default GridAssignedForms;
