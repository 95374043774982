import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 327px);
  overflow-y: auto;
  border-radius: 4px;
  margin-top: 20px;
  display: inline-block;
`;

export const UlSigner = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const LiSigner = styled.li`
  font-size: 12px;
  position: relative;
`;

export const TotalSigner = styled.div`
    font-size: 9px;
    float: left;
    font-weight: 900;
`;


export const useStyles = makeStyles((theme) => ({
    inActiveTree: {
        backgroundColor: '#fff'
    }
}));
