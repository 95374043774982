import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {colors} from "../theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 35px;
`;

export const Title = styled.h1`
    color: ${colors.blue};
    margin: -11px 0px 0 0;
    padding: 0;
    font-weight: bold;
`;

export const ViewWraper = styled.section`
      width: 105px;
      right: 0;
      height: 0px;
      position: absolute;
      top: 80px;
`;

export const TextView = styled.section`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    float: left;
    color: #666;
    margin-top: 7px;
    margin-right: 10px;
`;

export const ViewUL = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const ViewLI = styled.li`
    float:left;
    width: 34px;
    height: 34px;
    padding: 6px;
    cursor: pointer;
`;


export const useStyles = makeStyles((theme) => ({
    dropzone: {
        textAlign: 'center',
        padding: '30px',
        border: '3px dashed #eeeeee',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        cursor: 'pointer',
        marginBottom: '20px',
        height: '100%',
        overflowY: 'auto'
    },
    dropBox: {
        float: 'left',
        width: '100px',
        height: '150px',
        fontSize: '12px',
        wordBreak: 'break-word',
        display: 'inline-block',
        padding: '5px',
        color: '#000',
        margin: '1px 12px 18px 12px',
        position:'relative',
        overflowY: 'hidden'
    },
    iconDelete:{
        position: 'absolute',
        top: '-8px',
        color: 'red',
        right: '-7px'
    },
    selectedFileWrapper: {
        textAlign: 'center'
    },
    selectedFile:{
        color: '#000',
        fontWeight: 'bold'
    }
  }));


  



