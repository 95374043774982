import React, {useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import {useStyles} from "./style";
import "./style.scss";

import {Container} from "reactstrap";
import TitleDocPlace from "../../components/title/title";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LoadingComponent from "../../components/loading";
import {WrapperDocPlace} from "./components/wrapper/style";
import {UploadDocumentDocTypeDocPlace} from "./components/detalle/uploadDocuments";
import {LabelingDocPlace} from "./components/labelingTools/labeling";

import {LabelingDocPlaceLegacy} from "./components/labelingToolsLegacy/labeling";
import {ManualFieldsDocPlace} from "./components/manualFields/manualFields";

const DocumentTypeDetailDocPlace = (props) => {
  const notify = (msj, event) => toast[event](msj);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startPage] = useState(true);
  const [stepDocType, setStepDocType] = useState(0);
  const [documentTypeID, setDocumentTypeID] = useState("");
  const [outOCR, setOutOCR] = useState(false);
  const [listTree, setListTree] = React.useState([]);
  const [page] = useState(1);
  const [pageSize] = useState(200);
  let params = useParams();

  const { responseDocType1, errorDocType1, loading } = useSelector((state) => ({
    responseDocType1: state.DocType.responseDocType1,
    errorDocType1: state.DocType.errorDocType1,
    loading: state.DocType.loadingDocType1,
  }));

  useEffect(() => {
    if (params.id !== "null" && params.id) {
      setDocumentTypeID(params.id);
      setStepDocType(1);
    } else {
      setDocumentTypeID("");
    }
  }, [params.id]);

  const nextStep = (value) => {
    setStepDocType(stepDocType + 1);
    setOutOCR(value.useOCR);
    setDocumentTypeID(value.id);
    console.log(value)
    updateCallNew(value.id, value.useOCR ? 1 : 0, true);
  };

  useEffect(() => {
    if (stepDocType) {
      stepsToShow();
    }
  }, [stepDocType]);

  const stepsToShow = () => {
    switch (stepDocType) {
      case 0:
        return (
          <UploadDocumentDocTypeDocPlace
            callBack={nextStep.bind(this)}
            tree={listTree}
          />
        );
      case 1:
        if (params.ocr === "null" && params.id === "null" && stepDocType > 0) {
          navigate("/documentType");
        } else {
          console.log("params",params)
          return params.ocr === "0" ? (
            (params.id && params.newVersion == 'true') ? <LabelingDocPlace documentTypeID={params.id} />
            : <LabelingDocPlaceLegacy documentTypeID={params.id} />
          ) : (
            <ManualFieldsDocPlace documentTypeID={params.id} />
          );
        }
      default:
        return <div>Document Type</div>;
    }
  };

  const updateCallNew = (id, ocr, newVersion) => {
    navigate("/documentType/detail/" + id + "/" + ocr + "" + "/" + newVersion);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DocPlace Document Type Detail</title>
        </MetaTags>
        <Container fluid >
          <TitleDocPlace title={"Document Type Detail"} />
          <ToastContainer autoClose={3000} />
          <LoadingComponent
            show={loading}
            text={"Getting Document Type Detail..."}
          />
          <WrapperDocPlace>{stepsToShow()}</WrapperDocPlace>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DocumentTypeDetailDocPlace;
