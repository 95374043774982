import {
  DOCTYPE_LIST,
  DOCTYPE_LIST1,
  DOCTYPE_HEADER_POST,
  DOCTYPE_UPLOAD_POST,
  DOCTYPE_DELETE,
  DOCTYPE_UPDATE,
  DOCTYPE_ATTRIBUTES_LIST,
  DOCTYPE_ATTRIBUTES_LIST_SUCCESS,
  DOCTYPE_DELETE_SUCCESS,
  DOCTYPE_UPDATE_SUCCESS,
  DOCTYPE_UPLOAD_SUCCESS,
  DOCTYPE_HEADER_SUCCESS,
  DOCTYPE_LIST_SUCCESS,
  DOCTYPE_LIST1_SUCCESS,
  API_ERROR_DOCTYPE_ATTRIBUTES,
  API_ERROR_DOCTYPE_DELETE,
  API_ERROR_DOCTYPE_UPDATE,
  API_ERROR_DOCTYPE_HEADER,
  API_ERROR_DOCTYPE,
  API_ERROR_DOCTYPE1,
  API_ERROR_DOCTYPE_UPLOAD,
} from "./actionTypes";

export const postDocTypeUpload = (docTypeUpload, navigate) => {
  return {
    type: DOCTYPE_UPLOAD_POST,
    payload: { docTypeUpload, navigate },
  };
};

export const deleteDocType = (docTypeDelete, navigate) => {
  return {
    type: DOCTYPE_DELETE,
    payload: { docTypeDelete, navigate },
  };
};

export const updateDocType = (id, docTypeUpdate, navigate) => {
 
  return {
    type: DOCTYPE_UPDATE,
    payload: { id, docTypeUpdate, navigate },
  };
};

export const postDocTypeHeader = (docTypeHeader, navigate) => {
  return {
    type: DOCTYPE_HEADER_POST,
    payload: { docTypeHeader, navigate },
  };
};

export const getDocTypeList = (docType, navigate) => {
  return {
    type: DOCTYPE_LIST,
    payload: { docType, navigate },
  };
};

export const getDocTypeAttributeList = (docTypeATR, navigate) => {
  return {
    type: DOCTYPE_ATTRIBUTES_LIST,
    payload: { docTypeATR, navigate },
  };
};

export const getDocTypeList1 = (docType1, navigate) => {
  return {
    type: DOCTYPE_LIST1,
    payload: { docType1, navigate },
  };
};

export const docTypeDeleteSuccess = (docTypeDelete) => {
  return {
    type: DOCTYPE_DELETE_SUCCESS,
    payload: docTypeDelete,
  };
};
export const docTypeUpdateSuccess = (docTypeUpdate) => {
  return {
    type: DOCTYPE_UPDATE_SUCCESS,
    payload: docTypeUpdate,
  };
};

export const docTypeUploadSuccess = (docTypeUpload) => {
  return {
    type: DOCTYPE_UPLOAD_SUCCESS,
    payload: docTypeUpload,
  };
};

export const docTypeAttributeSuccess = (docTypeATR) => {
  return {
    type: DOCTYPE_ATTRIBUTES_LIST_SUCCESS,
    payload: docTypeATR,
  };
};

export const docTypeHeaderSuccess = (docTypeHeader) => {
  return {
    type: DOCTYPE_HEADER_SUCCESS,
    payload: docTypeHeader,
  };
};

export const docTypeSuccess1 = (docType1) => {
  return {
    type: DOCTYPE_LIST1_SUCCESS,
    payload: docType1,
  };
};

export const docTypeSuccess = (docType) => {
  return {
    type: DOCTYPE_LIST_SUCCESS,
    payload: docType,
  };
};

export const apiErrorDocTypeAttribute = (error) => {
  return {
    type: API_ERROR_DOCTYPE_ATTRIBUTES,
    payload: error,
  };
};

export const apiErrorDocTypeDelete = (error) => {
  return {
    type: API_ERROR_DOCTYPE_DELETE,
    payload: error,
  };
};
export const apiErrorDocTypeUpdate = (error) => {
  return {
    type: API_ERROR_DOCTYPE_UPDATE,
    payload: error,
  };
};

export const apiErrorDocTypeUpload = (error) => {
  return {
    type: API_ERROR_DOCTYPE_UPLOAD,
    payload: error,
  };
};

export const apiErrorDocTypeHeader = (error) => {
  return {
    type: API_ERROR_DOCTYPE_HEADER,
    payload: error,
  };
};

export const apiErrorDocType1 = (error) => {
  return {
    type: API_ERROR_DOCTYPE1,
    payload: error,
  };
};

export const apiErrorDocType = (error) => {
  return {
    type: API_ERROR_DOCTYPE,
    payload: error,
  };
};
