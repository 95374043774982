import styled from 'styled-components';

export const WrapperDocPlace = styled.div`
      width: 100%;
      height: calc(100vh - 140px);
      overflow-y: auto;
      overflow-x: hidden;
`;

export const WrapperListDocPlace = styled.div`
      width: 100%;
      height: calc(100vh - 58px);
      overflow-y: auto;
      padding: 16px;
`;







