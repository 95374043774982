import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.h1`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

export const SubTitle = styled.p`
  width: 100%;
  margin: 20px 0 20px 0;
  padding: 0;
`;

export const ContainerButtons = styled.p`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const useStyles = makeStyles((theme) => ({
  logoDocPlace: {
    objectFit: "cover",
    width: "70%",
  },
  logoMetaData: {
    objectFit: "cover",
    width: "auto",
  },
  logoAttach: {
    objectFit: "cover",
    width: "35px",
    height: "35px",
    margin: "0 !important",
  },
}));
