import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./style";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import Drag from "@mui/icons-material/DragIndicatorSharp";
import TagMenu from "@mui/icons-material/KeyboardArrowDown";
import { expressions } from "../../components/theme";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/AccountCircle';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, TextField } from "@mui/material";
import { getUserList } from "../../store/user/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "auto",
  width: "40%",
  height: "fit-content",
  bgcolor: "background.paper",
  p: 4,
};
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

export function SettingsDocPlace(props) {
  const classes = useStyles();
  const [labels, setLabels] = useState([{ label: "" }]);
  const [label, setLabel] = useState("");
  const [modalLabel, setModalLabel] = useState("");

  const [fmtCurrency, setFmtCurrency] = useState(false);

  const [optional, setOptional] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fmtDate, setFmtDate] = useState(false);
  const [fmtString, setFmtString] = useState(true);

  const [currentLabel, setCurrentLabel] = useState("");

  const [labelType, setLabelType] = React.useState("string");
  const [labelFormat, setLabelFormat] = React.useState("not-specified");
  const [startPage] = useState(true);
  const [open, setOpen] = React.useState(false);

  const [openSigners, setOpenSigners] = React.useState(false);

  const [users, setUsers] = React.useState("");
  const dragItem = useRef();
  const dragOverItem = useRef();

  const [showOrder, setShowOrder] = React.useState(false);


  const [signatures, setSignatures] = React.useState([{ order: 1, name: "", value: "", external: false }]);
  const [tempSignatures, setTempSignatures] = React.useState([]);



  const { responseUser, loadingUser } = useSelector(state => ({
    responseUser: state.User.responseUser,
    loadingUser: state.User.loadingUser,
  }))


  const handleClose = () => setOpen(false);





  useEffect(() => {
    props.getLabels(labels);
  }, [labels]);

  useEffect(() => {
    setLabels(props.setFields);
    if (props.setFields.length > 0) {
      let temp = [];
      props.setFields.map((item, i) => {
        let order = item.order ? item.order : i
        console.log("item",item)
        temp.push({ order: order, name: item.label, value: item.value, external: item.external })

      })
      setSignatures(temp)
    }

  }, [props.setFields]);

  useEffect(() => {
    if (props.deleteLabels === true) setLabels([{ label: "" }]);
  }, [props.deleteLabels]);




  useEffect(() => {
    if (props.deleteLabels === true) setLabels([{ label: "" }]);
  }, [props.deleteLabels]);



  const selectLabel = (label, i, text, val) => {
    setCurrentLabel(label);

    props.selectLabel(label, i, text, val);

  };

  const addLabel = (i, signature, value, type) => {
    let newLabel = labels;
    let data = {
      label: signature,
      value: value,
      order: i,
      external: type
    };
    newLabel[i] = (data);
    setLabel("");
    props.getLabels(labels, true);
  };
  const deleteLabel = (txt) => {
    let newLabel = labels;
    newLabel = newLabel.filter((e) => e.label !== txt);
    setLabels(newLabel);
    setLabel("");
    handleClose();

    deleteAllTags(txt);
    props.deleteRegion(txt);

    let newS = signatures;
    newS = newS.filter((e) => e.name !== txt);
    setSignatures(newS);


  };
  const [colorArray, setColorArray] = useState([
    "255, 225, 25",
    "0, 130, 200",
    "230, 25, 75",
    "60, 180, 75",
    "245, 130, 48",
    "145, 30, 180",
    "70, 240, 240",
    "240, 50, 230",
    "210, 245, 60",
    "250, 190, 212",
    "0, 128, 128",
    "220, 190, 255",
    "170, 110, 40",
    "255, 250, 200",
    "128, 0, 0",
    "170, 255, 195",
    "128, 128, 0",
    "255, 215, 180",
    "0, 0, 128",
    "128, 128, 128",
    "255, 255, 255",
    "0, 0, 0",
  ]);

  const isSelected = (text) => {
    try {
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (freelancer) {
          return freelancer.data.label == text;
        });
        //We found the match, hence it has been selected
        return typeof result[0] !== "undefined";
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteText = (text, label) => {
    props.deleteText(text, label);
  };

  const deleteAllTags = (label) => {
    try {
      //console.log(label)
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (item) {
          return item.data.label == label;
        });
        //We found the match, hence it has been selected
        result.map((item, i) => {
          deleteText(item.data.text, label);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const trimLabel = (label, limit) => {
    var dots = "...";
    if (label.length > limit) {
      label = label.substring(0, limit) + dots;
    }

    return label;
  }



  const showText = (label) => {
    try {
      //console.log(label)
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (item) {
          return item.data.label == label;
        });
        //We found the match, hence it has been selected
        return (
          <>
            {result.map((item, i) => (
              <span
                key={i + 9999}
                /* onClick={() => deleteText(item.data.text, label)} */
                className={classes.textListItem}
              >
                {" "}
                {item.data.text}
              </span>
            ))}{" "}
          </>
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  function getDifference(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.name === object2.name && object1.value === object2.value && object1.order === object2.order;
      });
    });
  }

  const showSigners = () => {

    let temp = [];
    temp = signatures.map(object => ({ ...object }))

    if (temp.length == 1 && temp[0].name == "" && temp[0].value == "") {
      setTempSignatures([{ order: 1, name: "", value: "", external: false }])
    } else {
      setTempSignatures(temp)
    }
    setOpenSigners(true)
  };
  const handleCloseSigners = (event, reason) => {
    const MySwal = withReactContent(Swal);
    if (reason != "backdropClick") {


      if (getDifference(signatures, tempSignatures).length > 0) {
        
        
        MySwal.fire({
          title: "Are you sure you want to discard your changes?",
          text: "",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
        }).then((willDelete) => {
          if (willDelete.value) {
            if (tempSignatures.length == 1 && tempSignatures[0].name == "" && tempSignatures[0].value == "") {
              setSignatures([{ order: 1, name: "", value: "", external: false }])
            } else {
              setSignatures(tempSignatures)
            }
            setOpenSigners(false);
          }
        });

      

      } else {
        setOpenSigners(false);

      }

    }



  }



  const validSigners = () => {
    let signs = [...signatures];
    let value = signs.filter((e, i) => e.value.length > 1)
    let name = signs.filter((e, i) => e.name.length > 1)


    return value.length == signs.length && name.length == signs.length
  }



  const addSignature = () => {

    let signs = [...signatures];

    signs.push({ order: signs.length + 1, name: "", value: "", external: true })
    setSignatures(signs)

  };



  const setName = (i, event) => {
    let signs = [...signatures];
    if (event.target.value == 0) {
      signs[i].name = event.target.innerText;

      let us = [...users]

      let item = us.filter((e) => e.label.trim() == event.target.innerText.trim())

      if (item.length > 0) {
        let value = {
          target: {
            value: item[0].value
          }
        }
        setValue(i, value);
        setType(i, false)
      }

    } else {
      signs[i].name = event.target.value;
    }
    setSignatures(signs)
    //addLabel(i, signs[i].name, signs[i].value)

  };
  const setValue = (i, event) => {
    let signs = [...signatures];

    signs[i].value = event.target.value;
    setSignatures(signs)
    //addLabel(i, signs[i].name, event.target.value)
  };

  const setOrder = (i, event) => {
    let signs = [...signatures];
    signs[i].order = event.target.value;

    //addLabel(i, signs[i].name, event.target.value)
    let sorted = signs.sort(
      (p1, p2) => (p1.order > p2.order) ? 1 : (p1.order < p2.order) ? -1 : 0);
    setSignatures(signs)
  };

  const removeSignature = (i) => {

    let signs = [...signatures];
    deleteLabel(signs[i].name)
    signs.splice(i, 1)
    setSignatures(signs)

  };

  const setType = (i, type) => {
    let signs = [...signatures];

    signs[i].external = type;


    setSignatures(signs)
  };


  const updateRecipients = () => {
    //sfd
      signatures.map((item, i) => {
        addLabel(i, item.name, item.value, item.external)
        //props.deleteRegion(item.name)


      })
      setOpenSigners(false);

    
  }

  useEffect(() => {
    dispatch(getUserList({ page: 1, pageSize: 20 }, navigate))
  }, startPage)
  useEffect(() => {
    if (responseUser && responseUser.data) {
      let newArray = [];
      responseUser.data.map((item, i) => {
        newArray.push({ label: item.fullName, value: item.value })
      })
      setUsers(newArray);
    }
  }, [responseUser])


  const handleChangeShowOrder = (event) => {
    setShowOrder(event.target.checked)
    props.order(event.target.checked)
  };


  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    position: 'relative',



    // change background colour if dragging
    background: isDragging ? "transparent" : "white",
    opacity:  isDragging ? "0.1" : "1",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",

  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  const onDragEnd = (result) => {
    if (showOrder == false){
      return
    }
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      signatures,
      result.source.index,
      result.destination.index
    );

    items.map((item, i) => {
      item.order = i + 1
    })
    setSignatures(items)
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Modal
          open={openSigners}
          onClose={handleCloseSigners}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              List of options
            </Typography>
            <Typography
              component={"div"}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Add the list of options and values for the component selected.
            </Typography>

            <FormControl fullWidth sx={{ mt: 2 }}>

              <FormGroup>
                <FormControlLabel control={<Checkbox
                  defaultValue={showOrder}
                  checked={showOrder}
                  onChange={handleChangeShowOrder}
                  inputProps={{ 'aria-label': 'controlled' }}
                />} label="Set order" />

              </FormGroup>


            </FormControl>
            <List sx={{ width: '100%', bgcolor: 'background.paper', height: "fit-content" }}>


              <Droppable droppableId="droppable" sx={{ width: '100%', bgcolor: 'background.paper', height: "fit-content" }}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}

                  >



                    {signatures.map((item, i) => {
                      return (

                        <Draggable key={i} draggableId={`item-${i}`}
                        isDragDisabled = {!showOrder} 
                         index={i}>
                          {(provided, snapshot) => (
                             
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                             
                            >
                              
                              <ListItem key={i}>
                                {showOrder &&
                                  <>
                                    <input type="number" min="1" value={signatures[i].order} onChange={(event) => setOrder(i, event)} style={{ width: "40px", textAlign: "center", padding: "4px", border: "1px solid lightgray", borderRadius: "4px" }}></input>
                                    <Drag />
                                  </>

                                }
                              
                                <TextField sx={{ width: "400px", mr: 2 }}
                                    required
                                    error={signatures[i].name.length < 3}
                                    onChange={(event) => setName(i, event)} value={signatures[i].name} label="Label" />
                            
                                    <TextField  sx={{ width: "400px", mr: 2 }}
                                      required
                                      onChange={(event) => setValue(i, event)}
                                      value={signatures[i].value}
                                      label="Value" />

                                <IconButton
                                  onClick={() => removeSignature(i)}
                                  color="primary"
                                  sx={{ p: "2px" }}
                                >
                                  <DeleteIcon sx={{ p: "2px" }} key={i + 200} />
                                </IconButton>

                              </ListItem>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                  </div>)}
              </Droppable>
            </List>




            <Button
              variant="contained"
              color="error"
              onClick={handleCloseSigners}
              sx={{ mt: 8, float: "right", width: "100px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={() => updateRecipients()}
              sx={{ mt: 8, mr: 2, float: "right", width: "100px" }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => addSignature()}
              sx={{ mt: 8, mr: 2, float: "left", width: "100px" }}
            >
              Add
            </Button>


          </Box>

        </Modal>
        <div className="addLabel" key={props.tagKey}>
          <Paper
            component="form"
            square
            sx={{ p: "1px", display: "flex", alignItems: "center" }}
          >

            {/**DOC-1719 clicking in the icon add the current label */}

            <Button
              variant="contained"
              color="info"
              sx={{ width: '200px', margin: '10px' }}
              onClick={showSigners}
            >
              Update options
            </Button>
          </Paper>

          <div className="labelsList" key={96856}>
            {labels.map(
              (text, i) =>
                text.label !== "" && (
                  <div key={i + 100}>
                    <Paper
                      component="form"
                      className={
                        currentLabel == text.label
                          ? classes.ltLabelSelected
                          : classes.ltLabelUnselected
                      }
                      key={i}
                      variant="outlined"
                      square
                      sx={{ p: "1px 1px", display: "flex", alignItems: "left" }}
                    >
                      <div
                        className="bm"
                        style={{ backgroundColor: `rgb(${colorArray[text.order]})` }}
                      ></div>
                      <div
                        sx={{ ml: 1, flex: 1 }}
                        title={`${text.label} [${text.type} - ${text.fmt}]`}

                        onClick={() => selectLabel(text.label, i, text, text)}

                        className={
                          isSelected(text.label)
                            ? classes.ltSelected
                            : classes.ltUnSelected
                        }
                        key={i}
                      >

                        {" "}
                        <b>Label:</b> {text.label} - <b>value:</b> [{text.value}]
                        
                      </div>

                      
                    </Paper>
                   
                  </div>
                )
            )}
          </div>
        </div>
      </DragDropContext>
    </>
  );
}

export default SettingsDocPlace;
