import {
  BoxCompleted,
  BoxDate,
  BoxShipments,
  BoxThumbnail,
  BoxTitle,
  BurgerIcon,
  PopupMenu,
  SquareBox,
  useStyles,
} from "./style";
import LocalShipping from "@mui/icons-material/LocalShipping";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Publish from "@mui/icons-material/Publish";
import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import Drag from "@mui/icons-material/DragIndicatorSharp";
import { expressions } from "../../../../components/theme";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import FormControl from "@mui/material/FormControl";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, TextField } from "@mui/material";
import { getUserList } from "../../../../store/user/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { publishForm,changeStatus } from "../../../../services/form";
import ShipmentDocPlace from "../Shipment";

const style = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "auto",
  width: "40%",
  height: "fit-content",
  bgcolor: "background.paper",
  p: 4,
};

export function Template(props, prev) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prevState) => !prevState);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };
  const title = () => {
    let max = 30;
    if (props.title.length > max) {
      return props.title.substring(0, max) + "...";
    } else {
      return props.title;
    }
  };
  useEffect(() => {
    if (props.closeMenu) {
      closeMenu();
    }
  }, [props.closeMenu]);


  const classes = useStyles();
  const [labels, setLabels] = useState([{ label: "" }]);
  const [label, setLabel] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentLabel, setCurrentLabel] = useState("");

  const [startPage] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState(props.status ? props.status : 0);  

  const [openSigners, setOpenSigners] = React.useState(false);

  const [users, setUsers] = React.useState("");

  const [showOrder, setShowOrder] = React.useState(false);
  const [showShippings, setShowShippings] = React.useState(false);

  const [signatures, setSignatures] = React.useState([
    { order: 1, name: "", email: "", external: false },
  ]);
  const [tempSignatures, setTempSignatures] = React.useState([]);

  const { responseUser, loadingUser } = useSelector((state) => ({
    responseUser: state.User.responseUser,
    loadingUser: state.User.loadingUser,
  }));

  const handleClose = () => setOpen(false);

  const addLabel = (i, signature, email, type) => {
    let newLabel = labels;
    let data = {
      label: signature,
      email: email,
      order: i,
      external: type,
    };
    newLabel[i] = data;
    setLabel("");
  };
  const deleteLabel = (txt) => {
    let newLabel = labels;
    newLabel = newLabel.filter((e) => e.label !== txt);
    setLabels(newLabel);
    setLabel("");
    handleClose();

    let newS = signatures;
    newS = newS.filter((e) => e.name !== txt);
    setSignatures(newS);
  };
  const [colorArray, setColorArray] = useState([
    "255, 225, 25",
    "0, 130, 200",
    "230, 25, 75",
    "60, 180, 75",
    "245, 130, 48",
    "145, 30, 180",
    "70, 240, 240",
    "240, 50, 230",
    "210, 245, 60",
    "250, 190, 212",
    "0, 128, 128",
    "220, 190, 255",
    "170, 110, 40",
    "255, 250, 200",
    "128, 0, 0",
    "170, 255, 195",
    "128, 128, 0",
    "255, 215, 180",
    "0, 0, 128",
    "128, 128, 128",
    "255, 255, 255",
    "0, 0, 0",
  ]);

  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return (
          object1.name === object2.name &&
          object1.email === object2.email &&
          object1.order === object2.order
        );
      });
    });
  }

  const showSigners = () => {
    let temp = [];
    temp = signatures.map((object) => ({ ...object }));

    if (temp.length == 1 && temp[0].name == "" && temp[0].email == "") {
      setTempSignatures([{ order: 1, name: "", email: "", external: false }]);
    } else {
      setTempSignatures(temp);
    }
    setOpenSigners(true);
  };
  const showShipping = () => {
    setShowShippings(true);
  };

  const handleCloseSigners = (event, reason) => {
    const MySwal = withReactContent(Swal);
    if (reason != "backdropClick") {
      if (getDifference(signatures, tempSignatures).length > 0) {
        MySwal.fire({
          title: "Are you sure you want to discard your changes?",
          text: "",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
        }).then((willDelete) => {
          if (willDelete.value) {
            if (
              tempSignatures.length == 1 &&
              tempSignatures[0].name == "" &&
              tempSignatures[0].email == ""
            ) {
              setSignatures([
                { order: 1, name: "", email: "", external: false },
              ]);
            } else {
              setSignatures(tempSignatures);
            }
            setOpenSigners(false);
          }
        });
      } else {
        setOpenSigners(false);
      }
    }
  };

  const validSigners = () => {
    let signs = [...signatures];
    let email = signs.filter((e, i) => expressions.email.test(e.email));
    let name = signs.filter((e, i) => e.name.length > 2);

    return email.length == signs.length && name.length == signs.length;
  };

  const addSignature = () => {
    let signs = [...signatures];

    signs.push({
      order: signs.length + 1,
      name: "",
      email: "",
      external: true,
    });
    setSignatures(signs);
  };

  const setName = (i, event) => {
    let signs = [...signatures];
    if (event.target.value == 0) {
      signs[i].name = event.target.innerText;

      let us = [...users];

      let item = us.filter(
        (e) => e.label.trim() == event.target.innerText.trim()
      );

      if (item.length > 0) {
        let email = {
          target: {
            value: item[0].email,
          },
        };
        setEmail(i, email);
        setType(i, false);
      }
    } else {
      signs[i].name = event.target.value;
    }
    setSignatures(signs);
    //addLabel(i, signs[i].name, signs[i].email)
  };
  const setEmail = (i, event) => {
    let signs = [...signatures];

    signs[i].email = event.target.value;
    setSignatures(signs);
    //addLabel(i, signs[i].name, event.target.value)
  };

  const setOrder = (i, event) => {
    let signs = [...signatures];
    signs[i].order = event.target.value;

    //addLabel(i, signs[i].name, event.target.value)
    let sorted = signs.sort((p1, p2) =>
      p1.order > p2.order ? 1 : p1.order < p2.order ? -1 : 0
    );
    setSignatures(signs);
  };

  const removeSignature = (i) => {
    let signs = [...signatures];
    deleteLabel(signs[i].name);
    signs.splice(i, 1);
    setSignatures(signs);
  };

  const setType = (i, type) => {
    let signs = [...signatures];

    signs[i].external = type;

    setSignatures(signs);
  };

  const updateRecipients = () => {
    //sfd
    if (validSigners() == true) {
      let signers = [];
      signatures.map((item, i) => {
        addLabel(i, item.name, item.email, item.external);
        //props.deleteRegion(item.name)
        signers.push({
          name: item.name,
          email: item.email,
          order: item.order.toString(),
        });
      });

      let body = {
        document_name: props.title,
        form_template_id: props.id,
        user_requested: signers,
      };
      props.loading(true);
      publishForm(body, (event) => {})
        .then((response) => {
          console.log(response);
          toast.success("The form has been published successfully.");
        })
        .catch((error) => {
          toast.error("An error has occurred. Please try again later.");
        })
        .finally(() => {
          setOpenSigners(false);
          props.loading(false);
        });
    } else {
      toast.error(
        "Please check the values. The email must be valid and the name should contain more at least 3 characters."
      );
    }
  };

  useEffect(() => {
    dispatch(getUserList({ page: 1, pageSize: 20 }, navigate));
  }, startPage);
  useEffect(() => {
    if (responseUser && responseUser.data) {
      let newArray = [];
      responseUser.data.map((item, i) => {
        newArray.push({ label: item.fullName, email: item.email });
      });
      setUsers(newArray);
    }
  }, [responseUser]);

  const handleChangeShowOrder = (event) => {
    setShowOrder(event.target.checked);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    position: "relative",

    // change background colour if dragging
    background: isDragging ? "transparent" : "white",
    opacity: isDragging ? "0.1" : "1",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
  });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (showOrder == false) {
      return;
    }
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      signatures,
      result.source.index,
      result.destination.index
    );

    items.map((item, i) => {
      item.order = i + 1;
    });
    setSignatures(items);
  };

  const handleDeleteTemplate = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this template. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform the delete operation here
        // You can call an API or update the state accordingly
        // Example: deleteTemplate(templateId)
        props.loading(true);
        changeStatus(id,1, (event) => {})
        .then((response) => {
          console.log(response);
          Swal.fire(
            'Deleted!',
            'The template has been deleted.',
            'success'
          );
          props.updateTemplates();
          closeMenu();
        })
        .catch((error) => {
          toast.error("An error has occurred. Please try again later.");
        })
        .finally(() => {
          props.loading(false);
        });

       
      }
    });
  };
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Modal
          open={openSigners}
          onClose={handleCloseSigners}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              List of recipients
            </Typography>
            <Typography
              component={"div"}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Add the information of the recipients. You can arrange the order
              in which the forms must be collected. When all recipients have
              been added, click on the Publish button.
            </Typography>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultValue={showOrder}
                      checked={showOrder}
                      onChange={handleChangeShowOrder}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Set order"
                />
              </FormGroup>
            </FormControl>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                height: "fit-content",
              }}
            >
              <Droppable
                droppableId="droppable"
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  height: "fit-content",
                }}
              >
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {signatures.map((item, i) => {
                      return (
                        <Draggable
                          key={i}
                          draggableId={`item-${i}`}
                          isDragDisabled={!showOrder}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ListItem key={i}>
                                {showOrder && (
                                  <>
                                    <input
                                      type="number"
                                      min="1"
                                      value={signatures[i].order}
                                      onChange={(event) => setOrder(i, event)}
                                      style={{
                                        width: "40px",
                                        textAlign: "center",
                                        padding: "4px",
                                        border: "1px solid lightgray",
                                        borderRadius: "4px",
                                      }}
                                    ></input>
                                    <Drag />
                                  </>
                                )}
                                {signatures[i].external == false ? (
                                  <ListItemAvatar>
                                    <Avatar
                                      style={{
                                        backgroundColor: "rgb(0, 106, 198)",
                                      }}
                                    >
                                      <ImageIcon />
                                    </Avatar>
                                  </ListItemAvatar>
                                ) : (
                                  <ListItemAvatar>
                                    <Avatar>
                                      <ImageIcon />
                                    </Avatar>
                                  </ListItemAvatar>
                                )}
                                <Autocomplete
                                  disablePortal
                                  sx={{ ml: 1, flex: 2 }}
                                  id="doctype-id"
                                  freeSolo
                                  options={users}
                                  disableClearable
                                  inputValue={signatures[i].name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      error={signatures[i].name.length < 3}
                                      onChange={(event) => setName(i, event)}
                                      value={signatures[i].name}
                                      label="Add name"
                                    />
                                  )}
                                  onChange={(value) => setName(i, value)}
                                />
                                <Autocomplete
                                  disablePortal
                                  sx={{ ml: 1, flex: 2 }}
                                  id="doctype-ids"
                                  freeSolo
                                  options={[{ label: "" }]}
                                  disableClearable
                                  inputValue={signatures[i].email}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      onChange={(event) => setEmail(i, event)}
                                      error={
                                        !expressions.email.test(
                                          signatures[i].email
                                        )
                                      }
                                      label="Add email"
                                    />
                                  )}
                                  onChange={(value) => setEmail(i, value)}
                                />

                                <IconButton
                                  onClick={() => removeSignature(i)}
                                  color="primary"
                                  sx={{ p: "2px" }}
                                >
                                  <DeleteIcon sx={{ p: "2px" }} key={i + 200} />
                                </IconButton>
                              </ListItem>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </List>

            <Button
              variant="contained"
              color="error"
              onClick={handleCloseSigners}
              sx={{ mt: 8, float: "right", width: "100px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => updateRecipients()}
              sx={{ mt: 8, mr: 2, float: "right", width: "100px" }}
            >
              Publish
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={() => addSignature()}
              sx={{ mt: 8, mr: 2, float: "left", width: "100px" }}
            >
              Add
            </Button>
          </Box>
        </Modal>
      </DragDropContext>
      <Modal
        open={showShippings}
        onClose={() => setShowShippings(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ShipmentDocPlace id={props.id} />
      </Modal>
      <SquareBox key={props.idx}>
        <BoxDate></BoxDate>
        <BoxThumbnail>
          <img src={props.thumbnail} alt="template" />
        </BoxThumbnail>
        <BoxTitle>{title()}</BoxTitle>
        <BoxShipments>
          Shipments: <span>{props.shared ? props.shared : 0}</span>
        </BoxShipments>
        <BoxCompleted>
          Completed: <span>{props.published ? props.published : 0}</span>
        </BoxCompleted>
        <BurgerIcon
          key={props.idx + "a"}
          onClick={toggleMenu}
          htmlFor="menu-checkbox"
        >
          <div></div>
          <div></div>
          <div></div>
        </BurgerIcon>
        <input
          type="checkbox"
          key={props.idx + "b"}
          style={{ display: "none" }}
          checked={showMenu}
          onChange={toggleMenu}
        />
        <PopupMenu show={showMenu}>
          <BoxDate>{title()}</BoxDate>
          <BurgerIcon
            key={props.idx + "c"}
            onClick={toggleMenu}
            htmlFor="menu-checkbox"
          >
            <div></div>
            <div></div>
            <div></div>
          </BurgerIcon>

          <div className="popmenu">
           
          { status > 1 && 
            <div className="line">
              <div>
                <LocalShipping />
              </div>
              <div onClick={() => showShipping()}>Shipping</div>
            </div>
            }
           
            { status > 0 && 
            <div className="line">
              <div>
                <Publish />
              </div>
              <div onClick={() => showSigners()}>Publish form</div>
            </div>
              }
            
            
            <div className="line">
              <div>
                <Edit />
              </div>
                            <div>
                <a href={`/formsTemplate/${props.id}`}> Form template</a>
              </div>
            </div>
            <div className="line">
              <div>
                <Delete />
              </div>
              <div>
              <div onClick={() => handleDeleteTemplate(props.id)}>Delete</div>
              </div>
            </div>
          </div>
        </PopupMenu>
      </SquareBox>
    </>
  );
}
export default Template;
