import React, { useEffect, useState } from "react";
import Bookmark from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/material/IconButton';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import DocumentPageDocPlace from "./pages";
export function DocumentListDocPlace(props) {

    const [documents, setDocuments] = useState([]);
    const [selected, setSelected] = useState();
    const [currentPage, setCurrentPage] = useState(0);


    const [ocr, setOcr] = useState([]);

    var ocrDocs = []

    useEffect(() => {
        setDocuments(props.documents);
      

    


    }, [props.documents]);

    useEffect(() => {
        if (props.deleteOCR === true) {
            setSelected('')
            setOcr([])
        }
    }, [props.deleteOCR]);
    useEffect(() => {
      
        move(props.doMove)
 }, [props.doMove]);

    const ocrDoc = (doc, id, pageNumber,originalName) => {
        setSelected(originalName);
       // console.log(doc)
        props.getDocument(doc.thumbnail, id, doc.fileName, doc.path, doc.ocr, doc, doc.fileOriginalName,pageNumber)
    };

    const move = (currentIndex) => {
        props.move(currentIndex)
    };
      
//ocr.includes(props.index) && props.documents[props.index].ocr == true
    return (
      
            <div className="documentList">
                {documents && documents.map((doc, i) => (
              <div key={i} title={selected+doc.fileOriginalName} className={selected == doc.fileOriginalName ? 'documentSelected' : ''}>
                    <DocumentPageDocPlace document={doc} key={i} index={i} ocrDoc={ocrDoc} originalName={doc.fileOriginalName} move={move} ></DocumentPageDocPlace>
                  </div>  
                ))}

            </div>
        
    )
}

export default DocumentListDocPlace

