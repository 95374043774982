import {
  DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_CLEAR_DATA,
  DELETE_ATTACHMENT_ERROR,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_METADATA,
  DELETE_METADATA_CLEAR_DATA,
  DELETE_METADATA_ERROR,
  DELETE_METADATA_SUCCESS,
  GET_ALL_ATTACHMENT,
  GET_ALL_ATTACHMENT_CLEAR_DATA,
  GET_ALL_ATTACHMENT_ERROR,
  GET_ALL_ATTACHMENT_SUCCESS,
  GET_ALL_METADATA,
  GET_ALL_METADATA_CLEAR_DATA,
  GET_ALL_METADATA_ERROR,
  GET_ALL_METADATA_SUCCESS,
  GET_REQUIRED_PROCESS,
  GET_REQUIRED_PROCESS_CLEAR_DATA,
  GET_REQUIRED_PROCESS_ERROR,
  GET_REQUIRED_PROCESS_SUCCESS,
  POST_METADATA,
  POST_METADATA_CLEAR_DATA,
  POST_METADATA_ERROR,
  POST_METADATA_SUCCESS,
} from "./actionTypes";

const initialState = {
  //ATTACHMENT
  loadingGetAttachment: false,
  responseGetAttachment: null,
  errorGetAttachment: null,

  loadingDeleteAttachment: false,
  responseDeleteAttachment: null,
  errorDeleteAttachment: null,
  //GET_REQUIRED_PROCESS
  loadingGetRequiredProcess: false,
  responseGetRequiredProcess: null,
  errorGetRequiredProcess: null,
  //METADATA
  loadingGetDocumentMetadata: false,
  responseGetDocumentMetadata: null,
  errorGetDocumentMetadata: null,

  loadingPostDocumentMetadata: false,
  responsePostDocumentMetadata: null,
  errorPostDocumentMetadata: null,

  loadingDeleteMetadata: false,
  responseDeleteMetadata: null,
  errorDeleteMetadata: null,
};

const WorkFlowProcess = (state = initialState, action) => {
  switch (action.type) {
    //ATTACHMENT
    case GET_ALL_ATTACHMENT:
      state = {
        ...state,
        errorGetAttachment: null,
        loadingGetAttachment: true,
        responseGetAttachment: null,
      };
      break;
    case GET_ALL_ATTACHMENT_SUCCESS:
      state = {
        ...state,
        errorGetAttachment: null,
        loadingGetAttachment: false,
        responseGetAttachment: action.payload,
      };
      break;
    case GET_ALL_ATTACHMENT_ERROR:
      state = {
        ...state,
        errorGetAttachment: action.payload,
        loadingGetAttachment: false,
        responseGetAttachment: null,
      };
      break;
    case GET_ALL_ATTACHMENT_CLEAR_DATA:
      state = {
        ...state,
        errorGetAttachment: null,
        loadingGetAttachment: false,
        responseGetAttachment: null,
      };
      break;
    //
    case DELETE_ATTACHMENT:
      state = {
        ...state,
        errorDeleteAttachment: null,
        loadingDeleteAttachment: true,
        responseDeleteAttachment: null,
      };
      break;
    case DELETE_ATTACHMENT_SUCCESS:
      state = {
        ...state,
        errorDeleteAttachment: null,
        loadingDeleteAttachment: false,
        responseDeleteAttachment: action.payload,
      };
      break;
    case DELETE_ATTACHMENT_ERROR:
      state = {
        ...state,
        errorDeleteAttachment: action.payload,
        loadingDeleteAttachment: false,
        responseDeleteAttachment: null,
      };
      break;
    case DELETE_ATTACHMENT_CLEAR_DATA:
      state = {
        ...state,
        errorDeleteAttachment: null,
        loadingDeleteAttachment: false,
        responseDeleteAttachment: null,
      };
      break;
    //GET_REQUIRED_PROCESS
    case GET_REQUIRED_PROCESS:
      state = {
        ...state,
        errorGetRequiredProcess: null,
        loadingGetRequiredProcess: true,
        responseGetRequiredProcess: null,
      };
      break;
    case GET_REQUIRED_PROCESS_SUCCESS:
      state = {
        ...state,
        errorGetRequiredProcess: null,
        loadingGetRequiredProcess: false,
        responseGetRequiredProcess: action.payload,
      };
      break;
    case GET_REQUIRED_PROCESS_ERROR:
      state = {
        ...state,
        errorGetRequiredProcess: action.payload,
        loadingGetRequiredProcess: false,
        responseGetRequiredProcess: null,
      };
      break;
    case GET_REQUIRED_PROCESS_CLEAR_DATA:
      state = {
        ...state,
        errorGetRequiredProcess: null,
        loadingGetRequiredProcess: false,
        responseGetRequiredProcess: null,
      };
      break;
    //METADATA
    case GET_ALL_METADATA:
      state = {
        ...state,
        errorGetDocumentMetadata: null,
        loadingGetDocumentMetadata: true,
        responseGetDocumentMetadata: null,
      };
      break;
    case GET_ALL_METADATA_SUCCESS:
      state = {
        ...state,
        errorGetDocumentMetadata: null,
        loadingGetDocumentMetadata: false,
        responseGetDocumentMetadata: action.payload,
      };
      break;
    case GET_ALL_METADATA_ERROR:
      state = {
        ...state,
        errorGetDocumentMetadata: action.payload,
        loadingGetDocumentMetadata: false,
        responseGetDocumentMetadata: null,
      };
      break;
    case GET_ALL_METADATA_CLEAR_DATA:
      state = {
        ...state,
        errorGetDocumentMetadata: null,
        loadingGetDocumentMetadata: false,
        responseGetDocumentMetadata: null,
      };
      break;
    case POST_METADATA:
      state = {
        ...state,
        errorPostDocumentMetadata: null,
        loadingPostDocumentMetadata: true,
        responsePostDocumentMetadata: null,
      };
      break;
    case POST_METADATA_SUCCESS:
      state = {
        ...state,
        errorPostDocumentMetadata: null,
        loadingPostDocumentMetadata: false,
        responsePostDocumentMetadata: action.payload,
      };
      break;
    case POST_METADATA_ERROR:
      state = {
        ...state,
        errorPostDocumentMetadata: action.payload,
        loadingPostDocumentMetadata: false,
        responsePostDocumentMetadata: null,
      };
      break;
    case POST_METADATA_CLEAR_DATA:
      state = {
        ...state,
        errorPostDocumentMetadata: null,
        loadingPostDocumentMetadata: false,
        responsePostDocumentMetadata: null,
      };
      break;
    case DELETE_METADATA:
      state = {
        ...state,
        errorDeleteMetadata: null,
        loadingDeleteMetadata: true,
        responseDeleteMetadata: null,
      };
      break;
    case DELETE_METADATA_SUCCESS:
      state = {
        ...state,
        errorDeleteMetadata: null,
        loadingDeleteMetadata: false,
        responseDeleteMetadata: action.payload,
      };
      break;
    case DELETE_METADATA_ERROR:
      state = {
        ...state,
        errorDeleteMetadata: action.payload,
        loadingDeleteMetadata: false,
        responseDeleteMetadata: null,
      };
      break;
    case DELETE_METADATA_CLEAR_DATA:
      state = {
        ...state,
        errorDeleteMetadata: null,
        loadingDeleteMetadata: false,
        responseDeleteMetadata: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default WorkFlowProcess;
