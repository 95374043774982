import React, {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import {useStyles} from './style';
import LoadingComponent from "../../../../components/loading";
import DialogActions from "@mui/material/DialogActions";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {postRecoverDoc} from "../../../../store/explorer/actions";

import {getDocTypeList1} from "../../../../store/docType/actions";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export function RecoverDocPlace(props, prev) {
    const classes = useStyles();
    const [name, setName] = React.useState('');
    const [showNew, setShowNew] = useState(false);
    const [item, setItem] = useState(false);
    const [invalidName, setInvalidName] = useState(false);
    const notify = (msj, event) => toast[event](msj);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [startPage] = useState(true);
    const [doctypes, setDoctypes] = useState([]);
    const [docTypesAPI, setDocTypesAPI] = useState([]);
    const [page] = useState(1);
    const [pageSize] = useState(1000);

    const [role, setRole] = useState("");



    const { responseRecoverDoc, errorRecoverDoc,loading2 } = useSelector(state => ({
        responseRecoverDoc: state.Explorer.responseRecoverDoc  === undefined ? 'none' : state.Explorer.responseRecoverDoc,
        errorRecoverDoc: state.Explorer.errorRecoverDoc,
        loading: state.Explorer.loadingRecoverDoc,
    }))
    const { responseDocType1, errorDocType1, loading } = useSelector((state) => ({
        responseDocType1: state.DocType.responseDocType1,
        errorDocType1: state.DocType.errorDocType1,
        loading: state.DocType.loadingDocType1,
    }));

    useEffect(() => {
        dispatch(
            getDocTypeList1(
                { page: page, pageSize: pageSize, sortBy: "created_at", sortOrder: 2, filtered: true },
                navigate
            )
        );
    }, [startPage, page, pageSize]);

    useEffect(() => {
        if (responseDocType1 && responseDocType1.data.length > 0) {
           setDoctypes(responseDocType1.data);

        }
    }, [responseDocType1]);


    useEffect(() => {


        setRole(props.itemRecover.fkDoctypeId)
        setItem(props.itemRecover);
        setShowNew(props.show)
    }, [props.show]);


    useEffect(() => {

        if (responseRecoverDoc !== 'none') {

            props.recover(name);
            close();
        }
    }, [responseRecoverDoc]);


    const close = () => {
        setInvalidName(false);
        props.close();
    }

    const RecoverFile = () => {
        let obj = [];
      
         obj.push({id:props.itemRecover.fkArchive.id})
            
        dispatch(postRecoverDoc(obj, navigate));
        }


    return (
        <BootstrapDialog
            onClose={() => {
                close()
            }}
            style={{ overflow: 'hidden' }}
            aria-labelledby="customized-dialog-title"
            open={showNew}
        >
            <LoadingComponent show={loading || loading2} text={"Recovering Item..."} />
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {
                close()
            }}>
                Recover Document
            </BootstrapDialogTitle>

            <div style={{ width: '500px', overflow: 'hidden', padding: '20px' }}>
                <p>
                Do you want to recover this item?

                </p>
            </div>
        
            <DialogActions>
                <Button variant="outlined" onClick={close.bind(this)} className={classes.buttonModelCancel}>CANCEL</Button>
                <Button variant="outlined" onClick={RecoverFile.bind(this)} className={classes.buttonModelOk}>Recover</Button>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default RecoverDocPlace
