import React from "react";
import MetaTags from "react-meta-tags";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

import { LiBackForm, LiDownload, NavForm, UlForm, WrapperForm } from "./style";
import DocumentListDocPlace from "./documentList";

const DocViewerForms = (props) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <MetaTags>
        <title>Completed form view</title>
      </MetaTags>
      <NavForm>
        <UlForm>
          <LiBackForm>
            <Button
              color="primary"
              onClick={() => {
                navigate("/assigned-forms");
              }}
              startIcon={<ArrowBackIosNewIcon />}
              sx={{ textTransform: "none" }}
            >
              Back
            </Button>
          </LiBackForm>
          <LiDownload>
            <Button
              color="primary"
              onClick={props.downloadFile}
              startIcon={<DownloadIcon />}
              sx={{ textTransform: "none" }}
            >
              Download
            </Button>
          </LiDownload>
        </UlForm>
      </NavForm>
      <WrapperForm>
        <Grid container>
          <Grid className="wrapperAside" item xs={10} md={10}>
            <div className="documentShowcase">
              <img
                style={{ width: "100%", pointerEvents: "none" }}
                src={props.currentDocument}
                alt="Current document"
              />
            </div>
          </Grid>
          <Grid className="wrapperAside" item xs={2} md={2}>
            <DocumentListDocPlace
              documents={props.documents}
              getDocument={props.getDocument}
            />
          </Grid>
        </Grid>
      </WrapperForm>
    </React.Fragment>
  );
};

export default DocViewerForms;
