import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Message, useStyles, WrapperForm } from "./style";
import LoadingComponent from "../../../../components/loading";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import moment from "moment-timezone";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import InputFormDocPlace from "../../../../components/form/input";
import DateRangePickerDocPlace from "../../../../components/form/dateRangePickerDocPlace";
import {
  getDocTypeAttributeList,
  getDocTypeList1,
} from "../../../../store/docType/actions";
import { Autocomplete, Box, TextField } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const OriginTypeList = [
  { value: "SCANNER", label: "SCANNER" },
  { value: "WEB_UI", label: "WEB_UI" },
];

export const criteriaOptions = [
  { value: "=", label: "Equal" },
  { value: ">=", label: "Greater or equal to" },
  { value: "<=", label: "Smaller or equal to" },
  { value: "val%", label: "Starts with" },
  { value: "%val", label: "Ends with" },
  { value: "%val%", label: "Contain" },
];
function subtractMonths(date, months) {
  date.setMonth(date.getMonth() - months);

  return date;
}
export function NewFilterDocPlace(props) {
  const classes = useStyles();
  const [showNew, setShowNew] = useState(false);
  const [value, setValue] = useState({ value: "", error: false });

  const [doctypeList, setDoctypeList] = useState([]);
  const [documentType, setDocumentType] = useState({});

  const [criteria, setCriteria] = useState({});

  const [attributesList, setAttributesList] = useState([]);
  const [attribute, setAttribute] = useState({});
  const [attributeDefault, setAttributeDefault] = useState({});
  const [selectedDateFrom, handleDateChangeFrom] = useState(
    subtractMonths(new Date(), 1)
  );
  const [selectedDateTo, handleDateChangeTo] = useState(new Date());

  const notify = (msj, event) => toast[event](msj);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState({
    value: [null, null],
    error: false,
    notReady: false,
  });

  const { responseDocType1, loading } = useSelector((state) => ({
    responseDocType1: state.DocType.responseDocType1,
    loading: state.DocType.loadingDocType1,
  }));

  const { responseDocTypeAttribute, loadingDocTypeAttribute } = useSelector(
    (state) => ({
      responseDocTypeAttribute: state.DocType.responseDocTypeAttribute,
      loadingDocTypeAttribute: state.DocType.loadingDocTypeAttribute,
    })
  );

  useEffect(() => {
    setShowNew(props.show);
    dispatch(
      getDocTypeList1(
        {
          page: 1,
          pageSize: 1000,
          sortBy: "name",
          sortOrder: 1,
          filtered: true,
        },
        navigate
      )
    );
  }, [props.show]);

  useEffect(() => {
    if (responseDocType1?.data?.length > 0) {
      let l = [];
      responseDocType1.data.map((item) => {
        l.push({
          value: item.id,
          label: item.name,
        });
      });
      if (l.length > 0) {
        setAttributeDefault({ value: l[0].value, label: l[0].label });
      }
      setDoctypeList(l);
    }
  }, [responseDocType1]);

  useEffect(() => {
    if (responseDocTypeAttribute?.data?.length > 0 && documentType !== "-1") {
      let l = [];
      Object.keys(responseDocTypeAttribute.data[0]).map((item, i) => {
        l.push({
          value: responseDocTypeAttribute.data[0][item].alias,
          label: item,
        });
      });
      setAttributesList(l);
    }
  }, [responseDocTypeAttribute]);

  const close = () => {
    setDocumentType("");
    setAttribute("");
    setCriteria({});
    setValue("");
    setDateRange({ value: [null, null], error: false, notReady: false });
    props.close();
  };

  const newAcction = () => {
    let date1 = dateRange.value[0];
    let date2 = dateRange.value[1];
    date1 = moment(date1).format("YYYY-MM-DD");
    date2 = moment(date2).format("YYYY-MM-DD");
    let isValid =
      !dateRange.error &&
      date1 &&
      date2 &&
      date1.toString() !== "Invalid Date" &&
      date2.toString() !== "Invalid Date"; //avoid invalids and wrong range
    if (isValid) {
      let filter = {
        ...(documentType.length > 0
          ? { docTypeId: documentType }
          : { docTypeId: "" }),
        criteria: [
          {
            ...(attribute.length > 0 ? { field: attribute } : { field: "" }),
            ...(criteria.length > 0
              ? { operator: criteria }
              : { operator: "" }),
            value: value.value,
          },
        ],
        // ...(date1 !== "Invalid date" && { fromDate: date1 }),
        // ...(date2 !== "Invalid date" && { toDate: date2 }),
      };

      let fromDate = date1 !== "Invalid date" ? date1 : undefined;
      let toDate = date2 !== "Invalid date" ? date2 : undefined;

      let nameDocType = "";
      responseDocType1.data.map((item) => {
        if (item.id === documentType) {
          nameDocType = item.name;
        }
      });
      filter.docTypeName = nameDocType;

      props.doctypeText(nameDocType);
      // props.done(filter);
      props.done({
        filter: filter,
        fromDate: fromDate,
        toDate: toDate,
      });
      close();
    } else {
      notify("Start Date is more recent than End Date", "error");
    }
  };

  const setValueAttribute = (event) => {
    setAttribute(event.target.value);
    setCriteria("");
  };

  const setValueCriteria = (event) => {
    setCriteria(event.target.value);
  };

  const setValueDoctype = (event, value) => {
    setAttribute("");
    setCriteria("");

    if (value?.value) {
      setDocumentType(value.value);

      if (value?.value !== "-1") {
        dispatch(getDocTypeAttributeList({ id: value.value }, navigate));
      } else {
        setAttributesList([]);
      }
    }
  };
  const onChangeRange = () => {
    //DOC-1604 / DOC-1754
    let date1 = dateRange.value[0];
    let date2 = dateRange.value[1];
    let isValid =
      !dateRange.error &&
      date1 &&
      date2 &&
      date1.toString() !== "Invalid Date" &&
      date2.toString() !== "Invalid Date"; //avoid invalids and wrong range
    // if (isValid) refreshDashboard();
  };

  return (
    <Dialog
      fullScreen
      open={showNew}
      onClose={close}
      TransitionComponent={Transition}
    >
      <LoadingComponent
        show={loading || loadingDocTypeAttribute}
        text={loading ? "Getting Document Types" : "Adding new Filter..."}
      />
      <AppBar sx={{ position: "relative" }}>
        <Toolbar style={{ backgroundColor: "#006ac6" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            New Filter
          </Typography>
          <Button autoFocus color="inherit" onClick={newAcction}>
            Create Filter
          </Button>
        </Toolbar>
      </AppBar>
      <article style={{ margin: "30px" }}>
        <Message>
          To add a new filter, all files marked as required must be completed.
        </Message>
        <WrapperForm>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Autocomplete
                  disablePortal
                  id="doctype-id"
                  options={doctypeList}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.value}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Document Type" focused />
                  )}
                  onChange={setValueDoctype}
                />
                <FormHelperText>* Select Document Type!</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="attributeID">Attribute</InputLabel>
                <Select
                  labelId="attributeID"
                  id="attribute-id"
                  value={attribute}
                  label="Attribute"
                  onChange={setValueAttribute}
                >
                  {attributesList.map((dt, idx) => (
                    <MenuItem key={idx} value={dt.value}>
                      {dt.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>* Select attribute!</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="criteriaID">Criteria</InputLabel>
                <Select
                  labelId="criteriaID"
                  id="criteria-id"
                  value={criteria}
                  label="Criteria"
                  onChange={setValueCriteria}
                >
                  {criteriaOptions.map((dt, idx) => (
                    <MenuItem key={idx} value={dt.value}>
                      {dt.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>* Select Criteria!</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputFormDocPlace
                  id={"value"}
                  field={value}
                  setField={setValue}
                  required={true}
                  type="text"
                  label="Value"
                  helperText="Enter value!"
                />
              </FormControl>
            </Grid>
            <div style={{ marginLeft: "30px", width: "100%" }}>
              <h3>Uploaded date</h3>
              <Grid item xs={12}>
                <DateRangePickerDocPlace
                  id={"dateSearch"}
                  field={dateRange}
                  setField={setDateRange}
                  required={true}
                  startDateText="From"
                  endDateText="To"
                  helperText=""
                  execute={onChangeRange}
                  displayMessage={false}
                />
              </Grid>
            </div>
          </Grid>
        </WrapperForm>
      </article>
    </Dialog>
  );
}

export default NewFilterDocPlace;
