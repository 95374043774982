import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { colors } from "../../../../components/theme";

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    width: "100%",
    fontSize: "12px",
    fontWeight: "lighter",
  },
}));

export const DateWrap = styled.div`
  display: grid;
  width: 100%;
`;
export const Title = styled.h4`
  color: #006ac6;
  margin: 0 auto;
  text-align: left;
  font-weight: 900;
  position: absolute;
`;
export const Container = styled.article`
  width: 100%;
  background-color: ${colors.white};
  display: inline-block;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: auto;
`;

export const ButtonContainerInferior = styled.section`
  float: right;
  right: 0;
  display: inline-block;
  text-align: right;
  width: 150px;
`;

export const ButtonContainerTools = styled.section`
  display: flex;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  justify-content: space-between;
`;

export const WrapperDatePicker = styled.section`
  width: 100%;
  padding: 20px;
  overflow: hidden;
  border-radius: 4px;
  display: inline-block;
  background-color: white;
`;

export const ContainerTextField = styled.div`
  width: 100%;
`;

export const DivHelperTextTitle = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
`;
