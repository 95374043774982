import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useStyles } from "./style";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingComponent from "../../../../components/loading";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { postReprocessDoc } from "../../../../store/explorer/actions";

import { getDocTypeList1 } from "../../../../store/docType/actions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export function ReprocessDocPlace(props, prev) {
  const classes = useStyles();
  const [name, setName] = React.useState("");
  const [showNew, setShowNew] = useState(false);
  const [item, setItem] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const notify = (msj, event) => toast[event](msj);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startPage] = useState(true);
  const [doctypes, setDoctypes] = useState([]);
  const [docTypesAPI, setDocTypesAPI] = useState([]);
  const [page] = useState(1);
  const [pageSize] = useState(1000);

  const [doctypeId, setDoctypeId] = useState("");
  const [archiveId, setArchiveId] = useState("");
  const [role, setRole] = useState("");

  const { responseReprocessDoc, errorReprocessDoc, loading2 } = useSelector(
    (state) => ({
      responseReprocessDoc:
        state.Explorer.responseReprocessDoc === undefined
          ? "none"
          : state.Explorer.responseReprocessDoc,
      errorReprocessDoc: state.Explorer.errorReprocessDoc,
      loading: state.Explorer.loadingReprocessDoc,
    })
  );
  const { responseDocType1, errorDocType1, loading } = useSelector((state) => ({
    responseDocType1: state.DocType.responseDocType1,
    errorDocType1: state.DocType.errorDocType1,
    loading: state.DocType.loadingDocType1,
  }));

  useEffect(() => {
    dispatch(
      getDocTypeList1(
        {
          page: page,
          pageSize: pageSize,
          sortBy: "created_at",
          sortOrder: 2,
          filtered: true,
        },
        navigate
      )
    );
  }, [startPage, page, pageSize]);

  useEffect(() => {
    if (responseDocType1 && responseDocType1.data.length > 0) {
      let doctypes = responseDocType1.data ? responseDocType1.data : [];
      let existNoDoctype = false;

      doctypes.map((d) => {
        if (d.id === "-1") {
          existNoDoctype = true;
        }
      });

      if (!existNoDoctype) {
        try {
          doctypes.unshift({
            createdAt: "2022-07-14T17:20:46",
            description: "10",
            dynamicLocationExpr: null,
            fileReferences: null,
            filenameFields: null,
            filenamePrefix: null,
            id: "-1",
            ignoreOcr: 0,
            name: "No DocType",
          });
        } catch (e) {
          console.log("err:: ", e);
        }
      }

      setDoctypes(doctypes);
    }
  }, [responseDocType1]);

  useEffect(() => {
    setItem(props.itemReprocess);
    setShowNew(props.show);
  }, [props.show]);

  useEffect(() => {
    if (responseReprocessDoc !== "none") {
      props.reprocess(name);
      close();
    }
  }, [responseReprocessDoc]);

  const close = () => {
    setInvalidName(false);
    props.close();
  };

  const onChange = (data) => {
    // let data = JSON.parse(event.target.value)

    setDoctypeId(data.target.value.id);
    //setRole(event.target.value);
    setArchiveId(data.target.value.targetArchiveId);
  };

  const reprocessFile = () => {
    let obj = {
      documentId: props.itemReprocess.id,
      doctypeId: doctypeId,
      archiveId: archiveId,
    };
    dispatch(postReprocessDoc(obj, navigate));
  };

  return (
    <BootstrapDialog
      onClose={() => {
        close();
      }}
      style={{ overflow: "hidden" }}
      aria-labelledby="customized-dialog-title"
      open={showNew}
    >
      <LoadingComponent
        show={loading || loading2}
        text={"Reprocessing Item..."}
      />
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          close();
        }}
      >
        Reprocess Document
      </BootstrapDialogTitle>

      <div style={{ width: "500px", overflow: "hidden", padding: "20px" }}>
        <p>
          You can select a new DocType or reprocess the document with the same
          DocType
        </p>
      </div>
      <DialogContent
        style={{ width: "500px", margin: "0 auto", overflow: "hidden" }}
        dividers
      >
        <Select onChange={onChange} style={{ width: "80%" }}>
          {(doctypes || []).map((item, i) => {
            return (
              <MenuItem key={i} value={item}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={close.bind(this)}
          className={classes.buttonModelCancel}
        >
          CANCEL
        </Button>
        <Button
          variant="outlined"
          onClick={reprocessFile.bind(this)}
          className={classes.buttonModelOk}
        >
          REPROCESS
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default ReprocessDocPlace;
