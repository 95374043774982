import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const TitleDocType = styled.label`
  font-size: 1.2em;
  color: #607d8b;
  text-align: center;
  font-weight: 900;
  margin: 10px 0px 1px 10px;
  display: inline-block;
`;

export const useStyles = makeStyles((theme) => ({
  buttons: {
    width: "100%",
    display: "inline-block",
    marginTop: "20px",
    height: "40px",
    color: "#560e1a",
  },
  btnSave: {
    backgroundColor: "#1876d2 !important",
    margin: "16px 0px 0 0px !important",
    color: "#fff !important",
    borderBottom: "2px solid #1876d2 !important",
    boxShadow: "0 0 0 !important",
  },
  closeUpload: {
    backgroundColor: "red",
    color: "red !important",
    margin: "16px 0 0 0 !important",
    border: "1px solid red !important",
    float: "right",
  },
  ButtonModal: {
    textTransform: "none !important",
    width: "250px",
  },
  helperText: {
    fontSize: "12px",
    backgroundColor: "#607d8b3d",
    padding: "5px",
    borderRadius: "3px",
  },
}));
