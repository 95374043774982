import React, { useEffect, useState } from "react";
import {
    PathOpen,
    PathTitle,
    useStyles, WrapperPath, WpFileWatcher
} from './style';
import TreeItems from '../../../Explorer/components/files/tree';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { NewFolderDocPlace } from "./newFolder";
import Switch from "@mui/material/Switch";
import CreateIcon from '@mui/icons-material/Add';
import { getTreeList } from "../../../../store/tree/actions";
import { styled } from '@mui/material/styles';
import { getDetailFolderList } from "../../../../store/document/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getCanonicalPath } from "../../../../services/ocr";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export function PathTreeEditDocPlace(props, prev) {
    const classes = useStyles();
    const [itemTree, setItemTree] = useState(null);
    const notify = (msj, event) => toast[event](msj);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [startPage] = useState(true);
    const [listTree, setListTree] = useState([]);
    const [defaultPath, setDefaultPath] = useState(null)
    const [fileWatcher, setFileWatcher] = useState(false);
    const [tree, setTree] = useState({
        label: '',
        value: '',
        parent: ''
    });
    const [mapPath, setMapPath] = useState([]);
    const [showTree, setShowTree] = useState(false);

    const [showNewFolder, setShowNewFolder] = useState(false);

    const { responseTree, errorTree, loading } = useSelector(state => ({
        responseTree: state.Tree.responseTree,
        errorTree: state.Tree.errorTree,
        loading: state.Tree.loadingTree,
    }))

    const getMapPath = (folderId) => {
    getCanonicalPath(folderId).then( response => {
        if (response.data.length > 0) {
            let listMP = [];
            let responseSorted = response.data.sort((a, b) => {
                if (a.Level > b.Level) {
                    return 1;
                }
                if (a.Level < b.Level) {
                    return -1;
                }
                return 0;
            });

            responseSorted.map((cp) => {
                if(cp.Type === 'Folder'){
                    listMP.push(
                        {
                            name: cp.Name,
                            id: cp.Id,
                            parent: cp.ParentArchiveId
                        }
                    )
                }
            });
            setMapPath(listMP);
        }
    });
    }



    useEffect(() => {
        if(props.folderId){
            getMapPath(props.folderId);
        }
        dispatch(getTreeList({ parentArchiveId: props.folderId, direction: '', hidenReservedFolder: props.hidenReservedFolder }, navigate));
    }, [startPage])

    useEffect(() => {
        setDefaultPath(props.folderName)

    }, [itemTree])

    const handleOnCloseMoveConfirmModal = () => {
        setShowTree(false);
    }

    const selectTree = (item) => {
        setItemTree(item);
        setTree({
            label: item.label,
            value: item.value,
            parent: !tree.label ? '' : item.parent ? item.parent.id : ''
        });
        dispatch(getTreeList({ parentArchiveId: item.value, direction: '', hidenReservedFolder: props.hidenReservedFolder }, navigate));

        let m = mapPath;
        m.push({ name: item.label, id: item.value, parent: !tree.label ? '' : item.parent ? item.parent.id : '' });
        setMapPath(m);
        props.callBack(item);
    }


    const backTree = (item) => {
        let newMap = [];
        let end = mapPath.length;

        dispatch(getTreeList({ parentArchiveId: item.value, direction: 'UP', hidenReservedFolder: props.hidenReservedFolder }, navigate));

        if (!item.value) { setTree([]); }

        mapPath.map((map, idx) => {
            if (map.id != mapPath[end - 1].id) {
                newMap.push(map);
            }
        })

        setMapPath(newMap);
    }

    const selectPath = (item) => {
        setShowTree(false);
    }

    const createFolder = (item) => {
        setShowNewFolder(true);
    }
    const closeModal = () => {
        //setShowTree(false);
        setShowNewFolder(false);
        try {
            dispatch(getTreeList({ parentArchiveId: itemTree.value, direction: '', hidenReservedFolder: props.hidenReservedFolder }, navigate));
        } catch (error) {
            dispatch(getTreeList({ parentArchiveId: '', direction: '', hidenReservedFolder: props.hidenReservedFolder }, navigate));
            console.log(error)
        }

    }
    useEffect(() => {
        setListTree(props.tree ? props.tree : []);
    }, [props.tree]);

    const callNewFolder = (name) => {
        setShowNewFolder(false);
        //props.newFolder({ name: name, item: item });
    };

    return (
        <>
            {defaultPath ? (
                <>
                    <span>
                        File Watcher Path
                    </span>
                    <WrapperPath className={props.emptyPath && !itemTree?.label && classes.errorPath}>
                        <PathTitle> {itemTree ? itemTree.label : (defaultPath ? defaultPath : '')}   </PathTitle>
                        <PathOpen onClick={() => setShowTree(true)}> Open </PathOpen>
                        <BootstrapDialog
                            onClose={() => {
                                setShowTree(false)
                            }}
                            aria-labelledby="customized-dialog-title"
                            open={showTree}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {
                                setShowTree(false)
                            }}>
                                Chose a Folder
                            </BootstrapDialogTitle>


                            <NewFolderDocPlace
                                newFolder={callNewFolder.bind(this)}
                                close={closeModal.bind(this)}
                                show={showNewFolder}
                                treePrevius={mapPath}
                            />


                            <DialogContent style={{ width: '500px' }} dividers>
                                {
                                    itemTree &&
                                    <Typography>
                                        <span
                                            style={{ display: 'inline-block', marginLeft: '30px', fontWeight: '900', color: '#1274ca', fontSize: '16px', marginTop: '15px' }}
                                        >{itemTree ? itemTree.label : null}</span>
                                    </Typography>
                                }
                                <div>
                                    {
                                        <TreeItems
                                            click={selectTree.bind(this)}
                                            backTree={backTree.bind(this)}
                                            treePrevius={mapPath}
                                            tree={listTree} />
                                    }
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        createFolder();
                                        //setShowTree(false)
                                    }}>
                                    Create Folder
                                </Button>

                                <Button autoFocus onClick={() => {
                                    setShowTree(false)
                                }}>
                                    Select
                                </Button>
                            </DialogActions>
                        </BootstrapDialog>
                    </WrapperPath>
                </>
            ) : (
                <>
                    <>
                        <div className="col-sm-6">
                            <WpFileWatcher>
                                File Watcher uploads{" "}
                                <Switch
                                    
                                    onChange={(v) => {
                                        setFileWatcher(v.currentTarget.checked);
                                    }}
                                />
                            </WpFileWatcher>
                        </div>
                        {fileWatcher &&
                            <>
                                <span>
                                    File Watcher Path
                                </span>
                                <WrapperPath className={props.emptyPath && !itemTree?.label && classes.errorPath}>
                                    <PathTitle> {itemTree ? itemTree.label : (defaultPath ? defaultPath : '')}   </PathTitle>
                                    <PathOpen onClick={() => setShowTree(true)}> Open </PathOpen>
                                    <BootstrapDialog
                                        onClose={() => {
                                            setShowTree(false)
                                        }}
                                        aria-labelledby="customized-dialog-title"
                                        open={showTree}
                                    >
                                        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {
                                            setShowTree(false)
                                        }}>
                                            Chose a Folder
                                        </BootstrapDialogTitle>


                                        <NewFolderDocPlace
                                            newFolder={callNewFolder.bind(this)}
                                            close={closeModal.bind(this)}
                                            show={showNewFolder}
                                            treePrevius={mapPath}
                                        />


                                        <DialogContent style={{ width: '500px' }} dividers>
                                            {
                                                itemTree &&
                                                <Typography>
                                                    <span
                                                        style={{ display: 'inline-block', marginLeft: '30px', fontWeight: '900', color: '#1274ca', fontSize: '16px', marginTop: '15px' }}
                                                    >{itemTree ? itemTree.label : null}</span>
                                                </Typography>
                                            }
                                            <div>
                                                {
                                                    <TreeItems
                                                        click={selectTree.bind(this)}
                                                        backTree={backTree.bind(this)}
                                                        treePrevius={mapPath}
                                                        tree={listTree} />
                                                }
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    createFolder();
                                                    //setShowTree(false)
                                                }}>
                                                Create Folder
                                            </Button>

                                            <Button autoFocus onClick={() => {
                                                setShowTree(false)
                                            }}>
                                                Select
                                            </Button>
                                        </DialogActions>
                                    </BootstrapDialog>
                                </WrapperPath>
                            </>
                        }
                    </>
                </>
            )}
        </>
    )
}

export default PathTreeEditDocPlace
