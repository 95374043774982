export const INPUT_METHOD_LIST = "INPUT_METHOD_LIST"
export const INPUT_METHOD_LIST_SUCCESS = "INPUT_METHOD_LIST_SUCCESS"
export const API_ERROR_INPUT_METHOD = "API_ERROR_INPUT_METHOD"
export const INPUT_METHOD_POST = "INPUT_METHOD_POST"
export const INPUT_METHOD_POST_SUCCESS = "INPUT_METHOD_POST_SUCCESS"
export const API_ERROR_INPUT_METHOD_POST = "API_ERROR_INPUT_METHOD_POST"
export const INPUT_METHOD_PUT = "INPUT_METHOD_PUT"
export const INPUT_METHOD_PUT_SUCCESS = "INPUT_METHOD_PUT_SUCCESS"
export const API_ERROR_INPUT_METHOD_PUT = "API_ERROR_INPUT_METHOD_PUT"
export const INPUT_METHOD_DELETE = "INPUT_METHOD_DELETE"
export const INPUT_METHOD_DELETE_SUCCESS = "INPUT_METHOD_DELETE_SUCCESS"
export const API_ERROR_INPUT_METHOD_DELETE = "API_ERROR_INPUT_METHOD_DELETE"