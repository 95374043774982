import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../../../components/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 35px;
`;

export const Title = styled.h1`
  color: ${colors.blue};
  margin: -11px 0px 0 0;
  padding: 0;
  font-weight: bold;
`;

export const ViewWraper = styled.section`
  width: 105px;
  right: 0;
  height: 0px;
  position: absolute;
  top: 80px;
`;

export const TextView = styled.section`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  float: left;
  color: #666;
  margin-top: 7px;
  margin-right: 10px;
`;

export const ViewUL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ViewLI = styled.li`
  float: left;
  width: 34px;
  height: 34px;
  padding: 6px;
  cursor: pointer;
`;

export const DragAndDropText = styled.div`
  color: ${colors.blue};
  font-size: 10px;
  word-break: break-word;
  width: 90%;
  line-height: 1;
  font-weight: 100;
  margin-top: -7px;
  text-align: center;
`;

export const TitleDocumentUpload = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.blue};
  word-break: break-word;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 24px;
`;

export const FileNewUpload = styled.article`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
`;

export const WrapperIcon = styled.div`
  //margin: 9px 0 0 0;
  width: 100%;
`;

export const useStyles = makeStyles((theme) => ({
  imgDoc: {
    width: "40px",
    height: "43px",
    objectFit: "contain",
  },
  imgDescription: {
    fontSize: "9px",
    lineHeight: "1",
    wordBreak: "break-all",
    marginBottom: "10px",
  },
  dropZoneComplete: {
    width: "100%",
    height: "100%",
    border: "2px dashed #006ac6",
    padding: "16px 0px 0px 5px",
    overflowY: "auto",
    backgroundColor: "#fff",
    display: "block",
    borderRadius: "12px",
  },
  dropzone: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    border: "0px dashed #fff",
    backgroundColor: "rgb(245, 247, 250)",
    overflow: "hide",
  },
  dropBox: {
    float: "left",
    width: "75px",
    height: "auto",
    fontSize: "12px",
    wordBreak: "break-word",
    display: "inline-block",
    padding: "5px",
    color: "#000",
    margin: "0 6px 0 0",
    position: "relative",
    overflowY: "hidden",
    textAlign: "center",
  },
  iconDelete: {
    position: "absolute",
    top: "-8px",
    color: "red",
    right: "-7px",
  },
  selectedFileWrapper: {
    textAlign: "center",
  },
  selectedFile: {
    color: "#000",
    fontWeight: "bold",
  },
  iconUploadFile: {
    color: "#006ac6",
    fontSize: "2rem !important",
    // top: "5px",
    // position: "absolute",
    // left: "34px",
  },
}));
