import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 380px);
  overflow-y: auto;
  border-radius: 4px;
  margin-top: 25px;
  display: inline-block;
`;
export const useStyles = makeStyles((theme) => ({
    inActiveTree: {
        backgroundColor: '#fff'
    }
}));
