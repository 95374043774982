export const LOGIN_USER = "LOGIN_USER"
export const CUSTOMER_USER = "CUSTOMER_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS"
export const CUSTOMER_ERROR = "CUSTOMER_ERROR"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
//Forget password
export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS= "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR= "FORGET_PASSWORD_ERROR"

//reset password from forgot password
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS= "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR= "RESET_PASSWORD_ERROR"

//Hash Validation
export const HASH_VALIDATE = "HASH_VALIDATE"
export const HASH_VALIDATE_SUCCESS= "HASH_VALIDATE_SUCCESS"
export const HASH_VALIDATE_ERROR= "HASH_VALIDATE_ERROR"

//reset redux app
export const RESET_APP= "RESET_APP"

/**REGISTER ESIGNATURE USER */
export const REGISTER_CLEAR_DATA = "REGISTER_CLEAR_DATA";
export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
/**END */

