import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {colors} from "../../../../components/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 35px;
`;

export const Title = styled.h1`
    color: ${colors.blue};
    margin: -11px 0px 0 0;
    padding: 0;
    font-weight: bold;
`;

export const ViewWraper = styled.section`

      width: 100%;

      right: 0;
      height: 0px;
      position: absolute;
      top: 80px;
`;

export const TextView = styled.section`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    float: left;
    color: #666;
    margin-top: 7px;
    margin-right: 10px;
`;

export const ViewUL = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const ViewLI = styled.li`
    float:left;
    width: 34px;
    height: 34px;
    padding: 6px;
    cursor: pointer;
`;

export const DragAndDropText = styled.div`

      color: ${colors.blue};

      font-size: 10px;
      word-break: break-word;
      width: 90%;
      line-height: 1;
      font-weight: 100;
      margin: -5px auto;
`;

export const TitleDocumentUpload = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: center;

    color: ${colors.blue};

    margin-top: -15px;
    word-break: break-word;
    line-height: 1;
    padding: 0px 5px 2px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 24px;
`;


export const FileNewUpload = styled.article`
    width: 100%;
    height: 120px;
    background-color: ${colors.white} ;
    border-radius: 5px;
    border: 1px dashed ${colors.blue};
    &:hover {
        background-color: ${colors.white};
        border: 1px dashed ${colors.blue};

        cursor:pointer;
    }
`;

export const useStyles = makeStyles((theme) => ({
    dropZoneComplete:{
        width: '100%',
        border: '1px dashed #006ac6',
        padding: '16px 0px 0px 5px',
        backgroundColor: '#fff',
        overflowY: 'auto',
        borderRadius: '5px'

    },
    dropzone: {
        textAlign: 'center',
        border: '0px dashed #fff',
        backgroundColor: '#fff',
        overflow: 'hide'
    },
    dropBox: {
        float: 'left',
        width: '100px',
        height: '150px',
        fontSize: '12px',
        wordBreak: 'break-word',
        display: 'inline-block',
        padding: '5px',
        color: '#000',
        margin: '1px 12px 18px 12px',
        position:'relative',
        overflowY: 'hidden'
    },
    iconDelete:{
        position: 'absolute',
        top: '-8px',
        color: 'red',
        right: '-7px'
    },
    selectedFileWrapper: {
        textAlign: 'center'
    },
    selectedFile:{
        color: '#000',
        fontWeight: 'bold'
    },
    iconUploadFile: {
        color: 'rgb(0, 106, 198);',

        fontSize: '3rem !important',
        margin: '20px 0px 12px 0px'
    }
  }));


  



