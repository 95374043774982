
import React, { useEffect,useState } from "react";

import { Wrapper } from "./style";

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Button from "@mui/material/Button";

import moment from "moment-timezone";
import PreviewIcon from "@mui/icons-material/Preview";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
export function GridDocPlace(props) {


  const selectOption = (op, id, row) => {
    optionSelect({ action: op, id: id, row: row });
  };

  const optionSelect = (event) => {
    props.option(event);
  };

 
  function parseStringToJson(inputString) {
    try {
      const jsonObject = JSON.parse(inputString);
      return jsonObject;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }
  
  const showType = (value) => {
    switch (value) {
      case 3:
        return "Date";
      case 4:
        return "Text";
      case 5:
        return "Number";
      case 6:
        return "List";
      case 7:
        return "Checkbox";
      case 8:
        return "Radio";
      default:
        return "Text";
    }
  };
  const showData = (value) => {
    let data = parseStringToJson(value);
    let html = "";
    (data.form[0].fields).map((item) => {
      if (item.fieldType > 2)
      html += `<div><b>${item.fieldTitle} (${showType(item.fieldType)})</b> ${item.fieldValue}</div>`;
    });
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Form values",
      html: html,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: "Close",
    });
  };
  /*  const inputString = '...'; // 
  const parsedObject = parseStringToJson(inputString);
  
  if (parsedObject !== null) {
    console.log(parsedObject);
  }
*/
  const headCols = [
    {
      field: 'email',
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: 'Email'
    },
    {
      field: 'templateName',
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: 'Document'
    },
    {
      field: 'CreatedAt',
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: 'Date',
      type: "date",
      valueFormatter: (params) => {
        return `${moment(params.value).format("MM/DD/YYYY")}`;
      },
    }, /* {
      field: "contentForm",
      headerName: "Form values",
      sortable: false,
      headerClassName: "MuiTableHead-root",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            title="Sign"
            onClick={() => {showData(params.value)}}
          >
            <PreviewIcon
              fontSize="medium"
              style={{ width: "28px", height: "28px", marginRight: "10px" }}
            />
            <span style={{ marginLeft: "10px" }}>View</span>
          </Button>
        </>
      ),
    },*/
  ]
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <Wrapper>
      <DataGrid

      treeData
        rows={props.data}
        columns={headCols}
        getRowId={(row) => row.id}
  getTreeDataPath={(row) => row.data}

        pageSize={10}
        rowsPerPageOptions={[5]}
        sortingOrder={["asc", "desc"]}
        getRowClassName={() => `MuiTableCell-row`}
        components={{
          Toolbar: CustomToolbar,
      }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
    </Wrapper>
  );
}

export default GridDocPlace;
