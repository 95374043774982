
import { useStyles } from "./style";


import React, { useEffect, useState, useRef } from "react";
import iconFolder from "../../../../assets/folderGray.png";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import ShipmentDocPlace from "../Shipment";
import { changeStatus } from "../../../../services/form";
import { toast } from "react-toastify";
import {
  SquareBox,
  BoxTitle,
  BoxDate,
  BurgerIcon,
  PopupMenu,
  BoxThumbnail,
  BoxShipments,
  BoxCompleted
} from "./style";

const style = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "auto",
  width: "40%",
  height: "fit-content",
  bgcolor: "background.paper",
  p: 4,
};



export function Template(props, prev) {

  const title = () => {
    let max = 30;
    if (props.title.length > max) {
      return props.title.substring(0, max) + '...';
    } else {
      return props.title;
    }
  }


  const handleRecoverTemplate = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to recover this template. ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Recover',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform the delete operation here
        // You can call an API or update the state accordingly
        // Example: deleteTemplate(templateId)
        props.loading(true);
        changeStatus(id,0, (event) => {})
        .then((response) => {
          props.loading(false);
          Swal.fire(
            'Recovered!',
            'The template has been succesfully recovered.',
            'success'
          );
          props.updateTemplates();
         
        })
        .catch((error) => {
          props.loading(false);
          toast.error("An error has occurred. Please try again later.");
        })
  
       
      }
    });
  };

  return (
    <>
     
     

      <SquareBox key={props.idx} onClick={()=>handleRecoverTemplate(props.id)} >
        <BoxThumbnail >
          <img
            src={iconFolder}
            alt="Templates folder"
          />

        </BoxThumbnail>
        <BoxTitle>{title()}</BoxTitle>
        <BoxDate>{props.date}</BoxDate>
      </SquareBox>
    </>

  );
}
export default Template;
