import React, { useEffect, useState, useCallback } from "react";
import Button from "@mui/material/Button";
import { Message, useStyles, WrapperForm } from "./style";
import LoadingComponent from "../../../../components/loading";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import InputFormDocPlace from "../../../../components/form/input";
import SelectDropdownDocPlace from "../../../../components/form/selectDropdown";
import { postRolList, putRolList } from "../../../../store/rol/actions";
import { getDocTypeList1 } from "../../../../store/docType/actions";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import TreeItems from "./tree";
import { getTreeList } from "../../../../store/tree/actions";
import SetRowGridDocPlace from "../../../../components/table/rows/row";
import { getDate } from "date-fns";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const OriginTypeList = [
  { value: "SCANNER", label: "SCANNER" },
  { value: "WEB_UI", label: "WEB_UI" },
];



export function NewItemDocPlace(props) {
  const classes = useStyles();
  const [showNew, setShowNew] = useState(false);
  const [name, setName] = useState({ value: "", error: false });
  const [description, setDescription] = useState({ value: "", error: false });
  const [readF, setReadF] = useState({ value: true, error: false });
  const [writeF, setWriteF] = useState({ value: "", error: false });
  const [deleteF, setDeleteF] = useState({ value: "", error: false });
  const [tagF, setTagF] = useState([]);

  const [documentType, setDocumentType] = useState({ value: "", error: false });
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [originID, setOriginID] = useState({ value: "", error: false });
  const [originType, setOriginType] = useState({ value: "", error: false });
  const [defaultData, setDefaultData] = React.useState("");

  const [itemTree, setItemTree] = React.useState(null);
  const [tree, setTree] = React.useState([]);
  const [treePrevius, setTreePrevius] = React.useState([]);

  const notify = (msj, event) => toast[event](msj);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const [isRoot, setRoot] = React.useState(true); //doc 1621, always starts in the root
  const [folders,setFolders] = React.useState([]);
  const [foldersID,setFoldersID] = React.useState([]);

  const { responseRolPost, loadingRolPost } = useSelector((state) => ({
    responseRolPost: state.Rol.responseRolPost,
    loadingRolPost: state.Rol.loadingRolPost,
  }));
  const { responseRolPut, loadingRolPut } = useSelector((state) => ({
    responseRolPut: state.Rol.responseRolPut,
    loadingRolPut: state.Rol.loadingRolPut,
  }));
  const { responseDocType1, errorDocType1, loading3 } = useSelector(
    (state) => ({
      responseDocType1: state.DocType.responseDocType1,
      errorDocType1: state.DocType.errorDocType1,
      loading3: state.DocType.loadingDocType1,
    })
  );
  const { responseTree, loading4 } = useSelector((state) => ({
    responseTree: state.Tree.responseTree,
    loading4: state.Tree.loadingTree,
  }));

  useEffect(() => {
    if (responseTree) {
      addRootFolder(responseTree);
    }
  }, [responseTree]);

  /**DOC 1621, add a root folder to the tree, only if it is in the root */
  const addRootFolder = (tree) => {
    if (treePrevius.length == 0 && tree.data) {
      tree.data.unshift({
        children: null,
        fileSize: null,
        folder: true,
        id: 'Root',
        name: 'All files and folders',
        nameLower: 'root',
        type: 'Folder',
        updateAt: null,
      });
    }

    setTree(tree)

  }

  useEffect(() => {
    setDefaultData(props.default);
    if (props.default) {  //UPDATE GET values
      setName({ value: props.default.name, error: false });
      setDescription({ value: props.default.description, error: false });
      setWriteF({ value: props.default.write, error: false });
      setDeleteF({ value: props.default.delete, error: false });
      setTagF(props.default.tag ? JSON.parse(props.default.tag) : []);

      let fd = [];
      let fdID = [];

      if(props.default.root===1){   //DOC-1621 add root to the list if the roles has it
        fd.push({ id: "Root", name: "All files and folders"});
        fdID.push("Root");
      }

      props.default.archivePermission.map((arc) => {
        fd.push({ id: arc.fkArchiveId, name: arc?.fkArchiveName });
        fdID.push(arc.fkArchiveId);
      });

      setFoldersID(fdID);
      setFolders(fd);
    } else {
      clearForm();
    }
    setShowNew(props.show);
  }, [props.default]);

  useEffect(() => {
    setShowNew(props.show);

    if(!props.default){
      setFoldersID([]);
    }

    dispatch(getTreeList({ parentArchiveId: "", direction: "", hidenReservedFolder: true }, navigate));
  }, [props.show]);

  useEffect(() => {
    if (responseRolPost !== null) {
      clearForm();
      close();
      props.done(true);
    }
  }, [responseRolPost]);

  useEffect(() => {
    if (responseRolPut?.id) {
      clearForm();
      close();
      props.done(true);
    }
  }, [responseRolPut]);

  const close = () => {
    props.close();
  };

  const clearForm = () => {
    setName({ value: "", error: false });
    setDescription({ value: "", error: false });
    setWriteF({ value: "", error: false });
    setDeleteF({ value: "", error: false });
    setTagF([]);
    setFolders([]);
  };

  const newAcction = () => {
    if (!name.error && name.value && !description.error && description.value) {
      /**DOC-1621 remove root from list of archive roles and add it as an attribute */
      let fID = foldersID;
      let isRoot = 0;
      let index = fID.indexOf("Root");
      if (index > -1) {
        fID.splice(index, 1);
        isRoot = 1;
      }
      /**** */
      if (defaultData) {  //UPDATE
        let obj = {
          customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
          archivePermission: fID,
          delete: deleteF.value ? 1 : 0,
          description: description.value,
          id: "",
          name: name.value,
          read: readF.value ? 1 : 0,
          tag: JSON.stringify(tagF),
          weight: 0,
          root: isRoot,   //DOC-1621
          write: writeF.value ? 1 : 0,
        };
         dispatch(putRolList({ data: obj, id: defaultData.id }, navigate)); //DOC-1621
      } else {  //create
        let obj = {
          customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
          archivePermission: fID,
          delete: deleteF.value ? 1 : 0,
          description: description.value,
          name: name.value,
          read: readF.value ? 1 : 0,
          tag: JSON.stringify(tagF),
          weight: 0,
          root: isRoot,  //DOC-1621
          write: writeF.value ? 1 : 0,
        };

        dispatch(postRolList(obj, navigate));
      }

    } else {
      notify("Please enter all the required information", "error");
    }
  };

  const filltags = useCallback(
    (e, newValue) => {
      setTagF(newValue);
    },
    [setTagF]
  );

  const selectTree = (item) => {
    setItemTree(item);
    setTree({
      label: item.label,
      value: item.value,
      parent: !tree.label ? "" : item.parent ? item.parent.id : "",
    });
    dispatch(
      getTreeList({ parentArchiveId: item?.value, direction: "", hidenReservedFolder: true }, navigate)
    );

    let m = treePrevius;
    m.push({
      name: item.label,
      id: item.value,
      parent: !tree.label ? "" : item.parent ? item.parent.id : "",
    });
    setTreePrevius(m);
  };

  const backTree = (item) => {
    let newMap = [];
    let end = treePrevius.length;

    dispatch(
      getTreeList({ parentArchiveId: item?.value, direction: "UP", hidenReservedFolder: true }, navigate)
    );

    if (!item.value) {
      setTree([]);
    }

    treePrevius.map((map, idx) => {
      if (map.id != treePrevius[end - 1].id) {
        newMap.push(map);
      }
    });

    setTreePrevius(newMap);
  };

  const fillFolders = (item) => {
    const find = folders.filter((fol) => fol.name === item.name);
    if (find.length <= 0) {  //add the folder only if the folder not exists in the selection
      let fd = folders;
      let fdI = foldersID;
      fd.push(item);
      fdI.push(item.id);
      setFolders(fd);
      setFoldersID(fdI);
      dispatch(
        getTreeList({ parentArchiveId: item?.value, direction: "UP", hidenReservedFolder:true }, navigate)
      );
    }
    console.log(item);
  };

  /** DOC-1527 remove one folder of permission list */
  const removeFolder = (item) => {
    let fd = [];
    let fdI = [];
    folders.map((folder) => {
      if (folder.id !== item.id) {
        fd.push(folder);
      }
    });
    setFolders(fd);
    foldersID.map((folder) => {
      if (folder !== item.id) {
        fdI.push(folder);
      }
    });
    setFoldersID(fdI);
    dispatch(
      getTreeList({ parentArchiveId: item?.value, direction: "UP", hidenReservedFolder:true }, navigate)
    );
  };

  return (
    <Dialog
      fullScreen
      open={showNew}
      onClose={close}
      TransitionComponent={Transition}
    >
      <LoadingComponent
        show={loadingRolPost || loadingRolPut || loading3 || loading4}
        text={
          defaultData
            ? "Updating..."
            : loading3
              ? "Getting Document Types"
              : "Creating..."
        }
      />
      <AppBar sx={{ position: "relative" }}>
        <Toolbar style={{ backgroundColor: "#006ac6" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {defaultData ? "Update Role" : "New Role"}
          </Typography>
          <Button autoFocus color="inherit" onClick={newAcction}>
            {defaultData ? "Update" : "Create"}
          </Button>
        </Toolbar>
      </AppBar>
      <article style={{ margin: "30px" }}>
        <Message>To add a new role, all fields must be entered.</Message>
        <WrapperForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFormDocPlace
                id={"name"}
                field={name}
                setField={setName}
                required={true}
                type="text"
                label="Name"
                helperText="Enter name!"
              />
            </Grid>
            <Grid item xs={6} className={classes.row}>
              <InputFormDocPlace
                id={"description"}
                field={description}
                setField={setDescription}
                required={true}
                type="text"
                label="Description"
                helperText="Enter Description!"
              />
            </Grid>
            <Grid item xs={4}>
              <Checkbox
                {..."Read"}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                disabled
                checked
              />
              <label>READ</label>
            </Grid>
            <Grid item xs={4}>
              <Checkbox
                {..."Write"}
                onChange={(e) => {
                  setWriteF({ value: e.target.checked, error: false });
                }}
                checked={writeF.value}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              />
              <label>WRITE</label>
            </Grid>
            <Grid item xs={4}>
              <Checkbox
                {..."Delete"}
                onChange={(e) => {
                  setDeleteF({ value: e.target.checked, error: false });
                }}
                checked={deleteF.value}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              />
              <label>DELETE</label>
            </Grid>
            <Grid item xs={12}>
              {folders.map((folder, idx) => (
                <Chip
                  onDelete={removeFolder.bind(this, folder)}
                  style={{ marginRight: "10px" }}
                  key={idx}
                  label={folder.name}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <TreeItems
                click={selectTree.bind(this)}
                addFolder={fillFolders.bind(this)}
                backTree={backTree.bind(this)}
                treePrevius={treePrevius}
                tree={tree}
              />
            </Grid>
          </Grid>
        </WrapperForm>
      </article>
    </Dialog>
  );
}

export default NewItemDocPlace;
