// Validates if the user is registered in eSignature
export const VALIDATE_USER_SIGNATURE = "VALIDATE_USER_SIGNATURE";
export const VALIDATE_USER_SIGNATURE_SUCCESS =
  "VALIDATE_USER_SIGNATURE_SUCCESS";
export const VALIDATE_USER_SIGNATURE_ERROR = "VALIDATE_USER_SIGNATURE_ERROR";
export const VALIDATE_USER_SIGNATURE_CLEAR_DATA =
  "VALIDATE_USER_SIGNATURE_CLEAR_DATA";
// ****---END---****

export const E_SIGNATURE_REQUESTS = "E_SIGNATURE_REQUESTS";
export const E_SIGNATURE_REQUESTS_SUCCESS = "E_SIGNATURE_REQUESTS_SUCCESS";
export const E_SIGNATURE_REQUESTS_ERROR = "E_SIGNATURE_REQUESTS_ERROR";
export const E_SIGNATURE_REQUESTS_CLEAR_DATA =
  "E_SIGNATURE_REQUESTS_CLEAR_DATA";

// List required signatures for documents
export const REQUIRED_SIGNATURE_DOCUMENTS = "REQUIRED_SIGNATURE_DOCUMENTS";
export const REQUIRED_SIGNATURE_DOCUMENTS_SUCCESS =
  "REQUIRED_SIGNATURE_DOCUMENTS_SUCCESS";
export const REQUIRED_SIGNATURE_DOCUMENTS_ERROR =
  "REQUIRED_SIGNATURE_DOCUMENTS_ERROR";
export const REQUIRED_SIGNATURE_DOCUMENTS_CLEAR_DATA =
  "REQUIRED_SIGNATURE_DOCUMENTS_CLEAR_DATA";
// ****---END---****
