import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import {
    useStyles
} from './style';
import TreeItems from './tree';
import LoadingComponent from "../../../../components/loading";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@mui/material/DialogActions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getTreeList } from "../../../../store/tree/actions";
import { postMoveDoc } from "../../../../store/explorer/actions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export function MoveDocPlace(props, prev) {
    const classes = useStyles();
    const [itemTree, setItemTree] = React.useState(null);
    const [tree, setTree] = React.useState([]);
    const [treePrevius, setTreePrevius] = React.useState([]);

    const [showNew, setShowNew] = useState(false);
    const [item, setItem] = useState(false);
    const notify = (msj, event) => toast[event](msj);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { responseTree, errorTree, loading } = useSelector(state => ({
        responseTree: state.Tree.responseTree,
        errorTree: state.Tree.errorTree,
        loading: state.Tree.loadingTree,
    }))
    const { responseMoveDoc, errorMoveDoc, loading2 } = useSelector(state => ({
        responseMoveDoc: state.Explorer.responseMoveDoc,
        errorMoveDoc: state.Explorer.errorMoveDoc,
        loading2: state.Explorer.loadingMoveDoc,
    }))


    useEffect(() => {
        setItem(props.itemMove);
        setItemTree({label: 'Root',value:'Root', parent: '' });//DOC-1702 set to root by default 
        dispatch(getTreeList({ parentArchiveId: '', direction: '' }, navigate));
        setShowNew(props.show)
    }, [props.show]);
    useEffect(() => {
        if (responseTree) {
            setTree(responseTree);
        }
    }, [responseTree]);
    useEffect(() => {
        if (responseMoveDoc !== null) {
            props.move(true)
            close();
        }
    }, [responseMoveDoc]);


    const close = () => {
        props.close();
    }

    const selectTree = (item) => {
        setItemTree(item);
        setTree({
            label: item.label,
            value: item.value,
            parent: !tree.label ? '' : item.parent ? item.parent.id : ''
        });
        dispatch(getTreeList({ parentArchiveId: item.value, direction: '' }, navigate));

        let m = treePrevius;
        m.push({ name: item.label, id: item.value, parent: !tree.label ? '' : item.parent ? item.parent.id : '' });
        setTreePrevius(m);
    }

    const backTree = (item) => {
        let newMap = [];
        let end = treePrevius.length;

        dispatch(getTreeList({ parentArchiveId: item.value, direction: 'UP' }, navigate));

        if (!item.value) { setTree([]); }

        treePrevius.map((map, idx) => {
            if (map.id != treePrevius[end - 1].id) {
                newMap.push(map);
            }
        })
        if(treePrevius.length>1){  /**DOC-1702 if it have at least 1 previous, then it is not root and we set the current item tree */
            setItemTree({label: treePrevius[treePrevius.length-2].name,value:treePrevius[treePrevius.length-2].id, parent: '' });
        }else{  //if it is root, we set a custom
            setItemTree({label: 'Root',value:'Root', parent: '' });
        }

        setTreePrevius(newMap);
    }

    const moveFile = () => {
        let arc = [];
        arc.push(item.fkArchive.id);

        let obj = {
            archiveOverride: false,
            keep: false,
            archives: arc
        }
        if(itemTree.value!=='Root'){  //DOC-1702 when it is not root, add the targetArchiveID field
            obj.targetArchiveId = itemTree.value;
        }

        dispatch(postMoveDoc(obj, navigate));
    }

    return (
        <BootstrapDialog
            onClose={() => {
                close();
            }}
            style={{ overflow: 'hidden' }}
            aria-labelledby="customized-dialog-title"
            open={showNew}
        >
            <LoadingComponent show={loading || loading2} text={"Moving Item..."} />
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {
                close();
            }}>
                Move document
            </BootstrapDialogTitle>
            <DialogContent style={{ width: '500px', overflow: 'hidden' }} dividers>
                <div style={{ marginLeft: '25px' }}>Move to: <span style={{ fontWeight: '900', color: '#006ac6' }}> {itemTree ? itemTree.label : ''}</span></div>
                <TreeItems
                    click={selectTree.bind(this)}
                    backTree={backTree.bind(this)}
                    treePrevius={treePrevius}
                    tree={tree}
                    hideTrash={true} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={close.bind(this)} className={classes.buttonModelCancel}>CANCEL</Button>
                <Button variant="outlined" onClick={moveFile.bind(this)} className={classes.buttonModelOk}>MOVE</Button>
            </DialogActions>
        </BootstrapDialog>

    )
}

export default MoveDocPlace
