import React, { useEffect, useState, useMemo, useCallback } from "react";
import MetaTags from "react-meta-tags";

import { Container } from "reactstrap";
import SearchDocPlace from "../../components/search/search";
import { toast, ToastContainer } from "react-toastify";
import GridDocPlace from "./components/grid/grid";
import NewItemDocPlace from "./components/new/new";
//API CALL
import {
  getInputMethodList,
  deleteInputMethod,
} from "../../store/inputMethod/actions";
//END
import { useDispatch, useSelector } from "react-redux";
import Title from "../../components/title/title";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/loading";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  useStyles
} from "./style";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import debounce from 'lodash.debounce';

const InputMethodDocPlace = (props) => {
  const notify = (msj, event) => toast[event](msj);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [startPage] = useState(true);
  const [pageSize, setPageSize] = useState(200);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(1000);
  const [sortBy, setSortBy] = useState("name");
  const [showNew, setShowNew] = React.useState(false);
  const [defaultData, setDefaultData] = React.useState("");
  const [inputMethodList, setInputMethodList] = React.useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const columns = [
    { name: "Name", label: "name", type: "string" },
    { name: "Pshysical Name", label: "physicalName", type: "string" },
    { name: "Method Description", label: "description", type: "string" },
    { name: "Origin Id", label: "originId", type: "string" },
    { name: "Origin Type", label: "originType", type: "string" },
  ];

  const actions = [
    { name: "Update", label: "update" },
    { name: "Delete", label: "delete" },
  ];

  const { responseInputMethod, errorInputMethod, loading } = useSelector(
    (state) => ({
      responseInputMethod: state.InputMethod.responseInputMethod,
      errorInputMethod: state.InputMethod.errorInputMethod,
      loading: state.InputMethod.loadingInputMethod,
    })
  );

  const { responseInputMethodDelete, loadingInputMethodDelete } = useSelector(
    (state) => ({
      responseInputMethodDelete: state.InputMethod.responseInputMethodDelete,
      loadingInputMethodDelete: state.InputMethod.loadingInputMethodDelete,
    })
  );

  useEffect(() => {
    dispatch(getInputMethodList({'name':' '}, navigate));
  }, [startPage]);

  useEffect(() => {
    if (responseInputMethod?.data.length > 0) {
      let i = [];
      responseInputMethod.data.map((item) => {
        i.push({
          id: item.id,
          name: item.content.Name,
          physicalName: item.content.PhysicalName,
          description: item.description,
          doctypeName: item.doctype.name,
          originId: item.originId,
          originTypeText: item.originType,
          originType: {
            value: item.originType,
            label: item.originType,
          },
          docType: {
            value: item.doctype.id,
            label: item.doctype.name,
          },
        });
      });
      setInputMethodList(i);
    }
  }, [responseInputMethod]);

  useEffect(() => {
    if (responseInputMethodDelete !== null) {
      dispatch(getInputMethodList({'name':' '}, navigate));
    }
  }, [responseInputMethodDelete]);

  const searchFile = (filterSearch) => {
    setValueSearch(filterSearch);
  };

  const refresh = (item) => {
    dispatch(getInputMethodList({'name':' '}, navigate));
  };

  const closeModal = () => {
    setDefaultData(null);
    setShowNew(false);
  };

  const takeAction = (value) => {
    switch (value.action) {
      case "update":
        // setDefaultData(value.id);
        // setShowNew(true);
        break;
      case "delete":
        //deleteItem(value.id.id);
        break;
    }
  };

  const optionGrid = (value) => {
    if (value.action === "edit") {
      setDefaultData(value.row.row);
      setShowNew(true);
    } else {
      deleteItem(value.id);
    }
  };

  const deleteItem = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Are you sure?",
      text: "",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.value) {
        dispatch(deleteInputMethod(id, navigate));
      }
    });
  };

  const getSearchFile = useEffect( () => {
    if (valueSearch) {
      dispatch(getInputMethodList({'name':valueSearch}, navigate));
    }
  },[dispatch, navigate, valueSearch]);

  const debouncedResults = useMemo(() => {
    return debounce(searchFile, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DocPlace Input Method</title>
        </MetaTags>
        <Container fluid>
          <Title title={"Input Method"} viewActive={false} />
          <ToastContainer autoClose={3000} />
          <LoadingComponent
            show={loading || loadingInputMethodDelete}
            text={
              loading ? "Loading Input Method..." : "Deleting Input Method..."
            }
          />
          <SearchDocPlace callSearch={debouncedResults.bind(this)}  clear={() => { refresh(""); }} />
          <Button
            style={{ marginTop: "19px", float: "right" }}
            onClick={() => {
              setShowNew(true);
            }}
            size="small"
            color="primary"
            variant="contained"
          >
            <AddBoxIcon />
            <span>Add New</span>
          </Button>
          <GridDocPlace data={inputMethodList} option={optionGrid.bind(this)} />
        </Container>
      </div>
      <NewItemDocPlace
        done={refresh.bind(0, this)}
        close={closeModal.bind(this)}
        default={defaultData}
        show={showNew}
      />
    </React.Fragment>
  );
};

export default InputMethodDocPlace;
