import React, { Component } from "react";
import { PropTypes } from "prop-types";
import objectAssign from "object-assign";
import style from "./styles";

class Region extends Component {
  constructor(props) {
    super(props);
  }
  renderHandles() {
    return (
      <div>
        <div data-dir="se" style={style.RegionHandleSE} />
        <div data-dir="sw" style={style.RegionHandleSW} />
        <div data-dir="nw" style={style.RegionHandleNW} />
        <div data-dir="ne" style={style.RegionHandleNE} />
      </div>
    );
  }

  getInitials(string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
  render() {
    const localStyle = {
      width: this.props.width + "%",
      height: this.props.height + "%",
      left: `${this.props.x}%`,
      top: `${this.props.y}%`,
      backgroundColor: "rgba(0,0,0,0.3)",
    };
    const dataRenderArgs = {
      data: this.props.data,
      isChanging: this.props.changing,
      index: this.props.index,
    };

    return (
      <>
        <div
          style={objectAssign(
            {},
            style.Region,
            localStyle,
            this.props.customStyle,
            this.props.data.regionStyle
          )}
          onClick={this.props.onClick}
          className={this.props.highlight ? "highlighted" : ""}
          onMouseDown={this.props.onCropStart}
          onTouchStart={this.props.onCropStart}
          data-wrapper="wrapper"
          title={`Placeholder`}
        >
          {this.props.data.label.length > 0 ? (
            <div
              style={{
                fontSize: "12px",
                pointerEvents: "none",
                textTransform: "Uppercase",
              }}
            >
              <b> {this.props.data.label}</b>
              <br />
              <span style={{ fontSize: "10px" }}>
                {this.props.data.text}
              </span>{" "}
            </div>
          ) : null}

          {this.props.dataRenderer
            ? this.props.dataRenderer(dataRenderArgs)
            : null}
        </div>
      </>
    );
  }
}
Region.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onCropStart: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  handles: PropTypes.bool,
  changing: PropTypes.bool,
  dataRenderer: PropTypes.func,
  data: PropTypes.object,
  customStyle: PropTypes.object,
};

export default Region;
