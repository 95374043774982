
import React, { useEffect,useState } from "react";

import { Wrapper } from "./style";

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Button from "@mui/material/Button";

import moment from "moment-timezone";
import PreviewIcon from "@mui/icons-material/Preview";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Download from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
export function GridDocPlace(props) {

  const selectOption = (op, id, row) => {
    optionSelect({ action: op, id: id, row: row });
  };

  const optionSelect = (event) => {
    props.option(event);
  };

 
  function parseStringToJson(inputString) {
    try {
      const jsonObject = JSON.parse(inputString);
      return jsonObject;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }
  
  const showType = (value) => {
    switch (value) {
      case 3:
        return "Date";
      case 4:
        return "Text";
      case 5:
        return "Number";
      case 6:
        return "List";
      case 7:
        return "Checkbox";
      case 8:
        return "Radio";
      default:
        return "Text";
    }
  };
  const showData = (value) => {
    let data = parseStringToJson(value);
    let html = "<table>";
    (data.form[0].fields).map((item) => {
      if (item.fieldType > 2)
      html += `<tr style='text-align:left;'><td><b>${item.fieldTitle} (${showType(item.fieldType)}):</b></td><td> ${item.fieldValue}</td></tr>`;
    });
    html += "</table>";
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Form values",
      html: html,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: "Close",
    });
  };

  const download = (value) => {
    
    const downloadURL = (value.pdf);

    // Crea un elemento 'a' temporal
    const link = document.createElement('a');
    link.href = downloadURL;

    // Le da un nombre al archivo descargado
    link.download = 'downloaded-form.pdf'; // Puedes cambiar el nombre del archivo aquí

    // Agrega el link al body y hace click en él (esto inicia la descarga)
    document.body.appendChild(link);
    link.click();

    // Limpia eliminando el link del body
    document.body.removeChild(link);
  }
  function getMimeType(url) {
    // Extract the file extension from the URL
    const ext = url.split(".").pop();

    // Map file extensions to MIME types
    const mimeTypes = {
      // Image
      bmp: "image/bmp",
      gif: "image/gif",
      jpeg: "image/jpeg",
      jpg: "image/jpeg",
      png: "image/png",
      svg: "image/svg+xml",
      tif: "image/tiff",
      tiff: "image/tiff",
      webp: "image/webp",

      // Document
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      odp: "application/vnd.oasis.opendocument.presentation",
      ods: "application/vnd.oasis.opendocument.spreadsheet",
      odt: "application/vnd.oasis.opendocument.text",
      pdf: "application/pdf",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      rtf: "application/rtf",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      zip: "application/zip",
    };

    // Return the corresponding MIME type or an empty string if the extension is not recognized
    return mimeTypes[ext.toLowerCase()] || "";
  }
  const viewFile = async (url) => {
    // Reverse proxy, the best option is to correctly set the CORS rules in AZURE.
    fetch( url, {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }) // FETCH BLOB FROM IT
      .then((response) => response.blob())
      .then((blobu) => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        var blob = new Blob([blobu], { type: getMimeType(url) }); //this makes the magic
        var blobURL = URL.createObjectURL(blob);

        var iframe = document.createElement("iframe"); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = "none";
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
      })
      .catch((err) => {
        alert("There was an error printing the file " + err);
        console.error(`There was an error reading the file ` + err);
      });
  }; 
  function printDocumentFromURL(page) {
    let fileToPrint = null;
      fileToPrint = page.pdf;
      //"Print the page"
    try {
      var URL = fileToPrint;
      console.log(URL)
      viewFile(URL);
    } catch (error) {
      alert("There was an error printing the file " + error);
      console.error(`There was an error printing the file ` + error);
      
    }
  }


const headCols = [
  {
    field: 'email',
    flex: 1,
    headerClassName: "MuiTableHead-root",
    headerName: 'Email'
  },
  {
    field: 'templateName',
    flex: 1,
    headerClassName: "MuiTableHead-root",
    headerName: 'Document'
  },
  {
    field: 'CreatedAt',
    flex: 1,
    headerClassName: "MuiTableHead-root",
    headerName: 'Date',
    type: "date",
    valueFormatter: (params) => {
      return `${moment(params.value).format("MM/DD/YYYY")}`;
    },
  },  {
    field: "contentForm",
    headerName: "Form values",
    sortable: false,
    headerClassName: "MuiTableHead-root",
    flex: 1,
    renderCell: (params) => (
      <>
        <Button
          title="View document"
          onClick={() => {showData(params.value)}}
        >
          <PreviewIcon
            fontSize="medium"
            style={{ width: "28px", height: "28px", marginRight: "10px" }}
          />
          <span style={{ marginLeft: "10px" }}>View</span>
        </Button>
      </>
    ),
  }, {
    field: "pathUrl",
    headerName: "Document",
    sortable: false,
    headerClassName: "MuiTableHead-root",
    flex: 1,
    renderCell: (params) => (
      <>
        <Button
          title="Download document"
          onClick={() => {download(params.value)}}
        >
          <DownloadIcon
            fontSize="medium"
            style={{ width: "28px", height: "28px", marginRight: "10px" }}
          />
          <span style={{ marginLeft: "10px" }}>Download</span>
        </Button>
        <Button
          title="Print document"
          onClick={() => {printDocumentFromURL(params.value)}}
        >
          <PrintIcon
            fontSize="medium"
            style={{ width: "28px", height: "28px", marginRight: "10px" }}
          />
          <span style={{ marginLeft: "10px" }}>Print</span>
        </Button>
      </>
    ),
  },
]
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <Wrapper>
      <DataGrid

      treeData
        rows={props.data}
        columns={headCols}
       
  getTreeDataPath={(row) => row.data}

        pageSize={10}
        rowsPerPageOptions={[5]}
        sortingOrder={["asc", "desc"]}
        getRowClassName={() => `MuiTableCell-row`}
        components={{
          Toolbar: CustomToolbar,
      }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
    </Wrapper>
  );
}

export default GridDocPlace;
