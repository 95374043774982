import {
  del,
  del_,
  dpost,
  get,
  getDoc,
  post,
  post_,
  postDownload,
  put,
} from "./apis";
import * as url from "./url";

const postLogin = (data) => post(url.POST_LOGIN, data);
//forget password
const postForgetPassword = (data) => post(url.FORGET_PASSWORD, data);
//resetPassword
const postResetPassword = (data) => post(url.RESET_PASSWORD, data);

const postHashValidate = (data) => post(url.HASH_VALIDATE, data);

const getCustomer = () => get(url.GET_CUSTOMER);
const getBillingCustomersNotFull = () =>
  get(url.GET_BILLING_CUSTOMERS_NOT_FULL);
const getUser = () => get(url.GET_USER);
const postUser = (data) => post(url.GET_USER2, data);
const putUser = (data, id) => put(url.GET_USER2 + "/Update/" + id, data);
const deleteUser = (id) => del(url.GET_USER2 + "/" + id);
const getRole = () => get(url.GET_ROLE);
const changePassword = (data) => post(url.CHANGE_PASSWORD, data);

const postUserAsgRolAPI = (data) => post(url.USER_ROL, data); //systemUserRoles
const putUserAsgRolAPI = (data) => put(url.USER_ROL, data);
const getUserArchiveRolesAPI = (userID) =>
  get(url.USER_ROL + "/AllDetail?SystemUserId=" + userID); //DOC-1778 get all user roles

const getRol = () => get(url.ROLS + "/all");
const postRol = (data) => post(url.ROLS, data);
const putRol = (data, id) => put(url.ROLS + "/Update/" + id, data);
const deleteRol = (id) => del(url.ROLS + "/" + id);

//const getInputMethod = () => get(url.GET_INPUT_METHOD);
const getInputMethod = (data) => post(url.GET_INPUT_METHOD, data);
const postInputMethodAPI = (data) => post(url.POST_INPUT_METHOD, data);
const deleteInputMethodAPI = (id) => del(url.POST_INPUT_METHOD + "/" + id);
const putInputMethodAPI = (id, data) =>
  put(url.POST_INPUT_METHOD + "/" + id, data);

const getDocType = (from, to, period) =>
  get(
    url.GET_DOCTYPE +
      (from ? "?from=" + from + "&to=" + to + "" : "?period=" + period + "")
  );
const getDocType1 = (
  page,
  pageSize,
  sortBy,
  sortOrder,
  filtered,
  masterTemplate
) =>
  get(
    `${url.GET_DOCTYPE1}?page=${page}&pageSize=${pageSize}&SortBy=${sortBy}&SortOrder=${sortOrder}&filtered=${filtered}` +
      (masterTemplate ? `&masterTemplate=1` : "")
  );

const postDocumentTypeHeader = (data) => post(url.POST_DOCTYPE_HEADER, data);
const deleteDocumentTypeAPI = (id) => del(url.DELETE_DOCTYPE_HEADER + "/" + id);

const updateDocumentTypeAPI = (id, data) =>
  put(url.UPDATE_DOCTYPE_HEADER + "/" + id, data.data);

const getDocTypeAttributeAPI = (id) =>
  get(url.DELETE_DOCTYPE_HEADER + "/Attributes/" + id);

const postNewFolderAPI = (data) => post(url.POST_NEW_FOLDER, data);
const postDeleteDocAPI = (data) => del(url.POST_DELETE_DOC, data);
const postRenameDocAPI = (data, id) =>
  put(url.POST_RENAME_DOC + "/" + id, data);
const postEmptyTrashbin = (data) => del(url.POST_EMPTY_TRASH_BIN, data); //DOC-1441

const postReprocessDocAPI = (data) => post(url.POST_REPROCESS_DOC, data);

const postRecoverDocAPI = (data) => dpost(url.POST_RECOVER_DOC, data);

const postMoveDocAPI = (data) => put(url.POST_MOVE_DOC, data);

const postSharedDocAPI = (data, id) =>
  post(url.POST_SHARED_DOC + "/" + id, data);
const putAttributeDocAPI = (data, id) =>
  put(url.PUT_ATTRIBUTE_DOC + "/" + id, data);
const postCommentDocAPI = (data, id) =>
  post(url.POST_COMMENT_DOC + "/" + id, data);

const postDownloadDocAPI = (archiveId, fileName, type) =>
  getDoc(url.POST_DOWNLOAD_DOC + "/" + archiveId + "/Download" + "");

const postDownloadFolderZipAPI = (name, data) =>
  postDownload(`${url.POST_DOWNLOAD_DOC_Zip}?optionalName=${name}`, data);

const getReviewal = (from, to, period) =>
  get(
    url.GET_REVIEWAL +
      (from ? "?from=" + from + "&to=" + to + "" : "?period=" + period + "")
  );

const getDocPercentage = (from, to, period) =>
  get(
    url.GET_DOC_PERCENTAGE +
      (from ? "?from=" + from + "&to=" + to + "" : "?period=" + period + "")
  );
const getSearchDocumentAPI = (page, pageSize, period, data) =>
  post(
    url.SEARCH_DOCUMENT_DOC +
      "?page=" +
      page +
      "&pageSize=" +
      pageSize +
      "&period=" +
      period +
      "",
    data
  );

const getSearchFormAPI = (page, pageSize, search) =>
  post(
    url.SEARCH_FORM_DOC + "Search?page=" + page + "&pageSize=" + pageSize + "",
    search
  );

const getDocShared = (from, to, period) =>
  get(
    url.GET_DOC_SHARED +
      (from ? "?from=" + from + "&to=" + to + "" : "?period=" + period + "")
  );

const getDocPD = (from, to, period) =>
  get(
    url.GET_DOC_PD +
      (from ? "?from=" + from + "&to=" + to + "" : "?period=" + period + "")
  );

const getTree = (parentArchiveId, direction, hideReservedFolder) =>
  get(
    url.GET_TREE +
      ("?hideReservedFolder=" +
        (hideReservedFolder ? hideReservedFolder : false)) +
      ("&parentArchiveId=" +
        (parentArchiveId ? parentArchiveId : "") +
        "&direction=" +
        direction +
        "") //DOC-1721 fix to not break the url when parenArchiveID is root or undefined
  );

const getDetailFolder = (parentArchiveId, page, pageSize) =>
  get(
    url.GET_DETAIL_FOLDER +
      (parentArchiveId
        ? "?parentFolderId=" +
          parentArchiveId +
          "&page=" +
          page +
          "&pageSize=" +
          pageSize +
          ""
        : "?page=" + page + "&pageSize=" + pageSize + "")
  );

const postSearchArchives = (parentArchiveId, page, pageSize, obTerm) =>
  post(
    url.GET_SEARCH_ARCHIVES +
      ("?parentFolderId=" +
        parentArchiveId +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize +
        ""),
    obTerm
  );

const postDocumentPages = (id, page, size) =>
  post(
    url.GET_DOCUMENT_PAGES + "/" + id + "?page=" + page + "&pageSize=" + size
  );

const postDocumentType = (data) => post(url.POST_DOCUMENT_TYPE, data);
const getDocumentTypeTemplate = () => get(url.POST_DOCUMENT_TYPE_TEMPLATE);
const getDocumentType = (page, pageSize) =>
  get(url.GET_DOCUMENT_TYPE + "?page=" + page + "&pageSize=" + pageSize + "");

const getDocumentPermissionsAPI = (id) =>
  get(url.GET_DOCUMENT_PERMISSIONS + "/" + id);

const getDocumentVersions = (id) => get(url.GET_DOCUMENT_VERSIONS + "/" + id);

const postRestoreDocumentVersion = (id) =>
  post(url.SET_DOCUMENT_VERSION + "/", id);

//create esignature user
const putEsignatureUser = (userId, data) =>
  put(url.REGISTER_USER_ESIGNATURE + "/" + userId, data);
/**END*/

//DocViewerListRequireSignature
const getListRequireSignatureDocumentAPI = (id) =>
  get(url.LIST_REQUIRE_SIGNATURE_DOCUMENT_API + id);
const getValidateUserSignatureAPI = () => get(url.VALIDATE_USER_SIGNATURE);
const getRequiredSignatureAPI = () => get(url.GET_REQUIRED_SIGNATURE_API);
const resendSignatureRequestEmailAPI = (data, documentId) =>
  post(url.RESEND_SIGNATURE_REQUEST_EMAIL, data, documentId);
const getESignatureRequestsAPI = () => get(url.E_SIGNATURE_REQUESTS);
//END

//AllFormsRequested
const getAllFormsRequestAPI = (data) =>
  post(url.GET_ALL_FORMS_REQUESTED_API, data);
//END AllFormsRequested

//_All_WorkFlow_//
const getAllDocumentAttachmentAPI = (id) => {
  return get(`${url.GET_ALL_DOCUMENT_ATTACHMENT_API}?documentId=${id}`);
};

const deleteAttachmentAPI = (id) => {
  return del_(`${url.DELETE_DOCUMENT_ATTACHMENT_API}/${id}`);
};

const getDocumentMetadataAPI = (id) => {
  return get(`${url.GET_DOCUMENT_METADATA}?documentId=${id}`);
};
const postDocumentMetadataAPI = (id, data) =>
  post_(`${url.POST_DOCUMENT_METADATA}?documentId=${id}`, data);

const deleteMetadataAPI = (id) => {
  return del_(`${url.DELETE_METADATA_API}/${id}`);
};

const getAllRequiredWorkFlowProcessAPI = () => {
  return get(url.GET_REQUIRED_WORK_FLOW_PROCESS);
};

export {
  //Auth
  postInputMethodAPI,
  postLogin,
  getUser,
  getCustomer,
  getBillingCustomersNotFull,
  getDocType,
  getDocType1,
  postDocumentTypeHeader,
  getReviewal,
  getDocPercentage,
  getDocShared,
  getDocPD,
  postDocumentPages,
  getTree,
  getInputMethod,
  getDetailFolder,
  postSearchArchives,
  postNewFolderAPI,
  postDeleteDocAPI,
  getSearchDocumentAPI,
  getSearchFormAPI,
  postRenameDocAPI,
  postReprocessDocAPI,
  postRecoverDocAPI,
  postMoveDocAPI,
  postSharedDocAPI,
  putAttributeDocAPI,
  postCommentDocAPI,
  postDownloadDocAPI,
  postDownloadFolderZipAPI,
  putInputMethodAPI,
  //DocType
  postDocumentType,
  getDocumentTypeTemplate,
  getDocumentType,
  deleteDocumentTypeAPI,
  updateDocumentTypeAPI,
  getDocTypeAttributeAPI,
  getDocumentPermissionsAPI,
  //ROLS
  getRol,
  postRol,
  putRol,
  deleteRol,
  //USER
  postUser,
  putUser,
  deleteUser,
  getRole,
  changePassword,
  getDoc,
  deleteInputMethodAPI,
  postUserAsgRolAPI,
  putUserAsgRolAPI,
  getUserArchiveRolesAPI, //DOC-1778
  //forget password
  postForgetPassword,
  //reset password
  postResetPassword,
  postHashValidate,
  postEmptyTrashbin,
  /**create esignature data */
  putEsignatureUser,
  // Require Signature list
  getListRequireSignatureDocumentAPI,
  getValidateUserSignatureAPI,
  getRequiredSignatureAPI,
  getESignatureRequestsAPI,
  // Resend email request Signature
  resendSignatureRequestEmailAPI,
  //AllFormsRequested
  getAllFormsRequestAPI,
  getDocumentVersions,
  postRestoreDocumentVersion,
  //AllWorkFlow
  getAllDocumentAttachmentAPI,
  getAllRequiredWorkFlowProcessAPI,
  getDocumentMetadataAPI,
  postDocumentMetadataAPI,
  deleteAttachmentAPI,
  deleteMetadataAPI,
};
