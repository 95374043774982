import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import View from "./view";
import LoadingComponent from '../../../../components/loading'
import {
  getListRequireSignatureDocument,
  listRequireSignatureDocumentClearData,
  resendSignatureRequest, resendSignatureRequestClearData
} from "../../../../store/document/actions";
import { toast } from "react-toastify";

export default function SignatureDrawerList(props) {
  const notify = (msj, event) => toast[event](msj);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasSignatures, setHasSignatures] = useState([]);
  const {
    responseListRequireSignatureDocument,
    loadingListRequireSignatureDocument,
  } = useSelector((state) => ({
    errorListRequireSignatureDocument:
      state.DetailFolder.errorListRequireSignatureDocument,
    responseListRequireSignatureDocument:
      state.DetailFolder.responseListRequireSignatureDocument,
    loadingListRequireSignatureDocument:
      state.DetailFolder.loadingListRequireSignatureDocument,
  }));

  const { responseResendSignatureRequest, loadingResendSignatureRequest } =
    useSelector((state) => ({
      responseResendSignatureRequest:
        state.DetailFolder.responseResendSignatureRequest,
      loadingResendSignatureRequest:
        state.DetailFolder.loadingResendSignatureRequest,
    }));

  const placeSignature = () => {
    navigate("/placeSignature/" + props?.data);
    return false;
  };
  
  const resendRequest = (email) => {
    setLoading(true);
    let obj = {obj: {email: email}, documentId: props?.data};
    dispatch(resendSignatureRequest(obj, props?.data));
  };
  
  //confirm if there is a data
  let Obj;
  if (hasSignatures.data && hasSignatures.data.length > 0) {
    Obj = {
      signers: hasSignatures.data[0].signers,
    };
  } else {
    Obj = {};
  }

  useEffect(() => {
    if (
      responseListRequireSignatureDocument &&
      responseListRequireSignatureDocument
    ) {
      setHasSignatures(responseListRequireSignatureDocument);
      dispatch(listRequireSignatureDocumentClearData());
    }
  }, [responseListRequireSignatureDocument]);

  useEffect(() => {
    if (!responseListRequireSignatureDocument) {
      dispatch(getListRequireSignatureDocument(props?.data));
    }
  }, []);

  // Resend request signature email
  useEffect(() => {
    if (responseResendSignatureRequest) {
      setLoading(false);
      props.closeSignature();
      notify('electronic signature request forwarding', 'success');
      dispatch(resendSignatureRequestClearData());
    }
  }, [responseResendSignatureRequest]);

  return (
    <>
      <LoadingComponent show={loading} text={"Resend request signature..."} />
      <View
        hasSignatures={hasSignatures}
        onPlaceSignature={placeSignature}
        resendRequest={resendRequest}
        signatures={Obj}
        loading={loadingListRequireSignatureDocument}
      />
    </>
    
  );
}
