import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { colors } from "../../../theme";

export const useStyles = makeStyles((theme) => ({
  headerStyle: {
    backgroundColor: colors.white,
    fontSize: "15px",
  },
}));

export const WrapperParagraph = styled.div`
  width: 100%;
  border-radius: 4px;
  display: inline-block;
  background-color: white;
  overflow-y: hidden;
`;

export const ShowContent = styled.div`
  background-color: ${colors.white};
  border-radius: 6px;
  padding: 2px;
  overflow: hidden;
  line-height: 0.5;
`;

export const Container = styled.div`
  width: 100%;
  padding: 25px;
  background-color: ${colors.white};
  position: relative;
  height: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainerTools = styled.section`
  position: absolute;
  bottom: -20px;
  right: 0;
`;
