import React, { useEffect } from "react";
import { Wrapper } from './style';

import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Delete from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';


export function GridDocPlace(props) {



    const selectOption = (op, id, row) => {
        console.log(op, id, row)
        optionSelect({ action: op, id: id, row: row });
    }
    
    const optionSelect = (event) => {
        console.log(event)
        props.option(event);
    }
    
    const headCols = [
        {
            field: 'name',
            flex: 1,
            headerClassName: 'MuiTableHead-root',
            headerName: 'Name',
        },
        {
            field: 'description',
            flex: 1,
            headerClassName: 'MuiTableHead-root',
            headerName: 'Description',
        },
        {
            field: 'read',
            flex: 1,
            headerClassName: 'MuiTableHead-root',
            headerName: 'Read Permission',
            valueGetter: (params) => `${params.row.read === 0 ? 'Not Active' : 'Active'}`
        },
        {
            field: 'write',
            flex: 1,
            headerClassName: 'MuiTableHead-root',
            headerName: 'Write Permission',
            valueGetter: (params) => `${params.row.write === 0 ? 'Not Active' : 'Active'}`
        },{
            field: 'delete',
            flex: 1,
            headerClassName: 'MuiTableHead-root',
            headerName: 'Delete Permission',
            valueGetter: (params) => `${params.row.delete === 0 ? 'Not Active' : 'Active'}`
        },
        {
            field: 'options',
            headerName: 'Options',
            sortable: false,
            headerClassName: 'MuiTableHead-root',
            flex: 1,
            renderCell: (params) => (
                <>
                    <Button onClick={() => selectOption('edit', params.id, params)} >
                        <EditIcon />
                        Edit
                    </Button >
                    <Button onClick={() => selectOption('remove', params.id, params)} >
                        <Delete />
                        Remove
                    </Button >
                </>
            ),
        },
    ];
    return (
        <Wrapper>

            <DataGrid
                rows={props.data}
                columns={headCols}
                pageSize={10}
                rowsPerPageOptions={[5]}
                sortingOrder={['asc', 'desc']}
                getRowClassName={() => `MuiTableCell-row`}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                  }}
            />



        </Wrapper>
    )
}

export default GridDocPlace
