import React, {useState} from "react";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Button from '@material-ui/core/Button';
import {Wrapper,useStyles} from './style';
import './styles.css'



export function SearchShipDocPlace(props) {
    const classes = useStyles();
    const [searchField, setSearchField] = React.useState('');
    
    const searchItems = () => {
        props.callSearch(searchField);
    };

    return (
        <Wrapper>
            <article className='baseSearch search'>
                <FindInPageIcon className={classes.iconSearch}/>
                <input type="text"
                        onKeyUp={e => {
                            setSearchField(e.target.value);
                            searchItems()
                        }}
                        className="form-control border-0 shadow-none" placeholder="Search"/>
                <Button
                    onClick={searchItems.bind()}
                    variant="contained" className={classes.buttonSearch} size="small" color="primary">
                    Search
                </Button>
            </article>
        </Wrapper>
    )
}

export default SearchShipDocPlace
