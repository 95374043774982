import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import { Radio, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormControl from "@mui/material/FormControl";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  ButtonContainer,
  ButtonsContainer,
  CheckContainer,
  Container,
  ContainerTextField,
  ContainerTools,
  Title,
  useStyles,
  WrapperRadioButton,
} from "./style";
import { colors } from "../../../../components/theme";
import Checkbox from "@mui/material/Checkbox";

const RadioButtonFormView = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const getDefaultValue = () => {
    let result = props.options.find(
      (item) =>
        item.value === "true" ||
        item.value === "1" ||
        item.value === "yes" ||
        item.value === "on" ||
        item.value === "checked" ||
        item.value === "selected" ||
        item.value == "default"
    );
    return result?.label;
  };

  return (
    <WrapperRadioButton>
      <Container>
        <>
          <Title>{props.title}</Title>
          <ButtonContainer>
            {props.viewPdf ? (
              <Button
                variant="text"
                onClick={props.onChangeViewPdf}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notSeeIntoPdfRadioB && colors.gray3,
                }}
                startIcon={<VisibilityOffIcon />}
              >
                Hide in PDF
              </Button>
            ) : (
              <Button
                variant="text"
                onClick={props.onChangeViewPdf}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notSeeIntoPdfRadioB && colors.gray3,
                }}
                startIcon={<VisibilityIcon />}
              >
                Show in PDF
              </Button>
            )}
          </ButtonContainer>
          <ContainerTextField>
            <TextField
              id="helpTextTitle"
              placeholder="Click to Enter Title"
              fullWidth={true}
              variant="standard"
              value={props._title}
              onChange={props.onChangeTitle}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
            />
          </ContainerTextField>
          <FormControl>
            <RadioGroup
              row={props.row}
              value={value}
              onChange={handleChange}
              name={props.name}
              defaultValue={getDefaultValue()}
            >
              {props.options.length > 0 &&
                props.options.map((value) => (
                  <FormControlLabel
                    key={value.id}
                    value={value.value}
                    control={<Radio size={props.size} />}
                    label={value.label}
                  />
                ))}
            </RadioGroup>
          </FormControl>
          <ContainerTextField>
            <TextField
              id="helpTestDescription"
              placeholder="Click to Enter Description"
              fullWidth={true}
              variant="standard"
              value={props._description}
              onChange={props.onChangeDescription}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "12px",
                  fontWeight: "lighter",
                },
              }}
            />
          </ContainerTextField>
          <ContainerTools>
            <CheckContainer>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={props.isChecked}
                    onChange={props.changeChecked}
                  />
                }
                label="Mandatory."
              />
            </CheckContainer>
            <ButtonsContainer>
              <Button
                onClick={props.onChangeSettings}
                style={{
                  textTransform: "none",
                  marginRight: "20px",
                }}
                variant={"text"}
                startIcon={<SettingsSuggestIcon />}
              >
                Settings
              </Button>
              <Button
                onClick={props.onChangeDelete}
                style={{
                  textTransform: "none",
                }}
                color="error"
                variant={"text"}
                startIcon={<DeleteForeverIcon />}
              >
                Delete
              </Button>
            </ButtonsContainer>
          </ContainerTools>
        </>
      </Container>
    </WrapperRadioButton>
  );
};

export default RadioButtonFormView;
