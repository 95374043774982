import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import { Container } from "reactstrap";
import SearchDocPlace from "../../components/search/search";
import { toast, ToastContainer } from "react-toastify";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import Grid from "@mui/material/Grid";
//API CALL
import { getUserList } from "../../store/user/actions";
import { getSearchDocument } from "../../store/search/actions";
//END
import TablePagination from "@mui/material/TablePagination";
import "./explorer-style.scss";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../components/title/title";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/loading";
import {
  File,
  TitleDocument,
  useStyles,
  WPFilter,
  WPFilterContainer,
  WPFilterSpan,
  WrapperFilter,
} from "./style";
import { WrapperFiles } from "../Explorer/components/files/style";
import CloseIcon from "@mui/icons-material/Close";
import iconFolder from "../../assets/196-folder-8.png";
import filePdf from "../../assets/file-pdf.png";
import { getDocumentPermissions } from "../../store/document/actions";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Drawer from "@material-ui/core/Drawer";
import OptionMenuDocPlace from "../Explorer/components/files/optionsMenu";
import { MoveDocPlace } from "./components/files/move";
import { ShareDocPlace } from "./components/files/share";
import { RenameDocPlace } from "./components/files/raname";
import { ReprocessDocPlace } from "./components/files/reprocess";
import { confirmAlert } from "react-confirm-alert";
import TextField from "@material-ui/core/TextField";
import { postDeleteDoc, postDocDownload } from "../../store/explorer/actions";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import NewFilterDocPlace from "./components/filter/filter";
import fileWord from "../../assets/file-word.png";
import fileImage from "../../assets/file-jpg.png";
import fileExcel from "../../assets/file-excel.png";
import fileZip from "../../assets/file-zip.png";
import fileDoc from "../../assets/file-doc.png";
import fileOther from "../../assets/file-other.png";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

let deleteWord = "";
let isDoubleClick = false;

let filterListC = {
  filters: [],
  fullTextSearch: {
    text: "",
    fields: [],
  },
  fromDate: "",
  toDate: "",
  daysAgo: 0,
  minsAgo: 0,
};

const SearchPageDocPlace = (props) => {
  const notify = (msj, event) => toast[event](msj);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startPage] = useState(true);

  const [page, setPage] = useState(1);
  const [mpage, setMPage] = useState(0);

  const [pageSize, setPageSize] = useState(50);
  const [period, setPeriod] = useState("");
  const [valueSearch, setValueSearch] = useState("");
  const [item, setItem] = React.useState({});
  const [showOption, setShowOption] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [showMove, setShowMove] = React.useState(false);
  const [showShare, setShowShare] = React.useState(false);
  const [showRename, setShowRename] = React.useState(false);
  const [doctypeText, setDoctypeText] = useState([]);
  const [listDocuments, setListDocuments] = useState([]);
  const [listDocumentsTotal, setListDocumentsTotal] = useState([]);
  const [permissions, setPermissions] = React.useState(false);
  const [showReprocess, setShowReprocess] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const [appliedFilters, setAppliedFilters] = React.useState({});
  const [filterList, setFilterList] = React.useState({
    filters: [],
    fullTextSearch: {
      text: "",
      fields: [],
    },
    fromDate: "",
    toDate: "",
    daysAgo: 0,
    minsAgo: 0,
  });

  const { responseSearchDocument, errorSearchDocument, loading } = useSelector(
    (state) => ({
      responseSearchDocument: state.Search.responseSearchDocument,
      errorSearchDocument: state.Search.errorSearchDocument,
      loading: state.Search.loadingSearchDocument,
    })
  );
  const { responseDeleteDoc, errorDeleteDoc, loading6 } = useSelector(
    (state) => ({
      responseDeleteDoc: state.Explorer.responseDeleteDoc,
      errorDeleteDoc: state.Explorer.errorDeleteDoc,
      loading6: state.Explorer.loadingDeleteDoc,
    })
  );
  const { responseDocumentPermissions, loadingDocumentPermissions } =
    useSelector((state) => ({
      responseDocumentPermissions:
        state.DetailFolder.responseDocumentPermissions,
      loadingDocumentPermissions: state.DetailFolder.loadingDocumentPermissions,
    }));
  const { responseDocDownload, loadingDocDownload } = useSelector((state) => ({
    responseDocDownload: state.Explorer.responseDocDownload,
    loadingDocDownload: state.Explorer.loadingDocDownload,
  }));
  const today = new Date();

  const fromDate = new Date(today.getFullYear(), today.getMonth(), 1);

  const toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const formattedFromDate = formatDate(fromDate);
  const formattedToDate = formatDate(toDate);

  const newFilter = {
    filter: {
      docTypeId: "",
      criteria: [
        {
          field: "",
          operator: "",
          value: "",
        },
      ],
      docTypeName: "",
    },
    fromDate: formattedFromDate,
    toDate: formattedToDate,
  };

  useEffect(() => {
    addNewFilter(newFilter);
  }, []);

  //end DOC-1520
  useEffect(() => {
    if (responseDocumentPermissions) {
      setPermissions(responseDocumentPermissions);
    }
  }, [responseDocumentPermissions]);

  useEffect(() => {
    if (responseDocDownload) {
      const blob = new Blob([responseDocDownload], {
        type: "application/octetstream",
      });
      const link = document.createElement("a");
      // create a blobURI pointing to our Blob
      const lastIndex = item?.fkArchive?.name?.lastIndexOf(".");
      const nameSplit = item?.fkArchive
        ? item?.fkArchive?.name?.slice(0, lastIndex)
        : "";
      link.href = URL.createObjectURL(blob);
      link.download = item.fkArchive
        ? item.fkArchive?.originalExtension
          ? nameSplit + item.fkArchive.originalExtension
          : item.fkArchive?.name
        : "";
      // some browser needs the anchor to be in the doc
      if (link.download) {
        document.body.append(link);
        link.click();
      }
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
  }, [responseDocDownload]);

  useEffect(() => {
    if (responseDeleteDoc !== null) {
      getSearchFile();
    }
  }, [responseDeleteDoc]);

  useEffect(() => {
    dispatch(getUserList({}, navigate));
    filterList.filters = [];
  }, [startPage]);

  useEffect(() => {
    if (responseSearchDocument?.data) {
      setListDocuments(responseSearchDocument.data);
      setListDocumentsTotal(responseSearchDocument.header.totalRecords);
    }
  }, [responseSearchDocument]);

  const getSearchFile = () => {
    if (valueSearch || filterList.filters.length > 0) {
      let obj = {
        filters: filterList.filters,
        fullTextSearch: {
          text: valueSearch.toLowerCase(),
          fields: [],
        },
        daysAgo: 0,
        minsAgo: 0,
      };

      dispatch(
        getSearchDocument(
          {
            page: page,
            pageSize: pageSize,
            period: period,
            data: obj,
          },
          navigate
        )
      );
    } else {
      notify("Enter a value", "error");
    }
  };

  const getSearchResetFile = () => {
    window.location.reload(true);
  };

  const setSearchFile = (filterSearch) => {
    setValueSearch(filterSearch.trim());
  };

  const clickItem = (item) => {
    dispatch(getDocumentPermissions(item.fkArchive.id, navigate));
    setTimeout(() => {
      setShowOption(true);
    }, 200);
    setItem(item);
  };

  const doubleClickItemC = (item) => {
    setTimeout(() => {
      //setShowOption(false);
    }, 200);
    // optionMenu('open');
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowOption(open);
  };

  const callAddComment = (comment) => {
    props.addComment({ comment: comment, item: item });
  };

  const callUpdateContent = (content) => {
    props.updateFile({ updateFile: content, item: item });
  };

  const optionMenu = (opt) => {
    let type = item.folder ? "Folder" : item.fkArchive.type;
    switch (opt) {
      case "open": {
        navigate("/docViewer/" + item.id);
        setShowOption(false);
        return null;
      }
      case "download": {
        dispatch(
          postDocDownload(
            {
              archiveId: item.fkArchive.id,
              fileName: item.fkArchive.name,
              type: "PDF",
            },
            navigate
          )
        );
        setShowOption(false);
        return null;
      }
      case "move": {
        setShowMove(true);
        setShowOption(false);
        return null;
      }
      case "rename": {
        setShowRename(true);
        setShowOption(false);
        return null;
      }
      case "reprocess": {
        setShowReprocess(true);
        setShowOption(false);
        return null;
      }
      case "delete": {
        deleteItem();
        setShowOption(false);
        return null;
      }
      case "share": {
        setShowShare(true);
        setShowOption(false);
        return null;
      }
      case "fileLocation": {
        if (item.fkArchive.parentArchiveId) {
          navigate("/explorer/" + item?.fkArchive?.id);
        } else {
          navigate("/explorer");
        }
        setShowOption(false);
        return null;
      }
      default: {
        return null;
      }
    }
  };

  const deleteItem = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="deleteConfirm custom-ui">
            <h1 style={{ margin: "-43px 0px 0px 0px" }}>Are you sure?</h1>
            <p>
              If you want to permanently delete the content, write the following
              text "DELETE" and press the delete button
            </p>

            <TextField
              style={{
                width: "100%",
                backgroundColor: "#fff",
                marginBottom: "26px",
              }}
              id="delete"
              variant="outlined"
              onChange={(e) => (deleteWord = e.target.value)}
              label="Write DELETE"
            />

            <Button className={classes.btnCancel} autoFocus onClick={onClose}>
              NO
            </Button>

            <Button
              className={classes.btnDelete}
              autoFocus
              onClick={() => {
                if (deleteWord === "DELETE") {
                  callDelete();
                  onClose();
                } else {
                  toast.error("Wrong Key!");
                }
              }}
            >
              Yes, Delete it!
            </Button>
          </div>
        );
      },
    });
  };

  const callDelete = () => {
    let id = [];
    id.push(item.folder ? item.id : item.fkArchive.id);
    dispatch(postDeleteDoc(id, navigate));
  };

  const callShare = (shareFiles) => {
    setShowShare(false);
  };

  const closeModal = () => {
    setShowMove(false);
    setShowShare(false);
    setShowFilter(false);
    setShowRename(false);
    setShowReprocess(false);
  };

  const callRename = (renameFiles) => {
    getSearchFile();
    setShowRename(false);
  };

  const callMove = (moveFile) => {
    setShowMove(false);
  };

  const callReprocess = () => {
    refresh("");
    //setPage(1);
  };

  const refresh = (item) => {};

  const addNewFilter = (fl) => {
    if (fl.length > 0) {
    }
    filterList.fullTextSearch = {
      text: valueSearch,
      fields: [],
    };

    filterList.filters.push(fl.filter);
    let newFilterList = filterList;
    newFilterList.fromDate = fl.fromDate;
    newFilterList.toDate = fl.toDate;

    dispatch(
      getSearchDocument(
        {
          page: 1,
          pageSize: pageSize,
          period: period,
          data: newFilterList,
        },
        navigate
      )
    );
    filterListC = newFilterList;
  };

  const removeFilter = (id) => {
    filterList.fullTextSearch = {
      text: valueSearch,
      fields: [],
    };

    filterList.filters.splice(id, 1);
    if (filterList.filters.length > 0 || filterList.fullTextSearch.text) {
      dispatch(
        getSearchDocument(
          {
            page: 1,
            pageSize: pageSize,
            period: period,
            data: filterList,
          },
          navigate
        )
      );
    } else {
      addNewFilter(newFilter);
      setListDocuments([]);
      setListDocumentsTotal([]);
    }
  };

  const removeFilterDate = () => {
    if (filterListC.filters.length > 0) {
      filterListC.fromDate = "";
      filterListC.toDate = "";
    }

    if (filterList.filters.length > 0) {
      filterList.fromDate = undefined;
      filterList.toDate = undefined;
    }

    dispatch(
      getSearchDocument(
        {
          page: 1,
          pageSize: pageSize,
          period: period,
          data: filterList,
        },
        navigate
      )
    );
  };

  const handleChangePage = (event, newPage, mrowsPerPage = 50) => {
    //let newPage = props.page;
    //newPage = objs.length <= 1 ? 1 : newPage + 1;
    console.log(event, mrowsPerPage, newPage);
    if (mrowsPerPage == 1) {
      mrowsPerPage = 50;
    }

    setMPage(newPage);

    let obj = {
      filters: filterList.filters,
      fullTextSearch: {
        text: valueSearch.toLowerCase(),
        fields: [],
      },
      daysAgo: 0,
      minsAgo: 0,
    };

    dispatch(
      getSearchDocument(
        {
          page: newPage + 1,
          pageSize: mrowsPerPage,
          period: period,
          data: obj,
        },
        navigate
      )
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleChangePage(null, 0, parseInt(event.target.value, 10));

    setPage(0);
  };

  const setFileType = (file) => {
    switch (file.fkArchive?.originalExtension?.toString().toLowerCase()) {
      case ".pdf":
        return filePdf;
      case ".doc":
      case ".docx":
        return fileWord;
      case ".jpg":
      case ".png":
      case ".jpeg":
        return fileImage;
      case ".xls":
      case ".csv":
      case ".xlsx":
        return fileExcel;
      case ".zip":
        return fileZip;
      case ".txt":
        return fileDoc;
      default:
        return fileOther;
    }
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>DocPlace Search </title>
      </MetaTags>
      <Container fluid>
        <Title title={"Search"} viewActive={false} />
        <ToastContainer autoClose={3000} />
        <LoadingComponent
          show={loading || loadingDocDownload}
          text={"Getting Documents Data..."}
        />
        <SearchDocPlace
          original={true}
          callSearch={setSearchFile.bind(this)}
          clear={() => {
            refresh("");
          }}
        />
        <Button
          onClick={getSearchFile.bind(this)}
          className={classes.btnSave}
          startIcon={<ContentPasteSearchIcon />}
          variant="contained"
          color="success"
        >
          SEARCH
        </Button>
        <Button
          onClick={getSearchResetFile.bind(this)}
          className={classes.btnReset}
          startIcon={<ContentPasteOffIcon />}
          variant="contained"
          color="success"
        >
          RESET SEARCH
        </Button>
        <Button
          onClick={(e) => {
            setShowFilter(true);
          }}
          className={classes.btnFilter}
          variant="outlined"
          startIcon={<FilterAltIcon />}
        >
          Filter
        </Button>
        <WrapperFilter>Filters:</WrapperFilter>
        <WPFilterContainer>
          {filterListC?.filters?.map((fl, ix) => {
            // Check if fl.docTypeId has data
            const hasDocTypeId = !!fl.docTypeId;

            // Check if any of the criteria have data
            const hasCriteriaData = fl.criteria?.some(
              (ct) => ct.field && ct.operator && ct.value
            );

            // If neither docTypeId nor criteria data is present, skip rendering
            if (!hasDocTypeId && !hasCriteriaData) {
              return null;
            }

            return (
              <WPFilter key={ix}>
                <CloseIcon
                  onClick={removeFilter.bind(this, ix)}
                  className={classes.iconCloseFilter}
                />
                {fl?.criteria?.map((ct, idx) => (
                  <React.Fragment key={idx}>
                    {ct.field && ct.operator && ct.value && (
                      <WPFilterSpan>
                        {ct.field}&nbsp;&nbsp;{ct.operator}&nbsp;&nbsp;
                        <strong>{ct.value}</strong>{" "}
                      </WPFilterSpan>
                    )}
                  </React.Fragment>
                ))}
                {fl.docTypeId && (
                  <WPFilterSpan>Doctype: {fl.docTypeName}</WPFilterSpan>
                )}
                {fl.fromDate && (
                  <WPFilterSpan>From: {fl.fromDate}</WPFilterSpan>
                )}
                {fl.toDate && <WPFilterSpan>To: {fl.toDate}</WPFilterSpan>}
              </WPFilter>
            );
          })}
          {filterListC?.fromDate && filterListC?.toDate && (
            <WPFilter key={"rangeDate"}>
              <CloseIcon
                onClick={removeFilterDate.bind()}
                className={classes.iconCloseFilter}
              />
              <WPFilterSpan>From: {filterListC?.fromDate}</WPFilterSpan>
              <WPFilterSpan>To: {filterListC?.toDate}</WPFilterSpan>
            </WPFilter>
          )}
        </WPFilterContainer>
        {listDocuments ? (
          listDocuments.length > 0 && (
            <div className="docFound">
              {" "}
              Documents found: {listDocumentsTotal
                ? listDocumentsTotal
                : 0}{" "}
            </div>
          )
        ) : (
          <div className="docFound"> 0</div>
        )}
        {listDocumentsTotal > 0 && (
          <TablePagination
            component="div"
            count={listDocumentsTotal ? listDocumentsTotal : 0}
            page={mpage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <WrapperFiles id="scrollableDiv">
              <div className={classes.float_center}>
                {listDocuments &&
                  listDocuments?.map((item, idx) => {
                    let type = item.folder
                      ? "Folder"
                      : item.fkArchive
                      ? item.fkArchive.type
                      : "Folder";

                    if (type === "Folder") {
                      return (
                        <File
                          onClick={clickItem.bind(this, item)}
                          onDoubleClick={doubleClickItemC.bind()}
                          key={idx}
                        >
                          <img
                            src={iconFolder}
                            className={classes.imgFolder}
                            alt=""
                          />
                          <TitleDocument classes={classes.titleShow}>
                            {item.fkArchive.name}
                          </TitleDocument>
                        </File>
                      );
                    } else {
                      return null;
                    }
                  })}
                {listDocuments &&
                  listDocuments?.map((item, idx2) => {
                    let type = item.folder
                      ? "Folder"
                      : item.fkArchive
                      ? item.fkArchive.type
                      : "Folder";

                    if (
                      !item.folder &&
                      item.fkArchive.id &&
                      type !== "Folder"
                    ) {
                      return (
                        <File
                          onClick={clickItem.bind(this, item)}
                          onDoubleClick={doubleClickItemC.bind()}
                          key={idx2}
                        >
                          <img
                            src={setFileType(item)}
                            className={classes.imgDocument}
                            alt=""
                          />
                          <HtmlTooltip
                            style={{ padding: "0 !important" }}
                            title={
                              <div style={{ padding: "0 !important" }}>
                                <div
                                  className={classes.titleTooltip}
                                  color="inherit"
                                >
                                  {item.fkArchive.name}
                                </div>
                              </div>
                            }
                          >
                            <TitleDocument classes={classes.titleShow}>
                              {item.fkArchive.name}
                            </TitleDocument>
                          </HtmlTooltip>
                        </File>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </WrapperFiles>
          </Grid>
        </Grid>
        {listDocumentsTotal > 0 && (
          <TablePagination
            component="div"
            count={listDocumentsTotal ? listDocumentsTotal : 0}
            page={mpage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <Drawer anchor="bottom" open={showOption} onClose={toggleDrawer(false)}>
          <OptionMenuDocPlace
            addComment={callAddComment.bind(this)}
            updateFile={callUpdateContent.bind(this)}
            click={optionMenu.bind(this)}
            item={item}
            permissions={permissions}
            isTrash={false}
          />
          <div onClick={toggleDrawer(false)} className={classes.closeOpt}>
            CLOSE
          </div>
        </Drawer>
        <ShareDocPlace
          share={callShare.bind(this)}
          itemShare={item}
          users={props.users}
          close={closeModal.bind(this)}
          show={showShare}
        />
        <RenameDocPlace
          rename={callRename.bind(this)}
          itemRename={item}
          close={closeModal.bind(this)}
          show={showRename}
        />
        <ReprocessDocPlace
          reprocess={callReprocess.bind(this)}
          itemReprocess={item}
          close={closeModal.bind(this)}
          show={showReprocess}
        />
        <MoveDocPlace
          treePrevius={props.treePrevius}
          move={callMove.bind(this)}
          tree={props.tree}
          close={closeModal.bind(this)}
          itemMove={item}
          show={showMove}
        />
        <NewFilterDocPlace
          done={addNewFilter.bind(this)}
          doctypeText={(value) => {
            setDoctypeText(value);
          }}
          close={closeModal.bind(this)}
          show={showFilter}
        />
      </Container>
    </div>
  );
};

export default SearchPageDocPlace;
