import React from "react";
import HeaderViewDocPlace from "./view";

const HeaderDocPlaceForms = ({
  getTitle,
  getDescription,
  type,
  title,
  description,
  deleteHeader,
  setting,
  getTitleHeader,
  getSubTitle,
  notSeeIntoPDF,
  notIntoPdfHeader,
  titleHeader,
  subTitleHeader,
}) => {
  const onChangeTitle = (value) => {
    getTitle(value.target.value);
  };
  const onChangeDescription = (value) => {
    getDescription(value.target.value);
  };

  const onChangeNotSeeIntoPDF = () => {
    notSeeIntoPDF((prevNotSeeIntoPDF) => !prevNotSeeIntoPDF);
  };

  const onChangeDelete = () => {
    deleteHeader(true);
  };

  const onChangeSetting = () => {
    setting(true);
  };

  const onChangeTitleHeader = (value) => {
    getTitleHeader(value.target.value);
  };

  const onChangeSubTitle = (value) => {
    getSubTitle(value.target.value);
  };

  return (
    <HeaderViewDocPlace
      type={type}
      title={title}
      description={description}
      titleHeader={titleHeader}
      subTitleHeader={subTitleHeader}
      onChangeTitle={onChangeTitle}
      onChangeDescription={onChangeDescription}
      onChangeNotSeeIntoPDF={onChangeNotSeeIntoPDF}
      onChangeDelete={onChangeDelete}
      onChangeSetting={onChangeSetting}
      onChangeTitleHeader={onChangeTitleHeader}
      onChangeSubTitle={onChangeSubTitle}
      notIntoPdfHeader={notIntoPdfHeader}
    />
  );
};

export default HeaderDocPlaceForms;
