import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useStyles } from "./style";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import TreeItems from "./tree";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { postNewFolder } from "../../../../store/explorer/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../../components/loading";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export function NewFolderDocPlace(props, prev) {
  const classes = useStyles();
  const [name, setName] = React.useState("");
  const [showNew, setShowNew] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const notify = (msj, event) => toast[event](msj);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { responseNewFolder, errorNewFolder, loading } = useSelector(
    (state) => ({
      responseNewFolder: state.Explorer.responseNewFolder,
      errorNewFolder: state.Explorer.errorNewFolder,
      loading: state.Explorer.loadingNewFolder,
    })
  );

  useEffect(() => {
    setShowNew(props.show);
  }, [props.show]);

  useEffect(() => {
    if (responseNewFolder && responseNewFolder.data) {
      props.newFolder(responseNewFolder);
      close();
    }
  }, [responseNewFolder]);

  const close = () => {
    setInvalidName(false);
    setName("");
    props.close(false);
  };

  const nameFolder = () => {
    var format = /[\\/<>:"|?*]/;
    if (format.test(name)) {
      //cmx
      setInvalidName(true);
    } else {
      setInvalidName(false);
      let obj = {
        customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
        name: name,
        parentArchiveId:
          props.treePrevius.length > 0
            ? props.treePrevius[props.treePrevius.length - 1].id
            : null,
      };
      dispatch(postNewFolder(obj, navigate));
    }
  };

  return (
    <BootstrapDialog
      onClose={() => {
        close();
      }}
      aria-labelledby="customized-dialog-title"
      open={showNew}
    >
      <LoadingComponent show={loading} text={"Creating New Folder..."} />
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          close();
        }}
      >
        Create a new Folder
      </BootstrapDialogTitle>
      <DialogContent style={{ width: "500px" }} dividers>
        <TextField
          style={{ width: "100%" }}
          id="name"
          error={invalidName}
          helperText={invalidName && "Invalid character for folder name"}
          variant="outlined"
          defaultValue={name}
          onChange={(e) => setName(e.target.value)}
          label="Name"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={close.bind(this)}
          className={classes.buttonModelCancel1}
        >
          CANCEL
        </Button>
        <Button
          variant="outlined"
          onClick={nameFolder.bind(this)}
          className={classes.buttonModelOk1}
        >
          SAVE
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default NewFolderDocPlace;
