export const REVIEWAL_LIST = "REVIEWAL_LIST"
export const REVIEWAL_LIST_SUCCESS = "REVIEWAL_LIST_SUCCESS"
export const DOC_PERCENTAGE_LIST = "DOC_PERCENTAGE_LIST"
export const DOC_PERCENTAGE_SUCCESS = "DOC_PERCENTAGE_SUCCESS"
export const DOC_SHARED_LIST = "DOC_SHARED_LIST"
export const DOC_SHARED_SUCCESS = "DOC_SHARED_SUCCESS"
export const DOC_PD_LIST = "DOC_PD_LIST"
export const DOC_PD_SUCCESS = "DOC_PD_SUCCESS"
export const API_ERROR_DOC_PD = "API_ERROR_DOC_PD"
export const API_ERROR_REVIEWAL = "API_ERROR_REVIEWAL"
export const API_ERROR_DOC_PERCENTAGE = "API_ERROR_DOC_PERCENTAGE"
export const API_ERROR_DOC_SHARED = "API_ERROR_DOC_SHARED"