import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
    useStyles,
} from './style';

import { Container } from "reactstrap";
import TitleDocPlace from "../../components/title/title";
import SearchDocPlace from "../../components/search/search";
import GridDocPlace from "./components/grid/grid";
import Button from '@mui/material/Button';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LoadingComponent from "../../components/loading";
import AddBoxIcon from '@mui/icons-material/AddBox';

import { getUserList, deleteUserList, getUserArchiveRoles } from "../../store/user/actions";
import NewItemDocPlace from "./components/new/new";
import { counter } from '@fortawesome/fontawesome-svg-core';

/**DOC-1768 general values to access through the different calls to the API */
let counterFillRoles =0;
let dataFillRoles = "";

const UserDocPlace = (props) => {
    const notify = (msj, event) => toast[event](msj);
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [startPage] = useState(true);
    const [dataRols, setDataRols] = useState([]);
    const [showNew, setShowNew] = React.useState(false);
    const [docTypesAPI, setDocTypesAPI] = useState([]);
    const [page] = useState(1);
    const [pageSize] = useState(1000);
    const [defaultData, setDefaultData] = React.useState('');


    const { responseUser, loadingUser } = useSelector(state => ({
        responseUser: state.User.responseUser,
        loadingUser: state.User.loadingUser,
    }))
    const { responseUserDelete, loadingUserDelete } = useSelector(state => ({
        responseUserDelete: state.User.responseUserDelete,
        loadingUserDelete: state.User.loadingUserDelete,
    }))
    const { responseUserArchiveRoles, loadingUserArchiveRoles } = useSelector((state) => ({  //DOCT-1768
        responseUserArchiveRoles: state.User.responseUserArchiveRoles,
        loadingUserArchiveRoles: state.User.loadingUserArchiveRoles,
    }));

    useEffect(() => {
        dispatch(getUserList({ page: page, pageSize: pageSize }, navigate))
    }, [startPage, page, pageSize])



    useEffect(() => {
        if (responseUser && responseUser.data) {
            dataFillRoles = [...responseUser.data]; //setup initial data
            dataFillRoles.forEach(element => { dispatch(getUserArchiveRoles(element?.id)) });  //DOC-1768 for each row make the request to backend to get the archive roles.
            setDataRols(responseUser.data);
            setDocTypesAPI(responseUser.data);

        }
    }, [responseUser])


    /**DOC-1768 this is a workaroung that is inneficient, we need to make changes in backend to get all the information in a single request. We consume the api for each result*/

    const fillRoles = ()=>{
        if(dataFillRoles && counterFillRoles < (dataFillRoles.length) ){
        let userID=dataFillRoles[counterFillRoles]?.id;
        if(userID)dispatch(getUserArchiveRoles(userID,navigate));
        }else{ //finish work
           setDataRols(dataFillRoles);
        }
    }

    useEffect(() => {
        if (responseUserArchiveRoles && responseUserArchiveRoles.data.length > 0) {
            //get the userID on response
            let rUserID = responseUserArchiveRoles.data[0].systemUserId;
            let position = -1;
            dataFillRoles.map((item, index) => { if (item.id == rUserID) { position = index; } });  //search the corresponding index for the userID in the array of users
            if (position >= 0) {  //if a match was found, fill out the data
                dataFillRoles[position].archiveRoles = responseUserArchiveRoles.data;
                let columnInfo = "";
                responseUserArchiveRoles.data.map((item) => { columnInfo = columnInfo.concat((columnInfo === "") ? item.archiveRole.name : ", " + item.archiveRole.name); });
                dataFillRoles[position].role = columnInfo;
            }
        }
    }, [responseUserArchiveRoles]);

    /**END DOC-1768 */


    useEffect(() => {
        if (responseUserDelete !== null) {
            dispatch(getUserList({ page: page, pageSize: pageSize }, navigate))
        }
    }, [responseUserDelete])


    const findDocumentTypes = (term) => {
        setDataRols(term
            ? docTypesAPI.filter(doc => (doc.fullName).toLowerCase().includes(term))
            : docTypesAPI);
    }

    const optionGrid = (value) => {
        if (value.action === 'edit') {
            setDefaultData(value.row.row);
            setShowNew(true);
        } else {
            deleteItem(value.id);
        }
    }

    const deleteItem = (id) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: 'Are you sure?',
            text: '',
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true
        }).then((willDelete) => {
            if (willDelete.value) {
                dispatch(deleteUserList(id, navigate))
            }
        });
    };

    const searchFile = (filterSearch) => {
        findDocumentTypes(filterSearch);
    }

    const refresh = () => {
        setDefaultData(null);
        dispatch(getUserList({ page: page, pageSize: pageSize }, navigate))
    }

    const closeModal = () => {
        setDefaultData(null);
        setShowNew(false);
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DocPlace Users</title>
                </MetaTags>
                <Container fluid>
                    <TitleDocPlace title={'Users'} viewActive={false} />
                    <ToastContainer autoClose={3000} />
                    <LoadingComponent show={loadingUser || loadingUserDelete} text={loadingUser ? "Getting Rols..." : 'Deleting Rol'} />
                    <SearchDocPlace
                        callSearch={searchFile.bind(this)}
                    />
                    <div className={classes.WrapperNew}>
                        <Button
                            onClick={() => {
                                setShowNew(true)
                            }}
                            style={{ marginTop: '20px' }}
                            size="small" color="primary"
                            variant="contained">
                            <AddBoxIcon />
                            <span>New</span>
                        </Button>
                    </div>
                    <GridDocPlace data={dataRols ? dataRols : []} option={optionGrid.bind(this)} />
                </Container>
            </div>
            <NewItemDocPlace
                done={refresh.bind(0, this)}
                close={closeModal.bind(this)}
                default={defaultData}
                show={showNew}
            />
        </React.Fragment>
    );
}

export default UserDocPlace;
