import React from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  ButtonContainer,
  ButtonContainerTools,
  Container,
  DivHelperTextTitle,
  useStyles,
  WrapperHeader,
} from "./style";
import { colors } from "../../../theme";

const HeaderViewDocPlace = (props) => {
  const classes = useStyles();

  return (
    <WrapperHeader>
      <Container>
        {props.type === "view" ? (
          <>
            <DivHelperTextTitle>{props.titleHeader}</DivHelperTextTitle>
            <h1 style={{lineHeight: '1'}}> {props.title} </h1>
            <p style={{lineHeight: '1', textAlign: 'justify'}}> {props.description} </p>
            <hr />
            <section className={classes.subtitle}>
              {props.subTitleHeader}
            </section>
          </>
        ) : (
          <>
            <ButtonContainer>
              <Button
                variant="text"
                onClick={props.onChangeNotSeeIntoPDF}
                style={{
                  textTransform: "none",
                  margin: "-12px 0 20px 0",
                  backgroundColor: props.notIntoPdfHeader && colors.gray3,
                }}
                startIcon={<VisibilityOffIcon />}
              >
                Not see into PDF
              </Button>
            </ButtonContainer>
            {/*<ContainerTextField>*/}
            {/*  <TextField*/}
            {/*    id="helpTextTitle"*/}
            {/*    placeholder="Click to Enter Title"*/}
            {/*    fullWidth={true}*/}
            {/*    variant="standard"*/}
            {/*    onChange={props.onChangeTitleHeader}*/}
            {/*    InputProps={{*/}
            {/*      disableUnderline: true,*/}
            {/*      style: {*/}
            {/*        fontSize: "12px",*/}
            {/*        fontWeight: "bold",*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</ContainerTextField>*/}
            <TextField
              className={classes.headerStyle}
              id="header"
              label="Header title"
              placeholder="Enter yout Title"
              variant="standard"
              fullWidth={true}
              required={true}
              onChange={props.onChangeTitle}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "2rem",
                  fontWeight: "bold",
                },
              }}
            />
            <TextField
              id="description"
              label="Header description"
              placeholder="Enter yout Description"
              variant="standard"
              fullWidth={true}
              required={true}
              onChange={props.onChangeDescription}
              InputProps={{
                disableUnderline: true,
              }}
            />
            {/*<ContainerTextField>*/}
            {/*  <TextField*/}
            {/*    id="helpTestDescription"*/}
            {/*    placeholder="Click to Enter Description"*/}
            {/*    fullWidth={true}*/}
            {/*    variant="standard"*/}
            {/*    onChange={props.onChangeSubTitle}*/}
            {/*    InputProps={{*/}
            {/*      disableUnderline: true,*/}
            {/*      style: {*/}
            {/*        fontSize: "12px",*/}
            {/*        fontWeight: "lighter",*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</ContainerTextField>*/}
            <ButtonContainerTools>
              <Button
                onClick={props.onChangeSetting}
                style={{
                  textTransform: "none",
                  marginRight: "20px",
                }}
                variant={"text"}
                startIcon={<SettingsSuggestIcon />}
              >
                Settings
              </Button>
              <Button
                onClick={props.onChangeDelete}
                style={{
                  textTransform: "none",
                }}
                color="error"
                variant={"text"}
                startIcon={<DeleteForeverIcon />}
              >
                Delete
              </Button>
            </ButtonContainerTools>
          </>
        )}
      </Container>
    </WrapperHeader>
  );
};

export default HeaderViewDocPlace;
