import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputDocPlace from "../../../components/form/input";
import SelectDropdownDocPlace from "../../../components/form/selectDropdown";
import { expressions } from "../../../components/theme";
import { useNavigate } from "react-router-dom";

const Step1 = (props) => {
  const emailErrorMessage = "Must be a valid email address";
  const countries = [{ label: "US citizen", value: "US" }]; //consume API in the future
  const [fullName, setFullName] = useState({
    value: props.data.fullName,
    error: false,
  }); //add full name
  const [nationality, setNationality] = useState({
    value: props.data.nationality,
    error: false,
  });
  const [email, setEmail] = useState({
    value: props.data.email,
    error: false,
    errorMessage: "",
  });
  const [nationalityList] = useState(countries);
  const [userID, setUserID] = useState("");

  const navigate = useNavigate();

  /**validate an go to the next step on the stepper */
  const handleSubmit = (e) => {
    if (nationality !== "") {
      //dispatch the api and go to the next step
      props.setData({
        fullName: fullName.value,
        nationality: nationality.value,
        email: email.value,
        userId: userID,
      });
      props.next();
    } else {
      //error
      toast.error("Some validations in the form didn't pass");
    }
    e.preventDefault();
  };
  /**get the userInformation of the logged user */
  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("docplace"));
    if (userInfo) {
      setFullName({ value: userInfo.fullName, error: false });
      setEmail({ value: userInfo.email, error: false });
      setUserID(userInfo.userId);
    }
  }, []);

  /**Back to previous page */
  const backPrevious = () => {
    navigate(-1);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box textAlign="center" sx={{ margin: "10px" }}>
        Let's start by creating an account for you
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} rowSpacing={3}>
          <Grid item xs={12}>
            <InputDocPlace
              id="fname"
              label="Full Name"
              field={fullName}
              setField={setFullName}
              type="text"
              required={true}
              disabled={true}
              expresionRegular={expressions.letterSpacing}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectDropdownDocPlace
              id={"nationalityL"}
              field={nationality}
              setField={setNationality}
              defaultList={nationality.value}
              required={true}
              label="Country of residence"
              arrayList={nationalityList}
              isMulti={false}
            />
          </Grid>
          {/*<Grid item xs={6}>*/}
          {/*  <DatePickerDocPlace*/}
          {/*    field={dateBirth}*/}
          {/*    setField={setBirth}*/}
          {/*    label="Date of Birth"*/}
          {/*    inputFormat="MM/dd/yyyy"*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item xs={6}>
            <InputDocPlace
              id="emailD"
              field={email}
              setField={setEmail}
              type="email"
              label="Email address"
              helperText={email.errorMessage}
              errorMessage={emailErrorMessage}
              expresionRegular={expressions.email}
              disabled={true}
              required={true}
            />
          </Grid>
          {/*<Grid item xs={6}>*/}
          {/*  <InputMask*/}
          {/*    mask='999-99-9999'*/}
          {/*    value={SSN.value}*/}
          {/*    onChange={(e) => { setSSN({ ...SSN, value: e.target.value }) }}*/}
          {/*    type="text"*/}
          {/*    label="SSN"*/}
          {/*    error={SSN.error}*/}
          {/*    required={true}*/}
          {/*    onKeyUp={(e) => {*/}
          {/*      if (expressions.SSN.test(SSN.value)) {*/}
          {/*        setSSN({ ...SSN, error: false });*/}
          {/*      } else {*/}
          {/*        setSSN({ ...SSN, error: true });*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {(inputProps) => <TextField {...inputProps} />}*/}
          {/*  </InputMask>*/}
          {/*</Grid>*/}
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="error"
              onClick={backPrevious}
              sx={{ mt: 2 }}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              sx={{ marginTop: "20px", float: "right" }}
              startIcon={<AddTaskIcon />}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Step1;
