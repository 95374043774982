import styled from 'styled-components';
import {colors} from '../../../../components/theme';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding: 15px 20px 20px 20px;
  margin-top: 28px;
  height: auto;
`;

export const Title = styled.div`
  width: 100%;
  height: 35px;
  background-color: #607d8b3d;
  color: ${colors.gray};
  margin-top: 21px;
  padding: 6px 0 0 12px;
  margin-bottom: 12px;
`;

export const TitleField = styled.h3`
  width: 100%;
  height: 40px;
  background-color: #1976d2;
  color: #ffffff;
  padding: 6px 0 0 15px;
  margin:0;
`;

export const ListAttributes = styled.ul`
  width: 50%;
  margin: 0 auto;
  color: ${colors.gray};
  padding: 6px 0 0 12px;
`;

export const ListAttributesLI = styled.li`
  width: 100%;
  height: 35px;
  display: inline-block;
  border-bottom: 1px dashed ${colors.gray};
  margin-top: 5px;
  position: relative;
  border-left: 3px solid #1976d2;
  padding: 3px 0px 0px 11px;
`;



export const useStyles = makeStyles((theme) => ({
    modeActive: {
        backgroundColor: '#006ac6',
        color: '#fff'
    },
    modeInactive: {
        backgroundColor: '#fff',
        color: '#006ac6'
    },
    iconSize:{
        fontSize: '22px'
    },
    removeField:{
        position: 'absolute',
        right: '0',
        cursor: 'pointer'
    }
  }));


  



