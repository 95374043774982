import React from "react";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import BackspaceIcon from "@mui/icons-material/Backspace";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { useStyles, Wrapper } from "./style";
import "./style.scss";

let valueS = "";

export function SearchDocPlace(props) {
  const classes = useStyles();
  const [searchField, setSearchField] = React.useState("");

  const searchItems = (term) => {
    props.callSearch(props.original ? term.trim() : term.toLowerCase().trim());
  };

  const handleChange = (e) => {
      setSearchField(e.target.value);
      searchItems(e.target.value);
      if(!e.target.value){
          clear();
      }
  }
  const handleMouseDown = () => {
      setSearchField("");
      searchItems(" ");
      clear();
}

  const clear = () => {
    if(props.clear){
      props.clear();
    }
  }

  const setDefaultValue = () => {
    return valueS;
  };

  return (
    <Wrapper>
      <article className="baseSearch">
        <FindInPageIcon className={classes.iconSearch} />
        <TextField
          type="text"
          value={searchField}
          onChange={handleChange}
          className={classes.inputSearch}
          placeholder="Search"
        />
        {searchField && (
          <Tooltip title="clear search" placement="left">
            <BackspaceIcon
              onClick={handleMouseDown}
              className={classes.buttonSearch}
              alt="Delete"
            />
          </Tooltip>
        )}
      </article>
    </Wrapper>
  );
}

export default SearchDocPlace;
