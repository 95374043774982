import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {colors} from "../../../../components/theme";

export const Wrapper = styled.div`
    width: 100%;
    height: calc(69vh);
    border-radius: 4px;
    margin-top: 20px;
    display: inline-block;
`;

export const WrapperTree = styled.div`
    width: 100%;
    height: calc(69vh);
    border-radius: 6px;
    background-color: #fff;
    display: inline-block;
    padding: 10px;
    overflow:auto;
`;

export const WrapperFiles = styled.div`
    width: 100%;
    height: calc(69vh);
    border-radius: 6px;
    background-color: #fff;
    display: inline-block;
    padding: 10px;
    text-align: center;
    position:relative;
    overflow-y: auto;
`;

export const WrapperBackTree = styled.article`
      width: auto;
      min-height: 30px;
      background-color: ${colors.ground};
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      font-weight: 100;
      text-align: left;
      padding-top: 5px;
      padding-left: 0px;
      margin-left: 15px;
      color: ${colors.Title2};
      padding-bottom: 7px;
`;

export const File = styled.div`
    width: 120px;
    height: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 5px;
    padding-top: 15px;
    &:hover {
        background-color: #eef5fb;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23199CE3FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        cursor:pointer;
    }
`;

export const FileNew = styled.div`
    width: 120px;
    height: 130px;
    background-color: #eef5fb;
    border-radius: 5px;
    &:hover {
        background-color: #eef5fb;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23199CE3FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        cursor:pointer;
    }
`;

export const FileNewUpload = styled.article`
    width: 120px;
    height: 130px;
    background-color: ${colors.green} ;
    border-radius: 5px;
    &:hover {
        background-color: ${colors.greenHover};
        border: 3px dashed ${colors.white};
        cursor:pointer;
    }
`;

export const TitleDocumentUpload = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${colors.white};
    margin-top: -15px;
    word-break: break-word;
    line-height: 1;
    padding: 0px 5px 2px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 24px;
`;

export const TitleDocument = styled.div`
    width: 100%;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: center;
    color: #006ac6;
    margin-top: -15px;
    word-break: break-word;
    line-height: 1;
    padding: 0px 5px 2px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 24px;
`;


export const TreeUl = styled.ul`
    list-style: none;
    margin: 0px 0px 0px 0px;
    padding:0;
`;

export const TreeLi = styled.li`
    margin: 20px 0 10px 20px;
    min-width: 150px;
    cursor:pointer;
    border-radius: 5px;
    &:hover {
      background-color: #1976d22e;
    }
`;

export const TreeLiText = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#006ac6;
    display: inline-block;
    margin-top: 9px;
    margin-left: -4px;
    max-width: 75%;
`;

export const WrapperMenuOption = styled.div`
    width: 100%;
    background-color: #eff3f7;
`;

export const MenuOptionFloder = styled.div`
    height: 250px;
    text-align: center;
    padding-top: 51px;
`;

export const MenuOptionFile = styled.div`
    height: 350px;
    text-align: center;
    padding-top: 51px;
`;

export const MenuOptionFile2 = styled.div`
    height: 350px;
    text-align: center;
    padding-top: 30px;
`;

export const MenuOptionFloderTitle = styled.h4`
    color: #006ac6;
    margin-top: 12px;
    margin-bottom: 17px;
    font-size: 20px;
    width: auto;
    text-align: center;
    padding-left: 17px;
    padding-right: 17px;
    line-height: 1;
    overflow: hidden;
  
`;

export const MenuOptionFloderUl = styled.ul`
      list-style: none;
      margin: 25px 0 0 0;
      border-left: 1px solid #9e9e9e87;
      height: 200px;
      padding: 53px 0px 0 15px;
`;

export const MenuOptionFileUl = styled.ul`
      list-style: none;
      margin: 46px 0 0 0;
      height: 280px;
      font-size: 13px;
      padding: 0px 0px 0 15px;
      overflow-y: auto;
`;

export const MenuOptionFloderLi = styled.li`
    font-weight: 900;
    min-height: 29px;
`;

export const MenuOptionFloderMenuUl = styled.ul`
      list-style: none;
      margin: 30px 0 0 0;
      border-left: 1px solid #9e9e9e87;
      height: 303px;
      padding: 8px 0px 0 15px;
`;

export const AttributeWrapper = styled.article`
      width: 100%;
      height: 238px;
      overflow-y: auto;
`;

export const CommentsWrapper = styled.article`
      width: 100%;
      height: 238px;
      overflow-y: auto;
`;

export const AttributeWrapperUL = styled.ul`
      list-style: none;
      margin:0;
      padding: 0;
      overflow-y: hidden;
`;

export const AttributeWrapperLI = styled.li`
      margin: 0;
      padding: 0;
      min-height: 40px;
      padding-top: 8px;
      font-size: 13px;
      font-size: 13px;
      background-color: #fff;
      border-bottom: 1px solid #607d8b;
`;

export const MessageWrapperUL = styled.ul`
      list-style: none;
      margin:0;
      padding: 0;
      overflow-y: hidden;
`;

export const MessageWrapperLI = styled.li`
      margin: 0;
      padding: 0 8px 8px 8px;
      min-height: 40px;
      padding-top: 8px;
      font-size: 13px;
      font-size: 13px;
      background-color: #fff;
      border-bottom: 1px solid #607d8b;
`;

export const CommentContent = styled.div`
      color:#000;
      font-weight: 100;
      font-size: 14px;
`;

export const CommentDate = styled.div`
      color:#006ac6;
      font-weight: 500;
      text-align: right;
`;

export const CommentUser = styled.div`
      color:#006ac6;
      font-weight: 500;
      text-align: left;
      font-size: 16px;
`;


export const AttributeWrapperEDLI = styled.li`
      padding: 0px 7px 7px 7px;
      margin-bottom: 6px;
      height: 55px;
      margin-top: 20px;
`;

export const MenuOptionFloderMenuLi = styled.li`
    font-weight: 900;
    height: 29px;
`;

export const MenuOptionFloderMenuTitle = styled.div`
      font-size: 2em;
      color: #607d8b;
      text-align: center;
`;

export const MenuOptionFloderMenuNew = styled.div`
      text-align: left;
      height: 30px;
      margin-top: 10px;
      padding-top: 7px;
      padding-left: 16px;
      color: #006ac6;
      cursor:pointer;
      &:hover {
        background-color: #88bdf30d;
      }
`;

export const CloseTxtUpload = styled.div`
      font-size: 14px;
      margin-left: 7px;
`;

export const CopyLinkContainer = styled.div`
    width: 100%;
    display: flex;
    -webkit-box-pack: end;
    justify-content: space-around;
    align-items: flex-end;
    flex-direction: column;
`;


export const useStyles = makeStyles((theme) => ({
    float_center: {
        display: 'grid',
        gridGap: '14px',
        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
        gridAutoRows: '120px'
    },
    clearRow: {
        padding: '0',
        margin: '0'
    },
    titleTooltip:{
        backgroundColor:"#1876d2",
        color: "#fff !important" ,
        width: '100%',
        height: 'auto',
        padding: '10px',
        fontSize: '13px !important',
        wordBreak: 'break-all !important',
        lineHeight: '1 !important'
    },
    imgDocument: {
        width: '60px',
        height: '76px',
        margin: '14px auto'
    },
    imgFolder: {
        width: '85px',
        height: '76px',
        margin: '14px auto'
    },
    imgFolderGray: {
        width: '85px',
        height: '76px',
        margin: '14px auto'
    },
    iconUploadFile: {
        color: '#fff',
        fontSize: '3rem !important',
        margin: '20px 0px 12px 0px'
    },
    imgNewFolder: {
        width: '32px',
        height: '32px',
        margin: '31px 0px 36px 0px'
    },
    imgFolderTree: {
        width: '20px',
        height: '18px',
        margin: '0px 12px 0px 0px'
    },
    imgArrowTree: {
        width: '8px',
        height: '12px',
        margin: '0px 12px 0px 0px'
    },
    closeFolder:{
        height: '25px',
        overflow: 'hidden'
    },
    openFolder:{
        height: '100%',
        overflow: 'auto'
    },
    spanText:{
        fontWeight: '100',
        marginLeft: '15px',
        wordBreak: 'break-all'
    },
    iconMenuOPT: {
        float: 'left',
        marginTop: '4px',
        marginRight: '20px'
    },
    iconGreen: {
        color: '#4caf50'
    },
    iconBlue: {
        color: '#006ac6'
    },
    iconRed: {
        color: '#eb0050'
    },
    iconYellow: {
        color: '#ff9800'
    },
    closeOpt: {
        top: '11px',
        left: '11px',
        color: 'white',
        width: '65px',
        cursor: 'pointer',
        height: '31px',
        padding: '5px 0 0 8px',
        zIndex: '9999',
        position: 'absolute',
        fontSize: '14px',
        backgroundColor: '#006ac6',
        borderRadius: '4px'
    },
    imgFile: {
        height: '235px',
        margin: '0 auto',
        objectFit: 'contain'
    },
    buttonModelOk: {
        float: 'right',
        marginTop: '3px !important',
        marginRight: '4px !important',
        backgroundColor: '#006ac6 !important',
        height: '38px',
        paddingTop: '7px',
        color: '#fff !important'
    },
    buttonModelCancel: {
        float: 'left',
        marginTop: '3px !important',
        marginRight: '15px !important',
        backgroundColor: 'red !important',
        height: '38px',
        paddingTop: '7px',
        color: '#fff !important'
    },
    buttonModelCopy: {
        float: 'left',
        marginTop: '3px !important',
        backgroundColor: '#4caf50 !important',
        height: '38px',
        paddingTop: '7px',
        color: '#fff !important'
    },
    attributeContent:{
        fontSize: '13px',
        wordBreak: 'break-all',
        color: '#607d8b',
        lineHeight: '1',
        paddingBottom: '6px',
        overflow: 'hidden'
    },
    attributeTitle:{
        color: '#006ac6',
        fontWeight: '900',
        lineHeight: '1',
        fontSize: '14px',
        height: '28px',
        overflow: 'hidden',
        paddingLeft: '30px !important',
        paddingTop: '0px !important',
        marginTop: '20px !important'
    },
    attributeRowColor:{
        backgroundColor: '#00b0ff05 !important'
    },
    attributeEdit:{
        height: '28px',
        padding: '3px',
        fontSize: '14px',
        backgroundColor: '#006ac6',
        marginBottom: '18px',
        display: 'block',
        width: '80px',
        color: '#fff',
        fontWeight: '900',
        cursor: "pointer"
    },
    attributeSave:{
        height: '28px',
        padding: '3px',
        fontSize: '14px',
        backgroundColor: '#006ac6',
        marginBottom: '18px',
        display: 'block',
        float: "right",
        width: '80px',
        color: '#fff',
        fontWeight: '900',
        cursor: "pointer",
        marginRight: '10px'
    },
    attributeCancel:{
        height: '28px',
        padding: '3px',
        fontSize: '14px',
        backgroundColor: 'red',
        marginBottom: '18px',
        display: 'block',
        float: "left",
        width: '80px',
        color: '#fff',
        fontWeight: '900',
        cursor: "pointer",
        marginLeft: '10px'
    },backArrowTree:{
        float: 'left',
        margin: '-2px 0px 0px 8px'
    },
    btnCancel:{
        backgroundColor: '#bb2929 !important',
        color:'#fff !important',
        float:'left'
    },
    btnDelete:{
        backgroundColor: '#006ac6 !important',
        color:'#fff !important',
        float:'right'
    },
    selectedFile:{
        backgroundColor: '#eef5fb',
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23199CE3FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`,
        cursor:'pointer'
    }

}));






