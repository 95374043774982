import {
  GET_ALL_FORMS_REQUESTED,
  GET_ALL_FORMS_REQUESTED_CLEAR_DATA,
  GET_ALL_FORMS_REQUESTED_ERROR,
  GET_ALL_FORMS_REQUESTED_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorGetAllFormsRequested: null,
  loadingGetAllFormsRequested: false,
  responseGetAllFormsRequested: null,
};

const AllFormsRequested = (state = initialState, action) => {
  switch (action.type) {
    // get all forms requested
    case GET_ALL_FORMS_REQUESTED:
      state = {
        ...state,
        errorGetAllFormsRequested: null,
        loadingGetAllFormsRequested: true,
      };
      break;
    case GET_ALL_FORMS_REQUESTED_SUCCESS:
      state = {
        ...state,
        errorGetAllFormsRequested: null,
        loadingGetAllFormsRequested: false,
        responseGetAllFormsRequested: action.payload,
      };
      break;
    case GET_ALL_FORMS_REQUESTED_ERROR:
      state = {
        ...state,
        errorGetAllFormsRequested: action.payload,
        loadingGetAllFormsRequested: false,
        responseGetAllFormsRequested: null,
      };
      break;
    case GET_ALL_FORMS_REQUESTED_CLEAR_DATA:
      state = {
        ...state,
        errorGetAllFormsRequested: null,
        loadingGetAllFormsRequested: false,
        responseGetAllFormsRequested: null,
      };
      break;
    // ****---END---****
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AllFormsRequested;
