import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";

import TitleDocPlace from "../../components/title/title";
import UploadFileRoRequestSignature from "./resource/uploadFile/uploadFile";
import GridBoxMail from "./resource/grid/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailFolderList,
  getSearchArchivesList,
} from "../../store/document/actions";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/loading";
import SearchDocPlace from "../../components/search/search";

const MailBoxSignatures = () => {
  const [detailFolder, setDetailFolder] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(9);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [textSearch, setTextSearch] = useState(null);

  const { responseDetailFolder, errorDetailFolder, loadingDetailFolder } =
    useSelector((state) => ({
      responseDetailFolder: state.DetailFolder.responseDetailFolder,
      errorDetailFolder: state.DetailFolder.errorDetailFolder,
      loadingDetailFolder: state.DetailFolder.loadingDetailFolder,
    }));

  const { responseSearchArchives, errorSearchArchives, loadingSearch } =
    useSelector((state) => ({
      responseSearchArchives: state.DetailFolder.responseSearchArchives,
      errorSearchArchives: state.DetailFolder.errorSearchArchives,
      loadingSearch: state.DetailFolder.loadingSearchArchives,
    }));

  let filterSearch;

  const searchFile = (_filterSearch) => {
    setTextSearch(_filterSearch);
    if (_filterSearch) {
      filterSearch = _filterSearch;
      let obTerm = {
        filters: [],
        fullTextSearch: { text: _filterSearch, fields: [] },
      };

      dispatch(
        getSearchArchivesList(
          {
            parentArchiveId: "",
            page: page,
            pageSize: pageSize,
            obTerm,
          },
          navigate
        )
      );
    } else {
      dispatch(
        getDetailFolderList(
          {
            parentArchiveId: "",
            page: page,
            pageSize: pageSize,
          },
          navigate
        )
      );
    }
  };

  useEffect(() => {
    let newObject = [];
    if (responseSearchArchives) {
      responseSearchArchives?.data?.map((documents, i) => {
        newObject.push({ ...documents, id: documents.id });
      });
      setDetailFolder(newObject);
      setRowCount(responseSearchArchives?.header?.totalRecords);
    }
  }, [responseSearchArchives]);

  useEffect(() => {
    let newObject = [];
    if (responseDetailFolder) {
      responseDetailFolder?.data?.map((documents, i) => {
        newObject.push({ ...documents, id: documents.id });
      });
      setDetailFolder(newObject);
      setRowCount(responseDetailFolder?.header?.totalRecords);
    }
  }, [responseDetailFolder]);

  useEffect(() => {
    if (!responseDetailFolder) {
      dispatch(
        getDetailFolderList(
          {
            parentArchiveId: "",
            page: page,
            pageSize: pageSize,
          },
          navigate
        )
      );
    }
  }, []);

  const changePage = (page) => {
    let p = page + 1;
    setPage(p);
    if (textSearch) {
      let obTerm = {
        filters: [],
        fullTextSearch: { text: textSearch, fields: [] },
      };
      dispatch(
        getSearchArchivesList(
          {
            parentArchiveId: "",
            page: p,
            pageSize: pageSize,
            obTerm,
          },
          navigate
        )
      );
    } else {
      dispatch(
        getDetailFolderList(
          {
            parentArchiveId: "",
            page: p,
            pageSize: pageSize,
          },
          navigate
        )
      );
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <TitleDocPlace title={"Documents for signature"} viewActive={false} />
        <LoadingComponent
          show={loadingDetailFolder || loadingSearch}
          text={"Obtaining documents..."}
        />
        <UploadFileRoRequestSignature page={page} pageSize={pageSize} />
        <SearchDocPlace original={false} callSearch={searchFile.bind(this)} />
        <GridBoxMail
          rowCount={rowCount}
          changePage={changePage}
          rows={detailFolder}
          pageSize={pageSize}
        />
      </Container>
    </React.Fragment>
  );
};

export default MailBoxSignatures;
