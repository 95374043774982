import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useStyles } from './style'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DateRangePickerDocPlace = ({ field, setField, startDateText, endDateText, helperText, required, id, execute, displayMessage = true }) => {
    const notify = (msj, event) => toast[event](msj);
    const classes = useStyles();

    const isRangeValid = (date1, date2) => {
        if ((date1 && date2) && date1.getTime() > date2.getTime()) {
            notify("Start Date is more recent than End Date", "error");
            return false;
        }
        return true;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Box sx={{ marginRight: '10px',  width: '100%'}}>
                    <MobileDatePicker
                        id={id + "-1"}

                        label={startDateText}
                        value={field.value[0]}
                        error={field.error}
                        required={required}
                        onChange={(newValue) => {
                            setField({ ...field, value: [newValue, field.value[1]], error: !isRangeValid(newValue, field.value[1]) });
                        }}
                        renderInput={(params) => <TextField sx={{width: '100%'}}  {...params} />}
                        onAccept={()=>{if(execute)execute();}}
                    />
                </Box>
                <Box sx={{width: '100%'}}>
                    <MobileDatePicker
                        id={id + "-2"}
                        label={endDateText}
                        error={field.error}
                        value={field.value[1]}
                        required={required}
                        onChange={(newValue) => {
                            setField({ ...field, value: [field.value[0], newValue], error: !isRangeValid(field.value[0], newValue) });
                        }}
                        renderInput={(params) => <TextField sx={{width: '100%'}} {...params} />}
                        onAccept={()=>{if(execute)execute();}}
                    />
                </Box>
            </Box>
           {displayMessage && <p className={classes.helperText}>{helperText}</p>}
        </LocalizationProvider>
    )
}


export default DateRangePickerDocPlace