import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import { Container } from "reactstrap";
import SearchDocPlace from "../../components/search/search";
import { toast, ToastContainer } from "react-toastify";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import Grid from "@mui/material/Grid";
//API CALL
import { getSearchForm } from "../../store/search/actions";
//END

import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../components/title/title";
import Template from "./components/template/template";


import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/loading";
import {
  useStyles,
  SquareBox,
  BoxTitle,
  Message
} from "./style";




import { WrapperFiles } from "../Explorer/components/files/style";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { uploadTemplate } from "../../services/FileUploadService";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));


const TrashBin = (props) => {
  const notify = (msj, event) => toast[event](msj);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startPage] = useState(true);

  const [page, setPage] = useState(1);
  const [mpage, setMPage] = useState(0);

  const [pageSize, setPageSize] = useState(50);
  const [valueSearch, setValueSearch] = useState("");
  const [item, setItem] = React.useState({});
  const [listDocuments, setListDocuments] = useState([]);
  const [listDocumentsTotal, setListDocumentsTotal] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [upDocType, setUpDocType] = React.useState({});
  const [loadingSave, setLoadingSave] = React.useState(false);
  
  /* Modal */
  const [open, setOpen] = React.useState(false);

  const { responseSearchForm, errorSearchForm, loading } = useSelector(
    (state) => ({
      responseSearchForm: state.Search.responseSearchForm,
      errorSearchForm: state.Search.errorSearchForm,
      loading: state.Search.loadingSearchForm,
    })
  );
  const [totalFiles, setTotalFiles] = React.useState([]);
  const [updateAllFiles, setUpdateAllFiles] = React.useState(false);
  
  const updateFile = (files) => {
    setTotalFiles(files);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    p: 4,
  };
  const openModal = () => {

    setOpen(true);
  };
  const closeModal = () => setOpen(false);


useEffect(() => {
  if (startPage) {
    getSearchFile(true);
  }
}, [startPage]);
  useEffect(() => {
    if (responseSearchForm?.data) {
      setListDocuments(responseSearchForm.data);
      setListDocumentsTotal(responseSearchForm.header.totalRecords);
    }
  }, [responseSearchForm]);

  const getSearchFile = (init) => {
    if (init == true) {
      let obj = {
        text: "",
        isDeleted: true,
     };

     dispatch(
       getSearchForm(
         {
           page: page,
           pageSize: pageSize,
           data: obj
         },
         navigate
       )
     );
    } else {
    if (valueSearch) {
      let obj = {
         text: valueSearch.toLowerCase(),
         isDeleted: true,
      };

      dispatch(
        getSearchForm(
          {
            page: page,
            pageSize: pageSize,
            data: obj,
          },
          navigate
        )
      );
    } else {
      notify("Enter a value", "error");
    }
  }
  };
  const getSearchResetFile = () => {
    window.location.reload(true);
  };

  const setSearchFile = (filterSearch) => {
    setValueSearch(filterSearch.trim());
  };

  const handleChangePage = (event, newPage, mrowsPerPage = 50) => {
    //let newPage = props.page;
    //newPage = objs.length <= 1 ? 1 : newPage + 1;
    
    if (mrowsPerPage == 1) {
      mrowsPerPage = 50;
    }

    setMPage(newPage);

    let obj = {
      filters: [],
      fullTextSearch: {
        text: valueSearch.toLowerCase(),
        isDeleted: true,
        fields: [],
      },
      daysAgo: 0,
      minsAgo: 0,
    };

    dispatch(
      getSearchForm(
        {
          page: newPage + 1,
          pageSize: mrowsPerPage,
          data: obj,
        },
        navigate
      )
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleChangePage(null, 0, parseInt(event.target.value, 10));

    setPage(0);
  };

  const doLoading = (value) => {
    setLoadingSave(value);
  };
  const saveUpload = () => {
    setLoadingSave(true);
    totalFiles.map((file) => {
      //setUploaded(true);
      //setMessageText("Uploading document");
      uploadTemplate(
        file,
        true,
        null,
        null,
        true,
        (event) => {
          //setLoading8(true);
        }
      )
        .then((response) => {
         
          notify(file.name + " u" + "ploaded", "success");
          //setCloseUploadFiles(true);
          closeModal();
          setTotalFiles([]);
          getSearchFile(true);
        })
        .catch((err) => {
         
          closeModal();
          toast.error(err?.response?.data?.error?.messages[0].message);
        })
        .finally(() => {
          setLoadingSave(false);
        });
    });

   // setUpDocType({});
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>DocPlace Forms Trash Bin </title>
      </MetaTags>
      <Container fluid>
        <Title title={"Trash Bin"} viewActive={false} />
        <ToastContainer autoClose={3000} />
        <LoadingComponent
          show={loading || loadingSave }
          text={"Processing..."}
        />
        <SearchDocPlace
          original={true}
          callSearch={setSearchFile.bind(this)}
          clear={() => {
            // refresh("");
          }}
        />
        <Button
          onClick={getSearchFile.bind(this)}
          className={classes.btnSave}
          startIcon={<ContentPasteSearchIcon />}
          variant="contained"
          color="success"
        >
          SEARCH
        </Button>
        <Button
          onClick={getSearchResetFile.bind(this)}
          className={classes.btnReset}
          startIcon={<ContentPasteOffIcon />}
          variant="contained"
          color="success"
        >
          RESET SEARCH
        </Button>

        {listDocuments ? (
          listDocuments.length > 0 && (
            <div className="docFound">
              {" "}
              Templates found: {listDocumentsTotal
                ? listDocumentsTotal
                : 0}{" "}
            </div>
          )
        ) : (
          <div className="docFound"> 0</div>
        )}
        {listDocumentsTotal > 0 && (
          <TablePagination
            component="div"
            count={listDocumentsTotal ? listDocumentsTotal : 0}
            page={mpage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <WrapperFiles id="scrollableDiv">
              <div className={classes.float_center}>
                {listDocuments &&
                  listDocuments?.map((item, idx) => {
                    return (

                      <Template
                        title={item.pathUrl[0].fileOriginalName}
                        idx={idx}
                        key={idx}
                        id={item.id}
                        loading={doLoading.bind(this)}
                        published={item.published}
                        shared={item.shared}
                        updateTemplates={()=>getSearchFile(true)}
                      />
                    )

                  })}
              </div>
            </WrapperFiles>
          </Grid>
        </Grid>
        {listDocumentsTotal > 0 && (
          <TablePagination
            component="div"
            count={listDocumentsTotal ? listDocumentsTotal : 0}
            page={mpage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

      </Container>

      
    </div>
  );
};

export default TrashBin;
