import React, {useEffect, useState} from "react";

export function DocumentListDocPlace(props) {

    const [documents, setDocuments] = useState([]);
    const [selected, setSelected] = useState();
    const inputRef = React.useRef(null)
    const [ocr, setOcr] = useState([]);

    useEffect(() => {
        setDocuments(props.documents);


    }, [props.documents]);

    useEffect(() => {
        if (documents && documents.length > 0) {
            setTimeout(() => {
                inputRef.current.click()
            }, 500);

        }
    }, [documents]);


    useEffect(() => {
        if (props.deleteOCR === true) {
            setSelected('')
            setOcr([])
        }
    }, [props.deleteOCR]);

    const ocrDoc = (doc, id) => {
        if (!ocr.includes(id)) {
            setOcr((regions) => [
                ...regions, id])
        }

        setSelected(doc);

        props.getDocument(doc, id, doc.fileName, doc.path, doc.ocr, doc, doc.fileOriginalName)
    };

    return (
        <>
            <div className="documentList">
                {documents.map((doc, i) => (


                    <div
                        key={i}
                        style={{ width: '100%' }}
                        className={selected === doc ? 'documentSelected' : ''}
                        onClick={() => ocrDoc(doc, i)}
                        ref={(i === 0) ? inputRef : null}
                    >
                        <img className="docImage" title={`Click to select`} src={doc} alt={doc.fileName} />
                        <div className="documentTitle" ><span className="number" >Page {i + 1}</span> </div>
                    </div>

                ))}
            </div>
        </>
    )
}

export default DocumentListDocPlace

