import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "./style";
import { uploadFile } from "../../services/FileUploadService";
import Dropzone from "react-dropzone";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import fileExcel from "../../assets/file-excel.png";
import fileWord from "../../assets/file-word.png";
import fileZip from "../../assets/file-zip.png";
import filePdf from "../../assets/file-pdf.png";
import fileDoc from "../../assets/file-doc.png";
import fileImage from "../../assets/file-jpg.png";
import fileOther from "../../assets/file-other.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

export function FileUploadDocPlace(props) {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFiles, setCurrentFiles] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [fileInfos, setFileInfos] = useState([]);

  useEffect(() => {
    props.uploadF(selectedFiles);
  }, [selectedFiles]);

  const upload = () => {
    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push(selectedFiles[i]);
    }

    props.uploadF(_progressInfos);
  };

  const remove = (file) => {
    const newFiles = [...selectedFiles]; // make a var for the new array
    newFiles.splice(file, 1); // remove the file from the array
    setSelectedFiles(newFiles); // update the state
  };

  const onDrop = (files) => {
      let newArray=[];
    if (files.length > 0) {
      files.map((item, idx) => {
        if (item.size > 100000000) {
          toast.warning("The file: " + item.name + " exceeds the maximun allowed size (100Mb).");
          item.splice(idx, 1);
         } 
      })
      newArray = selectedFiles.concat(files);
      setSelectedFiles(newArray);
    } 
    props.uploadF(selectedFiles);
  };
  const dropzoneRef = React.createRef();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <div>
      {currentFiles && (
        <div className="progress mb-3">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}

      <Dropzone onDrop={onDrop} ref={dropzoneRef} noClick noKeyboard>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} />
              <button
                type="button"
                onClick={openDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 0,
                  zIndex: 99999,
                }}
              >
                Drag and drop file here
              </button>

              {selectedFiles && selectedFiles.length ? (
                <div>
                  {selectedFiles.length > 10
                    ? `${selectedFiles.length} files`
                    : selectedFiles.map((file, index) => (
                      <div className={classes.dropBox} key={index}>
                        <img
                          alt={"DocPlace"}
                          src={
                            file.name.search("xls") > 0 ||
                              file.name.search("xlsx") > 0
                              ? fileExcel
                              : file.name.search("doc") > 0 ||
                                file.name.search("docx") > 0
                                ? fileWord
                                : file.name.search("zip") > 0 ||
                                  file.name.search("rar") > 0
                                  ? fileZip
                                  : file.name.search("pdf") > 0
                                    ? filePdf
                                    : file.name.search("txt") > 0
                                      ? fileDoc
                                      : file.name.search("jpeg") > 0 ||
                                        file.name.search("jpg") > 0 ||
                                        file.name.search("jfif") > 0 ||
                                        file.name.search("png") > 0 ||
                                        file.name.search("pjpeg") > 0 ||
                                        file.name.search("gif") > 0 ||
                                        file.name.search("gif") > 0
                                        ? fileImage
                                        : fileOther
                          }
                        />
                        <div style={{ lineHeight: "1" }}>{file.name}</div>
                        <div style={{ fontWeight: "9000" }}>
                          {(file.size / 1000000).toFixed(2)} MB
                        </div>
                        <IconButton
                          aria-label="close"
                          onClick={() => remove(index)}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 0,
                            zIndex: 999,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

export default FileUploadDocPlace;
